<template>
  <div class='editing-access'>
    <h2 class='mb-2 text-lg font-semibold uppercase text-gray-800'>{{ $t('access') }}</h2>
    <el-switch
      v-model='shared'
      :active-text="$t('public')"
      :inactive-text="$t('private')">
    </el-switch>
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'DashboardViewEditingAccess',
  computed: {
    ...mapFields('dashboardViews', [
      'currentDashboardView.shared'
    ]),
  },
}
</script>
