import base from './base'
import axios from 'axios'

const heavyBase = axios.create({
  baseURL: `${process.env.VUE_APP_FILES_BASE_URL}/api/v1`,
  headers: {
    Authorization: {
      toString () {
        return window.localStorage.getItem('access_token.bbridge_reach')
      }
    },
  }
})

const archiveInvestment = (investmentId) => base
  .put(`/investments/${investmentId}/archive`).then(resp => resp.data)

const unarchiveInvestment = (investmentId) => base
  .put(`/investments/${investmentId}/unarchive`).then(resp => resp.data)

const deleteInvestment = (investmentId) => base
  .delete(`/investments/${investmentId}`).then(resp => resp.data)

const getInvestments = (getInvestmentParams) => base
  .get(`/investments/`, { params: getInvestmentParams }).then(resp => resp.data)

const getBillable = (getInvestmentParams) => base
  .get(`/investments/billable`, { params: getInvestmentParams }).then(resp => resp.data)

const getInvestmentDrilldownOverview = (investmentId) => base
  .get(`/investments/investment_drilldown_overview?investment_id=${investmentId}`).then(resp => resp.data)

// needs to be a poast because of the way we are sending filter params in the body
const getFilteredInvestments = (getInvestmentParams) => base
  .post(`/investments/filtered`, getInvestmentParams ).then(resp => resp.data)

const getRequiredMissingInvestments = (caseParam) => heavyBase
  .get(`/investments/required_missing`, { params: caseParam }).then(resp => resp.data)

const getInvestmentSingleCustomFieldValue = (investmentId, customFieldId) => base
  .get(`/investments/${investmentId}/get_custom_field_value?custom_field_template_id=${customFieldId}`).then(resp => resp.data)

const getInvestmentsSearch = () => base
  .get(`/investments/get_investments_search`).then(resp => resp.data)

const getMyInvestmentsSearch = () => base
  .get(`/investments/get_my_investments_search`).then(resp => resp.data)

const getInvestmentCard = (investmentId, dashboardViewId) => base
  .get(`/investments/${investmentId}?dashboard_view_id=${dashboardViewId}&mode=card`).then(resp => resp.data)

const getInvestmentInfo = (investmentId, dashboardViewId) => base
  .get(`/investments/${investmentId}?dashboard_view_id=${dashboardViewId}&mode=detail`).then(resp => resp.data)

const postInvestment = (investmentParams, dashboardViewId) => base
  .post(`/investments/?dashboard_view_id=${dashboardViewId}`, investmentParams).then(resp => resp.data)

const searchInvestments = (searchParams, page) => base
  .get(`/investments/search_by_text/?search_text=${searchParams.search_text}&search_at=${searchParams.search_at}&in_progress=${searchParams.in_progress}&page=${page}`).then(resp => resp.data)

const searchInvestmentsWithKeys = (searchParams, page) => {
  const params = new URLSearchParams()

  Object.entries(searchParams).forEach(([key, value]) => {
    if (value !== undefined) {
      params.append(key, value)
    }
  })

  return base.get(`/investments/search_by_text_with_keys/?${params.toString()}&page=${page}`).then(resp => resp.data)
}

const updateInvestment = (investmentId, investmentParams, dashboardViewId) => base
  .patch(`/investments/${investmentId}?dashboard_view_id=${dashboardViewId}`, investmentParams).then(resp => resp.data)

const updateDashboardPositions = (params, dashboardViewId) => base
  .patch(`/dashboard_group_by_positions/update_positions?dashboard_view_id=${dashboardViewId}`, params).then(resp => resp.data)

const getInvestmentReviewBoardReports = (investmentId) => base
  .get(`/investments/investment_review_board_report/?investment_id=${investmentId}`).then(resp => resp.data)

const getCasenumYears = () => base
  .get(`/investments/casenum_years`, {}).then(resp => resp.data)

const createMsFolder = (investmentId) => base
  .patch(`/investments/${investmentId}/create_ms`).then(resp => resp.data)

const getInvestmentAttachments = (filters) => base
  .get('/investment_attachments/', { params: filters }).then(resp => resp.data)

const postInvestmentAttachment = (attachmentParams, contentType='multipart/form-data') => base
  .post('/investment_attachments/', attachmentParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const deleteInvestmentAttachment = (investmentAttachmentId) => base
  .delete(`/investment_attachments/${investmentAttachmentId}`).then(resp => resp.data)

const getMyCases = () => base.get('/investments/from_my_timesheets_or_custom_fields').then(resp => resp.data)

export default {
  archiveInvestment: archiveInvestment,
  deleteInvestment: deleteInvestment,
  getInvestments: getInvestments,
  getBillable: getBillable,
  getFilteredInvestments: getFilteredInvestments,
  getRequiredMissingInvestments: getRequiredMissingInvestments,
  getInvestmentsSearch: getInvestmentsSearch,
  getMyInvestmentsSearch: getMyInvestmentsSearch,
  getInvestmentCard: getInvestmentCard,
  getInvestmentInfo: getInvestmentInfo,
  postInvestment: postInvestment,
  searchInvestments: searchInvestments,
  searchInvestmentsWithKeys: searchInvestmentsWithKeys,
  updateInvestment: updateInvestment,
  updateDashboardPositions: updateDashboardPositions,
  getInvestmentReviewBoardReports: getInvestmentReviewBoardReports,
  getInvestmentDrilldownOverview: getInvestmentDrilldownOverview,
  unarchiveInvestment: unarchiveInvestment,
  createMsFolder: createMsFolder,
  getCasenumYears: getCasenumYears,
  getInvestmentAttachments: getInvestmentAttachments,
  postInvestmentAttachment: postInvestmentAttachment,
  deleteInvestmentAttachment: deleteInvestmentAttachment,
  getMyCases: getMyCases,
  getInvestmentSingleCustomFieldValue: getInvestmentSingleCustomFieldValue,
}
