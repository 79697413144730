<template>
  <div>
    <table class='text-sm table-fixed'>
      <thead>
        <tr class='uppercase'>
          <th class='text-left pr-2 py-2 w-40'>{{ $t('scheduleDate') }}</th>
          <th class='text-left px-2 py-2 w-32'>{{ $t('startTime') }}</th>
          <th class='text-left px-2 py-2 w-32 border-r'>{{$t('endTime')}}</th>
          <th class='text-left px-2 py-2 w-40'>{{ $t('scheduleType') }}</th>
          <th class='text-left px-2 py-2 border-r w-40'>{{ $t('place') }}</th>
          <th class='text-left px-2 py-2 w-40'>{{ $t('lawyerInCharge') }}</th>
          <th class='text-left px-2 py-2 w-40'>{{ $t('content') }}</th>
          <th class='text-left px-2 py-2 w-40'>{{ $t('updatedBy') }}</th>
          <th class='text-left pl-2 py-2'></th>
        </tr>
      </thead>
      <tbody>
        <case-schedule-row-add class='schedule-row border-b border-t' />
        <case-schedule-row
          v-for='schedule in schedules'
          :key='`case-schedules-${schedule.id}`'
          class='schedule-row border-b border-t'
          :schedule='schedule' />
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mapMultiRowFields } from 'vuex-map-fields'
import CaseScheduleRow    from '@/views/dashboard/CaseScheduleRow.vue'
import CaseScheduleRowAdd from '@/views/dashboard/CaseScheduleRowAdd.vue'

export default {
  name: 'CaseSchedules',
  components: {
    CaseScheduleRow,
    CaseScheduleRowAdd,
  },
  watch: {
    drilldownInvestmentId: {
      handler: function (newId) {
        if (newId > 0) {
          this.getSchedules(newId)
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapMultiRowFields('schedules', [
      'schedules',
    ]),
    ...mapGetters('investments', [
      'drilldownInvestmentId'
    ]),
  },
  methods: {
    ...mapActions('schedules', [
      'getSchedules',
    ]),
  },
}
</script>
