<template>
  <div class='pt-4'>
    <section class='my-4 flex flex-row justify-start'>
      <div class=''>
        <div class='text-left w-48 text-sm uppercase font-semibold text-gray-600 py-1'>{{ $t('commissionDistribution') }}</div>
        <div class='text-2xl font-semibold'>{{ totalCommissionsString }} {{invoiceCurrency}}</div>
      </div>
      <table class='table-fixed text-sm w-full'>
        <thead>
          <tr class='text-sm uppercase font-semibold text-gray-600'>
            <th class='pr-2 py-1 w-64 whitespace-no-wrap' style='min-width:10rem;'>{{ $t('appointee') }}</th>
            <th class='px-2 py-1 w-32 whitespace-no-wrap'>{{ $t('position') }}</th>
            <th class='px-2 py-1 w-32 text-right whitespace-no-wrap'></th>
            <th class='px-2 py-1 w-24 text-right whitespace-no-wrap'>{{ $t('totalWeight') }}</th>
            <th class='px-2 py-1 w-32 text-right whitespace-no-wrap'>{{ $t('financialAmount') }}</th>
            <th class='px-2 py-1 w-24'>{{ $t('updatedBy') }}</th>
            <th class='px-0 py-1 w-10 text-center'><plus-circle-icon @click='addNewCommission' class='standard-transition cursor-pointer inline-block border border-transparent rounded-full hover:border-gray-900' /></th>
          </tr>
        </thead>
        <tbody>
          <case-invoice-distribution-commissions-row v-for='(commission, index) in commissions'
            :key='`invoice-commission-${commission.id}-${index}`'
            :commission='commission'
            @remove-commission='removeCommission($event, index)'
            @update-per-pool-ratios='updatePerPoolRatios'
            class='border-t border-gray-200' />
        </tbody>
      </table>
    </section>
    <section class='my-4 flex flex-row justify-start'>
      <div class=''>
        <div class='text-left w-48 text-sm uppercase font-semibold text-gray-600 py-1'>{{ $t('earningsDistribution') }}</div>
        <div class='text-2xl font-semibold'>{{ totalEarningsString }} {{invoiceCurrency}}</div>
      </div>
      <table class='table-fixed text-sm w-full'>
        <thead>
          <tr class='text-sm uppercase font-semibold text-gray-600'>
            <th class='pr-2 py-1 w-64 whitespace-no-wrap' style='min-width:10rem;'>{{ $t('performer') }}</th>
            <th class='px-2 py-1 w-32 whitespace-no-wrap'>{{ $t('position') }}</th>
            <th class='px-2 py-1 w-32 text-right whitespace-no-wrap'>{{ $t('billableTime') }}</th>
            <th class='px-2 py-1 w-24 text-right whitespace-no-wrap'>{{ $t('totalWeight') }}</th>
            <th class='px-2 py-1 w-32 text-right whitespace-no-wrap'>{{ $t('financialAmount') }}</th>
            <th class='px-2 py-1 w-24'>{{ $t('updatedBy') }}</th>
            <th class='px-0 py-1 w-10 text-center'><plus-circle-icon @click='addNewEarning' class='standard-transition cursor-pointer inline-block border border-transparent rounded-full hover:border-gray-900' /></th>
          </tr>
        </thead>
        <tbody>
          <case-invoice-distribution-earnings-row
            v-for='(earning, index) in invoiceEmployeeEarnings'
            :key='`invoice-employee-earning-${earning.id}-${index}`'
            :earning='earning'
            @remove-earning='removeEarning($event, index)'
            @update-per-pool-ratios='updatePerPoolRatios'
            class='border-t border-gray-200' />
        </tbody>
        <tfoot>
          <tr class='border-t border-gray-200'>
            <td class='pr-2 py-1'></td>
            <td class='px-2 py-1'></td>
            <td class='px-2 py-1 text-right font-semibold'>{{ totalInvoicePoolRatioString }} %</td>
            <td class='px-2 py-1 text-right text-gray-500 font-semibold'>{{ numberFormat(totalDistributionAmount) }} {{ invoiceCurrency }}</td>
            <td class='pl-2 py-1'></td>
          </tr>
        </tfoot>
      </table>
    </section>
    <section class='my-4 flex flex-row justify-start'>
      <div class='px-2 py-1 text-right font-semibold text-red-600'>{{warningMessage}}</div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapMultiRowFields } from 'vuex-map-fields'
import { PlusCircleIcon }  from '@vue-hero-icons/outline'
import numbro from 'numbro'
import CaseInvoiceDistributionCommissionsRow from '@/components/invoices/CaseInvoiceDistributionCommissionsRow.vue'
import CaseInvoiceDistributionEarningsRow    from '@/components/invoices/CaseInvoiceDistributionEarningsRow.vue'


export default {
  name: 'CaseInvoiceDistributionDetails',
  components: {
    PlusCircleIcon,
    CaseInvoiceDistributionCommissionsRow,
    CaseInvoiceDistributionEarningsRow,
  },
  props: ['totalAmount'],
  watch: {
    'editingInvoice.id': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.getCommissions({
            investment_id: this.editingInvoice.investment_id,
            invoice_id: newVal })
          this.getInvoiceEmployeeEarnings({
            investment_id: this.editingInvoice.investment_id,
            invoice_id: newVal })
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapMultiRowFields('commissions', [
      'commissions',
    ]),
    ...mapMultiRowFields('invoiceEmployeeEarnings', [
      'invoiceEmployeeEarnings',
    ]),
    ...mapState('invoices', [
      'editingInvoice',
    ]),
    ...mapState('commissions', {
      'stateCommissions': 'commissions',
    }),
    invoiceCurrency () {
      return this.$t('krw') // need to get this from the case
    },
    subtotal () {
      return this.totalExpenses + this.totalTimeCost + parseFloat(this.fixed_charge_amount)
    },
    subtotalString () {
      return numbro(this.subtotal).format({ thousandSeparated: true, mantissa: 0 })
    },
    totalCommissionAmount () {
      return this.commissions.reduce((sum, commission) => {
        if (commission.investment_id === this.editingInvoice.investment_id && commission.invoice_id === this.editingInvoice.id) {
          return sum + parseFloat(commission.amount)
        } else {
          return sum
        }
      }, 0)
    },
    totalEarningAmount () {
      return this.invoiceEmployeeEarnings.reduce((sum, earning) => {
        if (earning.investment_id === this.editingInvoice.investment_id && earning.invoice_id === this.editingInvoice.id) {
          return sum + parseFloat(earning.amount)
        } else {
          return sum
        }
      }, 0)
    },
    totalDistributionAmount () {
      return this.totalCommissionAmount + this.totalEarningAmount
    },
    totalForInvoice () {
      return this.subtotal + parseFloat(this.total_tax)
    },
    totalEarnings () {
      return this.invoiceEmployeeEarnings.reduce((sum, earning) => {
        if (earning.investment_id === this.editingInvoice.investment_id && earning.invoice_id === this.editingInvoice.id) {
          return sum + parseFloat(earning.amount)
        } else {
          return sum
        }
      }, 0)
    },
    totalEarningsString () {
      return numbro(this.totalEarnings).format({ thousandSeparated: true, mantissa: 0 })
    },
    totalInvoiceCommissionPoolRatio () {
      return this.commissions.reduce((sum, commission) => {
        if (commission.investment_id === this.editingInvoice.investment_id && commission.invoice_id === this.editingInvoice.id) {
          return sum + parseFloat(commission.invoice_total_pool_ratio)
        } else {
          return sum
        }
      }, 0)
    },
    totalInvoiceEarningPoolRatio () {
      return this.invoiceEmployeeEarnings.reduce((sum, earning) => {
        if (earning.investment_id === this.editingInvoice.investment_id && earning.invoice_id === this.editingInvoice.id) {
          return sum + parseFloat(earning.invoice_total_pool_ratio)
        } else {
          return sum
        }
      }, 0)
    },
    totalInvoicePoolRatio () {
      return this.totalInvoiceEarningPoolRatio + this.totalInvoiceCommissionPoolRatio
    },
    totalInvoicePoolRatioString () {
      return this.totalInvoicePoolRatio.toFixed(2)
    },
    totalCommissions () {
      return this.commissions.reduce((sum, commission) => {
        if (commission.investment_id === this.editingInvoice.investment_id && commission.invoice_id === this.editingInvoice.id) {
          return sum + parseFloat(commission.amount)
        } else {
          return sum
        }
      }, 0)
    },
    totalCommissionsString () {
      return numbro(this.totalCommissions).format({ thousandSeparated: true, mantissa: 0 })
    },
    warningMessage () {
      if (this.totalInvoicePoolRatio.toFixed(2) > 100) {
        return this.$t('totalWeightOver100')
      } else if (this.totalInvoicePoolRatio.toFixed(2) < 100) {
        return this.$t('totalWeightBelow100')
      } else {
        return ''
      }
    },
  },
  methods: {
    ...mapActions('invoices', [
      'updateInvoice',
    ]),
    ...mapActions('commissions', [
      'getCommissions',
      'deleteCommission',
      'addNewCommission',
      'removeCommissionByIndex',
    ]),
    ...mapActions('invoiceEmployeeEarnings', [
      'getInvoiceEmployeeEarnings',
      'deleteInvoiceEmployeeEarning',
      'addNewEarning',
      'removeEarningByIndex',
    ]),
    cancelEdit () {
      this.$emit('cancel:invoice')
    },
    removeCommission (commission, index) {
      if (this.commissions[index].id > 0) {
        this.deleteCommission(this.commissions[index].id)
      } else {
        this.removeCommissionByIndex(index)
      }
    },
    removeEarning (earning, index) {
      if (this.invoiceEmployeeEarnings[index].id > 0) {
        this.deleteInvoiceEmployeeEarning(this.invoiceEmployeeEarnings[index].id)
      } else {
        this.removeEarningByIndex(index)
      }
    },
    numberFormat (num) {
      return numbro(num).format({ thousandSeparated: true, mantissa: 0 })
    },
    saveButtonCss (isDeactivated) {
      return isDeactivated ? 'bg-gray-800 opacity-50' : 'bg-gray-900'
    },
    updatePerPoolRatios () {
      this.commissions.forEach(commission => {
        if (this.totalInvoiceCommissionPoolRatio === 0) {
          commission.commission_pool_ratio = 0
        } else {
          commission.commission_pool_ratio = 100.0 * (parseFloat(commission.invoice_total_pool_ratio) / this.totalInvoiceCommissionPoolRatio)
        }
      })
      this.invoiceEmployeeEarnings.forEach(earnings => {
        if (this.totalInvoiceEarningPoolRatio === 0) {
          earnings.earnings_pool_ratio = 0
        } else {
          earnings.earnings_pool_ratio = 100.0 * (parseFloat(earnings.invoice_total_pool_ratio) / this.totalInvoiceEarningPoolRatio)
        }
      })
    },
  },
}
</script>
