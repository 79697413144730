<template>
  <transition name='fade'>
    <div v-if='showSidepanel'
      class='sidepanel fixed top-0 right-0 h-full bg-white shadow-2xl pb-2 lg:pb-16 text-left z-20 overflow-y-auto'
      :class='topPadding'
      :style='panelFullOrHalf'>
      <button
        v-if='showCopyControl'
        ref='shareIcon'
        @click='copyUrlToClipboard'
        class='cursor-pointer fixed z-20 hover:bg-gray-100'
        style='top:2rem;'
        :style='copyControlLocation'>
        <share-icon />
      </button>
      <button
        v-if='!isMobile && showToggleControl'
        ref='fullScreenToggle'
        @click='toggleFullscreen'
        class='cursor-pointer fixed z-20 hover:bg-gray-100' 
        style='top:2rem; right:6rem;'>
        <component :is='toggleFullScreenIcon'/>
      </button>
      <button
        ref='closeIcon'
        @click='sidepanelClose'
        @keydown.esc='sidepanelClose'
        class='hover:bg-gray-100 fixed z-20'
        style='top:2rem; right:2rem;'>
        <x-icon class='cursor-pointer '/>
      </button>
      <portal-target name='sidepanel-header'></portal-target>
      <slot></slot>
      <component :is='sidepanelContentComponent' />
      <loading-indicator  v-if='isLoading' />
      <div v-if='isLoading'
        class='overlay'
        :class='displayedCSS'
        :style='panelFullOrHalf'></div>

      <div
        class='fixed bottom-0 right-0'
        :style='panelFullOrHalf'>
        <portal-target name='sidepanel-footer'></portal-target>
      </div>
    </div>
  </transition>
</template>

<script>

import { mapState, mapGetters, mapActions } from 'vuex'
import { ShareIcon, XIcon }              from '@vue-hero-icons/outline'
import { LoginIcon, LogoutIcon }         from '@vue-hero-icons/solid'
import LoadingIndicator                  from '@/components/LoadingIndicator.vue'
import CalendarEventEdit                 from '@/components/calendar/CalendarEventEdit.vue'
import CaseInvoiceDetails                from '@/components/invoices/CaseInvoiceDetails.vue'
import ContactDrilldown                  from '@/views/contacts/ContactDrilldown.vue'
import CustomFieldTemplateDetails        from '@/views/custom_fields/CustomFieldTemplateDetails.vue'
import DashboardViewEditing              from '@/views/dashboard/DashboardViewEditing.vue'
import InvestmentDrilldown               from '@/views/dashboard/InvestmentDrilldown.vue'
import DepositAttachInvoices             from '@/views/deposits/DepositAttachInvoices.vue'
import EntityDrilldown                   from '@/views/entities/EntityDrilldown.vue'
import CompanyDrilldown                  from '@/components/CompanyDrilldown.vue'
import InvoicesCaseInvoices              from '@/views/invoices/InvoicesCaseInvoices.vue'
import UserDetails                       from '@/views/manage_access/UserDetails.vue'
import UserGroupDetails                  from '@/views/manage_access/UserGroupDetails.vue'
import Cases                             from '@/views/performance_reports/Cases.vue'
import TimeSheets                        from '@/views/performance_reports/TimeSheets.vue'
import TimeSheetsDetails                 from '@/views/performance_reports/TimeSheetsDetails.vue'

export default {
  name: 'Sidepanel',
  components: {
    LoginIcon,
    LogoutIcon,
    ShareIcon,
    XIcon,
    CalendarEventEdit,
    CaseInvoiceDetails,
    Cases,
    CompanyDrilldown,
    ContactDrilldown,
    CustomFieldTemplateDetails,
    DashboardViewEditing,
    DepositAttachInvoices,
    EntityDrilldown,
    InvestmentDrilldown,
    InvoicesCaseInvoices,
    LoadingIndicator,
    UserDetails,
    UserGroupDetails,
    TimeSheets,
    TimeSheetsDetails,
  },
  computed: {
    ...mapState([
      'isLoading',
      'isSidepanelFullWidth',
      'showSidepanel',
      'sidepanelContentComponent',
    ]),
    ...mapState('invoices', [
      'editingInvoiceTab'
    ]),
    ...mapGetters('invoices', [
      'editingInvoiceId'
    ]),
    displayedCSS () {
      return (this.isLoading) ? 'displayed' : ''
    },
    isMobile () {
      return window.navigator.userAgent.includes('Android') ||
             window.navigator.userAgent.includes('iPhone')
    },
    copyControlLocation () {
      return (this.isMobile || !this.showToggleControl) ? 'right: 6rem;' : 'right: 10rem;'
    },
    panelFullOrHalf () {
      return (this.isSidepanelFullWidth || this.isMobile) ? 'width:100%;' : 'resize:horizontal; width:63%; min-width: 700px;'
    },
    showCopyControl () {
      // right now, only show the control for investments from the dashboard
      // add in other types of sidepanels later
      return this.$route.name === 'Dashboard'
    },
    showToggleControl () {
      return this.$route.name !== 'DrilldownContainer'
    },
    topPadding () {
      return (['investment-drilldown', 'entity-drilldown'].includes(this.sidepanelContentComponent)) ? 'pt-0' : ''
    },
    toggleFullScreenIcon () {
      return (this.isSidepanelFullWidth) ? 'logout-icon' : 'login-icon'
    },
    invoiceQueryParam () {
      let params = this.$route.query
      if (params.tab === 'invoices' && this.editingInvoiceId) {
        return `&invoice_id=${this.editingInvoiceId}&invoice_tab=${this.editingInvoiceTab}`
      } else {
        return ''
      }
    },
    urlToCopy () {
      let params = this.$route.query
      return `${process.env.VUE_APP_CLIENT_BASE_URL}/drilldown?investment_id=${params.investment_id}&tab=${params.tab}${this.invoiceQueryParam}`
    }
  },
  watch: {
    showSidepanel: {
      handler: function (newVal) {
        if (newVal) {
          this.$nextTick(() => {
            this.$refs.closeIcon.focus()
          })
        } else {
          this.$nextTick(() => {
            this.resetSelectedDrilldownInvestment()
          })
        }
      }
    },
  },
  methods: {
    ...mapActions([
      'sidepanelClose',
      'setSidepanelFullscreen',
      'setSidepanelHalf',
    ]),
    ...mapActions('investments', [
      'resetSelectedDrilldownInvestment',
    ]),
    toggleFullscreen () {
      if (this.isSidepanelFullWidth) {
        this.setSidepanelHalf()
      } else {
        this.setSidepanelFullscreen()
      }
    },
    copyUrlToClipboard () {
      this.$copyText(this.urlToCopy).then(() => {
        this.$message({
          type: 'success',
          message: this.$t('copiedToClipboard'),
        })
      })

    }
  },
}
</script>

<style lang='scss' scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all .2s;
}

div.fade-enter,
div.fade-leave-to {
  opacity: 0;
  right: -420px;
}

.overlay {
  @apply bg-cover fixed top-0 right-0 h-full z-20;
  background-color: #223645;
  // transition: opacity 0.2s ease-in-out;
  // transition: visibility 0.2s ease-in-out;
}
.overlay.displayed {
  opacity: 0.8;
}

</style>
