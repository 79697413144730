<template>
  <tbody :class='editingTimesheetTBodyStyle'>
    <tr
      class='timesheet-row text-sm'
      :class='editingTimesheetRow'>
      <td class='border-r px-2 py-1'>{{ timesheetEntry.work_date }}</td>
      <td v-if='hasInvoice' class='border-r px-2 py-1'>
        {{ timesheetEntry.invoice_name }} ({{timesheetEntry.invoice_id}})
      </td>
      <td class='border-r px-2 py-1'>{{ timesheetEntry.work_type }}</td>
      <td class='border-r px-2 py-1'>{{ timesheetEntry.user_name }}</td>
      <td class='border-r px-2 py-1'>{{ timesheetEntry.user_position }}</td>
      <td class='border-r px-2 py-1'>{{ timesheetEntry.performance_lawyer }}</td>
      <td class='border-r px-2 py-1 text-right whitespace-no-wrap'>{{ numberStyle(timesheetEntry.rate) }}</td>
      <td class='border-r px-2 py-1 text-right whitespace-no-wrap'>{{ hoursWorked }}</td>
      <td class='border-r px-2 py-1 text-right whitespace-no-wrap'>{{ hoursBillable }}</td>
      <td class='px-2 py-1 truncate'>
        <el-tooltip placement='left' v-if='hasDescription'>
          <div slot='content' class='w-64 leading-5'>{{timesheetEntry.description }}</div>
          <span>{{timesheetEntry.description }}</span>
        </el-tooltip>
        <span v-else>{{timesheetEntry.description }}</span>
      </td>
      <td class='pl-2 py-1 text-right whitespace-no-wrap flex flex-row justify-end'>
        <button
          @click='toggleEdit'
          class='py-1 px-3 border border-solid border-transparent hover:border-gray-600 hover:shadow opacity-75 hover:opacity-100 rounded flex flex-row justify-between items-center'>
          {{ editButtonLabel }}
        </button>
        <button
          @click='confirmDeleteTimesheetEntry'
          class='py-1 px-3 border border-solid border-transparent hover:border-red-600 hover:shadow opacity-75 hover:opacity-100 text-red-800 rounded flex flex-row justify-between items-center'>
          {{ $t('delete') }}
        </button>
      </td>
    </tr>
    <tr v-if='showEditTimesheet' class='rounded-b-lg'>
      <td colspan='8'
        class='border rounded-b-lg'>
        <timesheet-entry-edit
          :timesheet-entry='timesheetEntry'
          @done-editing='closeEditTimesheetEntry' />
      </td>
    </tr>
  </tbody>
</template>

<script>
import { mapActions } from 'vuex'
import numbro from 'numbro'
import TimesheetEntryEdit from '@/views/timesheet/TimesheetEntryEdit.vue'

export default {
  name: 'CaseTimesheetEntryRow',
  components: {
    TimesheetEntryEdit,
  },
  props: ['timesheetEntry'],
  data () {
    return {
      showEditTimesheet: false,
    }
  },
  computed: {
    hasInvoice () {
      return this.timesheetEntry.invoice_id
    },
    hasDescription () {
      return this.timesheetEntry.description && this.timesheetEntry.description.length > 10 // only have a tooltip if kinda long
    },
    hoursWorked () {
      return numbro(this.timesheetEntry.work_hours).format({ thousandSeparated: true, mantissa: 1 })
    },
    hoursBillable () {
      return numbro(this.timesheetEntry.billable_hours).format({ thousandSeparated: true, mantissa: 1 })
    },
    editButtonLabel () {
      return (this.showEditTimesheet) ? this.$t('close') : this.$t('edit')
    },
    editingTimesheetRow () {
      return (this.showEditTimesheet) ? 'bg-gray-100 border-l border-r font-semibold' : ''
    },
    editingTimesheetTBodyStyle () {
      return (this.showEditTimesheet) ? 'shadow-lg rounded-lg' : ''
    },
  },
  methods: {
    ...mapActions('timesheetEntries', [
      'deleteCaseTimesheetEntry',
    ]),
    confirmDeleteTimesheetEntry () {
      this.$confirm(this.$t('timesheetDeleteConfirmMsg', [this.timesheetEntry.work_date]), 'Warning', {
        confirmButtonText: this.$t('delete'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        this.deleteCaseTimesheetEntry(this.timesheetEntry.id).then(() => {
          this.$message({
            type: 'success',
            message: this.$t('deleteConfirmed')
          })
        })
      }).catch(() => {
      })
    },
    toggleEdit () {
      this.showEditTimesheet = !this.showEditTimesheet
    },
    closeEditTimesheetEntry () {
      this.showEditTimesheet = false
    },
    editTimesheetEntry () {
      this.showEditTimesheet = true
    },
    numberStyle (number) {
      return Number(number).toLocaleString()
    },
  },
}
</script>
