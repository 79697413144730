import Vue          from 'vue'
import Vuex         from 'vuex'
import earningsApi from '@/api/v1/invoice_employee_earnings'
import cloneDeep    from 'lodash/cloneDeep'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

const newInvoiceEmployeeEarning = {
  id: 0,
  investment_id: 0,
  invoice_id: 0,
  user_id: 0,
  user_name: '',
  user_position: '',
  earnings_pool_ratio: 0,
  invoice_total_pool_ratio: 0,
  amount: 0
}

const state = () => ({
  invoiceEmployeeEarnings: [],
  editingInvoiceEmployeeEarning: cloneDeep(newInvoiceEmployeeEarning),
})


const getters = {
  totalEarningRatio: (state) => {
    return state.invoiceEmployeeEarnings.reduce((sum, earning) => {
      return sum + parseFloat(earning.invoice_total_pool_ratio)
    }, 0)
  },
  earningPoolTotalRatio: (state) => {
    return state.invoiceEmployeeEarnings.reduce((sum, earning) => {
      return sum + parseFloat(earning.earnings_pool_ratio)
    }, 0)
  },
  everyEarningHasUser: (state) => {
    return state.invoiceEmployeeEarnings.every(earning => earning.user_id && earning.user_id > 0)
  },
  getField,
}


const actions = {
  getInvoiceEmployeeEarnings ({ commit, dispatch }, filters) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      earningsApi.getInvoiceEmployeeEarnings(filters).then(resp => {
        commit('setInvoiceEmployeeEarnings', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  updateInvoiceEmployeeEarning ({ commit, dispatch }, earning) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      earningsApi.updateInvoiceEmployeeEarning(earning.id, earning).then(resp => {
        commit('updateInvoiceEmployeeEarnings', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  updateOrCreateEarnings ({ dispatch, state }) {
    return Promise.all(
      state.invoiceEmployeeEarnings.map((earning) => {
        if (earning.id > 0) {
          return dispatch('updateInvoiceEmployeeEarning', earning)
        } else {
          return dispatch('createInvoiceEmployeeEarning', earning)
        }
      })
    )
  },
  deleteInvoiceEmployeeEarning ({ commit, dispatch }, earningId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      earningsApi.deleteInvoiceEmployeeEarning(earningId).then(resp => {
        commit('removeInvoiceEmployeeEarningFromList', resp.id)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  createInvoiceEmployeeEarning ({ commit, dispatch }, earning) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      earningsApi.postInvoiceEmployeeEarning(earning).then(resp => {
        commit('createdEarningUpdate', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  addNewEarning ({ commit, rootState }) {
    if (rootState.invoices.editingInvoice.id > 0) {
      let newRow = cloneDeep(newInvoiceEmployeeEarning)
      newRow.investment_id = rootState.invoices.editingInvoice.investment_id
      newRow.invoice_id    = rootState.invoices.editingInvoice.id
      commit('addInvoiceEmployeeEarning', newRow)
    } else {
      console.error('there is no editing invoice')
    }
  },
  removeEarningByIndex ({ commit }, index) {
    commit('removeEarningByIndex', index)
  }
}


const mutations = {
  addInvoiceEmployeeEarning (state, earning) {
    state.invoiceEmployeeEarnings.push(earning)
  },
  createdEarningUpdate (state, earning) {
    const index = state.invoiceEmployeeEarnings.findIndex(c => c.user_id === earning.user_id)
    if (index >= 0) {
      state.invoiceEmployeeEarnings.splice(index, 1, earning)
    } else {
      state.invoiceEmployeeEarnings.unshift(earning)
    }
  },
  removeInvoiceEmployeeEarningFromList (state, earningId) {
    const index = state.invoiceEmployeeEarnings.findIndex(earning => earning.id === earningId)
    if (index >= 0) {
      state.invoiceEmployeeEarnings.splice(index, 1)
    }
  },
  removeEarningByIndex (state, index) {
    if (index >= 0) {
      state.invoiceEmployeeEarnings.splice(index, 1)
    }
  },
  setInvoiceEmployeeEarnings (state, fromApi) {
    state.invoiceEmployeeEarnings = fromApi
  },
  updateInvoiceEmployeeEarnings (state, earning)  {
    const index = state.invoiceEmployeeEarnings.findIndex(c => c.id === earning.id)
    if (index >= 0) {
      state.invoiceEmployeeEarnings.splice(index, 1, earning)
    }
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
