<template>
  <div v-if='showOverlay' class='overlay' :class='displayedCSS'></div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'Overlay',
  computed: {
    ...mapGetters([
      'showOverlay',
    ]),
    ...mapState([
      'showPopover',
    ]),
    displayedCSS () {
      if (this.showPopover) {
        return 'transparent'
      } else if (this.showOverlay) {
        return 'displayed'
      } else {
        return ''
      }
    },
  },
}
</script>

<style lang='scss' scoped>
  .overlay {
    @apply bg-cover fixed top-0 left-0 h-full w-full z-20;
    background-color: #223645;
    transition: opacity 0.2s ease-in-out;
    transition: visibility 0.2s ease-in-out;
  }
  .overlay.displayed {
    opacity: 0.8;
  }
  .overlay.transparent {
    opacity: 0;
  }
</style>
