<template>
  <div class='px-2 py-2 bg-gray-100 flex flex-row justify-between items-center'>
    <update-info :info='info' />
    <button
      @click='removeCaseInfo'>
      <x-circle-icon class='inline-bock text-red-900 opacity-75' />
    </button>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import { XCircleIcon }              from '@vue-hero-icons/outline'
import UpdateInfo from '@/components/case_infos/UpdateInfo.vue'

export default {
  name: 'CaseInfoHeader',
  components: {
    XCircleIcon,
    UpdateInfo,
  },
  props: [
    'info'
  ],
  methods: {
    ...mapActions('investments', [
      'deleteCaseInfo',
    ]),
    ...mapMutations('investments', [
      'removeFromDrilldownCaseInfos'
    ]),
    removeCaseInfo () {
      if (this.info.id > 0) {
        this.deleteCaseInfo(this.info)
      } else {
        this.removeFromDrilldownCaseInfos(this.info)
      }
    },
  },
}
</script>
