<template>
  <div>
    <table v-if='hasInvestments'
      class='table-fixed text-sm w-full'>
      <thead>
        <tr class='border-b'>
          <th class='pr-2 py-2 text-left'>ID</th>
          <th class='px-2 py-2 text-left'>{{ $t('lawyer') }}</th>
          <th class='px-2 py-2 text-left'>{{ $t('caseNo') }}</th>
          <th class='pr-2 py-2 text-left'>{{ $t('client') }}</th>
          <th class='px-2 py-2 text-left'>{{ $t('counterpart') }}</th>
          <th class='pl-2 py-2 text-left'>{{ $t('caseName') }}</th>
          <th class='pl-2 py-2 text-left'>{{ $t('unarchived') }}</th>
        </tr>
      </thead>
      <tbody>
        <search-result-investment v-for='investment in cases'
          :key='`case-${investment.id}`'
          :investment='investment'
          class='border-b hover:bg-blue-100 rounded cursor-pointer'
          @click.native='openNewPageForCase(investment.id)' />
      </tbody>
    </table>
    <div v-else
      class='mt-8 p-16 text-gray-600 text-center text-sm bg-gray-100 rounded-md'>
      {{ $t('noCaseForClient') }}
    </div>
  </div>
</template>

<script>
import SearchResultInvestment from '@/components/search/SearchResultInvestment.vue'

export default {
  name: 'EntityCases',
  components: {
    SearchResultInvestment,
  },
  props: {
    cases: {
      type: Array,
      default: () => [],
    }
  },
  computed: {
    hasInvestments () {
      return this.cases.length > 0
    }, 
  },
  methods: {
    openNewPageForCase (investmentId) {
      window.open(`/drilldown?investment_id=${investmentId}`, '_blank')
    }
  }
}
</script>
