<template>
  <div>
    <div v-if='!showConfirm'
      class='justify-between w-full bg-gray-100 pl-4 lg:pl-8 pr-2 lg:pr-8 py-4 text-sm shadow-lg'>
      <button class='inline-block py-3 px-16 text-white rounded-md font-bold border border-transparent hover:border-blue-500 hover:shadow-md' style='background-color: #223645;'
        :disabled='!isValidForm || !isSelectEntity || hasIncompleteRequiredFields'
        @click='save(investment, hasIncompleteRequiredFields)'>
        {{submitButtonText}}
      </button>
      <button class='inline-block mx-4 px-4 py-3 bg-transparent text-gray-600 hover:text-gray-900 border border-transparent rounded-md hover:border-gray-600 hover:shadow-md'
        @click='cancelEdits'>
        {{ $t('cancel') }}
      </button>
      <div class='inline-block mx-4 px-4 py-3 text-red-700' v-if='hasIncompleteRequiredFields'>
        {{ $t('incompleteFieldInfo', [incompleteRequiredFieldCount]) }}
      </div>
      <div class='inline-block mx-4 px-4 py-3 text-red-700' v-if='!isSelectEntity'>
        {{ $t('requiredClientEntry') }}
      </div>
    </div>

    <div v-else class='justify-between w-full bg-gray-100 pl-4 lg:pl-8 pr-2 lg:pr-8 py-4 text-sm shadow-lg'>
      <button class='inline-block py-3 px-16 text-white rounded-md font-bold border border-transparent hover:border-blue-500 hover:shadow-md' style='background-color: #223645;'
        :disabled='!isValidForm'
        @click='save(investment)'>
        {{submitButtonText}}
      </button>
      <button class='inline-block mx-4 px-4 py-3 bg-transparent text-gray-600 hover:text-gray-900 border border-transparent rounded-md hover:border-gray-600 hover:shadow-md'
        @click='cancelConfirm'>
        {{ $t('cancel') }}
      </button>
      <div class='inline-block mx-4 px-4 py-3 text-red-700 font-bold text-lg' v-if='hasIncompleteRequiredFields'>
        {{ $t('incompleteFieldInfo', [incompleteRequiredFieldCount]) }}
      </div>
      <div class='inline-block mx-4 px-4 py-3 text-red-700' v-if='!isSelectEntity'>
        {{ $t('requiredClientEntry') }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

export default {
  name: 'InvestmentMetadataActionButtons',
  props: {
    investment: Object,
    isValidForm: Boolean,
    defaultViewMode: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      showConfirm: false,
    }
  },
  computed: {
    ...mapState('investments', [
      'drilldownInvestmentCustomFields',
      'drilldownEditedFields',
    ]),
    ...mapGetters('investments', [
      'isNewInvestment',
      'requiredEmptyFields',
    ]),
    hasIncompleteRequiredFields () {
      return this.incompleteRequiredFieldCount > 0
    },
    isSelectEntity () {
      return this.investment.entity_id && this.investment.entity_id > 0 && this.investment.entity_ids && this.investment.entity_ids.length > 0
    },
    incompleteRequiredFieldCount () {
      return this.requiredEmptyFields.length
    },
    submitButtonText () {
      return (this.isNewInvestment) ? this.$t('create') : this.$t('save')
    },
  },
  methods: {
    ...mapActions([
      'sidepanelClose'
    ]),
    ...mapActions('investments', [
      'createInvestment',
      'createUnsavedInvestmentAttachments',
      'updateInvestmentData',
      'updateAllCustomFieldValues',
      'getInvestmentData',
      'getInvestmentCard',
      'getInvestmentCustomFields',
    ]),
    ...mapMutations('investments', [
      'setDrilldownIsViewMode',
      'clearUnsavedInvestmentAttachments',
      'updateDrilldownInvestmentDetails',
      'setDrilldownEditedFields',
      'pushDrilldownEditedFields',
    ]),
    cancelConfirm () {
      this.showConfirm = false
    },
    validField (field) {
      return field.id && field.value
    },
    save (investment, confirm) {
      if (confirm) {
        this.showConfirm = true
      } else {
        this.showConfirm = false
        this.submit(investment)
      }
    },
    submit (investment) {
      if (this.isNewInvestment) {
        // default value setting
        if (this.drilldownEditedFields.length > 0) {
          this.drilldownInvestmentCustomFields.forEach(field => {
            if (isNaN(this.drilldownEditedFields.find(editedField => editedField.id === field.id)) && this.validField(field)) {
              this.pushDrilldownEditedFields(field)
            }
          })
        } else {
          this.setDrilldownEditedFields(this.drilldownInvestmentCustomFields.filter(field => this.validField(field)))
        }
        this.createInvestment(investment).then(resp => {
          this.createUnsavedInvestmentAttachments(resp.id).then(() => {
            this.clearUnsavedInvestmentAttachments()
            this.updateAllCustomFieldValues(resp.id).then(() => {
              this.getInvestmentCard(resp.id)
              this.getInvestmentData(resp.id).then(() => {
                this.$message({
                  message: this.$t('investmentSuccessfullyCreated', [resp.investment_name]),
                  type: 'success'
                })
                this.setDrilldownIsViewMode(this.defaultViewMode)
              })
            })
          })
        })
      } else {
        this.updateAllCustomFieldValues(investment.id).then(() => {
          this.updateInvestmentData(investment).then(() => {
            this.$message({
              message: this.$t('investmentSuccessfullyUpdated', [investment.investment_name]),
              type: 'success'
            })
            this.setDrilldownIsViewMode(this.defaultViewMode)
          })
        })
      }
    },
    cancelEdits () {
      this.setDrilldownIsViewMode(this.defaultViewMode)
    },
    hideDeleteConfirm () {
      this.showDeleteConfirm = false
    },
    showDeleteConfirmation () {
      this.showDeleteConfirm = this.defaultViewMode
    },
  },
}
</script>

<style lang='scss' scoped>
button:disabled,
button[disabled] {
  opacity: 0.6;
  cursor: not-allowed;
}
</style>
