<template>
  <div class='relative entity-search'>
    <v-select
      v-model='selectedEntities'
      @input='updateSelectedEntities'
      @search='searchEntities'
      label='entity_name'
      ref='entitySearch'
      :placeholder="$t('client')"
      :filterable='false'
      :close-on-select='true'
      multiple
      :options='filteredOptions'>
      <template v-slot:option='option'>
        <div class='custom-field-option py-2 text-sm'>
          ({{ option.entity_name }})
        </div>
        <div v-if='ceoName(option.ceo_name)' class='opacity-75 px-5 py-1' style='color:blue'>
          {{ceoName(option.ceo_name)}}
        </div>
        <div v-if='companyNumber(option)' class='opacity-75 px-5 py-1' style='color:blue'>
          {{companyNumber(option)}}
        </div>
      </template>
      <template v-slot:no-options>
        <div class='py-3 text-gray-500 text-center'>{{ $t('emptyClientErrorMessage') }}</div>
      </template>
      <template v-slot:spinner>
        <i v-if='loading' class='el-icon-loading p-2' />
      </template>
      <template #list-footer>
        <div v-if='searchEntityNameText'
          @click='addEntity'
          class='px-2 py-3 border-t hover:bg-gray-200 text-xs cursor-pointer'>
          {{searchEntityNameText}}
          <span class='inline-block opacity-75 ml-2'>{{ $t('createNewEntityButtonText', [""]) }}</span>
        </div>
      </template>
    </v-select>
    <portal to='modals'>
      <entity-data-edit v-if='addNewEntity'
        :display-in-modal='true'
        @done-editing='doneEditing' />
    </portal>
  </div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import debounce       from 'debounce'
import EntityDataEdit from '@/views/entities/EntityDataEdit.vue'

export default {
  name: 'EntityMultySearch',
  components: {
    EntityDataEdit,
  },
  props: [
    'entityIds',
    'entityNames',
    'blockCreateEntity',
  ],
  data () {
    return {
      filteredOptions: [],
      selectedEntities: [],
      loading: false,
      addNewEntity: false,
    }
  },
  watch: {
    entityIds: {
      handler: function (newVal) {
        if (newVal) {
          this.selectedEntities = []
          this.selectedEntities = newVal.map(entityId => {return {id: entityId, entity_name: ''}})
          if (this.entityNames) {
            this.entityNames.forEach((entity_name, index) => {
              if (this.selectedEntities[index]) {
                this.selectedEntities[index].entity_name = entity_name
              }
            })
          }
        }
      },
      immediate: true
    }
  },
  computed: {
    selectedEntityIds () {
      return (this.selectedEntities.length > 0) ? this.selectedEntities.map(entity => entity.id) : []
    },
    selectedEntityNames () {
      return (this.selectedEntities.length > 0) ? this.selectedEntities.map(entity => entity.entity_name) : []
    },
    searchEntityNameText () {
      return this.$refs.entitySearch.search
    },
  },
  methods: {
    ...mapActions('entities', [
      'searchEntitiesByName',
      'newEntitySetName',
    ]),
    ...mapMutations([
      'setShowPopover',
    ]),
    searchEntities (text) {
      this.loading = true
      if (text && text.length >= 2) {
        this.searchEntitiesByName(text).then(resp => {
          this.filteredOptions = resp
          this.loading = false
        })
      } else {
        this.loading = false
      }
    },
    updateSelectedEntities () {
      this.$emit('update-selected-entities', this.selectedEntityIds, this.selectedEntityNames)
    },
    addEntity () {
      // NOTE: apple,firefox korean will not work on first click as mouseevents are broken
      this.setShowPopover(false)
      this.$store.dispatch('modalOpen', {height: 60, width: 60})
      this.newEntitySetName(this.searchEntityNameText)
      this.addNewEntity = true
    },
    doneEditing (resp) {
      this.addNewEntity = false
      this.updateSelectedEntity(resp)
      this.$store.dispatch('modalClose')
    },
    updateSelectedEntity (entity) {
      this.$emit('update-selected-multi-entites', {
        entityName: entity.entity_name,
        entityId: entity.id,
      })
      this.$refs.entitySearch.search = ''
      this.setShowPopover(false)
    },
    birthDate (birthDate) {
      return birthDate ? this.$t('birthDateDetail', [birthDate]) : ''
    },
    licenseNumber (licenseNumber) {
      return licenseNumber ? this.$t('licenseNumber', [licenseNumber]) : ''
    },
    companyNumber (option) {
      return option.entity_type === '개인' ? this.birthDate(option.birth_date) : this.licenseNumber(option.business_license_number)
    },
    ceoName (ceoName) {
      return ceoName ? this.$t('ceoNameDetail', [ceoName]) : ''
    },
  },
  created () {
    this.searchEntities = debounce(this.searchEntities, 300)
  },
}
</script>
