<template>
  <div>
    <label class='form-label'>{{ $t('selectableValues') }}</label>
    <div class='max-w-xs relative'>
      <input type='text' class='form-input w-full' v-model='additionalValue' v-on:keyup.enter='addToValues' ref='newValueTextbox' />
      <button class='absolute border py-2 pl-4 pr-2 border-t border-r border-b rounded-r block bg-gray-200' @click='addToValues' style='top: 0; right: 0;'><plus-circle-icon class='inline-block' style='margin-top: -2px;'></plus-circle-icon></button>
    </div>
    <draggable v-model='selectableValues'>
      <div v-for='selectableValue in selectableValues'
        :key='`selectableValues-${selectableValue}`'
        class='relative max-w-xs border-l border-r border-b border-gray-200'>
        <button @click='removeFromSelectableValues(selectableValue)' class='remove-selectable-button absolute' style='right: 0.5rem; top: 0.75rem;'><x-circle-icon class='inline-bock text-red-900 opacity-75' /></button>
        <div class='selectable-value pl-3 pr-2 py-3 '>{{selectableValue}}</div>
      </div>
    </draggable>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { PlusCircleIcon, XCircleIcon }  from '@vue-hero-icons/outline'
import draggable from 'vuedraggable'

export default {
  name: 'CustomFieldTemplateSelectableValues',
  components: {
    draggable,
    PlusCircleIcon,
    XCircleIcon,
  },
  data () {
    return {
      additionalValue: '',
    }
  },
  computed: {
    selectableValues: {
      get () {
        return this.editingCustomField.selectable_values
      },
      set (values) {
        this.updateCustomFieldSelectableValues(values)
      }
    },
    ...mapState('customFields', [
      'editingCustomField',
    ]),
  },
  methods: {
    addToValues () {
      this.addToSelectableValues(this.additionalValue)
      this.additionalValue = ''
    },
    enterValue () {
      this.additionalValue = ''
      this.$nextTick().then(() => {
        this.$refs.newValueTextbox.focus()
      })

    },
    ...mapMutations('customFields', [
      'addToSelectableValues',
      'removeFromSelectableValues',
      'updateCustomFieldSelectableValues',
    ]),
  },
}
</script>

<style lang='scss' scoped>

.remove-selectable-button:hover  ~ .selectable-value {
  background-color: #FDF2F8;
  text-decoration: line-through;
}

.form-label {
  @apply block font-normal mb-2 text-gray-600 text-sm tracking-wide uppercase;
}


</style>
