import base from './base'

const getDepositInvoices = (filters) => base
  .get('/deposit_invoices/', { params: filters }).then(resp => resp.data)

const postDepositInvoices = (depositInvoicesParams, contentType='application/json') => base
  .post('/deposit_invoices/', depositInvoicesParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const searchInvoices = (filters) => base
  .get('/deposit_invoices/search_by_case/', { params: filters }).then(resp => resp.data)

// const deleteExpense = (expenseId) => base
//   .delete(`/expenses/${expenseId}`).then(resp => resp.data)

export default {
  getDepositInvoices: getDepositInvoices,
  postDepositInvoices: postDepositInvoices,
  searchInvoices: searchInvoices,
}
