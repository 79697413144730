<template>
  <div class='timesheet-entry-edit'>
    <div v-if='isAdmin' class='flex flex-row justify-start items-center mt-4'>
      <label class='block w-32 flex-shrink-0 text-sm uppercase text-gray-700'>{{ $t('lawyer') }}</label>
      <user-search
        :user-ids='timesheetEntry.user_id'
        :user-names='timesheetEntry.user_name'
        :placeholder="$t('selectLawyer')"
        role='lawyer'
        class='w-40'
        @update-selected-user='updateSelectedUsers' />
    </div>
    <div class='flex flex-row justify-start items-center mt-4'>
      <label class='block w-32 flex-shrink-0 text-sm uppercase text-gray-700'>{{ $t('workDate') }}</label>
      <div v-if='hideDatePicker'>
        {{ timesheetEntry.work_date }}
      </div>
      <el-date-picker v-else
        :value='timesheetEntry.work_date'
        @input='$emit("update:timesheetEntry", {...timesheetEntry, work_date: $event})'
        class='w-40'
        value-format='yyyy-MM-dd'
        type='date'
        :picker-options="pickerOptions"
        :placeholder="$t('pickDay')" />
    </div>
    <div class='flex flex-row justify-start items-center mt-4'>
      <label class='block w-32 flex-shrink-0 text-sm uppercase text-gray-700'>{{ $t('workType') }}</label>
      {{ timesheetEntry.work_type }}
    </div>
    <div v-if='showDetailedWorkType' class='flex flex-row justify-start items-center mt-4'>
      <label class='block w-32 flex-shrink-0 text-sm uppercase text-gray-700'>{{ $t('detailedWorkType') }}</label>
      <select
        :value='timesheetEntry.detailed_work_type'
        @change='$emit("update:timesheetEntry", {...timesheetEntry, detailed_work_type: $event.target.value})'
        class='form-select w-40'>
        <option
          v-for='detailedWorkType in detailedWorkTypes'
          :key='`case-timesheet-work-type-${detailedWorkType}`'
          :value='detailedWorkType'>
          {{detailedWorkType}}
        </option>
      </select>
    </div>
    <div class='flex flex-row justify-start items-center mt-4'>
      <label class='block w-32 flex-shrink-0 text-sm uppercase text-gray-700'>{{ $t('bill') }}</label>
      <select
        :value='timesheetEntry.invoice_id'
        @change='$emit("update:timesheetEntry", {...timesheetEntry, invoice_id: $event.target.value})'
        class='form-select w-full'>
        <option v-for='invoice in invoicesByCase'
          :key='`invoice-${invoice.id}`'
          :value='invoice.id'>
          {{ `${invoice.invoice_date} ${invoice.invoice_title} ${numberStyle(invoice.total_amount)}` }}
        </option>
      </select>
    </div>
    <div class='flex flex-row justify-start items-center mt-4'>
      <label class='block w-32 flex-shrink-0 text-sm uppercase text-gray-700'>{{ $t('position') }}</label>
      <select
        :value='timesheetEntry.user_position'
        @change='$emit("update:timesheetEntry", {...timesheetEntry, user_position: $event.target.value})'
        class='form-select w-40'>
        <option
          v-for='userPosition in selectableUserPositions'
          :key='`case-timesheet-user-position-${userPosition}`'
          :value='userPosition'>
          {{userPosition}}
        </option>
      </select>
    </div>
    <div class='flex flex-row justify-start items-center mt-4'>
      <label class='block w-32 flex-shrink-0 text-sm uppercase text-gray-700'>{{ $t('hourlyRate') }}</label>
      <vue-numeric
        :value='timesheetEntry.rate'
        @change='$emit("update:timesheetEntry", {...timesheetEntry, rate: $event.target.value})'
        output-type='number'
        class='form-input w-40 text-right'
        :minus='false'
        separator=','/>
      <span class='text-gray-500 uppercase text-sm ml-2'>
        {{ $t('krwPerHour') }}
      </span>
    </div>
    <div class='flex flex-row justify-start items-center mt-4'>
      <label class='block w-32 flex-shrink-0 text-sm uppercase text-gray-700'>{{ $t('workHours') }}</label>
      <vue-numeric
        :value='timesheetEntry.work_hours'
        @change='$emit("update:timesheetEntry", {...timesheetEntry, work_hours: $event.target.value})'
        output-type='number'
        class='form-input w-40 text-right'
        :minus='false'
        :precision='1'
        separator=','/>
      <label class='block w-32 flex-shrink-0 text-sm uppercase text-gray-700 text-right pr-4'>{{ $t('billingTime') }}</label>
      <vue-numeric
        :value='timesheetEntry.billable_hours'
        @change='$emit("update:timesheetEntry", {...timesheetEntry, billable_hours: $event.target.value})'
        output-type='number'
        class='form-input w-40 text-right'
        :minus='false'
        :precision='1'
        separator=','/>
    </div>
    <div class='flex flex-row justify-start items-center mt-4'>
      <label class='block w-32 flex-shrink-0 text-sm uppercase text-gray-700'>{{ $t('detailsWork') }}</label>
      <input
        class='form-input w-full'
        type='text'
        :value='timesheetEntry.description'
        @change='$emit("update:timesheetEntry", {...timesheetEntry, description: $event.target.value})' />
    </div>
    <div class='flex flex-row justify-start items-center mt-4'>
      {{timesheetEntry.updated_by_name}}
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import UserSearch from '@/components/UserSearch.vue'
import dayjs from 'dayjs'

export default {
  name: 'TimesheetEntryInputForm',
  components: {
    UserSearch
  },
  props: [
    'timesheetEntry',
  ],
  data () {
    return {
      pickerOptions: {
          disabledDate: this.isDateDisabled
        },
    }
  },
  watch: {
    'timesheetEntry.work_hours': {
      handler: function (newVal) {
        if (newVal > 0) {
          this.$emit("update:timesheetEntry", {...this.timesheetEntry, billable_hours: newVal})
        }
      },
      immediate: true
    },
    'timesheetEntry.investment_id': {
      handler: function (newVal) {
        if (newVal > 0) {
          this.searchInvoicesByCase(newVal)
        }
      },
      immediate: true
    },
    'timesheetEntry.user_name': {
      handler: function () {
        this.$emit("update:timesheetEntry", {...this.timesheetEntry})
      },
    },
  },
  computed: {
    ...mapState('timesheetEntries', [
      'detailedWorkTypes',
      'selectableUserPositions',
      'workingDays',
      'koreanTime'
    ]),
    ...mapState('invoices', [
        'invoicesByCase',
      ]),
    ...mapGetters('users', [
      'isAdmin',
    ]),
    ...mapGetters('timesheetEntries', [
      'isWorkDateDisabled',
    ]),
    showDetailedWorkType () {
      return this.timesheetEntry.work_type && this.timesheetEntry.work_type.toLowerCase() === 'general'
    },
    hideDatePicker () {
      return this.isDateDisabled(this.timesheetEntry.work_date)
    },
    koreanDateTime () {
      return (this.koreanTime && this.koreanTime.trim() !== '') ? dayjs(this.koreanTime) : dayjs()
    },
  },
  methods: {
    ...mapActions('invoices', [
        'searchInvoicesByCase',
    ]),
    ...mapActions('timesheetEntries', [
        'getWorkingDaysByDateRange',
        'updateKoreanTime',
    ]),
    isDateDisabled(date) {
      if (this.isAdmin) {
        return false
      }
      return this.isWorkDateDisabled(date)
    },
    numberStyle (number) {
      return Number(number).toLocaleString()
    },
    updateSelectedUsers(userIdAndName) {
      this.$emit("update:timesheetEntry", {...this.timesheetEntry, 
                                          user_id: userIdAndName.user_id,
                                          user_name: userIdAndName.user_name})
    }
  },
  mounted () {
    this.updateKoreanTime().then(() => {
      if (this.koreanDateTime) {
        this.getWorkingDaysByDateRange(this.koreanDateTime.subtract(2, 'week'), this.koreanDateTime)
      }
    })
  },
}
</script>
