<template>
  <div class='pt-8'>
    <div id='extensionMessageContainer'></div>
    <div v-for='params in drilldownInvestmentCaseInfos' :key='params.label'>
      <a
        v-if='hasExtension'
        @click='runExtension(params)'
        class='hover:bg-gray-100 px-2 text-xs text-gray-600 hover:text-gray-900 underline whitespace-no-wrap'>
        {{params.label}}
      </a>
      <a
        v-else
        :href='params.url'
        @click='copyClient(params.client_name)'
        target='_blank'
        class='hover:bg-gray-100 px-2 text-xs text-gray-600 hover:text-gray-900 underline whitespace-no-wrap'>
        {{params.label}}
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import ChromeExtensionHelpers from '@/utils/chrome-extension-helpers'

export default {
  name: 'CaseCourtInfos',
  data () {
    return {
      hasExtension: false
    }
  },
  computed: {
    ...mapGetters('investments', [
      'drilldownInvestmentId',
    ]),
    ...mapState('investments', [
      'drilldownInvestmentCaseInfos',
    ]),
    scourtCaseInfos () {
      return this.drilldownInvestmentCaseInfos.filter(info => info.has_scourt)
    },
  },
  methods: {
    copyClient (client) {
      this.$copyText(client).then(() => {
        this.$message({
          type: 'success',
          message: this.$t('copyClient'),
        })
      })
    },
    runExtension (params) {
      ChromeExtensionHelpers.runExtension(params)
    },
  },
  mounted () {
    ChromeExtensionHelpers.checkExtension().then((hasExtension) => {
      this.hasExtension = hasExtension
    })
  },
}
</script>
