import Vue             from 'vue'
import Vuex            from 'vuex'
import investmentsApi  from '@/api/v1/investments.js'
import invoiceApi      from '@/api/v1/invoices.js'
import InvoicesHelpers from '@/utils/invoices-helpers.js'
import { getField, updateField } from 'vuex-map-fields'
import dayjs from 'dayjs'

Vue.use(Vuex)

const state = () => ({
  billableCases: [],
  drilldownBillableCase: {},
  invoices: [],
  invoiceAttachments: [],
  invoicesByCase: [],
  editingInvoice: InvoicesHelpers.newInvoice(),
  editingInvoiceTab: '',
  filterStartDate: dayjs().format('YYYY-MM-DD'),
  filterEndDate: dayjs().format('YYYY-MM-DD'),
})


const getters = {
  editingInvoiceId: state => {
    return (state.editingInvoice) ? state.editingInvoice.id : 0
  },
  invoiceWithId: state => (invoiceId) => {
    return (state.invoices.length) ? state.invoices.find(invoice => invoice.id === invoiceId) : null
  },
  getField,
}


const actions = {
  caseInvoiceDetailsOpen ({ commit }, invoice) {
    commit('setEditingInvoice', invoice)
  },
  invoiceOpen ({ commit, dispatch }, invoice) {
    commit('setEditingInvoice', invoice)
    dispatch('sidepanelOpen', { componentName: 'case-invoice-details' }, { root : true })
  },
  createInvoice ({ commit, dispatch }, invoice) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      invoiceApi.postInvoice(invoice).then(resp => {
        commit('addInvoice', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  createInvoiceAttachment ({ state, commit, dispatch }, file) {
    let data = new FormData()
    data.append('invoice_attachment[investment_id]', state.editingInvoice.investment_id)
    data.append('invoice_attachment[invoice_id]', state.editingInvoice.id)
    data.append('invoice_attachment[file]', file.file, file.file.name)
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      invoiceApi.postInvoiceAttachment(data).then(resp => {
        commit('addInvoiceAttachment', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  deleteInvoice ({ commit, dispatch }, invoiceId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      invoiceApi.deleteInvoice(invoiceId).then(resp => {
        commit('removeInvoiceFromList', resp.id)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  getBillableCases ({ commit, dispatch }, filters) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      investmentsApi.getBillable(filters).then(resp => {
        commit('setBillableCases', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  getInvoices ({ commit, dispatch }, filters) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      invoiceApi.getInvoices(filters).then(resp => {
        commit('setInvoices', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  getSingleInvoice ({ dispatch }, invoiceId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      invoiceApi.getInvoice(invoiceId).then(resp => {
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  getInvoiceAttachments ({ commit, dispatch }, invoiceId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      invoiceApi.getInvoiceAttachments({ invoice_id: invoiceId }).then(resp => {
        commit('setInvoiceAttachments', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  deleteInvoiceAttachment ({ commit, dispatch }, invoiceAttachmentId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      invoiceApi.deleteInvoiceAttachment(invoiceAttachmentId).then(resp => {
        commit('removeInvoiceAttachment', resp.id)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  resetDrilldownBillableCase ({ commit }) {
    commit('setDrilldownBillableCase', {})
  },
  updateInvoice ({ commit, dispatch }, invoice) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      invoiceApi.updateInvoice(invoice.id, invoice).then(resp => {
        commit('updateInvoices', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  updateFilterDateRange ({ commit }, dateRange) {
    commit('setFilterDateRange', dateRange)
  },
  totalUpdateForInvoice ({ state, dispatch }) {
    let promise1 = dispatch('updateInvoice', state.editingInvoice)
    let promise2 = dispatch('commissions/updateOrCreateCommissions', null, { root : true })
    let promise3 = dispatch('invoiceEmployeeEarnings/updateOrCreateEarnings', null, { root : true })
    let promise4 = dispatch('expenses/updateExpenses', null, { root : true })
    let promise5 = dispatch('timesheetEntries/updateTimesheetEntries', null, { root : true })

    Promise.all([promise1, promise2, promise3, promise4, promise5]).then(() => {
      let params = {investment_id: state.editingInvoice.investment_id, invoice_id: state.editingInvoice.id}
      dispatch('magams/createChangePerformances', params, { root : true })
    })
  },
  searchInvoicesByCase ({ commit, dispatch }, investmentId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      invoiceApi.searchInvoicesByCase(investmentId).then(resp => {
        commit('setInvoiceByCase', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  getInvoicesByCompany ({ dispatch }, companyId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      invoiceApi.getInvoicesByCompany(companyId).then(resp => {
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
}


const mutations = {
  setFilterDateRange (state, dateRange) {
    state.filterStartDate = dateRange.startDate
    state.filterEndDate = dateRange.endDate
  },
  addInvoice (state, invoice) {
    state.invoices.unshift(invoice)
  },
  addInvoiceAttachment (state, attachment) {
    state.invoiceAttachments.unshift(attachment)
  },
  removeInvoiceFromList (state, invoiceId) {
    const index = state.invoices.findIndex(invoice => invoice.id === invoiceId)
    if (index >= 0) {
      state.invoices.splice(index, 1)
    }
  },
  setBillableCases (state, fromApi) {
    state.billableCases = fromApi
  },
  setDrilldownBillableCase (state, billableCase) {
    state.drilldownBillableCase = billableCase
  },
  setEditingInvoice (state, invoice) {
    state.editingInvoice = invoice
  },
  setEditingInvoiceTab (state, tab) {
    state.editingInvoiceTab = tab
  },
  setInvoices (state, fromApi) {
    state.invoices = fromApi
  },
  setInvoiceAttachments (state, fromApi) {
    state.invoiceAttachments = fromApi
  },
  removeInvoiceAttachment (state, attachmentId) {
    const index = state.invoiceAttachments.findIndex(attachment => attachment.id === attachmentId)
    if (index >= 0) {
      state.invoiceAttachments.splice(index, 1)
    }
  },
  updateInvoices (state, updatedInvoice)  {
    const index = state.invoices.findIndex(invoice => invoice.id === updatedInvoice.id)
    if (index >= 0) {
      state.invoices.splice(index, 1, updatedInvoice)
    }
  },
  setInvoiceByCase (state, fromApi) {
    state.invoicesByCase = fromApi
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
