<template>
  <div class=''>
    <div v-if='isViewMode'>
      {{dateString}}
    </div>
    <div v-else>
      <el-date-picker
        v-model='dateRange'
        type='daterange'
        range-separator='~'
        :start-placeholder="$t('startDate')"
        :end-placeholder="$t('endDate')"
        size='small'
        value-format='yyyy-MM-dd'
        :picker-options='pickerOptions'>
      </el-date-picker>
      <button v-if='!showAddCalendarEvent && isValidDate' class='ml-2 text-sm font-bold' @click='showConfirm'>
        <calendar-icon class='mb-1 mr-1 inline-block'/>{{ $t('createEvent') }}
      </button>
      <div v-if='showAddCalendarEvent && isValidDate' class='p-8 text-center mt-4 border rounded-md'>
        <h4 class='mb-8'><span class='font-semibold'>{{drilldownInvestmentName}}-{{field.custom_field_name}}</span> {{ $t('createEventConfirm') }}</h4>
        <button @click='hideConfirm' class='py-3 px-6 text-sm border border-transparent mr-4 hover:border-gray-800 rounded-md'>{{ $t('cancel') }}</button>
        <button @click='addToCalendarEvent' class='p-3 text-blue-700 text-sm border border-transparent hover:border-blue-400 rounded-md'>{{ $t('createEvent') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { CalendarIcon } from '@vue-hero-icons/outline'
import dayjs            from 'dayjs'
import cloneDeep        from 'lodash/cloneDeep'

export default {
  name: 'CustomFieldDateRange',
  props: ['field', 'isViewMode'],
  components: {
    CalendarIcon,
  },
  data () {
    return {
      calendarEvent: {},
      showAddCalendarEvent: false,
      pickerOptions: {
        shortcuts: [{
          text: this.$t('today'),
          onClick (picker) {
            const end   = dayjs().format('YYYY-MM-DD')
            const start = dayjs().format('YYYY-MM-DD')
            picker.$emit('pick', [start, end])
          }
        }, {
          text: this.$t('lastWeek'),
          onClick (picker) {
            const end   = dayjs().format('YYYY-MM-DD')
            const start = dayjs().subtract(7, 'day')
            picker.$emit('pick', [start, end])
          }
        }, {
          text: this.$t('lastMonth'),
          onClick (picker) {
            const end = dayjs().format('YYYY-MM-DD')
            const start = dayjs().subtract(1, 'month')
            picker.$emit('pick', [start, end])
          }
        }, {
          text: this.$t('last3months'),
          onClick (picker) {
            const end = dayjs().format('YYYY-MM-DD')
            const start = dayjs().subtract(3, 'month')
            picker.$emit('pick', [start, end])
          }
        }]
      },
    }
  },
  computed: {
    ...mapGetters('investments', [
      'drilldownInvestmentName'
    ]),
    dateRange: {
      get () {
        return this.field.value_json && this.field.value_json.length === 2 ? this.field.value_json : ['', '']
      },
      set (updatedValue) {
        let dates = (updatedValue) ? updatedValue.map(value => dayjs(value).format('YYYY-MM-DD')) : []
        this.handleUpdate(dates)
      }
    },
    startDate () {
      return this.dateRange ? dayjs(this.dateRange[0]).format('YYYY-MM-DD') : null
    },
    endDate () {
      return this.dateRange ? dayjs(this.dateRange[1]).format('YYYY-MM-DD') : null
    },
    isValidDate () {
      return (this.dateRange && this.startDate !== 'Invalid Date' && this.endDate !== 'Invalid Date')
    },
    dateString () {
      return (this.isValidDate) ? `${dayjs(this.dateRange[0]).format('YYYY년 MM월 DD일')} ~ ${dayjs(this.dateRange[1]).format('YYYY년 MM월 DD일')}` : ''
    },
  },
  methods: {
    ...mapActions('investmentEvents', [
      'createInvestmentEvent',
    ]),
    calendarEventParams (date, comment) {
      return {title: this.drilldownInvestmentName + '-' +this.field.custom_field_name,
              description: this.drilldownInvestmentName + ' ' + this.$t("createEvent") + '(' + comment + ')',
              event_on: dayjs(date).format('YYYY-MM-DD')}
    },
    addToCalendarEvent () {
      this.showAddCalendarEvent = false
      this.createInvestmentEvent(this.calendarEventParams(this.startDate, this.$t('startDay'))).then(() => {
        this.$message({
          type: 'success',
          message: this.$t('createEventSuccess')
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('createEventCancel')
        })
      })
      this.createInvestmentEvent(this.calendarEventParams(this.endDate, this.$t('endDay'))).then(() => {
        this.$message({
          type: 'success',
          message: this.$t('createEventSuccess')
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('createEventCancel')
        })
      })
    },
    hideConfirm () {
      this.showAddCalendarEvent = false
    },
    showConfirm () {
      this.showAddCalendarEvent = true
    },
    handleUpdate (value) {
      let updated = cloneDeep(this.field)
      updated.value = value.toString()
      updated.value_json = value
      this.$emit('update-value', updated)
    },
  },
}
</script>
