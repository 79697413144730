import base from './base'

const getCompanies = (filters={}) => base
  .get('/companies/', { params: filters }).then(resp => resp.data)

const getCompany = (companyId) => base
  .get(`/companies/${companyId}`).then(resp => resp.data)

const getCompanyEntities = (companyId) => base
  .get(`/companies/${companyId}/entities`).then(resp => resp.data)

const getCompanyCases = (companyId) => base
  .get(`/companies/${companyId}/cases`).then(resp => resp.data)

export default {
  getCompanies: getCompanies,
  getCompany: getCompany,
  getCompanyEntities: getCompanyEntities,
  getCompanyCases: getCompanyCases,
}
