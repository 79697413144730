<template>
  <table v-if='hasDeposits'
    class='table-fixed w-full mt-16 text-sm'>
    <tr class='uppercase font-semibold text-gray-600 border-gray-200 border-b'>
      <th class='pr-2 py-2'>{{ $t('depositor') }}</th>
      <th class='px-2 py-2'>{{ $t('depositDate') }}</th>
      <th class='px-2 py-2'>{{ $t('accountNo') }}</th>
      <th class='px-2 py-2 text-right'>{{ $t('financialAmount') }}</th>
      <th class='px-2 py-2'>{{ $t('depositStatus') }}</th>
      <th class='px-2 py-2'>{{ $t('updatedBy') }}</th>
    </tr>
    <tbody>
      <tr v-for='deposit in invoiceDeposits'
        :key='`deposit-${deposit.id}`'
        class='border-b border-gray-200'>
        <td class='pr-2 py-2'>{{ deposit.from }}</td>
        <td class='px-2 py-2'>{{ dateTimeFormat(deposit.deposit_date) }}</td>
        <td class='px-2 py-2'>{{ deposit.account_number }}</td>
        <td class='px-2 py-2 text-right'>{{ depositAmountString(deposit.amount) }}</td>
        <td class='px-2 py-2'>{{ depositStatusString(deposit.status) }}</td>
        <td class='px-2 py-2'>{{ deposit.updated_by_names }}</td>
      </tr>
    </tbody>
    <tfoot>
      <tr class='font-semibold uppercase'>
        <td></td>
        <td></td>
        <td class='px-2 py-2'>{{ $t('sum') }}</td>
        <td class='px-2 py-2 text-right'>{{depositAmountString(invoiceDepositedTotal)}}</td>
        <td></td>
        <td></td>
      </tr>
    </tfoot>
  </table>
  <div v-else
    class='max-w-6xl mx-auto text-center p-16 rounded-md bg-gray-100 my-8'>
    There are no deposits for this invoice. Go to <router-link :to='{ name: "Deposits" }' class='underline py-2 px-1 hover:bg-gray-200' target='_blank'>Deposits</router-link> to link.
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import StringHelpers from '@/utils/string-helpers'
import dayjs from 'dayjs'

export default {
  name: 'InvoiceDepositsDetails',
  watch: {
    'editingInvoice.id': {
      handler: function (newVal, oldVal) {
        if (newVal && oldVal !== newVal) {
          this.getInvoiceDeposits(newVal)
        }

      },
      immediate: true
    }
  },
  computed: {
    ...mapState('invoices', [
      'editingInvoice',
    ]),
    ...mapState('deposits', [
      'invoiceDeposits',
      'depositStatuses',
    ]),
    ...mapGetters('deposits', [
      'depositStatusString',
    ]),
    hasDeposits () {
      return this.invoiceDeposits.length > 0
    },
    invoiceDepositedTotal () {
      return (this.hasDeposits)  ? this.invoiceDeposits.reduce((sum, deposit) => { return sum + parseFloat(deposit.amount)}, 0) : 0
    }
  },
  methods: {
    ...mapActions('deposits', [
      'getInvoiceDeposits',
    ]),
    depositAmountString (amount) {
      return StringHelpers.koreanMoney(amount)
    },
    dateTimeFormat(depositDate) {
      return dayjs(depositDate).format('YYYY-MM-DD HH:mm:ss')
    },
  },
}
</script>
