import Vue                from 'vue'
import Vuex               from 'vuex'
import depositsApi        from '@/api/v1/deposits'
import depositInvoicesApi from '@/api/v1/deposit_invoices'
import dayjs from 'dayjs'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

const state = () => ({
  deposits: [],
  depositInvoices: [],
  invoiceDeposits: [],
  drilldownDeposit: {},
  filters: {
    startDate: dayjs().subtract(1, 'week'),
    endDate: dayjs(),
    statuses: ['not_attributed', 'partially_attributed'],
  },
  depositStatuses: [
    {label: '청구서 미반영',  value: 'not_attributed'},
    {label: '부분 입금 처리', value: 'partially_attributed'},
    {label: '입금 처리 완료', value: 'fully_attributed'}
  ]
})


const getters = {
  depositStatusString: state => statusValue => {
    let found = state.depositStatuses.find(el => el.value === statusValue)
    return (found) ? found.label : ''
  },
  filterString: state => {
    return JSON.stringify(state.filters)
  },
  filterApiParams: state => {
    return {
      start_date:       dayjs(state.filters.startDate).format('YYYY-MM-DD'),
      end_date:         dayjs(state.filters.endDate).format('YYYY-MM-DD'),
      deposit_statuses: state.filters.statuses,
    }
  },
  getField,
}


const actions = {
  depositAttachInvoicesOpen ({ commit, dispatch }, deposit) {
    commit('setDrilldownDeposit', deposit)
    dispatch('sidepanelOpen', { componentName: 'deposit-attach-invoices' }, { root : true })
  },
  getDeposits ({ getters, commit, dispatch }) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      depositsApi.getDeposits(getters.filterApiParams).then(resp => {
        commit('setDeposits', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  searchInvoices (context, filters) { // for case search
    return new Promise(resolve => {
      depositInvoicesApi.searchInvoices(filters).then(resp => {
        resolve(resp)
      })
    })
  },
  getDepositInvoices ({commit, dispatch }, filters) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      depositInvoicesApi.getDepositInvoices(filters).then(resp => {
        commit('setDepositInvoices', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  getInvoiceDeposits ({commit, dispatch }, invoiceId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      depositsApi.getInvoiceDeposits(invoiceId).then(resp => {
        commit('setInvoiceDeposits', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  updateDeposit ({ commit, dispatch }, deposit) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      depositsApi.updateDeposit(deposit.id, deposit).then(resp => {
        commit('updateDeposits', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  updateDepositInvoices ({ dispatch }, depositInvoices) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      depositInvoicesApi.postDepositInvoices(depositInvoices).then(resp => {
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    }).then(() => {
      let params = {investment_id: depositInvoices.investment_id, invoice_id: depositInvoices.invoice_ids}
      return dispatch('magams/createChangePerformances', params, { root : true })
    })
  },
  createDeposits ({ commit, dispatch }, deposits) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      depositsApi.postDeposits(deposits).then(resp => {
        commit('addDeposits', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  deleteDeposit ({ commit, dispatch }, depositId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      depositsApi.deleteDeposit(depositId).then(resp => {
        commit('removeDeposit', resp.id)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  resetDrilldownDeposit ({ commit }) {
    commit('setDrilldownDeposit', {})
    commit('setDepositInvoices', [])
  },
  updateFilterDateRange ({ commit }, dateRange) {
    commit('setFilterDateRange', dateRange)
  },
}


const mutations = {
  addDeposits (state, deposits) {
    state.deposits = state.deposits.concat(deposits)
  },
  addToDepositInvoices (state, depositInvoice) {
    state.depositInvoices.push(depositInvoice)
  },
  removeDeposit (state, depositId) {
    const index = state.deposits.findIndex(deposit => deposit.id === depositId)
    if (index >= 0) {
      state.deposits.splice(index, 1)
    }
  },
  setDeposits (state, fromApi) {
    state.deposits = fromApi
  },
  setDepositInvoices (state, fromApi) {
    state.depositInvoices = fromApi
  },
  setInvoiceDeposits (state, fromApi) {
    state.invoiceDeposits = fromApi
  },
  setDrilldownDeposit (state, deposit) {
    state.drilldownDeposit = deposit
    state.drilldownDeposit.deposit_date = new Date(state.drilldownDeposit.deposit_date)
  },
  setFilterDateRange (state, dateRange) {
    state.filterStartDate = dateRange.startDate
    state.filterEndDate   = dateRange.endDate
  },
  updateDeposits (state, updatedDeposit) {
    const index = state.deposits.findIndex(deposit => deposit.id === updatedDeposit.id)
    if (index >= 0) {
      state.deposits.splice(index, 1, updatedDeposit)
    }
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
