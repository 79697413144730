<template>
  <div>
    <a v-if='viewLink' :href='url' target='_blank'><external-link-icon /></a>
    <iframe v-else-if='url' id='sharepoint-iframe' :src='altUrl' class='w-full' style='height: calc(100vh - 16rem);' type='text/html' @load='checkIframeExtension'></iframe>
    <div v-if='!url'>
      <p>{{ $t('sharepointFolderCreated') }}</p>
      <button
        class='border rounded-md w-24 h-10 text-white text-sm font-semibold mt-4'
        style='background-color: #223645;'
        :disabled='disabled'
        @click='retryCreateFolder'>{{ $t('tryAgain') }}</button>
    </div>
    <p id='extensionIframeMessageContainer' class='mt-2' v-show='!hasExtension'></p>
  </div>
</template>

<script>

import { ExternalLinkIcon } from '@vue-hero-icons/outline'
import { mapActions, mapGetters }        from 'vuex'
import ChromeExtensionHelpers from '@/utils/chrome-extension-helpers'

export default {
  name: 'MsFiles',
  components: { ExternalLinkIcon },
  data () {
    return {
      disabled: false,
      hasExtension: false
    }
  },
  computed: {
    ...mapGetters('investments', [
      'drilldownInvestmentMsDriveUrls',
    ]),
    url () {
      let folderStr = encodeURIComponent(this.$t('file'))
      let found = this.drilldownInvestmentMsDriveUrls.find(url => url.endsWith(folderStr))
      return found ? found : ''
    },
    altUrl() {
      if (this.url) {
        let parts = this.url.split(/sharepoint\.com/)
        let encodedId = parts[1].replace(/\(/g, '%28').replace(/\)/g, '%29').replace(/\//g, '%2F').replace(/-/g, '%2D').replace(/~/g, '%7E').replace(/\[/g, '%5B').replace(/\]]/g, '%5D').replace(/\{/g, '%7B').replace(/\}/g, '&7D')
        return `${parts[0]}sharepoint.com/sites/CaseManagementSystem/Shared%20Documents/Forms/AllItems.aspx?id=${encodedId}`
      } else {
        return ''
      }
    },
    viewLink () {
      return this.url && !this.hasExtension
    }
  },
  methods: {
    ...mapActions('investments', [
      'createMsFolder',
    ]),
    retryCreateFolder () {
      this.disabled = true
      this.createMsFolder().then(() => {
        setTimeout((() => this.disabled = false), 5000)
      })
    },
    checkIframeExtension () {
      ChromeExtensionHelpers.checkIframeExtension().then(() => {
        this.hasExtension = ChromeExtensionHelpers.hasExtension
      })
    },
  },
  mounted() {
    if (!this.hasExtension) {
      this.checkIframeExtension()
    }
  },
}
</script>
