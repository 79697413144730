import base from './base'

const getExpenses = (filters) => base
  .get('/expenses/', { params: filters }).then(resp => resp.data)

const postExpense = (expenseParams, contentType='application/json') => base
  .post('/expenses/', expenseParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const updateExpense = (expenseId, expenseParams, contentType='application/json') => base
  .patch(`/expenses/${expenseId}`, expenseParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const batchUpdateExpenseInoviceIds = (expenseIds, invoiceId) => base
  .patch(`/expenses/batch_update_invoice_ids`, 
    {
      expense_ids: expenseIds,
      invoice_id:  invoiceId
    }).then(resp => resp.data)

const deleteExpense = (expenseId) => base
  .delete(`/expenses/${expenseId}`).then(resp => resp.data)

const getExpenseAttachments = (filters) => base
  .get('/expense_attachments/', { params: filters }).then(resp => resp.data)

const postExpenseAttachment = (params) => base
  .post('/expense_attachments/', params).then(resp => resp.data)

const updateExpenseAttachment = (attachmentId, params) => base
  .patch(`/expense_attachments/${attachmentId}`, params).then(resp => resp.data)

const deleteExpenseAttachment = (attachmentId) => base
  .delete(`/expense_attachments/${attachmentId}`).then(resp => resp.data)

export default {
  getExpenses:                  getExpenses,
  postExpense:                  postExpense,
  updateExpense:                updateExpense,
  batchUpdateExpenseInoviceIds: batchUpdateExpenseInoviceIds,
  deleteExpense:                deleteExpense,
  getExpenseAttachments:        getExpenseAttachments,
  postExpenseAttachment:        postExpenseAttachment,
  updateExpenseAttachment:      updateExpenseAttachment,
  deleteExpenseAttachment:      deleteExpenseAttachment,
}
