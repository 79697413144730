<template>
  <case-schedule-row-edit v-if='editMode'
    :schedule='schedule'
    @done-update='viewMode'
    />
  <case-schedule-row-view v-else
    :schedule='schedule'
    @edit-schedule='editSchedule' />
</template>

<script>
import CaseScheduleRowEdit from '@/views/dashboard/CaseScheduleRowEdit.vue'
import CaseScheduleRowView from '@/views/dashboard/CaseScheduleRowView.vue'

export default {
  name: 'CaseScheduleRow',
  components: {
    CaseScheduleRowEdit,
    CaseScheduleRowView,
  },
  props: ['schedule'],
  data () {
    return {
      editMode: false,
    }
  },
  methods: {
    viewMode () {
      this.editMode = false
    },
    editSchedule () {
      this.editMode = true
    },
  },
}
</script>
