<template>
  <div 
    slot='content'
    class='px-2 lg:px-8 mt-8'>
    <div class='flex flex-row gap-x-4'>
      <h1 class='py-2 text-lg lg:text-xl font-semibold'>{{ $t('timesheetDetails') }}</h1>
    </div>
    <button 
      @click='exportExcel'
      class='block border px-4 py-2 text-gray-700 text-sm font-semibold rounded-md hover:border-blue-300 hover:shadow-md'>
      {{ $t('excelDownload') }}
    </button>
    <div class='mt-8'>
      <table 
        id='lawyerTimesheet' 
        class='table-fixed'>
        <thead>
          <tr class='whitespace-no-wrap border-b border-gray-200 text-sm uppercase'>
            <th class='py-2 px-3 text-right'>{{ $t('workType') }}</th>
            <th class='py-2 px-3 text-right'>{{ $t('detailedWorkType') }}</th>
            <th class='py-2 px-3 text-left'>{{ $t('caseId') }}</th>
            <th class='py-2 px-3 text-left'>{{ $t('caseName') }}</th>
            <th class='py-2 px-3 text-left'>{{ $t('client') }}</th>
            <th class='py-2 px-3 text-left'>{{ $t('counterpart') }}</th>
            <th class='py-2 px-3 text-left'>{{ $t('lawyer') }}</th>
            <th class='py-2 px-3 text-left'>{{ $t('salesLawyerShortened') }}</th>
            <th class='py-2 px-3 text-left'>{{ $t('performanceLawyerShortened') }}</th>
            <th class='py-2 px-3 text-left'>{{ $t('workDate') }}</th>
            <th class='py-2 px-3 text-left'>{{ $t('workHours') }}</th>
            <th class='py-2 px-3 text-left'>{{ $t('billingTime') }}</th>
            <th class='py-2 px-3 text-left'>{{ $t('description') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for='summary in drilldownLawyerTimeSheets'
              :key='`${summary.id}-${summary.casenum}-${summary.work_date}-${summary.work_type_name}`'
              class='border-b border-gray-200 text-xs'>
            <td class='py-2 px-3 whitespace-no-wrap'>{{summary.work_type_name}}</td>
            <td class='py-2 px-3 whitespace-no-wrap'>{{ (showDetailedWorkType(summary.work_type)) ? summary.detailed_work_type : ""}}</td>
            <td class='py-2 px-3 whitespace-no-wrap'>{{summary.casenum}}</td>
            <td class='py-2 px-3 whitespace-no-wrap'>{{summary.investment_name}}</td>
            <td class='py-2 px-3 whitespace-no-wrap'>{{summary.entity_name}}</td>
            <td class='py-2 px-3 whitespace-no-wrap'>{{summary.counterparty}}</td>
            <td class='py-2 px-3'>{{summary.lawyer_name}}</td>
            <td class='py-2 px-3 whitespace-no-wrap'>{{summary.sales_lawyer}}</td>
            <td class='py-2 px-3'>{{summary.performance_lawyer}}</td>
            <td class='py-2 px-3 whitespace-no-wrap'>{{summary.work_date}}</td>
            <td class='py-2 px-3 text-right'>{{summary.work_hours}}</td>
            <td class='py-2 px-3 text-right'>{{summary.billable_hours}}</td>
            <td class='py-2 px-3'>{{summary.description}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import * as XLSX    from 'xlsx-js-style'

export default {
  name: 'TimeSheets',
  computed: {
    ...mapState('lawyerPerformanceReports', [
      'drilldownLawyerTimeSheets'
    ]),
  },
  methods: {
    exportExcel () {
      let lawyer_name = this.drilldownLawyerTimeSheets ? this.drilldownLawyerTimeSheets[0].lawyer_name : ''
      var excelData = XLSX.utils.table_to_sheet(document.getElementById('lawyerTimesheet')); // table id를 넣어주면된다
      var workBook = XLSX.utils.book_new(); // 새 시트 생성
      XLSX.utils.book_append_sheet(workBook, excelData, '타임시트 상세내역');  // 시트 명명, 데이터 지정
      XLSX.writeFile(workBook, `${lawyer_name}_타임시트 상세내역.xlsx`); // 엑셀파일 만듬
    },
    showDetailedWorkType (workType) {
      return workType && workType.toLowerCase() === 'general'
    },
  },
}
</script>
