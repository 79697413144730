<template>
  <div>
    <h2 class='text-lg font-semibold uppercase text-gray-800'>{{ $t('filters') }}</h2>
    <dashboard-view-editing-filters-create-rule @update:addrule='addRule' />
    <dashboard-view-editing-filters-existing-rule
      v-for='(rule, index) in filterExpressions'
      :key='`dashboard-filter-layer-1-rule-${index}-${firstRule}`'
      :rule='rule'
      :expressions-index='index'
      @update:removerule='removeRule' />
    <div class='flex flex-row justify-start'>
      <label class='p-2 text-left text-gray-500 font-light cursor-pointer hover:bg-gray-200'>
      <input type='checkbox'
        class='form-checkbox mr-1'
        name='show_created_by_me'
        v-model='show_created_by_me'>
        <span class='text-xs whitespace-no-wrap'>{{ $t('caseCreatedByMe') }}</span>
    </label>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import DashboardViewEditingFiltersCreateRule   from '@/views/dashboard/DashboardViewEditingFiltersCreateRule.vue'
import DashboardViewEditingFiltersExistingRule from '@/views/dashboard/DashboardViewEditingFiltersExistingRule.vue'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'DashboardViewEditingFilters',
  components: {
    DashboardViewEditingFiltersCreateRule,
    DashboardViewEditingFiltersExistingRule
  },
  computed: {
    ...mapState('dashboardViews', [
      'currentDashboardView'
    ]),
    ...mapFields('dashboardViews', [
      'currentDashboardView.show_created_by_me',
    ]),
    filterExpressions () {
      return this.currentDashboardView.filters.expressions
    },
    filterLogicalOperator () {
      return this.currentDashboardView.filters.logical_operator
    },
    firstRule () {
      return this.currentDashboardView.filters.expressions[0]
    },
  },
  methods: {
    ...mapMutations('dashboardViews', [
      'updateCurrentDashboardViewFilters'
    ]),
    addRule (newRule) {
      let newFilters = cloneDeep(this.currentDashboardView.filters)
      newFilters.expressions.push(newRule)
      this.updateCurrentDashboardViewFilters(newFilters)
    },
    removeRule (index) {
      let newFilters = cloneDeep(this.currentDashboardView.filters)
      newFilters.expressions.splice(index, 1)
      this.updateCurrentDashboardViewFilters(newFilters)
    },
  },
}
</script>
