<template>
  <div class='relative'>
    <vue-editor
      v-model='newMessage'
      :placeholder="$t('newComment')"
      :editor-options='editorOptions'
      class='h-full'>
    </vue-editor>
    <div 
      class='absolute flex flex-row justify-end items-center gap-x-1'
      style='top: 0.375rem; right: 0.25rem;'>
      <button
        class='rounded-md px-4 py-2 text-white text-xs font-semibold '
        style='background-color: #223645;'
        :disabled='emptyComment'
        @click='submit()'>
        {{ $t('save') }}
      </button>
      <button
        class='border border-gray-400 rounded-md px-4 py-2 text-gray-800 text-xs'
        @click='cancel()'>
        {{ $t('cancel') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { VueEditor } from 'vue2-editor'

export default {
  name: 'CommentInputEdit',
  props: ['comment'],
  components: {
    VueEditor,
  },
  data () {
    return {
      editorFocused: null,
      editorOptions:{
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline'],
              [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
              ['clean'],
            ],
          }
        }
      },
      newMessage: this.comment.value_after,
    }
  },
  computed: {
    emptyComment () {
      return this.newMessage.trim() === ''
    },
  },
  methods: {
    ...mapActions('changeLogs', [
      'updateComment'
    ]),
    submit () {
      let comment = {
        id: this.comment.id,
        investmentId: this.comment.investment_id,
        value:        this.newMessage,
      }
      this.updateComment(comment).then(() => {
        this.newMessage = ''
        this.$emit('done-editing', this.comment)
      })
    },
    cancel () {
      this.$emit('done-editing', this.comment)
    },
  }
}
</script>
