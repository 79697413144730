<template>
  <div class='mb-4 flex flex-row justify-start items-start'>
    <label class='inline-block w-32 flex-none leading-10'>{{title}}</label>
    <div class='overflow-x-auto flex-grow'>
      <div>
        <table class='table-auto'>
          <thead>
            <tr>
              <th class='border px-2 py-2 text-left text-sm whitespace-nowrap w-3/12'
                v-for='header in headers'
                :key='`jail-infos-${header}-${caseInfo.id}-${caseInfo.rand}`'>
                {{header}}
              </th>
              <th class='border px-2 py-2 w-1/12'></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for='(row, index) in jailInfos' :key='`jail-row-${caseInfo.id}-${caseInfo.rand}-${index}`'>
              <td class='border text-left px-2 py-2'
                style='min-width: 120px;'
                v-for='header in headers'
                :key='`jail-col-${header}-${caseInfo.id}-${caseInfo.rand}-${index}`'>
                <input class='form-input w-full' v-model="jailInfos[index][header]" @change='updateJailInfos'>
              </td>
              <td class='border text-center px-2 py-2'>
                <button
                  @click='removeRow(index)'>
                  <x-circle-icon class='inline-bock text-red-900 opacity-75' />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <button
          @click='addBlankRow'
          class='mt-2 mb-4 px-4 py-2 rounded text-sm border border-transparent hover:border-blue-300 hover:shadow'>
          <plus-circle-icon class='inline-block text-gray-700' style='margin-top: -4px' />
          {{ $t('add') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { PlusCircleIcon, XCircleIcon } from '@vue-hero-icons/outline'
import { mapMutations } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'JailInfos',
  props: [
    'caseInfo',
    'headers',
    'title'
  ],
  components: { PlusCircleIcon, XCircleIcon },
  data () {
    return {
      jailInfos: cloneDeep(this.caseInfo.jail_infos),
    }
  },
  methods: {
    ...mapMutations('investments', [
      'updateDrilldownCaseInfoJailInfos',
    ]),
    addBlankRow () {
      let blankRow = {}
      this.headers.forEach(header => blankRow[header] = '')
      this.jailInfos.push(blankRow)
      this.updateJailInfos()
    },
    removeRow (index) {
      this.jailInfos.splice(index, 1)
      this.updateJailInfos()
    },
    updateJailInfos () {
      this.updateDrilldownCaseInfoJailInfos({caseInfo: this.caseInfo, jailInfos: this.jailInfos})
    },
  },
}
</script>
