import base from './base'

const getUsers = () => base
  .get('/users/').then(resp => resp.data)

const getUserGroups = () => base
  .get('/user_groups/').then(resp => resp.data)

const getUsersByIds = (userIds) => base
  .get('/users/users_by_ids/', { params: {user_ids: userIds} }).then(resp => resp.data)

const getCurrentUserInfo = () => base
  .get('/users/current_user_info').then(resp => resp.data)

const getTeams = (filters) => base
  .get('/users/teams', { params: filters }).then(resp => resp.data)

const searchUsers = (filterParams) => base
  .get('/users/search_by_text/', { params: filterParams }).then(resp => resp.data)

const postUserGroup = (params) => base
  .post('/user_groups/', params).then(resp => resp.data)

const putUser = (params) => base
  .put(`/users/${params.id}`, params).then(resp => resp.data)

const putUserGroup = (params) => base
  .put(`/user_groups/${params.id}`, params).then(resp => resp.data)

const deleteUser = (userID) => base
  .delete(`/users/${userID}`).then(resp => resp.data)

const reinstateUser = (userID) => base
  .patch(`/users/reinstate/?id=${userID}`).then(resp => resp.data)

const getCurrentUserConfigurations = () => base
  .get('/users/user_configurations').then(resp => resp.data)

const updateMyDefaultHourlyRate = (params) => base
  .patch('/users/edit_user_configurations', {'configurations' : {'my_default_hourly_rate' : params}}).then(resp => resp.data)

export default {
  getUsers: getUsers,
  getUserGroups: getUserGroups,
  getCurrentUserInfo: getCurrentUserInfo,
  getTeams: getTeams,
  postUserGroup: postUserGroup,
  putUser: putUser,
  putUserGroup: putUserGroup,
  searchUsers: searchUsers,
  getUsersByIds: getUsersByIds,
  deleteUser: deleteUser,
  reinstateUser: reinstateUser,
  getCurrentUserConfigurations: getCurrentUserConfigurations,
  updateMyDefaultHourlyRate: updateMyDefaultHourlyRate,
}
