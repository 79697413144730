import base from './base'

const getSmsMessages = (filters) => base
  .get('/sms_messages/', { params: filters }).then(resp => resp.data)

const postSmsMessage = (params) => base
  .post('/sms_messages/', params).then(resp => resp.data)

export default {
  getSmsMessages: getSmsMessages,
  postSmsMessage: postSmsMessage,
}
