<template>
  <el-timeline-item v-if='hasColumnKeys' :timestamp='timestamp'
    placement='top'>
    <slot />
  </el-timeline-item>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from 'dayjs'

export default {
  name: 'TimelineItem',
  props: [ 'log' ],
  computed: {
    ...mapGetters('changeLogs', [
      'getColumnName',
    ]),
    hasColumnKeys () {
      // this.log.value_before is `null` after submitting a comment. should be investigated further
      return this.log.value_before && Object.keys(this.log.value_before).filter(key => this.getColumnName(this.log.event_object, key) !== '').length > 0
    },
    objectLabel () {
      if (this.log.event_object === 'investment') {
        return 'case'
      } else if (['commission', 'earning'].includes(this.log.event_object)) {
        return this.$t('contribution')
      } else {
        return this.log.event_object
      }
    },
    timestamp () {
      let createdAt = this.log.eventType === 'comment' ? dayjs(this.log.created_at).format('HH:mm') : ''
      return `${dayjs(this.log.created_at).format('YYYY-MM-DD HH:mm:ss')}: ${this.log.username} ${this.log.event_type} ${this.objectLabel} ${this.log.value_after.id || ''} ${createdAt} [${this.log.investment_name}]`
    },
  },
}
</script>
