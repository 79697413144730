<template>
  <div class='flex flex-row justify-start'>
    <select v-model='selectedCustomFieldTemplate'
      class='form-select w-40'>
      <option
        v-for='template in filterableCustomFields'
        :key='`parent-template-${template.id}`'
        :disabled='isOrganizingCustomField(template.field_type)'
        :value='template'>
        <span v-if='isOrganizingCustomField(template.field_type)'>----</span>
        {{template.custom_field_name}}
        <span v-if='isOrganizingCustomField(template.field_type)'>----</span>
      </option>
    </select>
    <select v-if='hasFieldType'
      v-model='selectedOperator'
      class='form-select w-20'>
      <option v-for='operator in operators' :key='`operator-${operator}`'>
        {{operator}}
      </option>
    </select>



    <input v-if='isTextType'
      type='text'
      v-model='selectedValue'
      class='form-input flex-grow w-full h-12 block'/>
    <input v-else-if='isNumberType'
      type='number'
      v-model.number='selectedValue'
      class='form-input flex-grow w-full h-12 block'/>

    <!-- <select v-else-if='isSelectType'
      v-model='selectedValue'
      class='form-select' style='width: calc(100% - 18rem);'>
      <option
        v-for='selectableValue in selectableTemplateValues'
        :key='`parent-template-value-${selectableValue}`'>
        {{selectableValue}}
      </option>
    </select> -->
    <v-select v-else-if='isSelectType'
        class='form-select' style='width: calc(100% - 18rem);'
        v-model='selectedValues'
        :options='selectableTemplateValues'
        :close-on-select='false'
        multiple>
    </v-select>

    <el-date-picker v-else-if='isDateType'
      v-model='selectedValue'
      format='yyyy-MM-dd'
      type='date'
      class='rule-date-picker'
      :placeholder="$t('pickDay')">
    </el-date-picker>

    <select v-else-if='isContactType'
      v-model='selectedValue'
      class='form-select'
      style='width: calc(100% - 18rem);'>
      <option
        v-for='contact in contacts'
        :key='`parent-template-value-${contact.id}`'
        :value='contact.id'>
        {{contact.contact_name}} ({{contact.email}})
      </option>
    </select>

    <select v-else-if='isUserType'
      v-model='selectedValue'
      class='form-select'
      style='width: calc(100% - 18rem);'>
      <option
        v-for='user in users'
        :key='`parent-template-value-${user.id}`'
        :value='user.id'>
        {{user.name}} ({{user.email}})
      </option>
    </select>

    <div v-else class='pl-4 py-3 flex-grow text-gray-500 text-sm border-gray-100 bg-gray-100'>
      {{ $t('selectField') }}
    </div>

    <select v-if='showAndOrOperator'
      v-model='logical_operator'
      class='form-select'
      style='width: calc(100% - 18rem);'>
      <option value='and'>and</option>
      <option value='or'>or</option>
    </select>

    <button
      v-if='hasFieldType'
      class='border p-3 bg-gray-200 rounded-r-md text-sm text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md disabled:cursor-not-allowed'
      style='margin-left: -2px;'
      :disabled='disableAddCondition'
      @click='addRule'>
      <plus-circle-icon class='inline-block' style='margin-top: -2px;' />
    </button>
  </div>
</template>
<script>
import { PlusCircleIcon } from '@vue-hero-icons/outline'
import { mapState, mapGetters } from 'vuex'
import EventBus from '@/utils/event-bus'
import { mapFields } from 'vuex-map-fields'
export default {
  name: 'CustomFieldTemplateCreateRule',
  components: {
    PlusCircleIcon,
  },
  data () {
    return {
      numberOperators: [
        '=',
        '≠',
        '>',
        '≥',
        '<',
        '≤',
      ],
      stringOperators: [
        'includes',
      ],
      dateOperators: [
        'before',
        'after',
      ],
      selectedCustomFieldTemplate: {},
      selectedOperator: '',
      selectedValue: null,
      selectedValues: [],
    }
  },
  watch: {
    selectedCustomFieldTemplate () {
      this.selectedOperator = ''
      this.selectedValue = null
      this.selectedValues = []
    },
  },
  computed: {
    ...mapFields('dashboardViews', [
      'currentDashboardView.filters.logical_operator'
    ]),
    ...mapState('contacts', [
      'contacts',
    ]),
    ...mapGetters('customFields', [
      'filterableCustomFields'
    ]),
    ...mapState('users', [
      'users',
    ]),
    disableAddCondition () {
      return this.selectedOperator === '' // assume to have an operator, you need to have a template
    },
    hasFieldType () {
      return this.selectedCustomFieldTemplate.field_type &&
             this.selectedCustomFieldTemplate.field_type !== ''
    },
    showAndOrOperator () {
      // AND / OR 선택 가능하도록 추후에 수정. (현재는 default and)
      // this.selectedCustomFieldTemplate && this.selectedOperator && this.selectedValue
      return false
    },
    fieldType () {
      return (this.hasFieldType) ? this.selectedCustomFieldTemplate.field_type : ''
    },
    hasSelectedTemplate () {
      return this.selectedCustomFieldTemplate != {}
    },
    selectableTemplateValues () {
      return this.selectedCustomFieldTemplate.selectable_values
    },
    isSelectType () {
      return this.hasFieldType && ['dropdown', 'checkbox', 'multi-select'].includes(this.fieldType)
    },
    isDateType () {
      return this.hasFieldType && ['date', 'date-range'].includes(this.fieldType)
    },
    isTextType () {
      return this.hasFieldType && ['text', 'paragraph'].includes(this.fieldType)
    },
    isNumberType () {
      return this.hasFieldType && ['number'].includes(this.fieldType)
    },
    isContactType () {
      return this.hasFieldType && this.fieldType === 'contact'
    },
    isUserType () {
      return this.hasFieldType && this.fieldType === 'user'
    },
    operators () {
      switch (this.fieldType) {
        case 'number':
          return this.numberOperators
        case 'date':
        case 'date-range':
          return this.dateOperators
        case 'paragraph':
        case 'text':
        case 'url':
          return this.stringOperators
        case 'dropdown':
        case 'checkbox':
        case 'multi-select':
        case 'contact':
        case 'user':
          return this.stringOperators // TODO (Hana: 2021-4-28) maybe these should be different?
        default:
          return []
      }
    },
    ruleIsNotEmpty () {
      return this.selectedCustomFieldTemplate.id &&
             this.selectedCustomFieldTemplate.field_type &&
             this.selectedOperator &&
             (this.selectedValue || this.selectedValues)
    },
    selectedRule () {
      return {
        selectedTemplateId:        this.selectedCustomFieldTemplate.id,
        selectedFieldType:         this.selectedCustomFieldTemplate.field_type,
        selectedOperator:          this.selectedOperator,
        selectedValue:             this.selectedValue,
        selectedValues:            this.selectedValues
      }
    },
  },
  methods: {
    addRule () {
      this.$emit('add-rule', this.selectedRule)
      this.resetInput()
    },
    checkRuleAdd () {
      if (this.ruleIsNotEmpty) {
        this.addRule()
      }
    },
    isOrganizingCustomField (fieldType) {
      return fieldType === 'sections' || fieldType === 'header'
    },
    resetInput () {
      this.selectedOperator = ''
      this.selectedCustomFieldTemplate = {}
      this.selectedValue = null
      this.selectedValues = []
    },
  },
  mounted () {
    EventBus.$on('check-add-rule', () => this.checkRuleAdd())
  },
  beforeDestroy () {
    EventBus.$off('check-add-rule')
  }
}
</script>
