<template>
  <div>
    <div v-if='hasRule' class='p-3 border-l border-r border-b flex flex-row justify-between'>
      <div class='flex flex-row justify-between'>
        <div class='font-medium uppercase'>{{customFieldTemplateName}}</div>
        <div class='mx-4 w-24 text-center'>{{rule.operator}}</div>
        <div class='mr-4 font-medium whitespace-pre-line' v-if='isMultiRule'>{{displayValues}}</div>
        <div class='mr-4 font-medium' v-else>{{displayValue(rule.custom_field_type, rule.custom_field_value)}}</div>
      </div>
      <button @click="$emit('update:removerule', expressionsIndex)" class='text-red-800'><x-circle-icon class='inline-block' style='margin-top: -4px;'/></button>
    </div>
  </div>
</template>
<script>
import { XCircleIcon }          from '@vue-hero-icons/outline'
import { mapGetters, mapActions } from 'vuex'
import dayjs from 'dayjs'
export default {
  name: 'DashboardViewEditingFiltersExistingRule',
  components: {
    XCircleIcon,
  },
  props: [
    'rule',
    'expressionsIndex',
  ],
  data () {
    return {
      results: [],
    }
  },
  computed: {
    ...mapGetters('contacts', [
      'contactNameFromId',
    ]),
    ...mapGetters('customFields', [
      'customFieldNameFromId'
    ]),
    isMultiRule () {
      return this.rule.custom_field_values
    },
    hasRule () {
      return this.rule.operator !== undefined
    },
    customFieldTemplateId () {
      var customFieldTemplateId = null

      if (this.isMultiRule) {
        customFieldTemplateId = this.rule.custom_field_template_id
      } else if (this.hasRule) {
        customFieldTemplateId = this.rule.custom_field_template_id
      }

      return customFieldTemplateId
    },
    customFieldTemplateName () {
      var customFieldTemplateName = null

      if (this.isMultiRule) {
        customFieldTemplateName = this.customFieldTemplateId === 0 ?
                                    this.rule.custom_field_type + ' TYPE' :
                                    this.customFieldNameFromId(this.customFieldTemplateId)
      } else if (this.hasRule) {
        customFieldTemplateName = this.customFieldTemplateId === 0 ?
                                    this.rule.custom_field_type + ' TYPE' :
                                    this.customFieldNameFromId(this.customFieldTemplateId)
      }

      return customFieldTemplateName
    },
    displayValues () {
      if (this.rule.custom_field_type === 'user') {
        return this.results.map(user => `${user.name} (${user.email})`).join('\n')
      } else {
        return this.rule.custom_field_values.map((value) => {
          return this.displayValue(this.rule.custom_field_type, value)
        }).join(' or ')
      }
    },
  },
  methods: {
    ...mapActions('users', [
      'getUsersByIds',
    ]),
    displayValue (fieldType, value) {
      if (fieldType === 'contact') {
        return this.contactNameFromId(parseInt(value))
      } else if (fieldType === 'date') {
        return dayjs(value).format('YYYY-MM-DD')
      } else {
        return value
      }
    },
  },
  mounted () {
    if (this.rule.custom_field_type === 'user') {
      this.getUsersByIds(this.rule.custom_field_values).then(resp => {
        this.results = resp
      })
    }
  }
}
</script>
