<template>
  <span v-if='isInTableView'>
    {{fileName}}
  </span>
  <div v-else-if='isViewMode'>
    <div v-if='hasFile && fileURL'
      @click.stop='downloadFile'
      class='flex flex-row justify-between items-center filename-label border border-transparent cursor-pointer p-1 text-sm'>
      <label class='cursor-pointer'>{{ uploadText }}</label>
      <span class='flex flex-row items-center gap-x-px px-2 text-xs text-gray-600 opacity-75'>
        <document-download-icon class='inline-block h-5' />
        {{ $t('download') }}
      </span>
    </div>
    <span v-else
      class='text-gray-400'>
      {{ $t('noFile') }}
    </span>
    <div v-if='showMetadata'
      class='flex flex-row justify-start items-stretch leading-4 font-light text-xs p-1'>
      <h3 v-if='updatingField.metadata.file_date' class='text-gray-700'>{{ updatingField.metadata.file_date }}</h3>
      <h3 v-if='updatingField.metadata.file_type' class='pl-4 pr-2 text-gray-500'>{{ updatingField.metadata.file_type }}:</h3>
      <h3 v-if='updatingField.metadata.content_description' class='flex-grow text-gray-500'>{{ updatingField.metadata.content_description }}</h3>
    </div>
  </div>

  <div v-else>
    <div class='border rounded-t-md text-sm'>
      <div class='flex flex-row justify-between items-center'>
        <label class='pl-2 flex-grow hover:bg-gray-100 cursor-pointer upload-label'>
          {{ uploadText }}
          <input
            @change='fileSelect'
            type='file'
            ref='customFieldFile'
            class='bg-pink-200 hidden'>
        </label>
        <div v-if='hasFileOrPendingFile'
          @click.stop='downloadFile'
          class='flex flex-row items-center gap-x-px hover:bg-gray-100 px-2 text-xs text-gray-600 hover:text-gray-900 hover:underline'>
          <document-download-icon class='inline-block h-5' />
          {{ $t('download') }}
        </div>
      </div>
    </div>
    <div
      class='file-metadata flex flex-row justify-start items-stretch border-l border-b border-r rounded-b-md text-sm'>
      <el-date-picker
        v-model='updatingField.metadata.file_date'
        type='date'
        value-format='yyyy-MM-dd'
        :placeholder="$t('date')"
        style='width:8.5rem; border-color: transparent;'
        @blur='metadataChange' />
      <select
        v-model='updatingField.metadata.file_type'
        class='form-select w-24 h-10 rounded-none border-b-0 border-t-0'
        @change='metadataChange'>
        <option value='' disabled selected>{{ $t('division') }}</option>
        <option value='수신'>{{ $t('reception') }}</option>
        <option value='제출'>{{ $t('submission') }}</option>
        <option value='기타'>{{ $t('other') }}</option>
      </select>
      <input
        type='text'
        class='form-input h-10 flex-grow border-transparent'
        v-model='updatingField.metadata.content_description'
        :placeholder="$t('documentContent')"
        @blur='metadataChange'>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { DocumentDownloadIcon } from '@vue-hero-icons/outline'
import axios from 'axios'
import dayjs from 'dayjs'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'CustomFieldFile',
  components: {
    DocumentDownloadIcon,
  },
  props: [
    'field',
    'isViewMode'
  ],
  data () {
    return {
      fileInput: null,
      updatingField: {},
    }
  },
  watch: {
    'updatingField.file_url': {
      handler: function (newVal) {
        if (!newVal && !this.isViewMode) { // if the file_url doesn't exist
          this.resetFileMetadata()
        }
      },
      immediate: true
    }
  },
  computed: {
    fileURL () {
      return (this.updatingField.file_url) || ''
    },
    hasFileOrPendingFile () {
      return this.hasFile || this.hasPendingFile
    },
    hasFile () {
      return this.updatingField.value
    },
    hasPendingFile () {
      return (this.fileInput !== null)
    },
    fileName () {
      if (this.updatingField.display_value !== null ||
          this.updatingField.display_value !== '') {
        return this.updatingField.display_value
      } else if (this.hasPendingFile) {
        return this.fileInput.name
      } else {
        return ''
      }
    },
    uploadText () {
      if ([null, ''].includes(this.updatingField.display_value)) {
        return this.$t('uploadNewFile')
      } else {
        return this.fileName
      }
    },
    isInTableView () {
      return !('file_url' in this.updatingField) // the file custom field doens'tt have a url in the list view, but does in the sidepanel
    },
    showMetadata () {
      return this.hasFile
    },
  },
  methods: {
    ...mapMutations(['setIsLoading']),
    downloadFile () {
      if (this.fileURL.includes('ftp')) {
        let options = { responseType: 'blob', headers: { Authorization: `Bearer ${localStorage.getItem('access_token.bbridge_reach')}`} }
        this.setIsLoading(true)
        axios.get(this.fileURL, options)
          .then(response => {
            let blob = new Blob([response.data], { type: response.headers['content-type'] })
            let link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = this.fileName
            document.body.appendChild(link)
            link.click()
            URL.revokeObjectURL(link.href)
            link.remove()
          }).catch(async (err) => {
            if (err.response.status === 413) {
              alert(await err.response.data.text())
            } else {
              alert(this.$t('noFileAlert'))
            }
          }).finally(() => {
            this.setIsLoading(false)
          })
      } else {
        window.open(this.fileURL, '_blank')
      }
    },
    fileSelect () {
      this.fileInput           = this.$refs.customFieldFile.files[0]
      this.updatingField.value         = this.$refs.customFieldFile.files[0]
      this.updatingField.display_value = this.$refs.customFieldFile.files[0].name
      this.$emit('update-value', {...this.field,
                                  value: this.updatingField.value,
                                  display_value: this.updatingField.display_value,
                                  })
    },
    metadataChange () {
      this.$emit('update-value', {...this.updatingField})
    },
    resetFileMetadata () {
      this.updatingField.metadata.file_date           = dayjs().format('YYYY-MM-DD')
      this.updatingField.metadata.file_type           = ''
      this.updatingField.metadata.content_description = ''
    },
  },
  mounted () {
    this.updatingField = cloneDeep(this.field)
  },
}
</script>

<style lang='scss'>
  .file-metadata .el-date-editor.el-input.el-input--prefix.el-input--suffix.el-date-editor--date input {
    border-color: transparent;
  }

  .filename-label {
    @apply transition duration-150 ease-in-out;
  }

  .filename-label span {
    display: none;
  }

  .filename-label:hover {
    @apply border-gray-300 rounded-md shadow-md;
  }


  .filename-label:hover  span {
    display: block;
  }

  .upload-label::after {
    display: none;
    @apply pl-2 italic text-gray-600;

    content: 'replace file';
  }
  .upload-label:hover {
    @apply font-semibold;
  }
  .upload-label:hover::after {
    display: inline-block;
    @apply font-normal;
  }

</style>
