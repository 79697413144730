import cloneDeep from 'lodash/cloneDeep'

class UsersHelpers {
  static defaultUser () {
    return cloneDeep({
      id: 0,
      name: '',
      email: '',
      sms_number: '',
      roles: [],
      organogram: { 
        department: '', 
        team: '', 
        title: '', 
        layer: '' 
      },
    })
  }

  static defaultUserGroup () {
    return cloneDeep({
      id: 0,
      group_no: '',
      group_name: '',
      apply_to_investments: false,
      user_ids: [],
      user_names: '',
      dashboard_view_ids: [],
    })
  }
}

export default UsersHelpers
