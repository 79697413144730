<template>
  <v-select
    v-model='selectedInvoice'
    @input='updateSelectedInvoice'
    @search='searchCaseInvoices'
    label='case_name'
    :placeholder="$t('caseNamePlaceholder')"
    :options='searchResults'
    :close-on-select='true'
    :class='selectLayoutCSS'
    :disabled='disabled'>
    <template v-slot:option='option'>
        <div class='text-sm py-1 w-full'>{{ option.case_name }}</div>
        <div class='text-xs py-1 w-full opacity-75'>{{ $t('caseNameDetails', [option.invoice_title, option.invoice_date, numberStyle(option.invoice_amount), numberStyle(option.invoice_amount - option.total_attributed_amount)]) }}</div>
        <!-- <div>{{ option.investment_name }}</div> -->
    </template>
    <template v-slot:spinner>
      <i v-if='loading' class='el-icon-loading p-2' />
    </template>
  </v-select>
</template>

<script>
import { mapActions } from 'vuex'
import debounce from 'debounce'

export default {
  name: 'InvoiceSearch',
  props: [
    'depositId',
    'customClasses',
    'disabled'
  ],
  data () {
    return {
      selectedInvoice: null,
      searchResults: [],
      loading: false,
    }
  },
  watch: {
    investmentId: {
      handler: function (newVal) {
        if (newVal > 0) {
          this.selectedCase = this.getInvestmentsSearchById(newVal)
        }
      },
      immediate: true
    },
  },
  computed: {
    selectLayoutCSS () {
      return (this.customClasses) ? this.customClasses + ' invoice-search' : 'w-4/5 invoice-search'
    },
  },
  methods: {
    ...mapActions('deposits', [
      'searchInvoices'
    ]),
    searchCaseInvoices (text) {
      this.loading = true
      if (text) {
        this.searchInvoices({ deposit_id: this.depositId, search_text: text }).then(resp => {
          this.searchResults = resp
          this.loading = false
        })
      } else {
        this.loading = false
      }
    },
    updateSelectedInvoice () {
      if (this.selectedInvoice && this.selectedInvoice.id > 0) {
        this.$emit('update-selected-invoice', this.selectedInvoice)
      } else {
        this.$emit('update-selected-invoice', null)
      }
    },
    numberStyle (number) {
      return Number(number).toLocaleString()
    },
  },
  created () {
    this.searchCaseInvoices = debounce(this.searchCaseInvoices, 300)
  },
}
</script>
