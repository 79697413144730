import numbro from 'numbro'

class StringHelpers {
  static koreanMoney (amount) {
    return `${numbro(amount).format({ thousandSeparated: true, mantissa: 0 })} 원`
  }

  static stringIncludesInsensitive (str1, str2) {
    return str1 ? str1.toLowerCase().includes(str2.toLowerCase()) : ''
  }


  static koreanStatus (status) {
    switch (status) {
      case 'request':
      return '요청'
      case 'approved':
      return '승인'
      case 'rejected':
      return '거부'
      default:
      return ''
    }
  }

  static dayStringForIndex (index) {
    switch (index) {
      case 0:
      return '일'
      case 1:
      return '월'
      case 2:
      return '화'
      case 3:
      return '수'
      case 4:
      return '목'
      case 5:
      return '금'
      case 6:
      return '토'
      default: 
      return '-'
    }
  }
}

export default StringHelpers
