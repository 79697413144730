<template>
  <div class=''>
    <h2 class='text-lg uppercase font-semibold'>
      {{this.$t('createExpense')}}
    </h2>
    <expense-entry-input-form
      :expense-entry='expenseEntry'
      :new-attachment.sync='newAttachment'
      @update-expense-entry='(entry) => updateExpenseEntryValue(entry)' />
    <div class='mt-4 flex flex-row justify-start items-center gap-x-2'>
      <button class='px-4 py-2 bg-gray-900 border rounded-md border-transparent text-white opacity-75 hover:opacity-100 text-sm uppercase'
        @click='addExpenseEntry(true)'>
        <plus-circle-icon class='inline-block h-5' style='margin-top: -4px;' />
        {{ $t('create') }} {{ $t('andAddAnother') }}
      </button>
      <button class='px-4 py-2 bg-gray-100 border border-gray-500 rounded-md border-transparent text-gray-900 opacity-75 hover:opacity-100 text-sm uppercase'
        @click='addExpenseEntry(false)'>
        {{ $t('addAndClose') }}
      </button>
      <button class='px-4 py-2 bg-gray-100 border border-transparent hover:border-gray-400 rounded-md border-transparent text-gray-900 opacity-75 hover:opacity-100 text-sm uppercase'
        @click='doneEditing'>
        {{ $t('close') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { PlusCircleIcon }       from '@vue-hero-icons/outline'
import ExpenseEntryInputForm from '@/components/expenses/ExpenseEntryInputForm.vue'

export default {
  name: 'ExpenseEntryCreate',
  components: {
    PlusCircleIcon,
    ExpenseEntryInputForm,
  },
  data () {
    return {
      expenseEntry: {
        id: 0,
        expense_date: new Date(),
        amount: 0,
        tax_amount: 0,
        charge_vat: false,
        expense_type: '',
        description: '',
        is_billable: false,
        payment_type: '',
        expense_attachments: [],
      },
      newAttachment: {
        id: 0,
        investment_id: 0,
        expense_id: 0,
        attachment: null,
        file_url: '',
        file_name: '',
        changed: false,
        rand: Math.random()
      },
    }
  },
  computed: {
    ...mapState('investments', [
      'drilldownInvestment',
    ]),
  },
  methods: {
    ...mapActions('expenses', [
      'createExpense',
      'createAttachment',
      'updateAttachment',
    ]),
    doneEditing () {
      this.resetNewExpense()
      this.$emit('done-editing')
    },
    updateExpenseEntryValue (newExpenseEntry) {
      this.expenseEntry = newExpenseEntry
    },
    resetNewExpense () {
      this.expenseEntry = {
        id: 0,
        expense_date: new Date(),
        amount: 0,
        expense_type: '',
        description: '',
        is_billable: false,
        payment_type: '',
        expense_attachments: []
      }
    },
    resetNewAttachment () {
      this.newAttachment.id = 0
      this.newAttachment.investment_id = 0
      this.newAttachment.expense_id = 0
      this.newAttachment.attachment = null
      this.newAttachment.file_url = ''
      this.newAttachment.file_name = ''
      this.newAttachment.changed = false
      this.newAttachment.rand = Math.random()
    },
    addExpenseEntry (addAnotherBoolean) {
      this.expenseEntry.investment_id = this.drilldownInvestment.id
      this.createExpense(this.expenseEntry).then(resp => {
        let attachmentPromise
        if (this.newAttachment.changed) {
          this.newAttachment.expense_id    = resp.id
          this.newAttachment.investment_id = resp.investment_id
          attachmentPromise = this.submitAttachment(this.newAttachment)
        } else {
          attachmentPromise = Promise.resolve(null)
        }
        attachmentPromise.then(() => {
          this.resetNewExpense()
          this.resetNewAttachment()
          if (addAnotherBoolean) {
            // do nothing
          } else {
            this.doneEditing()
          }
        })
      })
    },
    submitAttachment (attachment) {
      if (attachment.id) {
        return this.updateAttachment(attachment)
      } else {
        return this.createAttachment(attachment)
      }
    },
  },
  mounted () {
  }
}
</script>
