<template>
  <div>
    <div class='mb-4 flex flex-row justify-start items-start'>
      <label class='inline-block w-32 flex-none leading-10'>{{ $t('laborBodyInCharge') }}</label>
      <v-select
        class='flex-grow'
        :value='caseInfo.jurisdiction_name'
        @input='updateCaseInfo("jurisdiction_name", $event)'
        :options='laborNameOptions'>
      </v-select>
    </div>
    <div class='mb-4 flex flex-row justify-start items-start'>
      <label class='inline-block w-32 flex-none leading-10'>{{ $t('laborCaseNo') }}</label>
      <input
        class='form-input flex-grow'
        :value='caseInfo.jurisdiction_case_number'
        @input='updateCaseInfo("jurisdiction_case_number", $event.target.value)'>
    </div>
    <div class='mb-4 flex flex-row justify-start items-start'>
      <label class='inline-block w-32 flex-none leading-10'>{{ $t('laborPersonInCharge') }}</label>
      <input
        class='form-input flex-grow'
        :value='caseInfo.contact_name'
        @input='updateCaseInfo("contact_name", $event.target.value)'>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LaborInfo',
  props: [
    'caseInfo',
  ],
  computed: {
    ...mapGetters('caseJurisdictions', [
      'jurisdictionByCaseType'
    ]),
    laborNameOptions () {
      return this.jurisdictionByCaseType('노동')
    }
  },
  methods: {
    updateCaseInfo(key, value) {
      this.$emit('update-case-info', { key, value })
    }
  },
}
</script>
