<template>
  <div class='pt-0.5 relative border border-transparent hover:border-gray-200 rounded-b-3xl w-full bg-white shadow-md z-20'>
    <div class='flex flex-col justify-start'>
      <div class='flex flex-row items-stretch justify-start w-full gap-x-4 lg:gap-x-8 border-b border-gray-100 lg:pr-16'>
        <h1 class='text-sm w-20 lg:w-24 bg-gray-50 lg:pl-10 flex-shrink-0 flex flex-row justify-end items-center'>ID</h1>
        <input
          class='form-input flex-grow h-10'
          type='text'
          v-model='id'
          v-on:keyup.enter='runSearch()'/>
      </div>
      <div class='flex flex-row items-stretch justify-start w-full gap-x-4 lg:gap-x-8 border-b border-gray-100 lg:pr-16'>
        <h1 class='text-sm w-20 lg:w-24 bg-gray-50 lg:pl-10 flex-shrink-0 flex flex-row justify-end items-center'>{{ $t('lawyer') }}</h1>
        <input
          class='form-input flex-grow h-10'
          type='text'
          v-model='lawyer'
          v-on:keyup.enter='runSearch()'/>
      </div>
      <div class='flex flex-row items-stretch justify-start w-full gap-x-4 lg:gap-x-8 border-b border-gray-100 lg:pr-16'>
        <h1 class='text-sm w-20 lg:w-24 bg-gray-50 lg:pl-10 flex-shrink-0 flex flex-row justify-end items-center'>{{ $t('caseNumber') }}</h1>
        <input
          class='form-input flex-grow h-10'
          type='text'
          v-model='case_no'
          v-on:keyup.enter='runSearch()'/>
      </div>
      <div class='flex flex-row items-stretch justify-start w-full gap-x-4 lg:gap-x-8 border-b border-gray-100 lg:pr-16'>
        <h1 class='text-sm w-20 lg:w-24 bg-gray-50 lg:pl-10 flex-shrink-0 flex flex-row justify-end items-center'>{{ $t('client') }}</h1>
        <input
          class='form-input flex-grow h-10'
          type='text'
          v-model='client'
          v-on:keyup.enter='runSearch()'/>
      </div>
      <div class='flex flex-row items-stretch justify-start w-full gap-x-4 lg:gap-x-8 border-b border-gray-100 lg:pr-16'>
        <h1 class='text-sm w-20 lg:w-24 bg-gray-50 lg:pl-10 flex-shrink-0 flex flex-row justify-end items-center'>{{ $t('counterpart') }}</h1>
        <input
          class='form-input flex-grow h-10'
          type='text'
          v-model='counterpart'
          v-on:keyup.enter='runSearch()'/>
      </div>
      <div class='flex flex-row items-stretch justify-start w-full gap-x-4 lg:gap-x-8 border-b border-gray-100 lg:pr-16'>
        <h1 class='text-sm w-20 lg:w-24 bg-gray-50 lg:pl-10 flex-shrink-0 flex flex-row justify-end items-center'>{{ $t('caseName') }}</h1>
        <input
          class='form-input flex-grow h-10'
          type='text'
          v-model='case_name'
          v-on:keyup.enter='runSearch()'/>
      </div>
    </div>
    <div class='pl-24 lg:pl-32 py-2 flex flex-row justify-start items-center gap-x-4'>
      <button
        class='py-3 uppercase px-6 lg:px-16 text-xs bg-gray-900 border rounded-md border-transparent text-white opacity-75 hover:opacity-100 hover:shadow-md'
        @click='runSearch()'>
        <search-icon class='inline-block h-5' />
        {{ $t('advancedSearch') }}
      </button>
      <button
        class='py-3 uppercase px-2 lg:px-4 text-xs rounded-md border border-gray-100 text-gray-600 hover:text-gray-900 hover:border-gray-200 opacity-75 hover:opacity-100 hover:shadow-md'
        @click='cancelSearch'>
        Cancel
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { SearchIcon } from '@vue-hero-icons/outline'

export default {
  name: 'AdvancedSearch',
  components: {
    SearchIcon,
  },
  computed: {
    ...mapState([
      'isSearching',
    ]),
    ...mapFields('investments', [
      'investmentSearchAdvancedParams',
      'investmentSearchAdvancedParams.id',
      'investmentSearchAdvancedParams.lawyer',
      'investmentSearchAdvancedParams.case_no',
      'investmentSearchAdvancedParams.case_name',
      'investmentSearchAdvancedParams.client',
      'investmentSearchAdvancedParams.counterpart',
      'searchFor',
    ]),
    ...mapFields([
      'showAdvancedSearch',
    ]),
  },
  methods: {
    ...mapActions([
      'searchClose',
    ]),
    ...mapActions('investments', [
      'investmentSearchAdvanced',
      'resetInvestmentSearchResults',
    ]),
    clearSearchText () {
      this.resetInvestmentSearchResults()
      this.searchClose()
      this.searchFor = '의뢰인'
    },
    runSearch() {
      this.investmentSearchAdvanced()
      this.$gtag.event('search_for_cases', {
        search_text: JSON.stringify(this.investmentSearchAdvancedParams),
        search_type: this.searchFor
      })
    },
    cancelSearch() {
      this.$emit('cancel-search')
    }
  },
  beforeDestroy() {
    this.clearSearchText()
  }
}
</script>

<style lang='scss' scoped>
.wrapper {
  background-color: #f5f5f5;
}
</style>
