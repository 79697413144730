<template>
  <tr>
    <td class='pr-1'>
      <el-date-picker
        v-model='scheduleDate'
        value-format='yyyy-MM-dd'
        type='date' 
        :placeholder="$t('pickDay')"
        class='w-full' />
    </td>
    <td class='px-1'>
      <el-time-picker
        v-model='startTime'
        format='HH:mm'
        value-format='HH:mm'
        :placeholder="$t('startTime')">
      </el-time-picker>
    </td>
    <td class='px-1 border-r'>
      <el-time-picker
        v-model='endTime'
        format='HH:mm'
        value-format='HH:mm'
        :placeholder="$t('endTime')">
      </el-time-picker>
    </td>
    <td class='px-1'>
      <v-select
        v-model='scheduleType'
        class='w-40'
        :options='scheduleTypes'
        :close-on-select='true'>
        <template v-slot:option='option'>
          <div class='px-2 py-2 items-center'>
            {{option.label}}
          </div>
        </template>
      </v-select>
    </td>
    <td class='px-1 border-r'>
      <input class='form-input w-full' type='text' v-model='location'>
    </td>
    <td class='px-1'>
      <user-search
        :user-ids='userIds'
        :user-names='userNames'
        :ref='schedule.id'
        role='lawyer'
        :is-multiple='true'
        @update-selected-user='updateSelectedUsers' />
    </td>
    <td class='px-1'>
      <input class='form-input w-full' type='text' v-model='description'>
    </td>
    <td class='px-1'>
      {{schedule.updated_by_name}}
    </td>
    <td class='pl-2 w-32 text-right'>
      <button class='bg-gray-200 hover:bg-gray-100 text-gray-800 font-bold py-3 px-4 rounded'
        @click='submitUpdate'>
        {{ $t('save') }}
      </button>
      <button class='hover:bg-gray-100 text-gray-600 py-3 px-1'
        @click='cancelEdit'>
        {{ $t('cancel') }}
      </button>
  </td>
  </tr>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import UserSearch from '@/components/UserSearch.vue'
import dayjs from 'dayjs'

export default {
  name: 'CaseScheduleRowEdit',
  props: ['schedule'],
  components: {
    UserSearch
  },
  data() {
    return {
      investment_id: 0,
      userIds: [],
      userNames: '',
      scheduleType: '',
      scheduleDate: dayjs().format('YYYY-MM-DD'),
      startTime: '',
      endTime: '',
      location: '',
      description: '',
    }
  },
  computed: {
    ...mapState('schedules', [
      'scheduleTypes'
    ]),
    ...mapState('users', [
      'users',
    ]),
    prepedForApi () {
      return {
        id:            this.schedule.id,
        investment_id: this.investment_id,
        schedule_date: this.scheduleDate,
        schedule_type: this.scheduleType,
        user_ids:      this.userIds,
        user_names:    this.userNames,
        start_time:    this.startTime,
        end_time:      this.endTime,
        location:      this.location,
        description:   this.description,
      }
    }
  },
  methods: {
    ...mapActions('schedules', [
      'updateSchedule',
    ]),
    cancelEdit () {
      this.$emit('done-update')
    },
    setupLocalSchedule () {
      this.investment_id = this.schedule.investment_id
      this.userIds       = this.schedule.user_ids
      this.userNames     = this.schedule.user_names
      this.scheduleType  = this.schedule.schedule_type
      this.scheduleDate  = this.schedule.schedule_date
      this.startTime     = this.schedule.start_time
      this.endTime       = this.schedule.end_time
      this.location      = this.schedule.location
      this.description   = this.schedule.description
    },
    submitUpdate () {
      this.updateSchedule(this.prepedForApi)
      this.$emit('done-update')
    },
    updateSelectedUsers (ids, nameAndEmails) {
      this.userIds= ids
      this.userNames = nameAndEmails
    }
  },
  mounted () {
    this.setupLocalSchedule()
  }
}
</script>
