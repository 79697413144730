import Vue          from 'vue'
import Vuex         from 'vuex'
import timesheetEntriesApi from '@/api/v1/timesheet_entries'
import workingDaysApi from '@/api/v1/working_days'
import koreanTimeApi from '@/api/v1/korean_time'
import cloneDeep    from 'lodash/cloneDeep'
import dayjs        from 'dayjs'
import { getField, updateField } from 'vuex-map-fields'
import DateHelpers from '@/utils/date-helpers'

Vue.use(Vuex)

const newTimesheetEntry = {
  id: 0,
  investment_id: 0,
  case_name: '',
  invoice_id: 0,
  user_id: 0,
  work_type: '',
  work_date: dayjs().format('YYYY-MM-DD'),
  work_hours: '',
  billable_hours: '',
  description: '',
  status: '',
  charge_vat: false,
  rate: 0,
  user_position: '',
}

const state = () => ({
  timesheetEntries: [],
  caseTimesheetEntries: [],
  editingTimesheetEntry: cloneDeep(newTimesheetEntry),
  caseWorkTypes: [
    'General',
    'Prospective Matter',
    'Office (법인)',
    'Office (기타)',
    'Pro Bono'
  ],
  detailedWorkTypes: [
    '서면작성',
    '변론활동',
    '회의',
    '기타'
  ],
  selectableUserPositions: [
    '',
    '파트너',
    '워킹파트너',
    '어쏘'
  ],
  workingDays: [],
  koreanTime: dayjs().format('YYYY-MM-DD'),
})


const getters = {
  timesheetEntriesNotOnInvoice: (state) => {
    return state.caseTimesheetEntries.filter(entry => !entry.invoice_id)
  },
  timesheetEntriesNotOnInvoiceCount: (state, getters) => {
    return getters.timesheetEntriesNotOnInvoice.length
  },
  timesheetEntriesWithInvoice: (state) => {
    return state.caseTimesheetEntries.filter(entry => entry.invoice_id)
  },
  timesheetEntriesWithInvoiceCount: (state, getters) => {
    return getters.timesheetEntriesWithInvoice.length
  },
  isWorkDateDisabled: (state) => (date) => {
    const koreanTimeNow = (state.koreanTime && state.koreanTime.trim() !== '') ? dayjs(state.koreanTime) : dayjs()
    const workDate = dayjs(date)
    
    if (DateHelpers.isDateAfterToday(state.koreanTime, date)) {
      return true 
    } else if (DateHelpers.isPublicHoliday(state.koreanTime, state.workingDays)) {
      const offsetValue = DateHelpers.calendarOffsetDays(state.koreanTime, state.workingDays)
      return workDate.isBefore(koreanTimeNow.subtract(offsetValue, 'day')) || 
             workDate.isSame(koreanTimeNow.subtract(offsetValue, 'day'), 'day')
    } else if (DateHelpers.isAfterPublicHolidayBefore12pm(state.koreanTime, state.workingDays)) {
      return workDate.isBefore(koreanTimeNow.subtract(9 + koreanTimeNow.day(), 'day'))
    } else if (DateHelpers.isAfterPublicHolidayAfter12pm(state.koreanTime, state.workingDays)) {
      return workDate.isBefore(koreanTimeNow.subtract(2 + koreanTimeNow.day(), 'day'))
    } else if (DateHelpers.isMondayBefore12pm(state.koreanTime)) {
      return workDate.isBefore(koreanTimeNow.subtract(10, 'day'))
    } else if (DateHelpers.isMonday(state.koreanTime)) {      
      return workDate.isBefore(koreanTimeNow.subtract(3, 'day'))
    } else if (DateHelpers.isTuesday(state.koreanTime)) {
      return workDate.isBefore(koreanTimeNow.subtract(4, 'day'))
    } else if (DateHelpers.isWednesday(state.koreanTime)) {
      return workDate.isBefore(koreanTimeNow.subtract(5, 'day'))
    } else if (DateHelpers.isThursday(state.koreanTime)) {
      return workDate.isBefore(koreanTimeNow.subtract(6, 'day'))
    } else if (DateHelpers.isFriday(state.koreanTime)) {
      return workDate.isBefore(koreanTimeNow.subtract(7, 'day'))
    } else if (DateHelpers.isSaturday(state.koreanTime)) {
      return workDate.isBefore(koreanTimeNow.subtract(8, 'day'))
    } else if (DateHelpers.isSunday(state.koreanTime)) {
      return workDate.isBefore(koreanTimeNow.subtract(9, 'day'))
    }

    return false
  },
  getField,
}


const actions = {
  getTimesheetEntries ({ commit, dispatch }, filters) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      timesheetEntriesApi.getTimesheetEntries(filters).then(resp => {
        commit('setTimesheetEntries', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  updateTimesheetEntry ({ commit, dispatch }, timesheetEntry) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      timesheetEntriesApi.updateTimesheetEntry(timesheetEntry.id, timesheetEntry).then(resp => {
        commit('updateTimesheetEntries', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  updateTimesheetEntries ({ state, dispatch }) {
    return Promise.all(
      state.timesheetEntries.map(entry => dispatch('updateTimesheetEntry', entry))
    )
  },
  deleteTimesheetEntry ({ commit, dispatch }, timesheetEntryId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      timesheetEntriesApi.deleteTimesheetEntry(timesheetEntryId).then(resp => {
        commit('removeTimesheetEntryFromList', resp.id)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  createTimesheetEntry ({ commit, dispatch }, timesheetEntry) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      timesheetEntriesApi.postTimesheetEntry(timesheetEntry).then(resp => {
        commit('addTimesheetEntry', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  getCaseTimesheetEntries ({ commit, dispatch }, filters) {
    let noInvoiceFilters = {
      investment_id: filters.investment_id,
      invoice_id:    [0, null],
      start_date:    filters.start_date,
      end_date:      filters.end_date,
      has_invoice:   false,
    }
    let getNoInvoice = new Promise(resolve => timesheetEntriesApi.getTimesheetEntries(noInvoiceFilters).then(resp => {
        resolve(resp)
    }))

    let hasInvoiceFilters = {
      investment_id: filters.investment_id,
      start_date:    filters.start_date,
      end_date:      filters.end_date,
      has_invoice:   true,
    }
    let hasInvoice = new Promise(resolve => timesheetEntriesApi.getTimesheetEntries(hasInvoiceFilters).then(resp => {
        resolve(resp)
    }))

    dispatch('activateLoading', null, { root : true })
    return Promise.all([getNoInvoice, hasInvoice]).then((entriesArray) => {
      let arr = []
      arr = arr.concat(entriesArray[0], entriesArray[1])
      commit('setCaseTimesheetEntries', arr)
      dispatch('deactiveLoading', null, { root : true })
    })
  },
  getCaseTimesheetEntriesByDate ({ getters, commit, dispatch }, filters) {
    dispatch('activateLoading', null, { root : true })
    return new Promise(resolve => timesheetEntriesApi.getTimesheetEntries(filters).then(resp => {
      let arr = []
      arr = arr.concat(getters.timesheetEntriesNotOnInvoice, resp)
      commit('setCaseTimesheetEntries', arr)
      dispatch('deactiveLoading', null, { root : true })
      resolve(resp)
    }))
  },
  createCaseTimesheetEntry ({ commit, dispatch }, timesheetEntry) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      timesheetEntriesApi.postTimesheetEntry(timesheetEntry).then(resp => {
        commit('addCaseTimesheetEntry', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  deleteCaseTimesheetEntry ({ commit, dispatch }, timesheetEntryId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      timesheetEntriesApi.deleteTimesheetEntry(timesheetEntryId).then(resp => {
        commit('removeCaseTimesheetEntryFromList', resp.id)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  updateCaseTimesheetEntry ({ commit, dispatch }, timesheetEntry) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      timesheetEntriesApi.updateTimesheetEntry(timesheetEntry.id, timesheetEntry).then(resp => {
        commit('updateCaseTimesheetEntries', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  getWorkingDaysByDateRange ({ commit, dispatch}, start_date, end_date) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      workingDaysApi.getWorkingDaysByDateRange(start_date, end_date).then(resp => {
        commit('setWorkingDays', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  updateKoreanTime ({ commit, dispatch }) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      koreanTimeApi.getKoreanTime().then(resp => {
        commit('setKoreanTime', resp.korean_time)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
}


const mutations = {
  setTimesheetEntries (state, entries) {
    state.timesheetEntries = entries
  },
  addTimesheetEntry (state, timesheetEntry) {
    state.timesheetEntries.unshift(timesheetEntry)
  },
  removeTimesheetEntryFromList (state, timesheetEntryId) {
    const index = state.timesheetEntries.findIndex(timesheetEntry => timesheetEntry.id === timesheetEntryId)
    if (index >= 0) {
      state.timesheetEntries.splice(index, 1)
    }
  },
  updateTimesheetEntries (state, timesheetEntry)  {
    const index = state.timesheetEntries.findIndex(entry => entry.id === timesheetEntry.id)
    if (index >= 0) {
      state.timesheetEntries.splice(index, 1, timesheetEntry)
    }
  },

  setCaseTimesheetEntries (state, entries) {
    state.caseTimesheetEntries = entries
  },
  addCaseTimesheetEntry (state, timesheetEntry) {
    state.caseTimesheetEntries.unshift(timesheetEntry)
  },
  removeCaseTimesheetEntryFromList (state, timesheetEntryId) {
    const index = state.caseTimesheetEntries.findIndex(timesheetEntry => timesheetEntry.id === timesheetEntryId)
    if (index >= 0) {
      state.caseTimesheetEntries.splice(index, 1)
    }
  },
  updateCaseTimesheetEntries (state, timesheetEntry)  {
    const index = state.caseTimesheetEntries.findIndex(entry => entry.id === timesheetEntry.id)
    if (index >= 0) {
      state.caseTimesheetEntries.splice(index, 1, timesheetEntry)
    }
  },
  setWorkingDays(state, workingDays) {
    state.workingDays = workingDays
  },
  setKoreanTime(state, koreanTime) {
    state.koreanTime = koreanTime
  },
  updateField
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
