<template>
  <div class='rounded-b-lg'>
    <timesheet-entry-input-form 
      :timesheet-entry.sync='editingTimesheetEntry' 
      class='p-4' />
    <div class='mt-4 flex flex-row justify-start items-center gap-x-2 bg-gray-100 p-4 rounded-b-lg text-sm'>
      <button class='px-6 py-2 bg-gray-900 border rounded-md border-transparent text-white opacity-75 hover:opacity-100 text-sm uppercase'
        @click='saveEditedTimesheetEntry'
        :disabled='disableAddTimeSheetRow'>
        {{ $t('save') }}
      </button>
      <button class='px-4 py-2 border border-transparent hover:border-gray-400 rounded-md border-transparent text-gray-900 opacity-75 hover:opacity-100 text-sm uppercase'
        @click='doneEditing'>
        {{ $t('cancel') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import TimesheetEntryInputForm from '@/components/timesheets/TimesheetEntryInputForm.vue'

export default {
  name: 'TimesheetEntryEdit',
  components: {
    TimesheetEntryInputForm,
  },
  props: [
    'timesheetEntry'
  ],
  data () {
    return {
      editingTimesheetEntry: {
        id: 0,
        investment_id: 0,
        user_id: 0,
        user_name: '',
        work_date: new Date(),
        work_hours: 0,
        work_type: 'General',
        detailed_work_type: '',
        billable_hours: 0,
        description: '',
        status: '',
        invoice_id: 0,
        rate: 0,
        user_position: '',
      },
    }
  },
  computed: {
    ...mapGetters('users', [
      'currentUserId',
      'currentUserName',
      'currentUserEmail',
      'currentUserPosition',
    ]),
    ...mapState('investments', [
      'drilldownInvestment',
    ]),
    isSelectedDetailedWorkTypeValid () {
      const isGeneral = this.editingTimesheetEntry.work_type && this.editingTimesheetEntry.work_type.toLowerCase() === 'general'
      const hasDetailedWorkType = this.editingTimesheetEntry.detailed_work_type && this.editingTimesheetEntry.detailed_work_type.length > 0
      return !isGeneral || hasDetailedWorkType
    },
    disableAddTimeSheetRow () {
      return  !this.editingTimesheetEntry.invoice_id || !this.isSelectedDetailedWorkTypeValid
    },
  },
  methods: {
    ...mapActions('timesheetEntries', [
      'updateCaseTimesheetEntry',
    ]),

    doneEditing () {
      this.$emit('done-editing')
    },
    resetNewTimesheetEntry () {
      this.editingTimesheetEntry.work_date = new Date()
      this.editingTimesheetEntry.work_hours = 0
      this.editingTimesheetEntry.work_type = 'General'
      this.editingTimesheetEntry.detailed_work_type = ''
      this.editingTimesheetEntry.billable_hours = 0
      this.editingTimesheetEntry.description = ''
      this.editingTimesheetEntry.status = 'General'
      this.editingTimesheetEntry.invoice_id = 0
      // this.editingTimesheetEntry.rate = 0 // don't reset the rate, so we can continue to enter in values
    },
    saveEditedTimesheetEntry () {
      this.$confirm(
        this.$t('saveChangesConfirmDetailed', 
        (this.editingTimesheetEntry.user_name == this.currentUserName) ? [this.currentUserName, this.currentUserEmail] : [this.editingTimesheetEntry.user_name, `ID: ${this.editingTimesheetEntry.user_id}`]),
        'Warning', {
          confirmButtonText: this.$t('confirmSave'),
          cancelButtonText: this.$t('cancel'),
          type: 'warning'
      }).then(() => {
        this.updateCaseTimesheetEntry(this.editingTimesheetEntry).then(() => {
          this.$message({
            type: 'success',
            message: this.$t('successfullySaved')
          })
          this.doneEditing()
        })
      }).catch(() => {
      })
    },
  },
  mounted () {
    this.editingTimesheetEntry = cloneDeep(this.timesheetEntry)
  },
  beforeDestroy () {
    this.resetNewTimesheetEntry()
  }
}
</script>
