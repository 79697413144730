<template>
  <div>
    <portal to='sidepanel-header'>
      <h1 class='text-2xl font-semibold px-8 py-8'>{{companyFromId ? companyFromId.company_name : ''}}</h1>
      <h2 class='text-sm px-8'>{{companyFromId && companyFromId.english_name ? companyFromId.english_name : ''}}</h2>
    </portal>
    <div 
      v-if='companyFromId'
      class='px-8'>
      <div class='mb-8'>
        <h1 class='font-semibold uppercase'>
          entities 
          <span class='text-normal'>({{ companyEntities.length }})</span>
        </h1>
        <table 
          v-if='companyEntities.length'
          class='mt-2 text-sm w-full'>
          <thead>
            <tr class='border-b'>
              <th class='p-1'>ID</th>
              <th class='p-1'>Entity Name</th>
              <th class='p-1'>Entity Type</th>
              <th class='p-1'>Company Type</th>
              <th class='p-1'>Business License Number</th>
            </tr>
          </thead>
          <tr class='border-b border-gray-100' 
            v-for='entity in companyEntities' 
            :key='`entity-${entity.id}`'>
            <td class='p-1'>{{ entity.id }}</td>
            <td class='p-1'>{{ entity.entity_name }}</td>
            <td class='p-1'>{{ entity.entity_type }}</td>
            <td class='p-1'>{{ entity.company_type }}</td>
            <td class='p-1'>{{ entity.business_license_number }}</td>
          </tr>
        </table>
        <div v-else
          class='mt-4 text-center text-gray-500 bg-gray-100 text-sm rounded-md p-16'>
          <p>No entities found</p>
        </div>
      </div>
      <div class='mb-8'>
        <h1 class='font-semibold uppercase'>
          cases 
          <span class='text-normal'>({{ companyCases.length }})</span>
        </h1>
        <table v-if='companyCases.length'
          class='mt-2 text-sm w-full'>
          <thead>
            <tr class='border-b'>
              <th class='p-1'>ID</th>
              <th class='p-1'>Name</th>
              <th class='p-1'>Clients Name</th>
              <th class='p-1'>Counterparty</th>
              <th class='p-1'>Archived</th>
            </tr>
          </thead>
          <tr class='border-b border-gray-100'
            v-for='companyCase in companyCases' 
            :key='`case-${companyCase.id}`'>
            <td class='p-1'>{{ companyCase.id }}</td>
            <td class='p-1'>{{ companyCase.investment_name }}</td>
            <td class='p-1'>{{ companyCase.clients_name }}</td>
            <td class='p-1'>{{ companyCase.counterparty }}</td>
            <td class='p-1'>{{ companyCase.archived }}</td>
          </tr>
        </table>
        <div v-else
          class='mt-4 text-center text-gray-500 bg-gray-100 text-sm rounded-md p-16'>
          <p>No cases found</p>
        </div>
      </div>
      <div class='mb-8'>
        <h1 class='font-semibold uppercase'>
          invoices 
          <span class='text-normal'>({{ invoicesByCompany.length }})</span>
        </h1>
        <table v-if='invoicesByCompany.length'
          class='mt-2 text-sm w-full'>
          <thead>
            <tr class='border-b'>
              <th class='p-1'>Invoice Date</th>
              <th class='p-1'>Invoice Title</th>
              <th class='p-1 text-right'>Total Amount</th>
            </tr>
          </thead>
          <tr class='border-b border-gray-100'
            v-for='invoice in invoicesByCompany' 
            :key='`invoice-${invoice.id}`'>
            <td class='p-1'>{{ invoice.invoice_date }}</td>
            <td class='p-1'>{{ invoice.invoice_title }}</td>
            <td class='p-1 text-right'>{{ formatNumber(invoice.total_amount) }}</td>
          </tr>
        </table>
        <div v-else
          class='mt-4 text-center text-gray-500 bg-gray-100 text-sm rounded-md p-16'>
          <p>No invoices found</p>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import numbro from 'numbro'

export default {
  name: 'CompanyDrilldown',
  components: {
  },
  props: {
  },
  data () {
    return {
      companyEntities: [],
      companyCases: [],
      invoicesByCompany: [],
    }
  },
  watch: {
    drilldownCompanyId: {
      handler: function (newVal) {
        if (newVal) {
          this.getCompanyEntities(newVal).then(resp => {
            this.companyEntities = resp
          })
          this.getCompanyCases(newVal).then(resp => {
            this.companyCases = resp
          })
          this.getInvoicesByCompany(newVal).then(resp => {
            this.invoicesByCompany = resp
          })
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('companies', [
      'drilldownCompanyId',
      'companies',
    ]),
    companyFromId () {
      return this.companies.find(company => company.id === this.drilldownCompanyId)
    },
  },
  methods: {
    ...mapActions('companies', [
      'getCompanyCases',
      'getCompanyEntities',
      'resetDrilldownCompany',
    ]),
    ...mapActions('invoices', [
      'getInvoicesByCompany',
    ]),
    formatNumber (number) {
      return numbro(number).format({
        thousandSeparated: true,
        mantissa: 0,
      })
    },
  },
  mounted () {
  },
  beforeDestroy () {
    this.resetDrilldownCompany()
  }
}
</script>

<style lang='scss' scoped>
button:disabled,
button[disabled] {
  opacity: 0.6;
  cursor: not-allowed;
}
</style>
