<template>
  <tr class=''>
    <td class='pr-2 py-2'>{{investment.casenum}}</td>
    <td class='pr-2 py-2'>{{investment.users}}</td>
    <td class='pr-2 py-2 whitespace-pre-line'>{{investment.case_infos}}</td>
    <td class='pr-2 py-2'>{{investment.entity_names}}</td>
    <td class='px-2 py-2'>{{investment.counterparty}}</td>
    <td class='pl-2 py-2'>{{investment.investment_name}}</td>
    <td class='pl-2 py-2'><span v-if='investment.archived'>{{$t('archived')}}</span></td>
  </tr>
</template>

<script>
export default {
  name: 'SearchResultInvestment',
  props: [
    'investment',
  ],
}
</script>
