<template>
  <div>
    <div class='sticky top-0 bg-white z-10 pt-8'>
      <div class='flex flex-row justify-between items-end px-2 lg:px-8'>
        <div>
          <h1 class='text-xl font-semibold uppercase'>
            {{title}}
          </h1>
          <h3 class='text-xs text-gray-800'>
            {{drilldownInvestment.casenum}}
          </h3>
          <h3 class='text-sm text-gray-800'>
            {{subTitle}}
            <span v-if='counterpartyExist'>
              <span class='inline-block px-2 text-gray-600'>v.</span>
              {{drilldownInvestment.counterparty}}
            </span>
          </h3>
        </div>
        <case-court-infos />
      </div>
      <div v-if='!isNewInvestment'
        class='tabs-container border-b pl-2 md:pl-4 lg:pl-8 flex flex-row justify-start mt-16 mb-8 text-sm text-gray-600 overflow-x-auto'
        style=''>
        <div class='tab' :class='selectedStyle("metadata")'    @click='setActiveTab("metadata")'>{{dataLabel}}</div>
        <div class='tab' :class='selectedStyle("contacts")'    @click='setActiveTab("contacts")'>{{contactsLabel}}</div>
        <div class='tab' :class='selectedStyle("case-infos")'  @click='setActiveTab("case-infos")'>{{ $t('caseNo') }}</div>
        <div class='tab' :class='selectedStyle("schedules")'   @click='setActiveTab("schedules")'>{{ $t('scheduleManage') }}</div>
        <div class='tab flex flex-row items-center gap-x-1' :class='selectedStyle("approvals")'   @click='setActiveTab("approvals")'>
          {{approvalsLabel}}
          <el-badge v-if='hasApprovalRequests'
            :value='drilldownApprovalRequestCount'
            class='top-0 right-0'>
          </el-badge>
        </div>
        <div class='tab' :class='selectedStyle("timesheet")'   @click='setActiveTab("timesheet")'>{{ $t('timesheet') }}</div>
        <div class='tab' :class='selectedStyle("expenses")'    @click='setActiveTab("expenses")'>{{ $t('expenseDetails') }}</div>
        <div class='tab' :class='selectedStyle("invoices")'    @click='setActiveTab("invoices")'>{{ $t('bill') }}</div>
        <div class='tab' :class='selectedStyle("files")'       @click='setActiveTab("files")'>{{filesLabel}}</div>
        <div class='tab' :class='selectedStyle("history")'     @click='setActiveTab("history")'>{{historyLabel}}</div>
      </div>
    </div>
    <component
      :is='tabContentComponent'
      @update-selected-tab='setActiveTab'
      class='px-2' />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import CaseTimesheet            from '@/views/dashboard/CaseTimesheet.vue'
import CaseExpenses             from '@/views/dashboard/CaseExpenses.vue'
import CaseInvoices             from '@/views/dashboard/CaseInvoices.vue'
import CaseSchedules            from '@/views/dashboard/CaseSchedules.vue'
import CaseCourtInfos           from '@/views/dashboard/CaseCourtInfos.vue'
import InvestmentApprovals      from '@/views/dashboard/InvestmentApprovals.vue'
import InvestmentContacts       from '@/views/dashboard/InvestmentContacts.vue'
import InvestmentFiles          from '@/views/dashboard/InvestmentFiles.vue'
import MsFiles                  from '@/views/dashboard/MsFiles.vue'
import InvestmentHistory        from '@/views/dashboard/InvestmentHistory.vue'
import InvestmentMetadata       from '@/views/dashboard/InvestmentMetadata.vue'
import InvestmentCaseInfos      from '@/views/dashboard/InvestmentCaseInfos.vue'

export default {
  name: 'InvestmentDrilldown',
  components: {
    CaseExpenses,
    CaseTimesheet,
    CaseInvoices,
    CaseSchedules,
    CaseCourtInfos,
    InvestmentContacts,
    InvestmentFiles,
    MsFiles,
    InvestmentHistory,
    InvestmentMetadata,
    InvestmentApprovals,
    InvestmentCaseInfos,
  },
  data () {
    return {
      activeTab: 'metadata',
    }
  },
  watch: {
    drilldownInvestmentId: {
      handler: function (newId) {
        if (newId) {
          this.getInvestmentDrilldownOverview()
        } else {
          this.setActiveTab('metadata')
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapState('investments', [
      'drilldownInvestment',
      'drilldownApprovalRequestCount'
    ]),
    ...mapGetters('investments', [
      'isInvestmentReadyToMove',
      'isNewInvestment',
      'customFields',
      'drilldownInvestmentId',
    ]),
    ...mapGetters('firms', [
      'isMsStorageMode',
    ]),
    tabContentComponent () {
      switch (this.activeTab) {
        case 'metadata':
          return 'investment-metadata'
        case 'case-infos':
          return 'investment-case-infos'
        case 'contacts':
          return 'investment-contacts'
        case 'expenses':
          return 'case-expenses'
        case 'invoices':
          return 'case-invoices'
        case 'timesheet':
          return 'case-timesheet'
        case 'schedules':
          return 'case-schedules'
        case 'approvals':
          return 'investment-approvals'
        case 'files':
          return this.isMsStorageMode ? 'ms-files' : 'investment-files'
        case 'history':
          return 'investment-history'
        default:
          return 'div'
      }
    },
    title () {
      return (this.isNewInvestment) ? this.$t('createNew') : this.drilldownInvestment.investment_name
    },
    accuserType () {
      return this.drilldownInvestment.accuser_type ? `[${this.drilldownInvestment.accuser_type}] ` : ''
    },
    subTitle () {
      return this.drilldownInvestment.entity_names ? this.accuserType.concat(this.drilldownInvestment.entity_names) : ''
    },
    counterpartyExist () {
      return this.drilldownInvestment.counterparty
    },
    hasApprovalRequests () {
      return this.drilldownApprovalRequestCount > 0
    },
    dataLabel () {
      return this.$t('caseInfo')
    },
    contactsLabel () {
      return this.$t('contactNo')
    },
    approvalsLabel () {
      return this.$t('approvalManage')
    },
    filesLabel () {
      return this.$t('fileManage')
    },
    historyLabel () {
      return this.$t('updateHistory')
    },
  },
  methods: {
    ...mapActions('investments', [
      'resetEditing',
      'getInvestmentDrilldownOverview'
    ]),
    setActiveTab (tab) {
      this.activeTab = tab
      // update router query params for copying and pasting
      if (this.$route.query.tab !== tab) {
        let newQueryParams = cloneDeep(this.$route.query)
        newQueryParams['tab'] = tab
        this.$router.replace({ query: newQueryParams })
      }
      // update local storage so that app remembers what you are looking at often
      if (localStorage.getItem('lastActiveTab') !== tab) {
        localStorage.setItem('lastActiveTab', tab)
      }
    },
    selectedStyle (tab) {
      return (this.activeTab === tab) ? 'selected' : ''
    },
    setupActiveTab () {
      // need to be able to filter later based on company type (case vs investment)
      const drilldownTabs = [
        'metadata',
        'comparables',
        'contacts',
        'expenses',
        'invoices',
        'timesheet',
        'schedules',
        'approvals',
        'files',
        'history',
        'case-infos'
      ]
      let queryParamTab   = this.$route.query.tab
      let localStorageTab = localStorage.getItem('lastActiveTab')

      if (queryParamTab && drilldownTabs.includes(queryParamTab)) {
        this.setActiveTab(queryParamTab)
      } else if (localStorageTab && drilldownTabs.includes(localStorageTab)) {
        this.setActiveTab(localStorageTab)
      } else {
        this.setActiveTab('metadata')
      }
    },
  },
  mounted () {
    this.setupActiveTab()
  },
  beforeDestroy () {
    let params = cloneDeep(this.$route.query)
    if (params['investment_id']) {
      delete params['investment_id']
    }
    if (params['tab']) {
      delete params['tab']
    }
    this.$router.replace({ query: params }).catch(() => {})
    this.resetEditing()
  }
}
</script>

<style lang='scss' scoped>

  .tab {
    @apply px-4 pb-2 pt-4 uppercase tracking-wide cursor-pointer whitespace-no-wrap;
  }

  .tab:hover {
    @apply bg-gray-100;
  }

  .tab.selected {
    @apply font-semibold;
    color: #0D4C76;
    border-bottom: solid 2px #0D4C76;
  }

  @media (min-width: 800px) {

.tab {
  @apply px-4;
}
}

  @media (min-width: 1000px) {

    .tab {
      @apply px-6;
    }
  }
</style>
