import base from './base'

const getInvoicesWithoutRatio = (filters) => base
  .get('/case_performance_reports/invoices_without_ratio/', { params: filters }).then(resp => resp.data)

const getCasesWithoutInvoice = (filters) => base
  .get('/case_performance_reports/cases_without_invoice/', { params: filters }).then(resp => resp.data)

const getExpensesWithoutInvoice = (filters) => base
  .get('/case_performance_reports/expenses_without_invoice/', { params: filters }).then(resp => resp.data)

const getTimesheetsWithoutInvoice = (filters) => base
  .get('/case_performance_reports/timesheets_without_invoice/', { params: filters }).then(resp => resp.data)

const getTimesheetsWithoutDeposit = (filters) => base
  .get('/case_performance_reports/timesheets_without_deposit/', { params: filters }).then(resp => resp.data)

const postDepositToInvoice = (params) => base
  .patch('/case_performance_reports/make_deposit_to_invoice/', { params: params }).then(resp => resp.data)

  export default {
  getInvoicesWithoutRatio: getInvoicesWithoutRatio,
  getCasesWithoutInvoice: getCasesWithoutInvoice,
  getExpensesWithoutInvoice: getExpensesWithoutInvoice,
  getTimesheetsWithoutInvoice: getTimesheetsWithoutInvoice,
  getTimesheetsWithoutDeposit: getTimesheetsWithoutDeposit,
  postDepositToInvoice: postDepositToInvoice,
}
