<template>
  <section class='flex flex-col justify-start gap-4'>
    <table v-if='hasTimesheetEntries'
      class='table-fixed flex-grow'>
      <tr class='text-sm uppercase font-semibold text-gray-600'>
        <th class='pr-2 py-2 w-32 whitespace-no-wrap'>
          <label class='flex flex-row items-center justify-start gap-x-1'>
            <input
              type='checkbox'
              class='form-checkbox'
              v-model='allEntriesToggled'>
            <span>{{ $t('billReflection') }}</span>
          </label>
        </th>
        <table-header-filter-text
          v-bind:filter-text.sync='filterDate'
          :default-label="$t('date')"
          class='px-2 py-2 w-24' />
        <th class='px-2 py-2 w-32 text-right border-r'>{{ $t('financialAmount') }}</th>
        <table-header-filter-text
          v-bind:filter-text.sync='filterLawyer'
          :default-label="$t('lawyer')"
          class='px-2 py-2 w-32' />
        <table-header-filter-text
          v-bind:filter-text.sync='filterPosition'
          :default-label="$t('position')"
          class='px-2 py-2 w-32' />
        <th v-if='calculateTotal'
          class='px-2 py-2 w-32'>
          <label class='flex flex-row items-center justify-start gap-x-1'>
            <input
              type='checkbox'
              class='form-checkbox'
              v-model='allEntriesTax'>
            <span>{{ $t('vat') }}</span>
          </label>
        </th>
        <th class='px-2 py-2 w-32 text-right'>{{ $t('hourlyBillingRate') }}</th>
        <th class='px-2 py-2 w-20 text-right'>{{ $t('time') }}</th>
        <table-header-filter-text
          v-bind:filter-text.sync='filterContent'
          :default-label="$t('content')"
          class='px-2 py-2 whitespace-no-wrap' />
        <table-header-filter-text
          v-bind:filter-text.sync='filterUpdatedBy'
          :default-label="$t('updatedBy')"
          class='px-2 py-2 whitespace-no-wrap' />
      </tr>
      <tbody>
        <tr v-for='entry in filteredTimesheetEntries'
          :key='`invoice-timesheet-entry-${entry.id}`'
          class='border-t border-gray-200 text-sm'>
          <td>
            <label class='flex flex-row items-center justify-start gap-x-1'>
              <input type='checkbox'
              class='form-checkbox'
                :checked='entry.invoice_id === editingInvoice.id'
                @click='toggleTimesheetEntry(entry)'>
              <span>{{ $t('billReflection') }}</span>
            </label>
          </td>
          <td class='pr-2 py-2 whitespace-no-wrap'>
            {{ entry.work_date }}
          </td>
          <td class='px-2 py-2 text-right border-r'>
            {{ numberStyle(entry.rate * entry.billable_hours) }}
            {{ invoiceCurrency }}
          </td>
          <td class='px-2 py-2'>{{ entry.user_name }}</td>
          <td class='px-2 py-2'>
            <select
              v-model='entry.user_position'
              class='form-select w-30'>
              <option
                v-for='userPosition in selectableUserPositions'
                :key='`case-timesheet-user-position-${userPosition}`'
                :value='userPosition'>
                {{userPosition}}
              </option>
            </select>
          </td>
          <td v-if='calculateTotal'
            class='px-2 py-2'>
            <label class='flex flex-row items-center justify-start gap-x-1'>
              <input
                type='checkbox'
                class='form-checkbox'
                v-model='entry.charge_vat'>
              <span>{{ $t('vat') }}</span>
            </label>
          </td>
          <td class='px-2 py-2 text-right text-gray-500'>{{ numberStyle(entry.rate) }} {{ invoiceCurrency }}</td>
          <td class='px-2 py-2 text-right'>{{ hoursDigits(entry.billable_hours) }}</td>
          <td class='pl-2 py-2 text-gray-500'>{{ entry.description }}</td>
          <td class='pl-2 py-2'>{{ entry.updated_by_name }}</td>
        </tr>
      </tbody>
    </table>
    <div v-else
      class='flex-grow text-sm text-center p-8 bg-gray-100'>
      {{ $t('noTimesheetHistory') }}
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { mapMultiRowFields } from 'vuex-map-fields'
import numbro from 'numbro'
import TableHeaderFilterText from '@/components/TableHeaderFilterText.vue'
import StringHelpers         from '@/utils/string-helpers'

export default {
  name: 'CaseInvoiceBillingDetailsTimesheet',
  components: {
    TableHeaderFilterText,
  },
  data () {
    return {
      allEntriesToggled: false,
      allEntriesTax: false,
      filterDate: '',
      filterLawyer: '',
      filterPosition: '',
      filterContent: '',
      filterUpdatedBy: '',
    }
  },
  watch: {
    'editingInvoice.id': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.getTimesheetEntries({
            investment_id: this.editingInvoice.investment_id,
            invoice_id: [this.editingInvoice.id, 0, null] })
        }
      },
      immediate: true
    },
    allEntriesToggled: {
      handler: function (newVal) {
        this.timesheetEntries.forEach(entry => {
          entry.invoice_id = (newVal) ? this.editingInvoice.id : 0
        })
      },
    },
    allEntriesTax: {
      handler: function (newVal) {
        this.timesheetEntries.forEach(entry => {
          entry.charge_vat = newVal
        })
      },
    }
  },
  computed: {
    ...mapMultiRowFields('timesheetEntries', [
      'timesheetEntries',
    ]),
    ...mapState('invoices', [
      'editingInvoice',
    ]),
    ...mapState('timesheetEntries', [
      'selectableUserPositions',
    ]),
    hasTimesheetEntries () {
      return this.timesheetEntries.length > 0
    },
    filteredTimesheetEntries () {
      if (this.filterDate ===       '' &&
          this.filterLawyer ===     '' &&
          this.filterPosition ===   '' &&
          this.filterContent ===    '' &&
          this.filterUpdatedBy ===  '') {
        return this.timesheetEntries
      } else {
        return this.timesheetEntries.filter(timesheetEntry =>
          (this.filterDate ===      '' || StringHelpers.stringIncludesInsensitive(timesheetEntry.work_date, this.filterDate)) &&
          (this.filterLawyer ===    '' || StringHelpers.stringIncludesInsensitive(timesheetEntry.user_name, this.filterLawyer)) &&
          (this.filterPosition ===  '' || StringHelpers.stringIncludesInsensitive(timesheetEntry.user_position, this.filterPosition)) &&
          (this.filterContent ===   '' || StringHelpers.stringIncludesInsensitive(timesheetEntry.description, this.filterContent)) &&
          (this.filterUpdatedBy === '' || StringHelpers.stringIncludesInsensitive(timesheetEntry.updated_by_name, this.filterUpdatedBy))
        )
      }
    },
    invoiceCurrency () {
      return this.$t('krw') // need to get this from the case
    },
    totalTimeCost () {
      return this.timesheetEntries.reduce((sum, entry) => {
        if (entry.investment_id === this.editingInvoice.investment_id &&
            entry.invoice_id === this.editingInvoice.id) {
          return sum + parseFloat(entry.rate * entry.billable_hours)
        } else {
          return sum
        }
      }, 0)
    },
    totalTimeCostString () {
      return numbro(this.totalTimeCost).format({ thousandSeparated: true, mantissa: 0 })
    },
    calculateTotal () {
      return this.editingInvoice.use_calculated_total
    },
  },
  methods: {
    ...mapActions('timesheetEntries', [
      'getTimesheetEntries',
    ]),
    hoursDigits (hours) {
       return numbro(hours).format({ thousandSeparated: true, mantissa: 1 })
    },
    toggleTimesheetEntry (entry) {
      if (entry.invoice_id === this.editingInvoice.id) {
        entry.invoice_id = 0
      } else {
        entry.invoice_id = this.editingInvoice.id
      }
    },
    numberStyle (number) {
      return Number(number).toLocaleString()
    },
  },
}
</script>
