<template>
  <div>
    <portal :to='titleLocation'>
      <h1 class='text-2xl font-semibold px-8 py-8'>{{ $t('addClient') }}</h1>
    </portal>
    <entity-data-edit-fields />
    <portal :to='actionButtonsLocation'>
      <div class='flex flex-row justify-between w-full bg-gray-100 px-8 pr-8 py-4 text-sm shadow-lg'>
        <button
          v-if='addingEntity'
          class='border px-6 h-12 text-sm text-white rounded-md font-bold'
          style='background-color: #223645;'
          :disabled='!drilldownEntityRequiredFieldsValid'
          @click='createNewEntity'>{{ $t('addClient') }}</button>
        <button
          v-else
          class='border px-6 h-12 text-sm text-white rounded-md font-bold'
          style='background-color: #223645;'
          :disabled='!drilldownEntityRequiredFieldsValid'
          @click='submitEditEntity'>{{ $t('save') }}</button>
        <div v-if='canDelete'>
          <button v-if='!showConfirmDelete' name='show-confirm-delete-btn' class='p-3 text-red-700 text-sm border border-transparent hover:border-red-400 rounded-md' @click='showConfirm'>{{ $t('delete') }} <span class='font-semibold'>{{drilldownEntityName}}</span></button>
          <div v-else class='p-8 text-center mt-4 border rounded-md'>
            <h4 class='mb-8'><span class='font-semibold'>{{drilldownEntityName}}</span> {{ $t('deleteConfirm') }}</h4>
            <button @click='hideConfirm' class='py-3 px-6 rounded-md text-sm border border-gray-400 mr-4 hover:border-gray-800'>{{ $t('cancel') }}</button>
            <button @click='confirmDelete' name='confirm-delete-btn' class='p-3 text-red-700 text-sm border border-transparent hover:border-red-400 rounded-md'>{{ $t('delete') }}</button>
          </div>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import EntityDataEditFields from '@/views/entities/EntityDataEditFields.vue'

export default {
  name: 'EntityDataEdit',
  components: {
    EntityDataEditFields,
  },
  props: {
    displayInModal: {
      type: Boolean,
      default: false,
    },
    cases: {
      type: Array,
      default: () => [],
    }
  },
  data () {
    return {
      showConfirmDelete: false,
    }
  },
  computed: {
    ...mapGetters('entities', [
      'drilldownEntityName',
      'drilldownEntityId',
      'drilldownEntityRequiredFieldsValid',
    ]),
    ...mapState('entities', [
      'drilldownEntity'
    ]),
    noCasesForEntity () {
      return this.cases.length === 0
    },
    canDelete () {
      return !this.addingEntity && this.noCasesForEntity
    },
    addingEntity () {
      return this.displayInModal // for now, if we are using the modal, it is adding
    },
    actionButtonsLocation () {
      return (this.displayInModal) ? 'modal-footer' : 'sidepanel-footer'
    },
    titleLocation () {
      return 'modal-header'
    },
  },
  methods: {
    ...mapActions([
      'sidepanelClose',
    ]),
    ...mapActions('entities', [
      'deleteEntity',
      'updateEntity',
      'createEntity',
    ]),
    ...mapActions('companies', [
      'getCompanies',
    ]),
    createNewEntity () {
      this.createEntity().then(resp => {
        this.doneEditing(resp)
      })
    },
    cancelEditing () {
      this.doneEditing(this.drilldownEntity)
    },
    confirmDelete () {
      this.deleteEntity(this.drilldownEntityId).then(() => {
        this.doneEditing(this.drilldownEntity)
      })
    },
    doneEditing (resp) {
      this.$emit('done-editing', resp)
    },
    hideConfirm () {
      this.showConfirmDelete = false
    },
    showConfirm () {
      this.showConfirmDelete = true
    },
    submitEditEntity () {
      this.updateEntity(this.drilldownEntity).then(() => {
        this.doneEditing(this.drilldownEntity)
      })
    },
  },
  mounted () {
    this.getCompanies()
  },
}
</script>

<style lang='scss' scoped>
button:disabled,
button[disabled] {
  opacity: 0.6;
  cursor: not-allowed;
}
</style>
