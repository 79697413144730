<template>
  <div class='p-8 bg-gray-100 text-center max-w-4xl'>
    {{ $t('finTeamOrParticipantOnly') }}
  </div>
</template>

<script>

export default {
  name: 'NoBillingAccess',
}
</script>
