<template>
  <div>
    <button v-if='isViewMode' 
      @click='openUrl()'
      class='underline hover:bg-blue-100'>
      {{localFieldValue}}
    </button>
    <div v-else 
      class='flex'>
      <span class='inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm'>
        URL
      </span>
      <input 
        type='text' 
        v-model='localFieldValue'
        @blur='handleUpdate'
        class='form-input' style='width: max(4rem, 66%);'>
      <button @click='openUrl()'
        class='border px-4 border-l-0 rounded-r-md font-bold bg-gray-200 text-sm'
        style='margin-left: -2px;'>
        {{ $t('move') }}
      </button>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CustomFieldUrl',
  props: ['field', 'isViewMode'],
  data () {
    return {
      localFieldValue: this.field.value
    }
  },
  methods: {
    handleUpdate () {
      this.$emit('update-value', {...this.field, value: this.localFieldValue})
    },
    openUrl () {
      window.open(this.localFieldValue, '_blank')
    },
  },
}
</script>
