import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import i18n from './i18n'
Vue.config.productionTip = false


//////////////// Icons
// import VueFeather from 'vue-feather'
// Vue.use(VueFeather)


////////////// Use prebuilt components from Element-UI
import Element from 'element-ui'
import locale from 'element-ui/lib/locale/lang/ko'
Vue.use(Element, {locale})

import Tree from 'element-ui'
Vue.use(Tree)

//////////////// for Invesstment ledger
import Calendar from 'element-ui'
Vue.use(Calendar)

//////////////// Allows us to portal components to different locations
import PortalVue from 'portal-vue'
Vue.use(PortalVue)


//////////////// Allows us to portal components to different locations
import VTooltip from 'v-tooltip'
Vue.use(VTooltip)


//////////////// Copy to system clipboard
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)


//////////////// Cookies
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)


//////////////// Number input field
import VueNumeric from 'vue-numeric'
Vue.use(VueNumeric)


//////////////// Maps
// var Leaflet = require('@/assets/plugins/leaflet')
// Vue.use(Leaflet)


/////////////// Need to get app id for google analytics
import VueGtag from "vue-gtag"
const isDevelopment = process.env.NODE_ENV == 'development'
Vue.use(VueGtag, {
  pageTrackerExcludedRoutes: [
    '/',
    'Reach',
    'Home',
    '도착하다',
    '도달하다'
  ],
  config: {
    id: process.env.VUE_APP_GA_ID,
    enabled: !isDevelopment,
  }
}, router)


///////////////// Creates links from strings
import linkify from 'vue-linkify'
Vue.directive('linkified', linkify)


///////////////// Add Auth Token to download file link
import VueAuthHref from 'vue-auth-href'
Vue.use(VueAuthHref)



///////////////// Lodash - Use Some javascript utilities that aren't built into ES6
import VueLodash from 'vue-lodash'
import cloneDeep    from 'lodash/cloneDeep'
import intersection from 'lodash/intersection'
import last         from 'lodash/last'
Vue.use(VueLodash, { lodash: { cloneDeep, last, intersection } })


import vSelect from 'vue-select'
import 'vue-select/src/scss/vue-select.scss'
Vue.component('v-select', vSelect)

import ECharts            from 'vue-echarts'
import { use }            from 'echarts/core'
import { CanvasRenderer } from 'echarts/renderers'
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent
} from 'echarts/components'

use([
  CanvasRenderer,
  GridComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent
])

// register globally (or you can do it locally)
Vue.component('v-chart', ECharts)


/////////////////// Styles to make tailwind work
import './assets/tailwind.css'


/////////////////// overriding styles
import './assets/overrides.scss'


///////////////// AUTH0 Login & Authorization
import { Auth0Plugin } from './auth'
const domain = process.env.VUE_APP_AUTH0_DOMAIN
const clientId = process.env.VUE_APP_AUTH0_CLIENT_ID
const audience = process.env.VUE_APP_AUTH0_AUDIENCE
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  }
})



new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
