<template>
  <div class='p-3 border-l border-r border-b flex flex-row justify-between'>
    <div class='flex flex-row justify-between'>
      <div class='inline-block font-medium uppercase'>{{customFieldNameFromId(rule.custom_field_template_id)}}</div>
      <div class='inline-block mx-4 w-24 text-center'>{{rule.operator}}</div>
      <div class='inline-block mr-4 font-medium uppercase'>{{displayValue(rule)}}</div>
    </div>
    <button @click='removeRule(rule)' class='text-red-800'><x-circle-icon class='inline-block' style='margin-top: -4px;'/></button>
  </div>
</template>
<script>
import { XCircleIcon }          from '@vue-hero-icons/outline'
import { mapGetters, mapState } from 'vuex'
import dayjs from 'dayjs'
export default {
  name: 'CustomFieldTemplateExistingRule',
  components: {
    XCircleIcon,
  },
  props: [
    'rule'
  ],
  computed: {
    ...mapState('contacts', [
      'contacts',
    ]),
    ...mapState('dashboardViews', [
      'currentDashboardView',
    ]),
    ...mapGetters('contacts', [
      'contactNameFromId',
    ]),
    ...mapGetters('customFields', [
      'customFieldNameFromId'
    ]),
    ...mapState('users', [
      'users',
    ]),
  },
  methods: {
    displayValue (rule) {
      if (rule.custom_field_type === 'user') {
        let found = this.users.find(user => user.id === rule.custom_field_value)
        if (found !== undefined) {
          return found.name
        } else {
          return "$t('noUserError')"
        }
      } else if (rule.custom_field_type === 'contact') {
        return this.contactNameFromId(parseInt(rule.custom_field_value))
      } else if (rule.custom_field_type === 'date') {
        return dayjs(rule.custom_field_value).format('YYYY-MM-DD')
      } else if (rule.custom_field_values) {
        return rule.custom_field_values.toString()
      } else {
        return rule.custom_field_value
      }
    },
    removeRule (rule) {
      this.$emit('remove-rule', rule)
    },
  },
}
</script>
