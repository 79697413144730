<template>
  <div class='mb-4 flex flex-row justify-start items-start'>
    <label class='inline-block w-32 flex-none leading-10'>{{title}}</label>
    <div class='overflow-x-auto flex-grow'>
      <div>
        <table class='table-auto'>
          <thead>
            <tr>
              <th class='border px-2 py-2 text-left text-sm whitespace-nowrap'
                v-for='header in headers'
                :key='`related-infos-${header}-${caseInfo.id}-${caseInfo.rand}`'
                :class='wideClass(header)'>
                {{header}}
              </th>
              <th class='border px-2 py-2 w-1/12'></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for='(row, index) in relatedInfos' :key='`row-${caseInfo.id}-${caseInfo.rand}-${index}`'>
              <td class='border text-left px-2 py-2'
                style='min-width: 120px;'
                v-for='header in headers'
                :key='`col-${header}-${caseInfo.id}-${caseInfo.rand}-${index}`'>
                <input class='form-input w-full' v-model="relatedInfos[index][header]" @change='updateRelatedInfos'>
              </td>
              <td class='border text-center px-2 py-2'>
                <button
                  @click='removeRow(index)'>
                  <x-circle-icon class='inline-bock text-red-900 opacity-75' />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <button
          @click='addBlankRow'
          class='mt-2 mb-4 px-4 py-2 rounded text-sm border border-transparent hover:border-blue-300 hover:shadow'>
          <plus-circle-icon class='inline-block text-gray-700' style='margin-top: -4px' />
          {{ $t('add') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { PlusCircleIcon, XCircleIcon } from '@vue-hero-icons/outline'
import { mapMutations } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'RelatedInfos',
  props: [
    'caseInfo',
    'headers',
    'title'
  ],
  components: { PlusCircleIcon, XCircleIcon },
  data () {
    return {
      relatedInfos: cloneDeep(this.caseInfo.related_infos),
    }
  },
  methods: {
    ...mapMutations('investments', [
      'updateDrilldownCaseInfoRelatedInfos',
    ]),
    addBlankRow () {
      let blankRow = {}
      this.headers.forEach(header => blankRow[header] = '')
      this.relatedInfos.push(blankRow)
      this.updateRelatedInfos()
    },
    removeRow (index) {
      this.relatedInfos.splice(index, 1)
      this.updateRelatedInfos()
    },
    updateRelatedInfos () {
      this.updateDrilldownCaseInfoRelatedInfos({caseInfo: this.caseInfo, relatedInfos: this.relatedInfos})
    },
    wideClass (header) {
      return ['재판부', '담당검사', '담당수사관', '구치소'].includes(header) ? 'w-5/12' : 'w-3/12'
    },
  },
}
</script>


