import Vue          from 'vue'
import Vuex         from 'vuex'
import authLogsApi  from '@/api/v1/auth_logs'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

const state = () => ({
  authLogs: [],
})

const getters = {
  getField,
}

const actions = {
  getAuthLogs ({ commit, dispatch }, filters={}) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      authLogsApi.getAuthLogs(filters).then(resp => {
        commit('setAuthLogs', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
}

const mutations = {
  setAuthLogs (state, authLogs) {
    state.authLogs = authLogs
  },
  updateField
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}