<template>
  <div class='pb-12 px-2 lg:px-8 pt-8'>
    <h1 class='text-2xl font-semibold'>
      {{ $t('depositDetailedData') }}
    </h1>
    <div>
      <div class='flex flex-row justify-start mt-16'>
        <label class='w-32 uppercase font-semibold text-sm'>{{ $t('status') }}</label>
        <div class=''>{{depositStatusLabel}}</div>
      </div>
      <div class='flex flex-row justify-start mt-4'>
        <label class='w-32 uppercase font-semibold text-sm pt-2'>{{ $t('depositDateTime') }}</label>
        <el-date-picker
          v-model='deposit_date'
          format='yyyy-MM-dd HH:mm:ss'
          value-format='yyyy-MM-dd HH:mm:ss'
          type='datetime'
          :placeholder="$t('depositDate')"
          @change='depositUpdated'>
        </el-date-picker>
      </div>
      <div class='flex flex-row justify-start mt-4 leading-10'>
        <label class='w-32 uppercase font-semibold text-sm'>{{ $t('depositAmount') }}</label>
        <vue-numeric
          v-model='amount'
          output-type='number'
          class='form-input mr-2'
          :minus='false'
          separator=','
          @change='depositUpdated'></vue-numeric>KRW
      </div>
      <div class='flex flex-row justify-start mt-4 leading-10'>
        <label class='w-32 uppercase font-semibold text-sm'>{{ $t('depositor') }}</label>
        <input class='form-input mr-2' type='text' v-model='from' @change='depositUpdated'>
      </div>
      <div class='flex flex-row justify-start mt-4 leading-10'>
        <label class='w-32 uppercase font-semibold text-sm'>{{ $t('note') }}</label>
        <textarea
          v-model='note'
          @change='depositUpdated'
          class='form-textarea'
          rows='3'
          style='width:32rem;'
          ></textarea>
      </div>
    </div>
    <div class='mt-16 flex flex-row justify-start items-center'>
      <h1 class='text-lg uppercase font-semibold text-gray-800'>
        {{ $t('bill') }}
      </h1>
    </div>
    <div class='flex flex-row items-center gap-x-1 mt-1'>
      <invoice-search
        :deposit-id='this.drilldownDeposit.id'
        @update-selected-invoice='updateSelectedInvoice' />
      <button
        :disabled='!validNewInvoice'
        class='uppercase pl-2 pr-4 py-2 text-xs bg-black border rounded-md border-transparent text-white opacity-75 hover:opacity-100 hover:shadow-md'
        @click='addNewInvoice'>
        <plus-circle-icon class='inline-block' style='margin-top: -4px;' />
        {{ $t('addCaseInvoice') }}
      </button>
    </div>
    <table class='table-fixed w-full mt-8 text-sm'>
      <tr class='uppercase font-semibold text-gray-600 border-gray-200 border-b'>
        <th class='pr-2 py-2 w-32 text-left'>{{ $t('billingName') }}</th>
        <th class='pr-2 py-2 w-32 text-left'>{{ $t('billingDate') }}</th>
        <th class='px-2 py-2 text-left'>{{ $t('case') }}</th>
        <th class='px-2 py-2 w-40 text-right'>{{ $t('totalBilledAmount') }}</th>
        <th class='px-2 py-2 w-40 text-right'>{{ $t('remainingAmount') }}</th>
        <th class='px-2 py-2 w-48 text-right'>{{ $t('enterCurrentDeposit') }}</th>
      </tr>
      <tbody class='overflow-y-auto '>
        <tr v-for='invoice in depositInvoices'
          :key='`deposit-${drilldownDeposit.id}-attach-invoices-${invoice.id}`'
          class='border-b border-gray-200'>
          <td class='pr-2 py-2'>{{ invoice.invoice_title }}</td>
          <td class='pr-2 py-2'>{{ invoice.invoice_date }}</td>
          <td class='px-2 py-2'>
            <a :href='drilldownLink(invoice.investment_id)'
              target='_blank'
              class='underline hover:bg-gray-100 py-2'>
              {{ invoice.case_name }}
            </a>
          </td>
          <td class='px-2 py-2 text-right'>{{ invoiceAmountString(invoice.invoice_amount) }}</td>
          <td class='px-2 py-2 text-right'>
            {{ invoiceRemaining(invoice) }}
          </td>
          <td class='px-2 py-2 text-right'>
            <vue-numeric
              v-model='invoice.deposit_attributed_amount'
              output-type='number'
              class='form-input text-right w-48'
              :class='validClass(invoice)'
              separator=','
              @change='changeInvoice(invoice.id)'>
              <div class='text-red-600'>{{ errorMsg(invoice) }}</div>
            </vue-numeric>
          </td>
        </tr>
      </tbody>
      <tfoot class='uppercase font-semibold'>
        <tr>
          <td class='px-2 py-2'></td>
          <td class='px-2 py-2'></td>
          <td></td>
          <td class='px-2 py-2 text-right'>{{ $t('sum') }}</td>
          <td class='px-2 py-2 text-right'>{{ $t('depositKrw', [totalAttributeAmount]) }}</td>
        </tr>
      </tfoot>
    </table>

    <portal to='sidepanel-footer'>
      <div class='justify-between w-full bg-gray-100 pl-8 pr-8 py-3 text-sm shadow-lg'>
        <button class='inline-block py-3 px-16 text-white rounded-md font-bold border border-transparent hover:border-blue-500 hover:shadow-md' style='background-color: #223645;'
          @click='saveEdits' v-if='!hasValidationErrors'>
          {{ $t('save') }}
        </button>
        <button class='inline-block mx-4 px-4 py-3 bg-transparent text-gray-600 hover:text-gray-900 border border-transparent rounded-md hover:border-gray-600 hover:shadow-md'
          @click='closeEdits'>
          {{ $t('cancel') }}
        </button>
        <div class='inline-block text-red-600 mx-4 px-4 py-3'>{{ totalAttributedError }}</div>
      </div>
    </portal>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { mapFields, mapMultiRowFields }       from 'vuex-map-fields'
import numbro                                 from 'numbro'
import InvoiceSearch                          from '@/components/invoices/InvoiceSearch.vue'
import { PlusCircleIcon }                     from '@vue-hero-icons/outline'
import cloneDeep                              from 'lodash/cloneDeep'

export default {
  name: 'DepositAttachInvoices',
  components: { InvoiceSearch, PlusCircleIcon },
  data () {
    return {
      newInvoice: {
        invoice_id: 0,
        invoice_date: '',
        investment_id: 0,
        invoice_amount: 0,
        deposit_attributed_amount: 0,
        attributed_amount: 0
      },
      depositChanged: false,
      changedInvoiceIds: [],
      filterByMe: true,
    }
  },
  computed: {
    ...mapFields('deposits', [
      'drilldownDeposit',
      'drilldownDeposit.deposit_date',
      'drilldownDeposit.amount',
      'drilldownDeposit.from',
      'drilldownDeposit.note',
    ]),
    ...mapMultiRowFields('deposits', [
      'depositInvoices',
    ]),
    ...mapState('deposits', [
      'filterStartDate',
      'filterEndDate',
      'depositStatuses',
    ]),
    ...mapState('deposits', {
      stateDepositInvoices: 'depositInvoices',
    }),
    changedDepositInvoicesParams () {
      return this.depositInvoices.filter((invoice) => this.changedInvoiceIds.includes(invoice.id))
        .map((invoice) => {
          return { deposit_id: this.drilldownDeposit.id, invoice_id: invoice.id, attributed_amount: invoice.deposit_attributed_amount }
      })
    },
    hasValidationErrors () {
      return this.totalAttributedError.length > 0 || this.depositInvoices.some(invoice => this.validClass(invoice).length > 0)
    },
    totalAttributedAmount () {
      return this.depositInvoices.reduce((sum, invoice) => {
        return sum + parseFloat(invoice.deposit_attributed_amount)
      }, 0)
    },
    totalAttributedError () {
      return (this.totalAttributedAmount > this.amount) ? this.$t('depositIsBigger') : ''
    },
    totalAttributeAmount () {
      return this.invoiceAmountString(this.depositInvoices.reduce((total, deposit) => {
        return total + parseFloat(deposit.deposit_attributed_amount)
      }, 0))
    },
    depositStatusLabel () {
      let depositStatus = this.depositStatuses.find(status => status.value === this.drilldownDeposit.status)
      return depositStatus ? depositStatus.label : this.drilldownDeposit.status
    },
    validNewInvoice () {
      return this.newInvoice.invoice_id > 0
    },
  },
  methods: {
    ...mapActions(['sidepanelClose']),
    ...mapActions('deposits', [
      'depositAttachInvoicesClose',
      'getDeposits',
      'getDepositInvoices',
      'resetDrilldownDeposit',
      'updateDeposit',
      'updateDepositInvoices',
    ]),
    ...mapMutations('deposits', [
      'addToDepositInvoices',
    ]),
    addNewInvoice () {
      if (this.depositInvoices.findIndex(i => i.id === this.newInvoice.id) < 0) {
        this.addToDepositInvoices(cloneDeep(this.newInvoice))
      }
      this.resetNewInvoice()
    },
    closeEdits () {
      this.getDeposits().then(() => this.sidepanelClose())
    },
    depositUpdated () {
      this.depositChanged = true
    },
    changeInvoice (invoiceId) {
      if (this.changedInvoiceIds.findIndex(i => i === invoiceId) < 0) {
        this.changedInvoiceIds.push(invoiceId)
      }
    },
    drilldownLink (investmentId) {
      return `drilldown?investment_id=${investmentId}&tab=invoices`
    },
    invoiceRemaining (invoice) {
      let remaining = parseFloat(invoice.invoice_amount) - parseFloat(invoice.total_attributed_amount)
      return this.invoiceAmountString(remaining)
    },
    invoiceAmountString (amount) {
      return numbro(amount).format({ thousandSeparated: true })
    },
    refreshDepositInvoices () {
      this.getDepositInvoices({ deposit_id: this.drilldownDeposit.id, filter_by_me: this.filterByMe })
    },
    resetNewInvoice () {
      this.newInvoice.invoice_id = 0
    },
    saveDeposit () {
      return this.updateDeposit(this.drilldownDeposit).then(() => this.depositChanged = false)
    },
    saveDepositInvoices () {
      let investmentId = this.depositInvoices.length > 0 ? this.depositInvoices[0].investment_id : 0
      return this.updateDepositInvoices({deposit_invoices: this.changedDepositInvoicesParams,
                                  deposit_id: this.drilldownDeposit.id,
                                  investment_id: investmentId,
                                  invoice_ids: this.changedInvoiceIds}).then(() => this.changedInvoiceIds = [])
    },
    saveEdits () {
      let promises = []
      if (this.depositChanged) {
        promises.push(this.saveDeposit())
      }
      if (this.changedDepositInvoicesParams.length > 0) {
        promises.push(this.saveDepositInvoices())
      }
      Promise.all(promises).then(() => {
        this.$message({
          type: 'success',
          message: this.$t('depositEditSuccessMsg')
        })
        this.closeEdits()
      })
    },
    errorMsg (invoice) {
      return this.validClass(invoice).length > 0 ? this.$t('depositBiggerThanBillable') : ''
    },
    updateSelectedInvoice (invoice) {
      if (invoice) {
        this.newInvoice.invoice_id = invoice.id
        Object.assign(this.newInvoice, invoice)
      } else {
        this.resetNewInvoice()
      }
    },
    validClass (invoice) {
      return (invoice.attributed_amount > invoice.invoice_amount) ? 'border-red-600 text-red-600' : ''
    },
  },
  mounted () {
    this.refreshDepositInvoices()
  },
  beforeDestroy () {
    this.resetDrilldownDeposit()
  }
}
</script>
