import base from './base'

const getDeposits = (filterParams) => base
  .get(`/deposits/`, { params: filterParams }).then(resp => resp.data)

const getInvoiceDeposits = (invoiceId) => base
  .get('/invoice_deposits/', { params: { invoice_id: invoiceId } }).then(resp => resp.data)

const postDeposits = (depositsParams, contentType='application/json') => base
  .post('/deposits/', depositsParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const updateDeposit = (depositId, depositParams, contentType='application/json') => base
  .patch(`/deposits/${depositId}`, depositParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const deleteDeposit = (depositId) => base
  .delete(`/deposits/${depositId}`).then(resp => resp.data)

export default {
  getDeposits: getDeposits,
  getInvoiceDeposits: getInvoiceDeposits,
  postDeposits: postDeposits,
  updateDeposit: updateDeposit,
  deleteDeposit: deleteDeposit,
}
