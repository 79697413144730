<template>
  <div>
    <div v-if='showEditButtons' class='w-full flex flex-row justify-between'>
      <button class='block border px-12 py-2 text-sm rounded-md uppercase hover:border-blue-300 hover:shadow-md'
        @click='editData'>
        {{ $t('modifyData') }}
      </button>
      <span class='float-right'>
        <button
          v-if='investment.archived'
          class='p-3 text-gray-700 text-sm border border-transparent hover:border-gray-400 hover:shadow-md rounded-md'
          @click='showUnarchiveConfirmation'>
          {{ $t('unarchived') }}
        </button>
        <button
          v-if='!investment.archived'
          class='p-3 text-gray-700 text-sm border border-transparent hover:border-gray-400 hover:shadow-md rounded-md'
          @click='showArchiveConfirmation'>
          {{ $t('archived') }}
        </button>
        <button
          class='p-3 text-red-700 text-sm border border-transparent hover:border-red-400 hover:shadow-md rounded-md'
          @click='showDeleteConfirmation'>
          {{ $t('delete') }}
        </button>
      </span>
    </div>
    <div v-else-if='showConfirmButtons' class='p-8 text-center mt-4 border rounded-md'>
      <h4 class='mb-8'><span class='font-semibold'>{{investment.entity_name}}-{{investment.investment_name}}</span>{{confirmMessage}}</h4>
      <button @click='hideConfirm' class='py-3 px-6 rounded-md text-sm border border-gray-400 mr-4 hover:border-gray-800'>{{ $t('cancel') }}</button>
      <button v-if='archiveMode' @click='submitArchive' class='p-3 text-red-700 text-sm border border-transparent hover:border-red-400 rounded-md'>{{ $t('archive') }}</button>
      <button v-if='unarchiveMode' @click='submitUnarchive' class='p-3 text-red-700 text-sm border border-transparent hover:border-red-400 rounded-md'>{{ $t('unarchive') }}</button>
      <button v-if='deleteMode' @click='submitDelete' class='p-3 text-red-700 text-sm border border-transparent hover:border-red-400 rounded-md'>{{ $t('delete') }}</button>
    </div>
  </div>
</template>

<script>

import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'InvestmentMetadataEditButtons',
  props: [ 'investment'],
  data () {
    return {
      confirmMode: '',
    }
  },
  computed: {
    confirmMessage () {
      if (this.archiveMode) {
        return this.$t('archiveConfirm')
      } else if (this.deleteMode) {
        return this.$t('deleteConfirm')
      } else if (this.unarchiveMode) {
        return this.$t('unarchiveConfirm')
      } else {
        return this.$t('error')
      }
    },
    archiveMode () {
      return this.confirmMode === 'archive'
    },
    deleteMode () {
      return this.confirmMode === 'delete'
    },
    unarchiveMode () {
      return this.confirmMode === 'unarchive'
    },
    showEditButtons () {
      return !this.showConfirmButtons
    },
    showConfirmButtons () {
      return this.confirmMode === 'delete' || this.confirmMode === 'archive' || this.confirmMode === 'unarchive'
    },
  },
  methods: {
    ...mapActions([
      'sidepanelClose'
    ]),
    ...mapActions('investments', [
      'archiveInvestment',
      'unarchiveInvestment',
      'deleteInvestment',
      'resetEditing',
    ]),
    ...mapMutations('investments', [
      'setDrilldownIsViewMode'
    ]),
    submitArchive () {
      this.archiveInvestment(this.investment.id).then(() => {
        this.resetEditing()
        this.sidepanelClose()
      })
    },
    submitUnarchive () {
      this.unarchiveInvestment(this.investment.id).then(() => {
        this.resetEditing()
        this.sidepanelClose()
      })
    },
    submitDelete () {
      this.deleteInvestment(this.investment.id).then(() => {
        this.resetEditing()
        this.sidepanelClose()
      })
    },
    editData () {
      this.setDrilldownIsViewMode(false)
    },
    hideConfirm () {
      this.confirmMode = ''
    },
    showArchiveConfirmation () {
      this.confirmMode = 'archive'
    },
    showUnarchiveConfirmation () {
      this.confirmMode = 'unarchive'
    },
    showDeleteConfirmation () {
      this.confirmMode = 'delete'
    },
  },
}
</script>
