<template>
  <div class=''>
    <div v-if='isViewMode'
      class='whitespace-pre-line break-words'
      :style='userFieldStyle'>
      <el-popover
        placement='top-start'
        :title="$t('details')"
        width='300'
        trigger='hover'
        :content='userDetails'>
        <span slot='reference'>{{ usersString }}</span>
      </el-popover>
    </div>
    <div v-else>
      <user-search
        :user-ids='field.value_json'
        :user-names='field.display_value'
        :is-multiple='true'
        :role='field.template_metadata.role'
        @update-selected-user='updateUser' />
    </div>
  </div>
</template>

<script>
import UserSearch from '@/components/UserSearch.vue'

export default {
  name: 'CustomFieldUser',
  props: ['field', 'isViewMode'],
  components: {
    UserSearch
  },
  computed: {
    usersCount () {
      return this.field.value_json.length
    },
    usersString () {
      return (this.field.display_value) ? this.field.display_value.replaceAll(',', ', ') : ''
    },
    userFieldStyle () {
      return `width: clamp(12rem,${this.usersCount * 12}rem, 36rem);`
    },
    userDetails () {
      return this.field.metadata.users ?
        this.field.metadata.users.map(user =>
          `${user.name}: ${user.email}`
        ).join('\n') : ''
    }
  },
  methods: {
    updateUser (ids, nameAndEmails) {
      this.$emit('update-value', {...this.field,
        value_json: ids,
        value: nameAndEmails.toString(),
        display_value: nameAndEmails.toString(),
      })
    }
  }
}
</script>
