import Vue from 'vue'
import Vuex from 'vuex'
import usersApi from '@/api/v1/users'
import { getField, updateField } from 'vuex-map-fields'
import UsersHelpers from '@/utils/users-helpers.js'

Vue.use(Vuex)

const state = () => ({
  currentUserInfo: {
    email: '',
    smsNumber: '',
    roles: '',
    userId: 0,
    firmId: 0,
    userName: '',
    department: '',
    team: '',
    title: '',
    layer: '',
  },
  drilldownUser: UsersHelpers.defaultUser(),
  drilldownUserGroup: UsersHelpers.defaultUserGroup(),
  showUserGroupDetails: false,
  users: [],
  userGroups: [],
  roles: [
    {name: '전체', role: ''},
    {name: '변호사', role: 'lawyer'},
    {name: '비서', role: 'secretary'}
  ],
  myUserDefaultHourlyRate: 0,
})

const getters = {
  isAdmin: state => {
    return state.currentUserInfo.roles.includes('admin')
  },
  isTeamLead: state => {
    return state.currentUserInfo.roles.includes('TeamLead')
  },
  isLawyer: state => {
    return state.currentUserInfo.roles.includes('lawyer')
  },
  isBilling: state => {
    return state.currentUserInfo.roles.includes('billing')
  },
  isSecretary: state => {
    return state.currentUserInfo.roles.includes('secretary')
  },
  isBbridgeAdmin: state => {
    return state.currentUserInfo.roles.includes('bbridge')
  },
  isLeaderGroup: state => {
    return ['대표변호사', '그룹장', '팀장', '부팀장'].includes(state.currentUserInfo.layer)
  },
  isPartner: state => {
    return ['파트너'].includes(state.currentUserInfo.layer)
  },
  isAssociateLawyer: state => {
    return state.currentUserInfo.position.includes('어쏘')
  },
  usersCount: state => {
    return state.users.length
  },
  userGroupsCount: state => {
    return state.userGroups.length
  },
  getUserNameFromId: state => id => {
    let found = state.users.find(user => user.id === id)
    return (found) ? found.name : ''
  },
  usersByRole: state => whichRole => {
    return state.users.filter(user => user.roles.includes(whichRole))
  },
  userNamesFromIds: state => userIds => {
    let found = ''
    if (userIds) {
      found = state.users.filter(user => userIds.includes(user.id)).map(user => user.name).join(',')
    }
    return found
  },
  filteredUsersByIds: state => userIds => {
    return state.users.filter(user => userIds.includes(user.id))
  },
  currentUserId: state => {
    return state.currentUserInfo.userId
  },
  currentUserName: state => {
    return state.currentUserInfo.userName
  },
  currentUserEmail: state => {
    return state.currentUserInfo.email
  },
  currentUserPosition: state => {
    return state.currentUserInfo.position
  },
  getField,
}

const actions = {
  createUserGroup ({ commit, dispatch }, group) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      usersApi.postUserGroup(group).then(resp => {
        commit('setDrilldownUserGroup', resp)
        commit('addDrilldownUserGroupToUserGroups')
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  deleteUser ({commit}, userID) {
    return new Promise(resolve => {
      usersApi.deleteUser(userID).then(resp => {
        commit('setDrilldownUser', resp)
        commit('reinstateUser', resp)
        resolve(resp)
      })
    })
  },
  reinstateUser ({commit}, userID) {
    return new Promise(resolve => {
      usersApi.reinstateUser(userID).then(resp => {
        commit('setDrilldownUser', resp)
        commit('reinstateUser', resp)
        resolve(resp)
      })
    })
  },
  getUsers ({ commit }) {
    return new Promise((resolve) => {
      usersApi.getUsers().then(resp => {
        commit('setUsers', resp)
        resolve(resp)
      })
    })
  },
  getUsersByIds (context, userIds) {
    return new Promise(resolve => {
      usersApi.getUsersByIds(userIds).then(resp => {
        resolve(resp)
      })
    })
  },
  getUserGroups ({ commit }) {
    return new Promise((resolve) => {
      usersApi.getUserGroups().then(resp => {
        commit('setUserGroups', resp)
        resolve(resp)
      })
    })
  },
  getCurrentUserInfo ({ commit }) {
    return usersApi.getCurrentUserInfo().then(resp => {
      localStorage.setItem('roles', resp.roles.toString())
      localStorage.setItem('userId', resp.id)
      localStorage.setItem('firmId', resp.firm_id)
      localStorage.setItem('email', resp.email)
      localStorage.setItem('smsNumber', resp.sms_number)
      localStorage.setItem('userName', resp.name)
      localStorage.setItem('department', resp.organogram.department)
      localStorage.setItem('team', resp.organogram.team)
      localStorage.setItem('title', resp.organogram.title)
      localStorage.setItem('layer', resp.organogram.layer)
      localStorage.setItem('position', resp.organogram.position)
      commit('updateCurrentUserFromLocalStorage')
    })
  },
  updateUser ({ commit, dispatch }, user) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      usersApi.putUser(user).then(resp => {
        commit('setDrilldownUser', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  updateUserGroup ({ commit, dispatch }, group) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      usersApi.putUserGroup(group).then(resp => {
        commit('setDrilldownUserGroup', resp)
        commit('addDrilldownUserGroupToUserGroups')
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  userDetailsOpen ({ commit, dispatch }, user) {
    commit('setDrilldownUser', user)
    dispatch('sidepanelOpen', { componentName: 'user-details'}, { root : true })
  },
  resetDrilldownUserGroup ({ commit }) {
    commit('setDrilldownUserGroup', UsersHelpers.defaultUserGroup())
  },
  resetDrilldownUser ({ commit }) {
    commit('setDrilldownUser', UsersHelpers.defaultUser())
  },
  userGroupDetailsOpen ({ commit, dispatch }, group) {
    commit('setDrilldownUserGroup', group)
    dispatch('sidepanelOpen', { componentName: 'user-group-details' }, { root : true })
  },
  searchUsersByText (context, filterParams) {
    return new Promise(resolve => {
      usersApi.searchUsers(filterParams).then(resp => {
        resolve(resp)
      })
    })
  },
  getDefaultHourlyRate ({ commit }) {
    return new Promise((resolve) => {
      usersApi.getCurrentUserConfigurations().then(resp => {
        commit('setUserHourlyRate', resp.configurations.my_default_hourly_rate)
        resolve(resp)
      })
    })
  },
  updateDefaultHourlyRate ({ state, commit }) {
    return new Promise((resolve) => {
      usersApi.updateMyDefaultHourlyRate(state.myUserDefaultHourlyRate).then(resp => {
        commit('setUserHourlyRate', resp.configurations.my_default_hourly_rate)
        resolve(resp.configurations.my_default_hourly_rate)
      })
    })
  }
}

const mutations = {
  addDrilldownUserGroupToUserGroups (state) {
    let index = state.userGroups.findIndex((group) => group.id === state.drilldownUserGroup.id)
    if (index < 0) {
      state.userGroups.push(state.drilldownUserGroup)
    } else {
      state.userGroups.splice(index, 1, state.drilldownUserGroup)
    }
  },
  removeUser (state, userID) {
    const index = state.users.findIndex(user => user.id === userID)
    if (index >= 0) {
      state.users.splice(index, 1)
    }
  },
  reinstateUser (state, reinstateUser) {
    const index = state.users.findIndex(user => user.id === reinstateUser.id)
    if (index >= 0) {
      state.users[index] = reinstateUser
    }
  },
  setDrilldownUser (state, user) {
    state.drilldownUser = user
  },
  setDrilldownUserGroup (state, group) {
    state.drilldownUserGroup = group
  },
  setUsers (state, fromApi) {
    state.users = fromApi
  },
  setUserGroups (state, fromApi) {
    state.userGroups = fromApi
  },
  updateCurrentUserFromLocalStorage (state) {
    state.currentUserInfo.roles      = localStorage.getItem('roles')
    state.currentUserInfo.userId     = parseInt(localStorage.getItem('userId'))
    state.currentUserInfo.firmId  = parseInt(localStorage.getItem('firmId'))
    state.currentUserInfo.email      = localStorage.getItem('email')
    state.currentUserInfo.smsNumber  = localStorage.getItem('smsNumber')
    state.currentUserInfo.userName   = localStorage.getItem('userName')
    state.currentUserInfo.department = localStorage.getItem('department')
    state.currentUserInfo.team       = localStorage.getItem('team')
    state.currentUserInfo.title      = localStorage.getItem('title')
    state.currentUserInfo.layer      = localStorage.getItem('layer')
    state.currentUserInfo.position   = localStorage.getItem('position')
  },
  setUserHourlyRate (state, fromApi) {
    state.myUserDefaultHourlyRate = fromApi
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
