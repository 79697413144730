<template>
  <div>
    <table v-if='hasTimeSheetEntriesOnInvoice'
      class='mt-4 w-full table-fixed text-sm'>
      <thead>
        <tr class='text-xs uppercase text-gray-700'>
          <th class='w-24 pr-2 py-2 border-r'>{{ $t('workDate') }}</th>
          <th class='w-24 px-2 py-2 border-r'>{{ $t('bill') }}</th>
          <th class='w-24 px-2 py-2 border-r'>{{ $t('workType') }}</th>
          <table-header-filter-text
            v-bind:filter-text.sync='filterUserName'
            :default-label="$t('lawyer')"
            class='w-24 px-2 py-2 border-r' />
          <table-header-filter-text
            v-bind:filter-text.sync='filterUserPosition'
            :default-label="$t('position')"
            class='w-24 px-2 py-2 border-r' />
          <table-header-filter-text
            v-bind:filter-text.sync='filterPerformanceLawyer'
            :default-label="$t('performanceLawyer')"
            class='w-24 px-2 py-2 border-r' />
          <th class='w-24 px-2 py-2 border-r text-right'>{{ $t('timeCharge') }}</th>
          <th class='w-16 px-2 py-2 border-r text-right'>{{ $t('workHours') }}</th>
          <th class='w-16 px-2 py-2 border-r text-right'>{{ $t('billingTime') }}</th>
          <th class='w-32 px-2 py-2 whitespace-no-wrap'>{{ $t('detailsWork') }}</th>
          <th class='w-32 text-right'>
          </th>
        </tr>
      </thead>
      <case-timesheet-entry-row
        v-for='entry in filteredData'
        :key='`case-timesheet-entry-${entry.id}`'
        :timesheet-entry='entry'
        class='border-t'/>
      <tfoot>
        <tr class='border-t uppercase text-sm font-semibold'>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class='px-2 py-2 border-r text-right'>{{ $t('total') }}</td>
          <td class='px-2 py-2 border-r text-right'>{{totalWorkHours}}</td>
          <td class='px-2 py-2 border-r text-right'>{{totalBillableHours}}</td>
          <td></td>
          <td></td>
        </tr>
      </tfoot>
    </table>
    <div v-else
      class='mt-2 text-center bg-gray-100 p-8 rounded'>
      {{ $t('noTimesheetOnInvoices') }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import numbro from 'numbro'
import CaseTimesheetEntryRow from '@/views/timesheet/CaseTimesheetEntryRow.vue'
import TableHeaderFilterText from '@/components/TableHeaderFilterText.vue'
import StringHelpers         from '@/utils/string-helpers'

export default {
  name: 'CaseTimesheetsWithInvoice',
  components: {
    TableHeaderFilterText,
    CaseTimesheetEntryRow,
  },
  data () {
    return {
      filterUserName: '',
      filterUserPosition: '',
      filterPerformanceLawyer: '',
    }
  },
  watch: {
    filteredData: {
      handler: function () {
        this.$emit('update:filterWithInvoice', this.filteredData)
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters('timesheetEntries', [
      'timesheetEntriesWithInvoice',
      'timesheetEntriesWithInvoiceCount',
    ]),
    hasTimeSheetEntriesOnInvoice () {
      return this.timesheetEntriesWithInvoiceCount > 0
    },
    filteredData () {
      if (this.filterUserName ===          '' &&
          this.filterUserPosition ===      '' &&
          this.filterPerformanceLawyer === '') {
        return this.timesheetEntriesWithInvoice
      }
      else {
        return this.timesheetEntriesWithInvoice.filter(entry =>
          (this.filterUserName === '' || StringHelpers.stringIncludesInsensitive(entry.user_name, this.filterUserName)) &&
          (this.filterUserPosition === '' || StringHelpers.stringIncludesInsensitive(entry.user_position, this.filterUserPosition)) &&
          (this.filterPerformanceLawyer === '' || StringHelpers.stringIncludesInsensitive(entry.performance_lawyer, this.filterPerformanceLawyer))
        )
      }
    },
    totalWorkHours () {
      let sum = 0
      if (this.timesheetEntriesWithInvoice.length > 0) {
        sum = this.timesheetEntriesWithInvoice.map(entry => entry.work_hours).reduce((a, b) => (a + b))
      }
      return numbro(sum).format({ thousandSeparated: true, mantissa: 1 })
    },
    totalBillableHours () {
      let sum = 0
      if (this.timesheetEntriesWithInvoice.length > 0) {
        sum = this.timesheetEntriesWithInvoice.map(entry => entry.billable_hours).reduce((a, b) => (a + b))
      }
      return numbro(sum).format({ thousandSeparated: true, mantissa: 1 })
    }
  },
}
</script>
