<template>
  <div class='text-sm pt-8'>
    <entity-data-view v-if='hasEntity' :entity='invoiceEntity' />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import EntityDataView from '@/components/entities/EntityDataView.vue'

export default {
  name: 'InvoiceEntityDetails',
  components: {
    EntityDataView,
  },
  data () {
    return {
      invoiceEntity: null
    }
  },
  watch: {
    'editingInvoice.id': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.getEntity(this.editingInvoice.entity_id).then(resp => {
            this.invoiceEntity = resp
          })
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapState('invoices', [
      'editingInvoice',
    ]),
    hasEntity () {
      return this.invoiceEntity ? Object.keys(this.invoiceEntity).length > 0 : false
    },
  },
  methods: {
    ...mapActions('entities', [
      'getEntity',
    ]),
  },
}
</script>
