<template>
  <component
    :is='inputType'
    :field='field'
    :is-view-mode='isViewMode'
    @update-value='handleUpdate' />
</template>

<script>

import { mapActions }         from 'vuex'
import CustomFieldCheckbox    from '@/components/custom_fields/CustomFieldCheckbox.vue'
import CustomFieldMultiSelect from '@/components/custom_fields/CustomFieldMultiSelect.vue'
import CustomFieldDate        from '@/components/custom_fields/CustomFieldDate.vue'
import CustomFieldDateRange   from '@/components/custom_fields/CustomFieldDateRange.vue'
import CustomFieldDropdown    from '@/components/custom_fields/CustomFieldDropdown.vue'
import CustomFieldNumber      from '@/components/custom_fields/CustomFieldNumber.vue'
import CustomFieldParagraph   from '@/components/custom_fields/CustomFieldParagraph.vue'
import CustomFieldText        from '@/components/custom_fields/CustomFieldText.vue'
import CustomFieldFile        from '@/components/custom_fields/CustomFieldFile.vue'
import CustomFieldContact     from '@/components/custom_fields/CustomFieldContact.vue'
import CustomFieldUser        from '@/components/custom_fields/CustomFieldUser.vue'
import CustomFieldUrl         from '@/components/custom_fields/CustomFieldUrl.vue'
import CustomFieldTable       from '@/components/custom_fields/CustomFieldTable.vue'
import CustomFieldHeader      from '@/components/custom_fields/CustomFieldHeader.vue'
import CustomFieldFund        from '@/components/custom_fields/CustomFieldFund.vue'

export default {
  name: 'CustomFieldValue',
  props: {
    field: {
      type: Object,
    },
    isViewMode: {
      type: Boolean,
      default: true,
    },
    handleUpdateDefault: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    CustomFieldCheckbox,
    CustomFieldMultiSelect,
    CustomFieldDate,
    CustomFieldDateRange,
    CustomFieldDropdown,
    CustomFieldNumber,
    CustomFieldParagraph,
    CustomFieldText,
    CustomFieldFile,
    CustomFieldContact,
    CustomFieldUser,
    CustomFieldUrl,
    CustomFieldTable,
    CustomFieldHeader,
    CustomFieldFund,
  },
  computed: {
    inputType () {
      return `custom-field-${this.field.field_type}`
    },
  },
  methods: {
    ...mapActions('investments', [
      'editDrilldownFieldValue',
    ]),
    ...mapActions('contacts', [
      'updateContactField',
    ]),
    handleUpdate (field) {
      if (this.handleUpdateDefault) {
        if (field.template_type === 'case') {
          this.editDrilldownFieldValue(field)
        } else if (field.template_type === 'contact') {
          this.updateContactField(field)
        } else {
          this.$emit('update-value', field)
        }
      } else {
        this.$emit('update-value', field)
      }
    },
  }
}
</script>
