<template>
  <div class=''>
    <select v-model='val' class='form-select w-64' @blur='updateField'>
      <option v-for='selectable in tableSelectableValues' :key='`table-dropdown-selectableValue-${field.custom_field_name}-${selectable}`'>
        {{selectable}}
      </option>
    </select>
  </div>
</template>

<script>

export default {
  name: 'TableTypeCustomFieldDropdown',
  props: ['field', 'row', 'rowIndex', 'colKey'],
  data () {
    return {
      val: this.row[this.colKey],
    }
  },
  computed: {
    tableSelectableValues () {
      var data = this.field.table_selectable_values.find(value => value.name === this.colKey)
      return data ? data.values : []
    }
  },
  methods: {
    updateField () {
      this.$emit('update-value-json', this.rowIndex, this.colKey, this.val)
      this.$emit('blur')
    }
  },
}
</script>
