import base from './base'

const getCardHistories = (filters) => base
  .get('/card_histories/', { params: filters }).then(resp => resp.data)

const getMyCardHistories = (filters) => base
  .get('/card_histories/my_card_histories/', { params: filters }).then(resp => resp.data)

const uploadCardHistories = (file, contentType='multipart/form-data') => base
  .post('/card_histories/upload_card_histories/', file, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const updateCardHistory = (cardHistoryId, cardHistory, contentType='application/json') => base
  .patch(`/card_histories/${cardHistoryId}`, cardHistory, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

export default {
  getCardHistories: getCardHistories,
  getMyCardHistories: getMyCardHistories,
  uploadCardHistories: uploadCardHistories,
  updateCardHistory: updateCardHistory,
}
