<template>
  <tbody :class='editingExpenseTBodyStyle'>
    <tr :class='editingExpenseRow'>
      <td class='px-2 py-2 text-center'><input type='checkbox' v-model='selectExpense' /></td>
      <td class='px-2 py-2 text-left border-r whitespace-no-wrap'>{{ editingExpense.expense_date }}</td>
      <td class='px-2 py-2 text-right border-r whitespace-no-wrap'>{{ numberStyle(editingExpense.amount) }}</td>
      <td class='px-2 py-2 whitespace-no-wrap'>{{ editingExpense.expense_type }}</td>
      <td class='px-2 py-2 border-l border-r text-center'>{{ includesVatString(editingExpense.charge_vat) }}</td>
      <td class='px-2 py-2 border-l border-r text-center'>{{ includeBillingText(editingExpense.is_billable) }}</td>
      <td class='px-2 py-2'>{{ editingExpense.invoice_title }}</td>
      <td class='px-2 py-2'>{{ editingExpense.invoice_status }}</td>
      <td class='px-2 py-2'>{{ editingExpense.payment_type }}</td>
      <td class='px-2 py-2'>{{ editingExpense.description }}</td>
      <td class='px-2 py-2'>
        <a v-if='editingExpense.external_url' :href='editingExpense.external_url' target='_blank'><external-link-icon /></a>
      </td>
      <td class='px-2 py-2'>
        <div v-for='expenseAttachment in editingExpense.expense_attachments'
          :key='`editing-${editingExpense.id}-attachment-${expenseAttachment.id}`'>
          <a v-if='expenseAttachment.file_url'
            :href='fileURL(expenseAttachment.file_url)'
            @click.prevent='downloadFile(expenseAttachment.file_url, expenseAttachment.file_name)'
            download
            target='_blank'
            class='inline-block bg-gray-100 py-1 px-1 hover:underline hover:bg-transparent h-12 leading-10'>{{ expenseAttachment.file_name }}</a>
          <span v-else></span>
        </div>
      </td>
      <td class='px-2 py-2'>
        {{ editingExpense.updated_by_name }}
      </td>
      <td class='pl-2 py-1 whitespace-no-wrap'>
        <div class='flex flex-row justify-end'>
          <button
            @click='toggleEdit'
            class='py-1 px-3 border border-solid border-transparent hover:border-gray-600 hover:shadow opacity-75 hover:opacity-100 rounded flex flex-row justify-between items-center'>
            {{ editButtonLabel }}
          </button>
          <button
            @click='confirmDeleteExpenseEntry'
            class='py-1 px-3 border border-solid border-transparent hover:border-red-600 hover:shadow opacity-75 hover:opacity-100 text-red-800 rounded flex flex-row justify-between items-center'>
            {{ $t('delete') }}
          </button>
        </div>
      </td>
    </tr>
    <tr v-if='showEditExpense' class='rounded-b-lg'>
      <td colspan='12'
        class='border rounded-b-lg'>
        <expense-entry-edit
          :expense-entry='expense'
          @done-editing='closeEditExpenseEntry' />
      </td>
    </tr>
  </tbody>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import cloneDeep                from 'lodash/cloneDeep'
import StringHelpers            from '@/utils/string-helpers'
import ExpenseEntryEdit         from '@/components/expenses/ExpenseEntryEdit.vue'
import { ExternalLinkIcon } from '@vue-hero-icons/outline'
import axios from 'axios'

export default {
  name: 'CaseExpensesRow',
  components: {
    ExternalLinkIcon,
    ExpenseEntryEdit,
  },
  props: [
    'expense',
  ],
  data () {
    return {
      showEditExpense: false,
      selectExpense: false,
    }
  },
  watch: {
    selectExpense: {
      handler: function (newVal) {
        this.$emit('update-selected-expense', {
          expenseId: this.expense.id,
          status: newVal
        })
      },
    },
    'expense.selectExpense': {
      handler: function (newVal) {
        this.selectExpense = newVal
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('investments', [
      'drilldownInvestment',
    ]),
    editingExpense: {
      get: function () {
        return cloneDeep(this.expense)
      },
      set: (newValue) => {
        newValue
      }
    },
    title () {
      return `${this.expense.case_name} ${this.expense.expense_date} ${this.expense.amount}`
    },
    editingExpenseRow () {
      return (this.showEditExpense) ? 'bg-gray-100 border-l border-r font-semibold' : ''
    },
    editingExpenseTBodyStyle () {
      return (this.showEditExpense) ? 'shadow-lg rounded-lg' : ''
    },
    editButtonLabel () {
      return (this.showEditExpense) ? this.$t('close') : this.$t('edit')
    },
  },
  methods: {
    ...mapActions('expenses', [
      'deleteExpense',
    ]),
    toggleEdit () {
      this.showEditExpense = !this.showEditExpense
    },
    closeEditExpenseEntry () {
      this.showEditExpense = false
    },
    editExpenseEntry () {
      this.showEditExpense = true
    },
    confirmDeleteExpenseEntry () {
      this.$confirm(this.$t('deleteSpendingConfirm', [this.title]), this.$t('warning'), {
        confirmButtonText: this.$t('delete'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        this.deleteExpense(this.editingExpense.id).then(() => {
          this.$message({
            type: 'success',
            message: this.$t('deleteConfirmed')
          })
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('deleteCanceled')
        })
      })
    },
    includesVatString (includeVatBoolean) {
      return includeVatBoolean ? this.$t('included') : this.$t('notIncluded')
    },
    includeBillingText (isBillable) {
      return isBillable ? this.$t('billAmount') : this.$t('notBillable')
    },
    numberStyle (number) {
      return StringHelpers.koreanMoney(number)
    },
    fileURL (url) {
      return (url) || ''
    },
    downloadFile (url, fileName) {
      axios.get(url, { responseType: 'blob', headers: { Authorization: `Bearer ${localStorage.getItem('access_token.bbridge_reach')}`} })
        .then(response => {
          const blob = new Blob([response.data], { type: response.headers['content-type'] })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = fileName
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(() => alert(this.$t('noFileAlert')))
    },
  },
}
</script>
