import Vue             from 'vue'
import Vuex            from 'vuex'
import cardHistoryApi  from '@/api/v1/card_histories'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

const state = () => ({
  cardTitles: [
    '교통비',
    '교통비(야근)',
    '식대',
    '식대(야근)',
    '실비',
    'I-Point',
    '팀 비용',
    '업무비',
    '',
  ],
  cardHistories: [],
})

const getters = {
  getField,
}

const actions = {
  getCardHistories ({ commit, dispatch }, filters) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      cardHistoryApi.getCardHistories(filters).then(resp => {
        commit('setCardHistories', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      }).catch(() => {
        commit('setCardHistories')
      })
    })
  },
  getMyCardHistories ({ commit, dispatch }, filters) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      cardHistoryApi.getMyCardHistories(filters).then(resp => {
        commit('setCardHistories', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  uploadCardHistories (context, fileData) {
    return new Promise(resolve => {
      context.dispatch('activateLoading', null, { root : true })
      cardHistoryApi.uploadCardHistories(fileData).then(resp => {
        context.dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  updateCardHistory ({ state, commit, dispatch }, cardHistory) {
    return new Promise(resolve => {
      let selectedCard = state.cardHistories.find(card => card.id === cardHistory.id)
      dispatch('activateLoading', null, { root : true })
      cardHistoryApi.updateCardHistory(cardHistory.id, selectedCard).then(resp => {
        commit('updateCardHistories', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
}

const mutations = {
  setCardHistories (state, fromApi) {
    state.cardHistories = fromApi
  },
  updateCardHistories (state, updatedCardHistory) {
    const index = state.cardHistories.findIndex(cardHistory => cardHistory.id === updatedCardHistory.id)
    if (index >= 0) {
      state.cardHistories.splice(index, 1, updatedCardHistory)
    }
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
