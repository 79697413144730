import base from './base'

const getContacts = () => base
  .get(`/contacts/`).then(resp => resp.data)

const getContactsForInvestment = (investmentId) => base
  .get(`/contacts/get_mapping_investment_contacts/?investment_id=${investmentId}`).then(resp => resp.data)

const getContactsForLimitedPartner = (limitedPartnerId) => base
  .get(`/contacts/?limited_partner_id=${limitedPartnerId}`).then(resp => resp.data)

const getMyContact = () => base
  .get(`/contacts/check_my_contact/`).then(resp => resp.data)

const getConnectionByContact = (contactId) => base
  .get(`/contacts/get_connection_by_contact/?contact_id=${contactId}`).then(resp => resp.data)

const uploadContactsFile = (file, contentType='multipart/form-data') => base
  .post('/contacts/upload_contacts_file', file, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const searchContacts = (searchString) => base
  .get(`/contacts/search_by_text/?search_text=${searchString}`).then(resp => resp.data)

const postContact = (contactParams, contentType='application/json') => base
  .post('/contacts/', contactParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const updateContact = (contactId, contactParams) => base
  .patch(`/contacts/${contactId}`, contactParams).then(resp => resp.data)

const updateMyContact = (contactId, contactParams, contentType='application/json') => base
  .patch(`/contacts/update_my_contact/?id=${contactId}`, contactParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const deleteContact = (contactId) => base
  .delete(`/contacts/${contactId}`).then(resp => resp.data)

const postMappingInvestmentContact = (mappingInvestmentContactParams, contentType='application/json') => base
  .post('/mapping_investment_contacts/', mappingInvestmentContactParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const updateMappingInvestmentContact = (mappingInvestmentContactId, mappingInvestmentContactParams, contentType='application/json') => base
  .patch(`/mapping_investment_contacts/?id=${mappingInvestmentContactId}`, mappingInvestmentContactParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const deleteMappingInvestmentContact = (mappingInvestmentContactParams, contentType='application/json') => base
  .post('/mapping_investment_contacts/delete_by_investment_and_contact_id', mappingInvestmentContactParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

export default {
  deleteContact: deleteContact,
  getContacts: getContacts,
  getContactsForInvestment: getContactsForInvestment,
  getContactsForLimitedPartner: getContactsForLimitedPartner,
  getMyContact: getMyContact,
  getConnectionByContact: getConnectionByContact,
  uploadContactsFile: uploadContactsFile,
  postContact: postContact,
  searchContacts: searchContacts,
  updateContact: updateContact,
  updateMyContact: updateMyContact,
  postMappingInvestmentContact: postMappingInvestmentContact,
  updateMappingInvestmentContact: updateMappingInvestmentContact,
  deleteMappingInvestmentContact: deleteMappingInvestmentContact,
}
