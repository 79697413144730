import Vue          from 'vue'
import Vuex         from 'vuex'
import commissionsApi from '@/api/v1/commissions'
import cloneDeep    from 'lodash/cloneDeep'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

const newCommission = {
  id: 0,
  investment_id: 0,
  invoice_id: 0,
  user_id: 0,
  user_name: '',
  user_position: '',
  commission_pool_ratio: 0,
  invoice_total_pool_ratio: 0,
  amount: 0
}

const state = () => ({
  commissions: [],
  editingCommission: cloneDeep(newCommission),
})


const getters = {
  totalCommissionRatio: (state) => {
    return state.commissions.reduce((sum, commission) => {
      return sum + parseFloat(commission.invoice_total_pool_ratio)
    }, 0)
  },
  commissionPoolTotalRatio: (state) => {
    return state.commissions.reduce((sum, commission) => {
      return sum + parseFloat(commission.commission_pool_ratio)
    }, 0)
  },
  everyCommissionHasUser: (state) => {
    return state.commissions.every(commission => commission.user_id && commission.user_id > 0)
  },
  getField,
}


const actions = {
  getCommissions ({ commit, dispatch }, filters) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      commissionsApi.getCommissions(filters).then(resp => {
        commit('setCommissions', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  updateCommission ({ commit, dispatch }, commission) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      commissionsApi.updateCommission(commission.id, commission).then(resp => {
        commit('updateCommissions', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  updateOrCreateCommissions ({ dispatch, state }) {
    return Promise.all(
      state.commissions.map((commission) => {
        if (commission.id > 0) {
          return dispatch('updateCommission', commission)
        } else {
          return dispatch('createCommission', commission)
        }
      })
    )
  },
  deleteCommission ({ commit, dispatch }, commissionId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      commissionsApi.deleteCommission(commissionId).then(resp => {
        commit('removeCommissionFromList', resp.id)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  createCommission ({ commit, dispatch }, commission) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      commissionsApi.postCommission(commission).then(resp => {
        commit('createdCommissionUpdate', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  addNewCommission ({ commit, rootState }) {
    if (rootState.invoices.editingInvoice.id > 0) {
      let newRow = cloneDeep(newCommission)
      newRow.investment_id = rootState.invoices.editingInvoice.investment_id
      newRow.invoice_id = rootState.invoices.editingInvoice.id
      commit('addCommission', newRow)
    } else {
      console.error('there is no editing invoice')
    }
  },
  removeCommissionByIndex ({ commit }, index) {
    commit('removeCommissionByIndex', index)
  }
}


const mutations = {
  addCommission (state, commission) {
    state.commissions.push(commission)
  },
  createdCommissionUpdate (state, commission) {
    const index = state.commissions.findIndex(c => c.user_id === commission.user_id)
    if (index >= 0) {
      state.commissions.splice(index, 1, commission)
    } else {
      state.commissions.unshift(commission)
    }
  },
  removeCommissionFromList (state, commissionId) {
    const index = state.commissions.findIndex(commission => commission.id === commissionId)
    if (index >= 0) {
      state.commissions.splice(index, 1)
    }
  },
  removeCommissionByIndex (state, index) {
    if (index >= 0) {
      state.commissions.splice(index, 1)
    }
  },
  setCommissions (state, fromApi) {
    state.commissions = fromApi
  },
  updateCommissions (state, commission)  {
    const index = state.commissions.findIndex(c => c.id === commission.id)
    if (index >= 0) {
      state.commissions.splice(index, 1, commission)
    }
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
