import base from './base'

const getConnections = () => base
  .get(`/connections/`).then(resp => resp.data)

const postConnection = (connectionParams, contentType='application/json') => base
.post('/connections/', connectionParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const updateConnection = (connectionId, connectionParams, contentType='application/json') => base
.patch(`/connections/${connectionId}`, connectionParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

export default {
  getConnections: getConnections,
  postConnection: postConnection,
  updateConnection: updateConnection,
}
