<template>
  <div class='pl-8'>
    <h1 class='text-2xl font-semibold mb-4'>
      <div class='inline-block w-32'>To:</div>
      <div class='inline-block w-4/5 text-right'>{{contact.contact_name}} - {{contact.phone_number}}</div>
    </h1>
    <div v-if='canSms'>
      <h1 class='text-2xl font-semibold mb-4'>
        <div class='inline-block w-32'>From:</div>
        <div class='inline-block w-4/5 text-right'>{{currentUserInfo.userName}} - {{currentUserInfo.smsNumber}}</div>
      </h1>
      <div class='mb-4'>
        <textarea class='form-textarea w-full' v-model='content' rows='9'></textarea>
        <p>{{bytesize}} / {{$t('2000bytes')}}</p>
      </div>
      <div class='mb-8'>
        <button
          :disabled='!contentValid'
          class='inline-block mt-4 py-3 px-12 text-white text-sm rounded-md font-semibold border border-transparent hover:border-blue-500 hover:shadow-md'
          style='background-color: #223645;'
          @click='submitSms'>{{ $t('send') }}</button>
        <p v-if='contentTooBig' class='text-red-600'>{{ $t('less2000bytes') }}</p>
      </div>
      <div class='mb-4'>
        <label class='inline-block cursor-pointer'>
          <input type='checkbox'
            class='form-checkbox m-2'
            name='allSms'
            v-model='allSms'>
          <span>{{ $t('allSentHistory') }}</span>
        </label>
      </div>
    </div>

    <div>
      <h2 class='text-2xl font-semibold mb-2'>{{ $t('record') }}</h2>
      <div class='border-b'>
        <div class='inline-block w-1/12 mr-1 align-top font-semibold'>{{ $t('from') }}</div>
        <div class='inline-block w-1/12 mr-1 align-top font-semibold'>{{ $t('to') }}</div>
        <div class='inline-block w-1/2 mr-2 align-top whitespace-pre font-semibold'>{{ $t('content') }}</div>
        <div class='inline-block w-1/12 mr-1 align-top font-semibold'>{{ $t('day') }}</div>
        <div class='inline-block w-1/12 mr-1 align-top font-semibold'>{{ $t('time') }}</div>
        <div class='inline-block w-1/12 align-top font-semibold'>{{ $t('status') }}</div>
      </div>
      <div class='flex justify-center' v-if='loading'>
        <i class='el-icon-loading p-2 text-5xl' />
      </div>
      <div v-for='message in filteredMessages' :key='`message-${message.id}`' class='border-b'>
        <div class='inline-block w-1/12 mr-1 align-top'>{{ message.from_name }}</div>
        <div class='inline-block w-1/12 mr-1 align-top'>{{ message.to_name }}</div>
        <div class='inline-block w-1/2 mr-2 align-top whitespace-pre-line'>{{ message.content }}</div>
        <div class='inline-block w-1/12 align-top mr-1'>{{ dateFromString(message.created_at) }}</div>
        <div class='inline-block w-1/12 align-top mr-1'>{{ timeFromString(message.created_at) }}</div>
        <div class='inline-block w-1/12 align-top'>{{ message.status }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import api from '@/api/v1/sms_messages'

export default {
  name: 'ContactSms',
  props: ['contact'],
  data () {
    return {
      content: '',
      messages: [],
      allSms: false,
      loading: false,
    }
  },
  watch: {
    contact: {
      handler: function () {
        this.refreshMessages()
      },
      immediate: true,
    }
  },
  computed: {
    ...mapGetters('investments', [
      'drilldownInvestmentId'
    ]),
    ...mapState('users', [
      'currentUserInfo',
    ]),
    bytesize () {
      return new Blob([this.content]).size
    },
    canSms () {
      return this.currentUserInfo.smsNumber && this.currentUserInfo.smsNumber.length > 8
    },
    contentValid () {
      return !this.contentEmpty && !this.contentTooBig
    },
    contentEmpty () {
      return this.bytesize < 0
    },
    contentTooBig () {
      return this.bytesize > 2000
    },
    filteredMessages () {
      return this.allSms ? this.messages : this.messages.filter(message => message.created_by === this.currentUserInfo.userId)
    }
  },
  methods: {
    dateFromString (str) {
      return str.split(' ')[1]
    },
    timeFromString (str) {
      return str.split(' ')[0]
    },
    refreshMessages () {
      this.loading = true
      this.messages = []
      api.getSmsMessages({
        investment_id: this.drilldownInvestmentId
      }).then(resp => {
        this.messages = resp
        this.loading = false
      })
    },
    submitSms () {
      this.$confirm(this.$t('sentSmsConfirm', [this.contact.contact_name]), this.$t('warning'), {
        confirmButtonText: this.$t('send'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        api.postSmsMessage({
          contact_id: this.contact.id,
          investment_id: this.drilldownInvestmentId,
          content: this.content,
        }).then(resp => {
          this.messages.push(resp)
          this.$message({
            type: 'success',
            message: this.$t('sendSuccess')
          })
        }).catch((error) => {
          if (error.response) {
            this.$message({
              type: 'error',
              message: error.response.data.error
            })
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('sendCancel')
        })
      })
    },
  },
}
</script>
