import dayjs from 'dayjs'

class DateHelpers {
  static isDateAfterToday(today, workDate) {
    return DateHelpers.isValidDate(today) &&
           DateHelpers.isValidDate(workDate) &&
           dayjs(workDate).isAfter(dayjs(today))
  }
  static isMondayBefore12pm(date) {
    return DateHelpers.isValidDate(date) &&
           dayjs(date).day() === 1 && 
           dayjs(date).hour() < 12
           
  }
  static isMonday(date) {
    return DateHelpers.isValidDate(date) && dayjs(date).day() === 1
  }
  static isTuesday(date) {
    return DateHelpers.isValidDate(date) && dayjs(date).day() === 2
  }
  static isWednesday(date) {
    return DateHelpers.isValidDate(date) && dayjs(date).day() === 3
  }
  static isThursday(date) {
    return DateHelpers.isValidDate(date) && dayjs(date).day() === 4
  }
  static isFriday(date) {
    return DateHelpers.isValidDate(date) && dayjs(date).day() === 5
  }
  static isSaturday(date) {
    return DateHelpers.isValidDate(date) && dayjs(date).day() === 6
  }
  static isSunday(date) {
    return DateHelpers.isValidDate(date) && dayjs(date).day() === 0
  }
  static isPublicHoliday(today, weekdays) {
    const todayDate = dayjs(today).format('YYYY-MM-DD')
    const holidaysDates = DateHelpers.consecutivePublicHolidays(weekdays).map(holiday => holiday.market_date)
    return DateHelpers.isValidDate(today) && holidaysDates.includes(todayDate)
  }
  static isAfterPublicHolidayBefore12pm(today, weekdays) {
    const yesterdayDate = dayjs(today).subtract(1, 'day').format('YYYY-MM-DD')
    const holidaysDates = DateHelpers.consecutivePublicHolidays(weekdays).map(holiday => holiday.market_date)
    return holidaysDates.includes(yesterdayDate) &&
           dayjs(today).hour() < 12
  }
  static isAfterPublicHolidayAfter12pm(today, weekdays) {
    const yesterdayDate = dayjs(today).subtract(1, 'day').format('YYYY-MM-DD')
    const holidaysDates = DateHelpers.consecutivePublicHolidays(weekdays).map(holiday => holiday.market_date)
    return holidaysDates.includes(yesterdayDate) &&
           dayjs(today).hour() >= 12
  }
  static calendarOffsetDays(date, weekdays) {
    const holidays = DateHelpers.consecutivePublicHolidays(weekdays)
    const workDate =  dayjs(date)
    const offsetDays = holidays.find(holiday => workDate.isSame(dayjs(holiday.market_date), 'day'))
    
    return offsetDays ? parseInt(offsetDays.calendar_offset_days) : 0
  }
  static consecutivePublicHolidays(weekdays) {
    return weekdays.filter(holiday => 
           !holiday.is_open && 
           dayjs(holiday.market_date).day() !== 0 && 
           dayjs(holiday.market_date).day() !== 6 &&
           holiday.calendar_offset_days && 
           holiday.calendar_offset_days !== '')
  }
  static isValidDate(date) {
    if (date && dayjs(date).isValid()) {
      return true
    } else {
      console.error('Invalid date: ', date)
      return false
    }
  }
}

export default DateHelpers
