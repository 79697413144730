<template>
    <div class='leading-10 text-sm'>
      <span class='inline-block text-semibold mr-2 text-sm'>{{info.jurisdiction_type}}</span>
      <span v-if='info.id'
        class='inline-block text-gray-500 uppercase text-xs'>
        {{ $t('updatedAt', [info.last_updated_at]) }}
      </span>
      <span v-else
        class='inline-block text-gray-500 uppercase text-xs'>
        (new)
      </span>
    </div>
</template>

<script>
export default {
  name: 'UpdateInfo',
  props: [
    'info'
  ],
}
</script>

