<template>
  <div>
    <div class='mb-4 flex flex-row justify-start items-start'>
      <label class='inline-block w-32 flex-none leading-10'>{{ $t('jurisdictionCourt') }}</label>
      <v-select
       class='flex-grow'
       :value='caseInfo.jurisdiction_name'
        @input='updateCaseInfo("jurisdiction_name", $event)'
       :options='courtNameOptions'>
      </v-select>
    </div>
    <div class='mb-4 flex flex-row justify-start items-start'>
      <label class='inline-block w-32 flex-none leading-10'>{{ $t('courtCaseNumber') }}</label>
      <input class='form-input w-1/4'
        :class='errorClass'
        v-model='caseNumber'>
      <div v-if='!validEntry' class='ml-2 text-red-400'>{{errorMessage}}</div>
    </div>
    <div class='mb-4 flex flex-row justify-start items-start'>
      <label class='inline-block w-32 flex-none leading-10'>{{ $t('personInvolved') }}</label>
      <input class='form-input w-1/4'
        :value='caseInfo.client_name'
        @input='updateCaseInfo("client_name", $event.target.value)'>
    </div>
    <related-infos
      :title="$t('trialInfo')"
      :headers='["재판부", "재판장", "주심"]'
      :case-info='caseInfo'>
    </related-infos>
    <jail-infos
      :title="$t('jailInfo')"
      :headers='["구치소", "수용번호"]'
      :case-info='caseInfo' />
  </div>
</template>

<script>
import RelatedInfos from '@/components/case_infos/RelatedInfos.vue'
import JailInfos    from '@/components/case_infos/JailInfos.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'CourtInfo',
  props: [
    'caseInfo',
  ],
  components: {
    RelatedInfos,
    JailInfos,
  },
  data () {
    return {
      caseNumber: (this.caseInfo.year && this.caseInfo.gubun && this.caseInfo.serial_number) ? `${this.caseInfo.year}${this.caseInfo.gubun}${this.caseInfo.serial_number}` : '',
    }
  },
  watch: {
    caseNumber: {
      handler: function (newVal) {
        if (newVal) {
          let matchStr = newVal.match(/^(\d{4})([가-힣]+)(\d+)$/) // Array ["2021다나90835", "2021", "다나", "90835"]
          if (matchStr) {
            this.updateCaseInfo('year', matchStr[1]); // 년도
            this.updateCaseInfo('gubun', matchStr[2]); // 구분
            this.updateCaseInfo('serial_number', matchStr[3]); // 일련번호
            this.updateCaseInfo('jurisdiction_case_number', `${matchStr[1]}${matchStr[2]}${matchStr[3]}`);
          } else {
            // reset to get error
            this.updateCaseInfo('year', '');
            this.updateCaseInfo('gubun', '');
            this.updateCaseInfo('serial_number', '');
            this.updateCaseInfo('jurisdiction_case_number', '');
          }
        }
      },
    },
  },
  computed: {
    ...mapGetters('caseJurisdictions', [
      'jurisdictionByCaseType'
    ]),
    courtNameOptions () {
      return this.jurisdictionByCaseType('법원')
    },
    errorClass () {
      return this.validEntry ? '' : 'border-red-400'
    },
    errorMessage () {
      return this.validEntry ? '' : 'invalid'
    },
    validEntry () {
      return (/^(\d{4})$/).test(this.caseInfo.year) && (/^([가-힣]+)$/).test(this.caseInfo.gubun) && (/^(\d+)$/).test(this.caseInfo.serial_number)
    },
  },
  methods: {
    updateCaseInfo(key, value) {
      this.$emit('update-case-info', { key, value })
    }
  },
}
</script>
