<template>
  <div>
    <div v-if='isViewMode'>
      {{localFieldValue}}
    </div>
    <input
      v-else
      type='text'
      v-model='localFieldValue'
      @blur='handleUpdate'
      class='form-input w-full'/>
  </div>
</template>

<script>

export default {
  name: 'CustomFieldText',
  props: [ 'field', 'isViewMode' ],
  data () {
    return {
      localFieldValue: this.field.value
    }
  },
  methods: {
    handleUpdate () {
      this.$emit('update-value', {...this.field, value: this.localFieldValue})
    }
  },
}
</script>
