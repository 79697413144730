<template>
  <div class='uppercase tracking-wide cursor-pointer w-full overflow-x-auto'>
    <div class='flex'>
      <label v-for='option in options'
        :key='option.name'
        class='text-left p-3 text-gray-700 font-light whitespace-no-wrap flex flex-row justify-start items-center gap-x-1'>
         <input type='checkbox'
          class='form-checkbox'
          :name='option.name'
          :value='option.value'
          @change='changeOptions'
          v-model='checkedOptions'>
          {{ optionText(option.name) }}
      </label>
      <select v-model='filterTemplateId'
        v-if='filteredByCustomField'
        class='form-select w-50'>
        <option v-for='template in templatesForFiltering'
          :key='template.id'
          :value='template.id'>
          {{template.custom_field_name}}
        </option>
      </select>
    </div>
  </div>
</template>

<script>

import { mapGetters, mapState } from 'vuex'

export default {
  name: 'HistoryFilters',
  data () {
    return {
      filterTemplateId: 0,
      options: [
        {name: 'comments', value: 'comment'},
        {name: 'investment', value: 'investment'},
        {name: 'custom field', value: 'custom field'},
        {name: '청구서', value: 'invoice,deposit_to_invoices'},
        {name: '지출', value: 'expense'},
        {name: '기여도', value: 'commission,earning'},
        {name: '타임시트', value: 'timesheet_entry'},
      ],
      checkedOptions: ['comment'],
    }
  },
  watch: {
    filterParams: {
      handler: function (newVal) {
        this.changeFilterParams(newVal)
      }
    }
  },
  computed: {
    ...mapState('investments', [
      'drilldownInvestmentCustomFields',
    ]),
    ...mapGetters('investments', [
      'drilldownInvestmentId',
    ]),
    ...mapState('customFields', [
      'customFieldTemplates',
    ]),
    filteredByCustomField () {
      return this.checkedOptions.includes('custom field')
    },
    templatesForFiltering () {
      let templates = []
      if (this.drilldownInvestmentCustomFields.length > 0) {
        templates = this.drilldownInvestmentCustomFields.slice(0)
      } else {
        templates = this.customFieldTemplates.slice(0)
      }
      templates.unshift({id: 0, custom_field_name: 'show all'})
      return templates
    },
    filterParams () {
      return { filterText: this.checkedOptions,
               customFieldTemplateId: this.filterTemplateId,
               drilldownInvestmentId: this.drilldownInvestmentId }
    }
  },
  methods: {
    optionText (optionValue) {
      switch (optionValue) {
        case 'custom field':
          return 'data updates'
        case 'investment':
          return 'create case'
        default:
          return `${optionValue}`
      }
    },
    changeOptions () {
      this.$emit('change-options', this.checkedOptions)
    },
    changeFilterParams (filterParams) {
      this.$emit('change-filter-params', filterParams)
    },
  },
}
</script>
