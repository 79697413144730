<template>
  <div>
    <h2 class='text-lg font-semibold uppercase text-gray-800'>{{ $t('displayDates') }}</h2>
    <div class='flex flex-row justify-start'>
      <label class='block pt-2 w-40 text-gray-500'>
        {{ $t('scheduleTypes') }}
      </label>
      <ul>
        <li
          v-for='schedType in scheduleTypes'
          :key='`include-date-schedule-type-${schedType}`'
          class='py-2 text-gray-800'>
          <label>
            <input
              type='checkbox'
              :value='schedType'
              v-model='schedule_types'>
              {{schedType}}
            </label>
        </li>
      </ul>
    </div>
    <div class='mt-2 flex flex-row justify-start'>
      <label class='block pt-2 w-40 text-gray-500'>
        {{ $t('customFields') }}
      </label>
      <ul>
        <li
          v-for='dateField in dateFields'
          :key='`date-field-display-${dateField.id}`'
          class='py-2 text-gray-800'>
          <label>
            <input
              type='checkbox'
              :value='dateField.id'
              v-model='calendar_field_template_ids'>
              {{dateField.custom_field_name}}
            </label>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'DashboardViewEditingDisplayDates',
  computed: {
    ...mapState('schedules', [
      'scheduleTypes'
    ]),
    ...mapGetters('customFields', [
      'customFieldsByTypes'
    ]),
    ...mapFields('dashboardViews', [
      'currentDashboardView.calendar_field_template_ids',
      'currentDashboardView.schedule_types',
    ]),
    dateFields () {
      return this.customFieldsByTypes(['date', 'date-range'])
    }
  },
}
</script>
