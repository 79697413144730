import { render, staticRenderFns } from "./UserGroupDetails.vue?vue&type=template&id=7564544e&scoped=true"
import script from "./UserGroupDetails.vue?vue&type=script&lang=js"
export * from "./UserGroupDetails.vue?vue&type=script&lang=js"
import style0 from "./UserGroupDetails.vue?vue&type=style&index=0&id=7564544e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7564544e",
  null
  
)

export default component.exports