<template>
  <tr 
    class='invoice-row text-sm cursor-pointer hover:bg-gray-100' 
    @click='editInvoice'>
    <td class='pl-2 py-2'>{{ invoice.id }}</td>
    <td class='pl-2 py-2 whitespace-no-wrap'>{{ invoice.invoice_date }}</td>
    <td class='px-2 py-2'>{{ invoice.invoice_title }}</td>
    <td class='pl-2 py-2 whitespace-no-wrap'>{{ invoice.invoice_type }}</td>
    <td class='pl-2 py-2'>{{ invoice.payment_type }}</td>
    <td class='pl-2 py-2'>{{ invoice.account_number }}</td>
    <td class='pl-2 py-2 text-center'>
      <check-icon v-if='isCompletedPoolRatio' class='inline-block h-4' />
    </td>
    <td class='px-2 py-2 border-r text-right'>{{ amountString }}</td>
    <td class='pl-2 py-2 whitespace-no-wrap'>
      <div v-for='(paid_info, index) in invoice.paid_info'
        :key='`paid-date-${index}`'>
        {{ dateTimeFormat(paid_info.paid_date) }}
      </div>
      <div v-if='paidInfoExist'
        class='uppercase font-semibold'>
        {{ $t('sum') }}
      </div>
    </td>
    <td class='px-2 py-2 border-r text-right'>
      <div v-for='(paid_info, index) in invoice.paid_info'
        :key='`paid-amount-${index}`'>
        {{ numberStyle(paid_info.paid_amount) }}
      </div>
      <div class='uppercase font-semibold'>
        {{ numberStyle(totalPaidAmount) }}
      </div>
    </td>
    <td class='px-2 py-2'><a v-if='invoice.external_url' :href='invoice.external_url' target='_blank'><external-link-icon /></a></td>
    <td class='px-2 py-2'>{{ invoice.status }}</td>
    <td class='px-2 py-2'>{{ invoice.deposit_status }}</td>
    <td class='pl-2 py-2 text-right'>
      <div class='flex flex-row justify-center items-center'>
        <button class='uppercase rounded-md p-2 text-xs border border-transparent hover:bg-white text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 whitespace-no-wrap flex flex-row justify-center items-center gap-x-1'
          @click.stop='openPdfCreator(invoice.id, invoice.investment_id)'>
          <file-text-icon class='h-5' />
          PDF
        </button>
        <button class='uppercase rounded-md p-2 text-xs border border-transparent hover:bg-white text-gray-700 opacity-75 hover:opacity-100 hover:border-red-300 hover:text-red-500 whitespace-no-wrap flex flex-row justify-center items-center gap-x-1'
          @click.stop='confirmDeleteInvoice'>
          <trash-icon class='h-5' />
          {{ $t('delete') }}
        </button>
      </div>
    </td>
  </tr>
</template>

<script>
import { mapActions } from 'vuex'
import { FileTextIcon } from 'vue-feather-icons'
import { ExternalLinkIcon, TrashIcon, CheckIcon } from '@vue-hero-icons/outline'
import numbro from 'numbro'
import dayjs  from 'dayjs'

export default {
  name: 'CaseInvoiceRow',
  components: {
    ExternalLinkIcon,
    TrashIcon,
    CheckIcon,
    FileTextIcon,
  },
  props: ['invoice'],
  computed: {
    amountString () {
      return this.numberStyle(this.invoice.total_amount)
    },
    totalPaidAmount () {
      let sum = 0
      if (this.invoice.paid_info) {
        sum = this.invoice.paid_info.map(info => parseInt(info.paid_amount)).reduce((a,b) => (a+b))
      }
      return sum
    },
    paidInfoExist () {
      return this.totalPaidAmount > 0
    },
    isCompletedPoolRatio () {
      return this.invoice.pool_ratio === 100
    },
  },
  methods: {
    ...mapActions('invoices', [
      'deleteInvoice',
    ]),
    ...mapActions('magams', [
      'deleteInvoiceChangePerformances',
    ]),
    confirmDeleteInvoice () {
      this.$confirm(`${this.invoice.invoice_type} invoice를 삭제하시겠습니까?`, 'Warning', {
        confirmButtonText: this.$t('delete'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        this.deleteInvoice(this.invoice.id).then(() => {
          let params = {investment_id: this.invoice.investment_id, invoice_id: this.invoice.id}
          this.deleteInvoiceChangePerformances(params).then(() => {
            this.$message({
              type: 'success',
              message: this.$t('deleteConfirmed')
            })
          })
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('deleteCanceled')
        })
      })
    },
    editInvoice () {
      this.$emit('edit:invoice', this.invoice)
    },
    numberStyle (num) {
      return num ? numbro(num).format({ thousandSeparated: true, mantissa: 0 }) : ''
    },
    dateTimeFormat (paidDate) {
      return paidDate ? dayjs(paidDate).format('YYYY-MM-DD HH:mm:ss') : '-'
    },
    openPdfCreator(invoiceId, investementId) {
      let route = this.$router.resolve({
        name: 'InvoicePdfCreator',
        query: {
          investment_id: investementId,
          invoice_id:    invoiceId
        }
      })
      window.open(route.href, '_blank')
    },
  },
}
</script>

<style lang='scss' scoped>
  .invoice-row .el-date-editor.el-input, 
  .el-date-editor.el-input__inner {
    width: 100%;
  }
</style>
