<template>
  <tr class='border-t border-gray-200 text-sm'>
    <td class='pr-2 py-2'>
      <label class='flex flex-row items-center justify-start gap-x-1 whitespace-no-wrap'>
        <input type='checkbox'
        class='form-checkbox'
          :checked='updatingExpense.invoice_id === editingInvoice.id'
          @click='toggleExpense'>
        <span>{{ $t('performanceDeduction') }}</span>
      </label>
    </td>
    <td class='px-2 py-2 whitespace-no-wrap'>
      {{ updatingExpense.expense_date }}
    </td>
    <td class='px-2 py-2 w-40 text-right whitespace-no-wrap'>{{ numberStyle(updatingExpense.amount - taxAmount) }} {{ invoiceCurrency }}</td>
    <td class='px-2 py-2 w-40 text-right whitespace-no-wrap'>{{ numberStyle(taxAmount) }} {{ invoiceCurrency }}</td>
    <td class='px-2 py-2 w-40 text-right whitespace-no-wrap border-r'>{{ numberStyle(updatingExpense.amount) }} {{ invoiceCurrency }}</td>
    <td class='px-2 py-2'>{{ updatingExpense.user_names }}</td>
    <td
      class='px-2 py-2 border-l border-r'>
      <label v-if='hasInvoice' class='flex flex-row items-center justify-start gap-x-1 whitespace-no-wrap'>
        <input
          type='checkbox'
          class='form-checkbox'
          v-model='updatingExpense.charge_vat'
          @change='updateExpenseTax'>
        <span>{{ $t('vat') }}</span>
      </label>
    </td>
    <td v-if='useCalculatedTotal'
      class='px-2 py-2 text-left border-r'>
      <label v-if='hasInvoice' class='flex flex-row items-center justify-start gap-x-1 whitespace-no-wrap'>
        <input
          type='checkbox'
          class='form-checkbox'
          v-model='updatingExpense.is_billable'>
        <span>{{ $t('billAmount') }}</span>
      </label>
    </td>
    <td class='p-2 whitespace-no-wrap'>{{ updatingExpense.description }}</td>
    <td class='p-2'>{{ updatingExpense.payment_type }}</td>
    <td class='p-2'><a v-if='updatingExpense.external_url' :href='updatingExpense.external_url' target='_blank'><external-link-icon /></a></td>
    <td class='p-2'>
      <a :href='decodedURL(updatingExpense.file_url)'
        @click.prevent='downloadFile(updatingExpense.file_url, updatingExpense.file_name)'
        v-if='updatingExpense.file_url'
        download
        target='_blank'
        class='inline-block bg-gray-100 py-1 px-1 hover:underline hover:bg-transparent h-12 leading-10'>{{ updatingExpense.file_name }}</a>
    </td>
  </tr>
</template>

<script>
import axios from 'axios'
import { ExternalLinkIcon } from '@vue-hero-icons/outline'

export default {
  name: 'CaseInvoiceBillingDetailsExpenseRow',
  props: ['expense', 'useCalculatedTotal', 'editingInvoice'],
  data () {
    return {
      updatingExpense: this.expense
    }
  },
  components: {
    ExternalLinkIcon,
  },
  computed: {
    hasInvoice () {
      return this.updatingExpense.invoice_id === this.editingInvoice.id
    },
    invoiceCurrency () {
      return this.$t('krw') // need to get this from the case
    },
    taxAmount () {
      return this.updatingExpense.tax_amount
    },
  },
  methods: {
    toggleExpense () {
      if (this.updatingExpense.invoice_id === this.editingInvoice.id) {
        this.updatingExpense.invoice_id = 0
      } else {
        this.updatingExpense.invoice_id = this.editingInvoice.id
      }
    },
    numberStyle (number) {
      return Number(number).toLocaleString()
    },
    decodedURL (url) {
      return decodeURI(url) || ''
    },
    downloadFile (url, fileName) {
      axios.get(url, { responseType: 'blob', headers: { Authorization: `Bearer ${localStorage.getItem('access_token.bbridge_reach')}`} })
        .then(response => {
          const blob = new Blob([response.data], { type: response.headers['content-type'] })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = fileName
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(() => alert(this.$t('noFileAlert')))
    },
    updateExpenseTax () {
      if (this.updatingExpense.charge_vat) {
        this.updatingExpense.tax_amount = Math.floor(parseFloat(this.updatingExpense.amount) / 11)
      } else {
        this.updatingExpense.tax_amount = 0
      }
    },
  },
}
</script>
