import base from './base'

const getCommissions = (filters) => base
  .get('/commissions/', { params: filters }).then(resp => resp.data)

const postCommission = (commissionParams, contentType='application/json') => base
.post('/commissions/', commissionParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const updateCommission = (commissionId, commissionParams, contentType='application/json') => base
.patch(`/commissions/${commissionId}`, commissionParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const deleteCommission = (commissionId) => base
.delete(`/commissions/${commissionId}`).then(resp => resp.data)

export default {
  getCommissions: getCommissions,
  postCommission: postCommission,
  updateCommission: updateCommission,
  deleteCommission: deleteCommission,
}
