<template>
  <section class='flex flex-col justify-start gap-4'>
    <div v-if='calculateTotal'>
      <div class='text-left w-48 text-sm uppercase font-semibold text-gray-600 py-2'>{{ $t('expenseCurrency', [invoiceCurrency]) }}</div>
      <div class='text-2xl font-semibold'>{{ totalExpensesString }}</div>
    </div>
    <div v-if='hasExpenses'
      class='overflow-x-auto'>
      <table class='table-fixed min-w-full'>
        <thead>
          <tr class='text-sm uppercase font-semibold text-gray-600'>
            <th class='pr-2 py-2 w-32 whitespace-no-wrap'>
              <label class='flex flex-row items-center justify-start gap-x-1'>
                <input
                  type='checkbox'
                  class='form-checkbox'
                  :checked='allExpensesInvoice'
                  @click='toggleAllExpenses'>
                <span>{{ $t('performanceDeduction') }}</span>
              </label>
            </th>
            <th class='px-2 py-2 w-24'>{{ $t('date') }}</th>
            <th class='px-2 py-2 w-32 text-right'>{{ $t('totalDepositWoTaxAmount') }}</th>
            <th class='px-2 py-2 w-32 text-right'>{{ $t('vat') }}</th>
            <th class='px-2 py-2 w-32 text-right border-r'>{{ $t('financialAmount') }}</th>
            <th class='px-2 py-2 w-32 whitespace-no-wrap'>{{ $t('lawyer') }}</th>
            <th
             class='px-2 py-2 w-32 border-l border-r'>
              <label class='flex flex-row items-center justify-start gap-x-1 whitespace-no-wrap'>
                <input
                  type='checkbox'
                  class='form-checkbox'
                  v-model='allExpensesTax'>
                <span>{{ $t('vat') }}</span>
              </label>
            </th>
            <th  v-if='calculateTotal'
              class='px-2 py-2 w-24 text-left whitespace-no-wrap border-r'>
              <label class='flex flex-row items-center justify-start gap-x-1 whitespace-no-wrap'>
                <input
                  type='checkbox'
                  class='form-checkbox'
                  v-model='allExpensesBillable'>
                <span>{{ $t('billableYn') }}</span>
              </label>

            </th>
            <th class='p-2 whitespace-no-wrap'>{{ $t('content') }}</th>
            <th class='p-2 whitespace-no-wrap'>{{ $t('paymentType') }}</th>
            <th class='p-2 whitespace-no-wrap'>{{ $t('link') }}</th>
            <th class='p-2 whitespace-no-wrap'>{{ $t('attachedFile') }}</th>
          </tr>
        </thead>
        <tbody>
          <case-invoice-billing-details-expense-row
            v-for='expense in expenses'
            :key='`invoice-expense-${expense.id}`'
            :expense='expense'
            :use-calculated-total='calculateTotal'
            :editing-invoice='editingInvoice'>
          </case-invoice-billing-details-expense-row>
        </tbody>
      </table>
    </div>
    <div v-else
      class='text-sm text-center p-8 bg-gray-100'>
      {{ $t('noExpenseDetails') }}
    </div>
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapMultiRowFields } from 'vuex-map-fields'
import numbro        from 'numbro'
import axios from 'axios'
import CaseInvoiceBillingDetailsExpenseRow from '@/components/invoices/CaseInvoiceBillingDetailsExpenseRow.vue'

export default {
  name: 'CaseInvoiceBillingDetailsExpenses',
  components: {
    CaseInvoiceBillingDetailsExpenseRow
  },
  data () {
    return {
      allExpensesInvoice: false,
      allExpensesBillable: false,
      allExpensesTax: false,
    }
  },
  watch: {
    'editingInvoice.id': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.getExpenses({
            investment_id: this.editingInvoice.investment_id,
            invoice_id: newVal,
            include_no_invoice: true
          }).then(() => {
            this.$nextTick().then(() => {
              if (this.editingInvoice.toggleAllExpenses) {
                this.toggleAllExpenses()
              }
              this.setSelectedExpensesWithInvoice(newVal)
            })
          })
        }
      },
      immediate: true
    },
    allExpensesTax: {
      handler: function (newVal) {
        this.expenses.forEach(expense => {
          expense.charge_vat = newVal
          this.updateExpenseTax(expense)
        })
      },
    },
    allExpensesBillable: {
      handler: function (newVal) {
        this.expenses.forEach(expense => {
          expense.is_billable = newVal
        })
      },
    },
  },
  computed: {
    ...mapMultiRowFields('expenses', [
      'expenses',
    ]),
    ...mapState('expenses', [
      'selectedExpenseIds',
    ]),
    ...mapState('invoices', [
      'editingInvoice',
    ]),
    hasExpenses () {
      return this.expenses.length > 0
    },
    invoiceCurrency () {
      return this.$t('krw') // need to get this from the case
    },
    totalExpenses () {
      return this.expenses.reduce((sum, expense) => {
        if (expense.investment_id === this.editingInvoice.investment_id &&
            expense.invoice_id    === this.editingInvoice.id &&
            expense.is_billable) {
          return sum + parseFloat(expense.amount)
        } else {
          return sum
        }
      }, 0)
    },
    totalExpensesString () {
      return numbro(this.totalExpenses).format({ thousandSeparated: true, mantissa: 0 })
    },
    calculateTotal () {
      return this.editingInvoice.use_calculated_total
    },
  },
  methods: {
    ...mapActions('expenses', [
      'getExpenses',
      'resetSelectedExpenseIds',
    ]),
    setSelectedExpensesWithInvoice (editingInvoiceId) {
      this.expenses.forEach(expense => {
        if (this.selectedExpenseIds.includes(expense.id)) {
          expense.invoice_id = editingInvoiceId
        }
      })
    },
    toggleAllExpenses () {
      this.allExpensesInvoice = !this.allExpensesInvoice
      if (this.allExpensesInvoice) {
        this.expenses.forEach(expense => {
          expense.invoice_id = this.editingInvoice.id
        })
      } else {
        this.expenses.forEach(expense => {
          expense.invoice_id = 0
        })
      }
    },
    toggleExpense (expense) {
      if (expense.invoice_id === this.editingInvoice.id) {
        expense.invoice_id = 0
      } else {
        expense.invoice_id = this.editingInvoice.id
      }
    },
    numberStyle (number) {
      return Number(number).toLocaleString()
    },
    decodedURL (url) {
      return decodeURI(url) || ''
    },
    downloadFile (url, fileName) {
      axios.get(url, { responseType: 'blob', headers: { Authorization: `Bearer ${localStorage.getItem('access_token.bbridge_reach')}`} })
        .then(response => {
          const blob = new Blob([response.data], { type: response.headers['content-type'] })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = fileName
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(() => alert(this.$t('noFileAlert')))
    },
    updateExpenseTax (expense) {
      if (expense.charge_vat) {
        expense.tax_amount = Math.floor(parseFloat(expense.amount) / 11)
      } else {
        expense.tax_amount = 0
      }
    },
  },
  beforeDestroy () {
    delete(this.editingInvoice.toggleAllExpenses) // no need after closing
    this.resetSelectedExpenseIds()
  },
}
</script>
