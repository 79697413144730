<template>
  <div class='p-8 bg-gray-100 rounded-md'>
    <h1 class='text-xl font-semibold uppercase mb-10'>
      {{title}}
    </h1>
    <div class='mb-4'>
      <label class='inline-block w-40'>{{ $t('title') }}</label>
      <input type='text' class='form-input' v-model='title'>
    </div>
    <div class='mb-4'>
      <label class='inline-block w-40'>{{ $t('eventDate') }}</label>
      <el-date-picker
        v-model='event_on'
        format='yyyy-MM-dd'
        type='date' 
        :placeholder="$t('pickDay')">
      </el-date-picker>
    </div>
    <div class='mb-4 flex flex-row'>
      <label class='inline-block w-40'>{{ $t('internalRepresentative')}}</label>
      <v-select
        v-model='selectedUsers'
        label='name'
        :options='users'
        :close-on-select='false'
        multiple
        class='w-full'>
        <template v-slot:option='option'>
          {{ option.name }}
        </template>
      </v-select>
    </div>
    <div class='mb-4 flex flex-row'>
      <label class='inline-block w-40'>{{ $t('externalRepresentative')}}</label>
      <v-select
        v-model='selectedContacts'
        label='contact_name'
        :options='contacts'
        :close-on-select='false'
        multiple
        class='w-full'>
        <template v-slot:option='option'>
          {{ option.contact_name }}
        </template>
      </v-select>
    </div>
    <div class='mb-4'>
      <label class='inline-block mb-2 w-40'>{{ $t('description') }}</label>
      <textarea type='text' class='form-input w-full' rows='3' v-model='description'></textarea>
    </div>
    <div class='mt-8'>
      <button 
        class='border px-8 h-12 text-white rounded-md font-bold hover:shadow-md' 
        style='background-color:#223645;'
        @click='submitUpdate'>
        {{buttonActionText}}
      </button>
      <button class='ml-4 px-8 h-12 border rounded-md border-transparent hover:border-gray-500' @click='sidepanelClose'>{{ $t('cancel') }}</button>
      <button v-if='isEditing' @click='showDeleteConfirm' class='float-right p-3 text-red-700 text-sm border border-transparent hover:border-red-400 rounded-md'>{{ $t('delete') }}</button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mapFields }            from 'vuex-map-fields'
import dayjs from 'dayjs'

export default {
  name: 'CalendarEventEdit',
  computed: {
    ...mapState('investmentEvents', [
      'editingCalendarEvent',
    ]),
    ...mapFields('investmentEvents', [
      'editingCalendarEvent.id',
      'editingCalendarEvent.title',
      'editingCalendarEvent.event_on',
      'editingCalendarEvent.description',
      'editingCalendarEvent.internal_owner_ids',
      'editingCalendarEvent.external_contact_ids',
    ]),
    ...mapState('users', [
      'users'
    ]),
    ...mapState('contacts', [
      'contacts',
    ]),
    isExistingEvent () {
      return this.id > 0
    },
    buttonActionText () {
      return this.isExistingEvent ?  this.$t('saveChanges')  : this.$t('add')
    },
    isEditing () {
      return this.id > 0
    },
    selectedUsers: {
      get () {
        if (this.internal_owner_ids) {
          return this.users.filter(user => this.internal_owner_ids.includes(user.id))
        } else {
          return []
        }
      },
      set (users) {
        this.internal_owner_ids = users.map(user => user.id)
      }
    },
    selectedContacts: {
      get () {
        if (this.external_contact_ids) {
          return this.contacts.filter(contact => this.external_contact_ids.includes(contact.id))
        } else {
          return []
        }
      },
      set (contacts) {
        this.external_contact_ids = contacts.map(contact => contact.id)
      }
    }
  },
  methods: {
    ...mapActions([
      'sidepanelClose'
    ]),
    ...mapActions('investmentEvents', [
      'updateInvestmentEvent',
      'createInvestmentEvent',
      'deleteInvestmentEvent',
    ]),
    submitUpdate () {
      this.event_on = dayjs(this.event_on).format('YYYY-MM-DD')
      if (this.isExistingEvent) {
        this.updateInvestmentEvent(this.editingCalendarEvent)
      } else {
        this.createInvestmentEvent(this.editingCalendarEvent)
      }
      this.sidepanelClose()
    },
    showDeleteConfirm () {
      this.$confirm( this.$t('deleteEventConfirmation', [this.title]), this.$t('warning'), {
        confirmButtonText: this.$t('delete'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        this.deleteInvestmentEvent(this.id).then(() => {
          this.$message({
            type: 'success',
            message: this.$t('deleteConfirmed')
          })
          this.sidepanelClose()
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('deleteCanceled')
        })
      })
    },
  },
}
</script>
