import base from './base'

const getCaseJurisdictions = (filterParams) => base
  .get('/case_jurisdictions', { params: filterParams }).then(resp => resp.data)

const getCourtInfos = (caseID) => base
  .get(`/court_infos?investment_id=${caseID}`).then(resp => resp.data)

const getCaseInfos = (caseID) => base
  .get(`/case_infos?investment_id=${caseID}`).then(resp => resp.data)

const postCaseInfos = (caseID, data) => base
  .post(`/case_infos?investment_id=${caseID}`, {case_infos: data}).then(resp => resp.data)

const deleteCaseInfos = (caseID, caseInfoID) => base
  .delete(`/case_infos/${caseInfoID}?investment_id=${caseID}`).then(resp => resp.data)

export default {
  getCaseJurisdictions: getCaseJurisdictions,
  getCourtInfos: getCourtInfos,
  getCaseInfos: getCaseInfos,
  postCaseInfos: postCaseInfos,
  deleteCaseInfos: deleteCaseInfos,
}
