import base from './base'

const getInvoices = (filters) => base
  .get('/invoices/', { params: filters }).then(resp => resp.data)

const getInvoice = (invoiceId) => base
  .get(`/invoices/${invoiceId}`).then(resp => resp.data)

const getInvoiceAttachments = (filters) => base
  .get('/invoice_attachments/', { params: filters }).then(resp => resp.data)

const postInvoice = (invoiceParams, contentType='application/json') => base
  .post('/invoices/', invoiceParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const postInvoiceAttachment = (attachmentParams, contentType='multipart/form-data') => base
  .post('/invoice_attachments/', attachmentParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const deleteInvoiceAttachment = (invoiceAttachmentId) => base
  .delete(`/invoice_attachments/${invoiceAttachmentId}`).then(resp => resp.data)

const updateInvoice = (invoiceId, invoiceParams, contentType='application/json') => base
  .patch(`/invoices/${invoiceId}`, invoiceParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const deleteInvoice = (invoiceId) => base
  .delete(`/invoices/${invoiceId}`).then(resp => resp.data)

const searchInvoicesByCase = (investmentId) => base
  .get('/invoices/invoices_by_case/', { params: {investment_id: investmentId} }).then(resp => resp.data)

  const getInvoicesByCompany = (companyId) => base
  .get('/invoices/invoices_by_company/', { params: {company_id: companyId} }).then(resp => resp.data)


export default {
  getInvoices: getInvoices,
  getInvoice: getInvoice,
  getInvoiceAttachments: getInvoiceAttachments,
  deleteInvoiceAttachment: deleteInvoiceAttachment,
  postInvoice: postInvoice,
  postInvoiceAttachment: postInvoiceAttachment,
  updateInvoice: updateInvoice,
  deleteInvoice: deleteInvoice,
  searchInvoicesByCase: searchInvoicesByCase,
  getInvoicesByCompany: getInvoicesByCompany,
}
