<template>
  <div class=''>
    <div v-if='isViewMode'>
      {{dateString}}
    </div>
    <div v-else>
      <el-date-picker
        v-model='localFieldValue'
        @blur='handleUpdate'
        @change='handleUpdate'
        type='date'
        value-format='yyyy-MM-dd'
        :placeholder="$t('pickDay')">
      </el-date-picker> 
      <button 
        v-if='showSetToday' 
        class='ml-2 text-sm font-bold' 
        @click='setPickerDate'>
        <calendar-icon class='mb-1 mr-1 inline-block'/> {{ $t('useTodaysDate') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { CalendarIcon } from '@vue-hero-icons/outline'
import dayjs            from 'dayjs'

export default {
  name: 'CustomFieldDate',
  props: ['field', 'isViewMode'],
  components: {
    CalendarIcon,
  },
  data () {
    return {
      calendarEvent: {},
      showAddCalendarEvent: false,
      localFieldValue: this.field.value
    }
  },
  computed: {
    ...mapGetters('investments', [
      'drilldownInvestmentName'
    ]),
    dateString () {
      return (this.isValidDate) ? dayjs(this.localFieldValue).format('YYYY년 MM월 DD일') : ''
    },
    showSetToday () {
      return this.localFieldValue !== this.getTodayDate()
    },
    isValidDate () {
      return dayjs(this.localFieldValue).isValid()
    },
  },
  methods: {
    ...mapActions('investmentEvents', [
      'createInvestmentEvent',
    ]),
    handleUpdate() {
      this.$emit('update-value', {...this.field, value: this.localFieldValue});
    },
    getTodayDate() {
      return dayjs().format('YYYY-MM-DD')
    },
    setPickerDate() {
      this.localFieldValue = this.getTodayDate()
      this.handleUpdate()
    }
  },
}
</script>
