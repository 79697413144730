<template>
  <div class='px-2 lg:px-8'>
    <div>
      <h1 class='text-lg lg:text-2xl font-semibold my-6'>{{ $t('userDetails') }}</h1>
    </div>
    <div class='my-6'>
      <div class='my-6 flex flex-row justify-start items-stretch gap-x-2'>
        <label class='flex-shrink-0 block w-32 p-2 leading-8 text-sm uppercase text-gray-600 font-semibold bg-gray-100'>{{ $t('email') }}</label>
        <label class='block w-32 p-2 leading-8 text-base'> {{drilldownUser.email}} </label>
      </div>
      <div class='my-6 flex flex-row justify-start items-stretch gap-x-2'>
        <label class='flex-shrink-0 block w-32 p-2 leading-8 text-sm uppercase text-gray-600 font-semibold bg-gray-100' style='line-height: 2rem;'>{{ $t('userName') }}</label>
        <input type='text' class='form-input w-full' v-model='name' />
      </div>
      <div class='my-6 flex flex-row justify-start items-stretch gap-x-2'>
        <label class='flex-shrink-0 block w-32 p-2 leading-8 text-sm uppercase text-gray-600 font-semibold bg-gray-100'>{{ $t('smsNo') }}</label>
        <input type='text' class='form-input w-full' v-model='sms_number' @input='filterInvalidChars' />
      </div>
      <div class='my-6 flex flex-row justify-start items-stretch gap-x-2'>
        <label class='flex-shrink-0 block w-32 p-2 leading-8 text-sm uppercase text-gray-600 font-semibold bg-gray-100'>{{ $t('roles') }}</label>
        <v-select v-model='roles' multiple :options='roleOptions' class='w-full'></v-select>
      </div>
      <div class='my-6 flex flex-row justify-start items-stretch gap-x-2'>
        <label class='flex-shrink-0 block w-32 p-2 leading-8 text-sm uppercase text-gray-600 font-semibold bg-gray-100'>{{layerLabel}}</label>
        <select v-model='layer'
                class='form-select'>
          <option value=""></option>
          <option v-for='layer in filteredLayer'
                  :key='layer.id'
                  :value='layer.field_name'>
            {{layer.field_name}}
          </option>
        </select>
      </div>
      <div class='my-6 flex flex-row justify-start items-stretch gap-x-2'>
        <label class='flex-shrink-0 block w-32 p-2 leading-8 text-sm uppercase text-gray-600 font-semibold bg-gray-100'>{{departmentLabel}}</label>
        <select v-model='department'
                class='form-select'>
          <option value=""></option>
          <option v-for='department in filteredDepartment'
                  :key='department.id'
                  :value='department.field_name'>
            {{department.field_name}}
          </option>
        </select>
      </div>
      <div class='my-6 flex flex-row justify-start items-stretch gap-x-2'>
        <label class='flex-shrink-0 block w-32 p-2 leading-8 text-sm uppercase text-gray-600 font-semibold bg-gray-100'>{{teamLabel}}</label>
        <select v-model='team'
                class='form-select'>
          <option value=""></option>
          <option v-for='team in filteredTeam'
                  :key='team.id'
                  :value='team.field_name'>
            {{team.field_name}}
          </option>
        </select>
      </div>
      <div class='my-6 flex flex-row justify-start items-stretch gap-x-2'>
        <label class='flex-shrink-0 block w-32 p-2 leading-8 text-sm uppercase text-gray-600 font-semibold bg-gray-100'>{{titleLabel}}</label>
        <select v-model='title'
                class='form-select'>
          <option value=""></option>
          <option v-for='title in filteredTitle'
                  :key='title.id'
                  :value='title.field_name'>
            {{title.field_name}}
          </option>
        </select>
      </div>
      <div class='my-6 flex flex-row justify-start items-stretch gap-x-2'>
        <label class='flex-shrink-0 block w-32 p-2 leading-8 text-sm uppercase text-gray-600 font-semibold bg-gray-100'>{{positionLabel}}</label>
        <select v-model='position'
                class='form-select'>
          <option value=""></option>
          <option v-for='position in filteredPositions'
                  :key='position.id'
                  :value='position.field_name'>
            {{position.field_name}}
          </option>
        </select>
      </div>
      <div class='my-6 flex flex-row justify-start items-stretch gap-x-2'>
        <label class='flex-shrink-0 block w-32 p-2 leading-8 text-sm uppercase text-gray-600 font-semibold bg-gray-100'>{{ $t('lawyerLicense') }}</label>
        <input type='text' class='form-input w-full' v-model='lawyer_licence' />
      </div>
      <div class='my-6 flex flex-row justify-start items-stretch gap-x-2'>
        <label class='flex-shrink-0 block w-32 p-2 leading-8 text-sm uppercase text-gray-600 font-semibold bg-gray-100'>{{ $t('qualificationDate') }}</label>
        <input type='number' class='form-input w-40' v-model='qualification_date' />
      </div>
      <div class='my-6 flex flex-row justify-start items-stretch gap-x-2'>
        <label class='flex-shrink-0 block w-32 p-2 leading-8 text-sm uppercase text-gray-600 font-semibold bg-gray-100'>{{ $t('careerReduction') }}</label>
        <vue-numeric
          v-model='career_reduction'
          output-type='number'
          class='form-input w-40 text-right'
          :minus='false'
          separator=','>
        </vue-numeric>
      </div>
      <div class='my-6 flex flex-row justify-start items-stretch gap-x-2'>
        <label class='flex-shrink-0 block w-32 p-2 leading-8 text-sm uppercase text-gray-600 font-semibold bg-gray-100'>{{ $t('compensationRate') }}</label>
        <vue-numeric
          v-model='compensation_rate'
          output-type='number'
          class='form-input w-40 text-right'
          :minus='false'
          separator=','>
        </vue-numeric>
      </div>
      <div class='my-6 flex flex-row justify-start items-stretch gap-x-2'>
        <label class='flex-shrink-0 block w-32 p-2 leading-8 text-sm uppercase text-gray-600 font-semibold bg-gray-100'>{{ $t('timesheetHourlyRate') }}</label>
        <vue-numeric
          v-model='timesheet_hourly_rate'
          output-type='number'
          class='form-input w-40 text-right'
          :minus='false'
          separator=','>
        </vue-numeric>
      </div>
      <div class='my-6 flex flex-row justify-start items-stretch gap-x-2'>
        <label class='flex-shrink-0 block w-32 p-2 leading-8 text-sm uppercase text-gray-600 font-semibold bg-gray-100'>{{ $t('entryDate') }}</label>
        <el-date-picker
          v-model='entry_date'
          format='yyyy-MM-dd'
          type='date'
          :placeholder="$t('pickDay')">
        </el-date-picker>
      </div>
      <div class='my-6 flex flex-row justify-start items-stretch gap-x-2'>
        <label class='flex-shrink-0 block w-32 p-2 leading-8 text-sm uppercase text-gray-600 font-semibold bg-gray-100'>{{ $t('resignationDate') }}</label>
        <el-date-picker
          v-model='resignation_date'
          format='yyyy-MM-dd'
          type='date'
          :placeholder="$t('pickDay')">
        </el-date-picker>
      </div>
      <div class='my-6 flex flex-row justify-start items-stretch gap-x-2'>
        <label class='flex-shrink-0 block w-32 p-2 leading-8 text-sm uppercase text-gray-600 font-semibold bg-gray-100'>{{ $t('salarySystem') }}</label>
        <select
          v-model='salary_system'
          class='p-2 border rounded-md'>
          <option value=''></option>
          <option value='고정급제'>{{ $t('fixedPaySystem') }}</option>
          <option value='별산제'>{{ $t('proportionalSystem') }}</option>
        </select>
      </div>
      <div class='my-6 flex flex-row justify-start items-stretch gap-x-2'>
        <label class='flex-shrink-0 block w-32 p-2 leading-8 text-sm uppercase text-gray-600 font-semibold bg-gray-100'>{{ $t('notesForUserDetails') }}</label>
        <textarea type='text' class='form-input w-full' rows='3' v-model='note' />
        <!-- <textarea type='text' class='form-input w-full' rows='3' v-model='description'></textarea> -->
      </div>

      <div v-if='isAdmin'
        class='text-left p-1'>
        <button v-if='drilldownUser.has_resigned === false'
          class='hover:bg-red-200 border rounded-md py-2 px-8 text-xs hover:border-red-400'
          @click='showConfirmResign'>
          {{ $t('resigned') }}
        </button>
        <button v-if='drilldownUser.has_resigned === true'
          class='hover:bg-red-200 border rounded-md py-2 px-8 text-xs hover:border-red-400'
          @click='showConfirmReinstate'>
          복직
        </button>
      </div>
    </div>
    <portal to='sidepanel-footer'>
      <div class='bg-gray-100 py-4 px-4'>
        <button
          class='mr-4 block py-3 px-16 text-white rounded-md font-bold border border-transparent hover:border-blue-500 hover:shadow-md'
          style='background-color: #223645;'
          @click='submitUpdate'>
          {{ $t('save') }}
        </button>
      </div>
    </portal>
    
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { mapFields } from 'vuex-map-fields'
export default {
  name: 'UserDetails',
  data () {
    return {
      showConfirmDelete: false,
      selectableSalarySystem: [
        '',
        '고정급제',
        '별산제'
      ]
    }
  },
  computed: {
    ...mapFields('users', {
      drilldownUser: 'drilldownUser',
      name: 'drilldownUser.name',
      sms_number: 'drilldownUser.sms_number',
      roles: 'drilldownUser.roles',
      department: 'drilldownUser.organogram.department',
      team: 'drilldownUser.organogram.team',
      title: 'drilldownUser.organogram.title',
      position: 'drilldownUser.organogram.position',
      layer: 'drilldownUser.organogram.layer',
      lawyer_licence: 'drilldownUser.lawyer_licence',
      qualification_date: 'drilldownUser.qualification_date',
      career_reduction: 'drilldownUser.career_reduction',
      entry_date: 'drilldownUser.entry_date',
      resignation_date: 'drilldownUser.resignation_date',
      salary_system: 'drilldownUser.salary_system',
      note: 'drilldownUser.note',
      compensation_rate: 'drilldownUser.compensation_rate',
      timesheet_hourly_rate: 'drilldownUser.timesheet_hourly_rate',
    }),
    ...mapState('codeLists', [
      'codeLists',
    ]),
    ...mapGetters('users', [
      'isAdmin',
    ]),
    roleOptions () {
      return ['lawyer', 'secretary', 'billing']
    },
    departments () {
      return this.codeLists.department ? this.codeLists.department : []
    },
    teams () {
      return this.codeLists.team ? this.codeLists.team : []
    },
    titles () {
      return this.codeLists.title ? this.codeLists.title : []
    },
    positions () {
      return this.codeLists.position ? this.codeLists.position : []
    },
    layers () {
      return this.codeLists.layer ? this.codeLists.layer : []
    },
    departmentLabel () {
      let found = this.departments.find(ret => ret.is_header)
      return found ? found.field_name : this.$t('department')
    },
    teamLabel () {
      let found = this.teams.find(ret => ret.is_header)
      return found ? found.field_name : this.$t('team')
    },
    titleLabel () {
      let found = this.titles.find(ret => ret.is_header)
      return found ? found.field_name : this.$t('jobPosition')
    },
    positionLabel () {
      let found = this.positions.find(ret => ret.is_header)
      return found ? found.field_name : this.$t('position')
    },
    layerLabel () {
      let found = this.layers.find(ret => ret.is_header)
      return found ? found.field_name : this.$t('inquiryAuthority')
    },
    filteredDepartment () {
      return this.departments.filter(ret => ret.is_header === false)
    },
    filteredTeam () {
      return this.teams.filter(ret => ret.is_header === false)
    },
    filteredTitle () {
      return this.titles.filter(ret => ret.is_header === false)
    },
    filteredPositions () {
      return this.positions.filter(ret => ret.is_header === false)
    },
    filteredLayer () {
      return this.layers.filter(ret => ret.is_header === false)
    },
  },
  methods: {
    ...mapActions('users', [
      'getUsers',
      'updateUser',
      'deleteUser',
      'reinstateUser',
      'resetDrilldownUser',
    ]),
    ...mapActions([
      'sidepanelClose',
    ]),
    filterInvalidChars () {
      this.sms_number = this.sms_number.replace(/\D/g, '')
    },
    submitUpdate () {
      this.updateUser(this.drilldownUser)
    },
    showConfirmResign () {
      this.$confirm(this.$t('resignUser', [this.drilldownUser.name]), this.$t('warning'), {
        confirmButtonText: this.$t('resignConfirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        this.deleteUser(this.drilldownUser.id).then(() => {
          this.$message({
            type: 'success',
            message: this.$t('resignationComplete')
          })
          this.sidepanelClose()
          this.getUsers()
        })
      }).catch(() => {
        // do nothing
      })
    },
    showConfirmReinstate () {
      this.$confirm(this.$t('reinstateUser', [this.drilldownUser.name]), this.$t('warning'), {
        confirmButtonText: this.$t('reinstateConfirm'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        this.reinstateUser(this.drilldownUser.id).then(() => {
          this.$message({
            type: 'success',
            message: this.$t('reinstateComplete')
          })
          this.sidepanelClose()
          this.getUsers()
        })
      }).catch(() => {
        // do nothing
      })
    },
  },
  beforeDestroy() {
    this.resetDrilldownUser()
  },
}
</script>
