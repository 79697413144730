<template>
  <div>
    <select class='form-select'
            @change='updateUserRoleMetadata'
            v-model='userRole'>
      <option v-for='role in roles'
              :value='role.role'
              :key='`role-${role.name}`'>
        {{ role.name }}
      </option>
    </select>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CustomFieldTemplateMetadata',
  props: [ 'metadata' ],
  data () {
    return {
      userRole: '',
    }
  },
  watch: {
    'metadata.role': {
      handler: function (newVal) {
        if (newVal) {
          this.userRole = newVal
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState('users', [
      'roles',
    ]),
  },
  methods: {
    updateUserRoleMetadata () {
      this.$emit('update-metadata-role', this.userRole)
    },
  },
}
</script>
