<template>
  <div 
    class='px-2 lg:px-8' 
    slot='content'>
    <div class='mt-8 pb-8'>
      <h2 class='uppercase text-gray-800 font-semibold text-lg lg:text-xl'>{{userName}} - {{caseType}}</h2>
    </div>
    <table class='table-fixed text-sm'>
      <thead>
        <tr>
          <th class='border-b pb-1 px-4 text-right'>{{ $t('queueNo') }}</th>
          <th class='border-b pb-1 px-4 text-left'>{{ $t('caseNo') }}</th>
          <th class='border-b pb-1 px-4 text-left'>{{ $t('caseName') }}</th>
          <th class='border-b pb-1 px-4 text-left'>{{ $t('clientName') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for='(summary, index) in drilldownCases'
            :key='summary.user_id'>
          <td class='border-b py-2 px-4 text-right'>{{index + 1}}</td>
          <td class='border-b py-2 px-4 text-left'>{{summary.casenum}}</td>
          <td class='border-b py-2 px-4 text-left'>{{summary.investment_name}}</td>
          <td class='border-b py-2 px-4 text-left'>{{summary.entity_name}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Cases',
  computed: {
    ...mapState('lawyerPerformanceReports', [
      'drilldownCasesInfo'
    ]),
    count () {
      return this.drilldownCases.length
    },
    drilldownCases () {
      return this.drilldownCasesInfo.cases
    },
    userName () {
      return this.drilldownCasesInfo.userName
    },
    caseType () {
      if (this.drilldownCasesInfo.caseType === 'new') {
        return this.$t('newCase')
      } else if (this.drilldownCasesInfo.caseType === 'closed') {
        return this.$t('closedCase')
      } else {
        return this.drilldownCasesInfo.caseType
      }
    },
  },
}
</script>
