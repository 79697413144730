<template>
  <div
    class='relative h-10 border border-transparent hover:border-gray-200'
    :class='searchHeight'>
    <search-icon
      class='absolute standard-transition text-gray-500 cursor-pointer hover:text-blue-800'
      :size='iconSize'
      style='top:8px; left:8px;'
      @click.stop='clickSearchIcon' />
    <select v-if='isSearching'
      v-model='searchFor'
      class='absolute border-r h-10 w-16 lg:w-24 px-1 lg:px-2 text-gray-700 text-xs lg:text-sm'
      style='top:1px; left:2.2rem; height:calc(2.5rem - 2px);'>
      <option 
        v-for='searchType in searchForTypes' 
        :key='`search-all-${searchType}`' 
        :value='searchType'>
        {{ searchType }}
      </option>
    </select>
    <input
      v-model='searchText'
      ref='searchBox'
      type='text'
      :placeholder='placeholder'
      :disabled='showAdvancedSearch'
      class='search-input standard-transition border h-10 focus:border-blue-400 focus:shadow'
      :class='searchInputClass'
      v-on:keyup.enter='searchAll(searchText.trim())'>
    <button 
      v-if='isSearching' 
      class='px-2 h-10 text-xs hover:bg-gray-200 absolute top-0 right-0 z-10 bg-white border w-16'
      :class='buttonClass'
      @click.stop='clearSearchText()'>
      {{ $t('cancelSearch') }}
    </button>
    <advanced-search 
      v-if='showAdvancedSearch'
      @cancel-search='clearSearchText()'/>
  </div>

</template>

<script>
import { mapState, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { SearchIcon } from '@vue-hero-icons/outline'
import EventBus       from '@/utils/event-bus'
import AdvancedSearch from '@/components/search/AdvancedSearch.vue'

export default {
  name: 'SearchAll',
  components: {
    SearchIcon,
    AdvancedSearch,
  },
  data () {
    return {
      searchForTypes: [
        'ID',
        '변호사',
        '사건번호',
        '사건명',
        '의뢰인',
        '상대방',
        'ALL',
        '상세검색',
      ],
    }
  },
  watch: {
    searchFor: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal && newVal !== '상세검색') {
          let trimmed = this.searchText.trim()
          if (trimmed !== '') {
            this.searchAll(trimmed)
          }
        } else if (newVal == '상세검색') {
          this.showAdvancedSearch = true
          this.searchText = ''
        }
      },
      immediate: false
    },
    searchInvestmentsInProgress: {
      handler: function () {
        this.setSearchResultsPageIndex(1)
        if (this.searchText.trim() !== '') {
          this.searchAll(this.searchText.trim())
        }
      },
      immediate: false
    },
    isSearching: {
      handler: function (newVal) {
        if (!newVal) {
          this.showAdvancedSearch = false
        } else if (this.searchFor == '상세검색') {
          this.showAdvancedSearch = true
        }
      },
    }
  },
  computed: {
    ...mapState([
      'isSearching',
    ]),
    ...mapFields([
      'showAdvancedSearch',
    ]),
    ...mapState('investments', [
      'searchInvestmentsInProgress',
    ]),
    ...mapFields('investments', [
      'searchText',
      'searchFor',
    ]),
    placeholder () {
      return this.showAdvancedSearch ? 'Combine Search Parameters Below' : this.$t('enterAfterSearch')
    },
    searchHeight () {
      return this.showAdvancedSearch ? 'h-auto rounded-t-full' : 'h-10 rounded-full'
    },
    searchInputClass () {
      let classes = ''
      classes += this.showAdvancedSearch ? 'rounded-t-3xl' : 'rounded-full'
      classes += this.isSearching ? ' w-full pl-10' : ' w-10 px-0'
      return classes
    },
    buttonClass () {
      return this.showAdvancedSearch ? 'hidden' : 'rounded-r-full'
    },
    iconSize() {
      return this.showAdvancedSearch ? '0' : '24'
    }
  },
  methods: {
    ...mapActions([
      'searchClose',
      'searchOpen',
      'sidepanelClose',
    ]),
    ...mapActions('investments', [
      'resetInvestmentSearchResults',
      'investmentSearch',
      'setSearchResultsPageIndex',
    ]),
    searchAll (searchString) {
      this.investmentSearch()
      this.$gtag.event('search_for_cases', {
        search_text: searchString, 
        search_type: this.searchFor
      })
    },
    clearSearchText () {
      this.searchText = ''
      this.searchClose()
      this.resetInvestmentSearchResults()
    },
    clickSearchIcon () {
      this.searchOpen()
      this.sidepanelClose()

      this.$nextTick(() => {
        this.focusTextbox()
        this.searchText = this.searchText.trim()
      })
    },
    focusTextbox () {
      this.$refs.searchBox.focus()
    },
  },
  mounted () {
    EventBus.$on('close-search', () => this.clearSearchText())
  },
  beforeDestroy () {
    this.showAdvancedSearch = false
    EventBus.$off('close-search')
  }
}
</script>

<style lang='scss' scoped>
input:focus {
  outline-width: 0;
}

.search-input {
  text-indent: 3.5rem;
}

@media (min-width: 800px) {
  .search-input {
    text-indent: 6rem;
  }
}

.search-input:disabled {
  background-color: #FFF;
}
</style>