<template>
  <div class='pb-8'>
    <table class='w-full table-fixed mb-10'>
      <thead>
        <tr class='font-semibold'>
          <th class='w-8'></th>
          <th class='w-40 px-2 py-2 border-r text-left'>{{ $t('requestDate') }}</th>
          <th class='text-left w-32 px-2 py-2 border-r uppercase'>{{ $t('requester') }}</th>
          <th class='text-left w-40 px-2 py-2 uppercase'>{{ $t('title') }}</th>
          <th class='text-left px-2 py-2 uppercase'>{{ $t('content') }}</th>
        </tr>
      </thead>
      <tbody>
        <need-approval-row
          v-for='approvalResponse in approvalResponses'
          :key='`need-approval-${approvalResponse.id}`'
          :approvalResponse='approvalResponse'
          @update-selected-request='updateSelectedRequest'
          class='border-b border-t'/>
      </tbody>
    </table>
    <approval-processing :requests='requestApprovalList' class='w-full'/>
  </div>
</template>

<script>
import NeedApprovalRow    from '@/components/approvals/NeedApprovalRow.vue'
import ApprovalProcessing from '@/components/approvals/ApprovalProcessing.vue'

export default {
  name: 'NeedsYourApproval',
  components: {
    NeedApprovalRow,
    ApprovalProcessing,
  },
  props: [
    'approvalResponses'
  ],
  data () {
    return {
      requestApprovalList: [],
    }
  },
  methods: {
    updateSelectedRequest (approvalResponse, checked) {
      if (checked) {
        this.requestApprovalList.push(approvalResponse)
      } else {
        const index = this.requestApprovalList.findIndex(item => item.id === approvalResponse.id)
        if (index >= 0) {
          this.requestApprovalList.splice(index, 1)
        }
      }
    }
  }
}
</script>
