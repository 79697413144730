<template>
  <tr>
    <td class='pr-1'>
      <el-date-picker
        v-model='scheduleDate'
        value-format='yyyy-MM-dd'
        type='date' 
        :placeholder="$t('pickDay')"
        class='w-full' />
    </td>
    <td class='px-1'>
      <el-time-picker
        v-model='startTime'
        format='HH:mm'
        value-format='HH:mm'
        :placeholder="$t('startTime')">
      </el-time-picker>
    </td>
    <td class='px-1 border-r'>
      <el-time-picker
        v-model='endTime'
        format='HH:mm'
        value-format='HH:mm'
        :placeholder="$t('endTime')">
      </el-time-picker>
    </td>
    <td class='px-1'>
      <v-select
        v-model='scheduleType'
        class='w-40'
        :options='scheduleTypes'
        :close-on-select='true'>
        <template v-slot:option='option'>
          <div class='px-2 py-2 items-center'>
            {{option.label}}
          </div>
        </template>
      </v-select>
    </td>
    <td class='px-1 border-r'>
      <input class='form-input w-full' type='text' v-model='location'>
    </td>
    <td class='px-1'>
      <user-search
        :user-ids='userIds'
        :user-names='userNames'
        role='lawyer'
        :is-multiple='true'
        @update-selected-user='updateSelectedUsers' />
    </td>
    <td class='px-1'>
      <input class='form-input w-full' type='text' v-model='description'>
    </td>
    <td class='px-1'>
    </td>
    <td class='pl-2 w-32 text-right'>
      <button class='uppercase px-4 py-3 bg-black border rounded-md border-transparent text-white opacity-75 hover:opacity-100 hover:shadow-md'
        @click='addSchedule'>
        <plus-circle-icon class='inline-block' style='margin-top: -4px;' />
        {{ $t('add') }}
      </button>
    </td>
  </tr>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { PlusCircleIcon } from '@vue-hero-icons/outline'
import UserSearch from '@/components/UserSearch.vue'

import dayjs from 'dayjs'

export default {
  name: 'CaseScheduleRowAdd',
  components: {
    PlusCircleIcon,
    UserSearch
  },
  data() {
    return {
      investment_id: 0,
      userIds: [],
      userNames: '',
      scheduleType: '',
      scheduleDate: dayjs().format('YYYY-MM-DD'),
      startTime: '',
      endTime: '',
      location: '',
      description: '',
    }
  },
  computed: {
    ...mapGetters('investments', [
      'drilldownInvestmentId'
    ]),
    ...mapState('schedules', [
      'scheduleTypes'
    ]),
    prepedForApi () {
      return {
        investment_id: this.drilldownInvestmentId,
        schedule_date: this.scheduleDate,
        schedule_type: this.scheduleType,
        user_ids:      this.userIds,
        start_time:    this.startTime,
        end_time:      this.endTime,
        location:      this.location,
        description:   this.description,
      }
    }
  },
  methods: {
    ...mapActions('schedules', [
      'createSchedule',
    ]),
    addSchedule () {
      this.createSchedule(this.prepedForApi).then(() => {
        this.resetNewSchedule()
      })
    },
    resetNewSchedule () {
      this.investment_id = 0
      this.scheduleDate = new Date()
      this.scheduleType = ''
      this.userIds = []
      this.startTime = ''
      this.endTime = ''
      this.location = ''
      this.description = ''
    },
    updateSelectedUsers (ids, nameAndEmails) {
      this.userIds= ids
      this.userNames = nameAndEmails
    }
  },
}
</script>
