<template>
  <div class='flex-grow'>
    <input class='form-input' type='number' v-model='val' @blur='updateField' @focus='$emit("focus")'>
  </div>
</template>

<script>

export default {
  name: 'TableTypeCustomFieldNumber',
  props: ['row', 'rowIndex', 'colKey'],
  data () {
    return {
      val: this.row[this.colKey]
    }
  },
  methods: {
    updateField () {
      this.$emit('update-value-json', this.rowIndex, this.colKey, this.val)
      this.$emit('blur')
    }
  },
}
</script>
