<template>
  <button
    class='logout-button block uppercase py-4 rounded-md tracking-wide border border-white'
    @click='logout'
    @mouseover='mouseHoverOn'
    @mouseleave='mouseHoverOff'>
    Logout
    <span 
      class='mouse-subtext block font-normal text-sm tracking-normal text-gray-500 opacity-0' 
      :class='hoverShow'>{{currentUserInfo.email}}</span>
  </button>
</template>

<script>

import { mapState } from 'vuex'
export default {
  name: 'LogoutButton',
  data () {
    return {
      mouseHover: false,
    }
  },
  computed: {
    ...mapState([
      'showNavMenu'
    ]),
    ...mapState('users', [
      'currentUserInfo'
    ]),
    hoverShow () {
      return (this.mouseHover) ? 'opacity-100' : ''
    },
  },
  methods: {
    logout () {
      this.$auth.logout({
        returnTo: window.location.origin
      })
    },
    mouseHoverOff () {
      this.mouseHover = false
    },
    mouseHoverOn () {
      this.mouseHover = true
    },
  },
}
</script>

<style scoped lang='scss'>
.mouse-subtext {
  @apply transition duration-150 ease-in-out;
}

.logout-button {
  @apply transition duration-150 ease-in-out;
  width: 280px;
}

.logout-button:hover {
  @apply font-semibold;
  border: solid 1px #4681A9;
}

</style>
