<template>
  <transition name='fade'>
    <div v-if='showModal' 
      class='fixed bg-white shadow-2xl z-30 rounded-lg overflow-hidden' 
      :style='modalSizeAndLocation'>
      <x-icon @click='modalClose' class='cursor-pointer absolute' style='top: 1.5rem; right: 1.5rem;' />
      <header style='height: 6.25rem;'><portal-target name='modal-header' /></header>
      <div style='height: calc(100% - 11.25rem);' class='px-8 pb-16 text-left overflow-y-auto'><slot></slot></div>
      <footer style='height: 5rem;'><portal-target name='modal-footer' /></footer>
    </div>
  </transition>
</template>

<script>

import { mapState, mapActions } from 'vuex'
import { XIcon } from '@vue-hero-icons/outline'

export default {
  name: 'Modal',
  components: {
    XIcon
  },
  computed: {
    ...mapState([
      'showModal',
      'modalSize',  
    ]),
    modalSizeAndLocation () {
      return `top: calc(50% - ${this.offsetVertical}); left: calc(50% - ${this.offsetHorizontal}); height: ${this.modalHeight}rem; width: ${this.modalWidth}rem;`
    },
    modalHeight () { // in rem
      const maxHeight = window.innerHeight / 16
      let height = (this.modalSize.height) ? this.modalSize.height : '52' // taken as parameter
      return Math.min(maxHeight, height)
    },
    modalWidth () { // in rem
      const maxWidth = window.innerWidth / 16
      let width = (this.modalSize.width) ? this.modalSize.width : '34' // taken as parameter
      return Math.min(maxWidth, width)
    },
    offsetHorizontal () {
      return `${this.modalWidth / 2}rem` 
    },
    offsetVertical () {
      return `${this.modalHeight / 2}rem`
    },
  },
  methods: {
    ...mapActions([
      'modalClose'
    ])
  },
}
</script>

<style lang='scss' scoped>
.fade-enter-active, 
.fade-leave-active {
  transition: all .2s;
}

</style>
