<template>
  <div class='flex flex-row mt-2'>
    <select v-model='processingApproval.status' class='font-bold form-select w-32 bg-gray-200'>
      <option v-for='status in approvalType' :key='`approval-processing-status-${status}`'>
        {{status}}
      </option>
    </select>
    <input class='form-input w-1/3' placeholder='Comment' type='text' v-model='processingApproval.comment'>
    <button class='uppercase w-24 px-4 py-3 bg-black border rounded-md border-transparent text-white opacity-75 hover:opacity-100 hover:shadow-md'
      @click='approveRequest'>
      {{buttonText}}
    </button>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'ApprovalProcessing',
  props: ['requests'],
  data () {
    return {
      processingApproval: {
        status: 'approved',
        comment: '',
      },
    }
  },
  computed: {
    ...mapState('approvals', [
      'statusTypes',
    ]),
    approvalType () {
      return this.statusTypes.filter(type => type !== 'request')
    },
    buttonText () {
      return (this.processingApproval.status === 'approved') ? this.$t('approve') : this.$t('reject')
    }
  },
  methods: {
    ...mapActions('approvals', [
      'updateApprovalStatus',
    ]),
    approveRequest () {
      this.updateApprovalStatus({approvalResponses: this.requests, status: this.processingApproval})
      this.processingApproval = { status: 'approved', comment: ''}
    },
  },
}
</script>
