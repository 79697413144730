<template>
  <tr>
    <td class='py-1 pr-1'>{{schedule.schedule_date}}</td>
    <td class='py-1 px-2'>{{schedule.start_time}}</td>
    <td class='py-1 px-2 border-r'>{{schedule.end_time}}</td>
    <td class='py-1 px-2' :style='scheduleTypeColor'>{{schedule.schedule_type}}</td>
    <td class='py-1 px-2 border-r'>{{schedule.location}}</td>
    <td class='py-1 px-2'>{{schedule.user_names}}</td>
    <td class='py-1 px-2'>{{schedule.description}}</td>
    <td class='py-1 px-2'>{{schedule.updated_by_name}}</td>
    <td class='w-32 py-1 pl-2 text-right'>
      <el-dropdown split-button type='primary' @click='editSchedule'>
        {{ $t('edit') }}
        <el-dropdown-menu>
          <button class='px-4' @click='deleteConfirm'>{{ $t('delete') }}</button>
        </el-dropdown-menu>
      </el-dropdown>
    </td>
  </tr>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'CaseScheduleRowView',
  props: ['schedule'],
  computed: {
    scheduleTypeColor () {
      return ['불변기일','선고기일'].includes(this.schedule.schedule_type) ? 'color: red;' : ''
    },
  },
  methods: {
    ...mapActions('schedules', [
      'deleteSchedule',
    ]),
    editSchedule () {
      this.$emit('edit-schedule')
    },
    deleteConfirm () {
      this.$confirm(this.$t('deleteScheduleConfirm'), 'Warning', {
        confirmButtonText: this.$t('delete'),
        cancelButtonText: this.$t('keep'),
        type: 'warning'
      }).then(() => {
        this.deleteSchedule(this.schedule.id).then(() => {
          this.$message({
            type: 'success',
            message: this.$t('deleteConfirmed')
          })
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('deleteCanceled')
        })
      })
    }
  },
}
</script>
