<template>
  <th class='relative font-semibold'>
    <div 
      @click='sortByField'
      class='whitespace-no-wrap pr-8 cursor-pointer'>
      {{ headerLabel }}
      <component 
        :is='sortIcon'
        class='h-4 text-gray-600'
        :class='showSortIcon' />
    </div>
    <el-popover 
      v-model='showFilterDropdown'
      placement='bottom' 
      width='240'>
      <div class='relative'>
        <input type='text'
         v-model='filterValue'
         :placeholder='defaultLabel'
         class='form-input w-full'>
        <x-circle-icon 
          v-show='hasFilterValue'
          @click='clearFilterValue'
          class='cursor-pointer absolute h-5 text-gray-600 hover:text-gray-900'
          style='top: 0.75rem; right: 0.5rem' />
      </div>
      <div slot='reference' 
        class='absolute' 
        style='top: 0.5rem; right: 0.5rem;'>
        <filter-icon 
          class='inline-block h-4 w-4 rounded cursor-pointer text-gray-600 hover:text-gray-800 opacity-25 hover:opacity-75 hover:bg-gray-200'
          :class='hasFilterStyle' />
      </div>
    </el-popover>
  </th>
</template>

<script>
import { FilterIcon,
         SortAscendingIcon,
         SortDescendingIcon,
         XCircleIcon, 
       } from '@vue-hero-icons/outline'

export default {
  name: 'TableHeaderFilterText',
  components: {
    FilterIcon,
    SortAscendingIcon,
    SortDescendingIcon, 
    XCircleIcon,
  },
  props: [
    'defaultLabel',
    'filterText', 
    'isSortingBy', // 1 is asc, -1 is dsc, 0 is not sorting by this field
  ],
  data () {
    return {
      headerLabel: this.defaultLabel,
      filterValue: this.filterText,
      showFilterDropdown: false
    }
  },
  watch: {
    filterValue: function (newFilterValue) {
      if (newFilterValue === '') {
        this.headerLabel = this.defaultLabel
      } else {
        this.headerLabel = newFilterValue
      }
      this.updateFilter()
    }
  },
  computed: {
    hasFilterValue () {
      return this.filterValue
    },
    hasFilterStyle () {
      return (this.hasFilterValue) ? 'opacity-100 text-blue-800' : ''
    },
    showSortIcon () {
      return (this.isSortingBy) ? 'inline-block' : 'hidden'
    },
    sortIcon () {
      switch (this.isSortingBy) {
        case 1:
        return 'sort-ascending-icon'
        case -1:
        return 'sort-descending-icon'
        case 0:
        default:
        return 'span'
      }
    }
  },
  methods: {
    clearFilterValue () {
      this.filterValue = ''
    },
    sortByField () {
      this.$emit('clicked-field-label')
    },
    updateFilter () {
      const emitText = 'update:filterText'
      this.$emit(emitText, this.filterValue)
    },
  }
}
</script>
