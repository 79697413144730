<template>
  <div>
    <case-invoice-details-title class='mb-8' />
    <case-invoice-details-overview  />
    <div
      class='tabs-container mt-16 pt-4 pb-2 border-b border-gray-300 text-gray-600'>
      <label
        class='mr-8 px-4 pb-2 pt-3 rounded-t cursor-pointer'
        :class='selectedStyle("case-invoice-billing-details-timesheet")'>
        <input value='case-invoice-billing-details-timesheet' v-model='view' type='radio' name='view' class='hidden'/>
        {{ $t('billingDetailsTimesheet') }}
      </label>
      <label
        class='mr-8 px-4 pb-2 pt-3 rounded-t cursor-pointer'
        :class='selectedStyle("case-invoice-billing-details-expenses")'>
        <input value='case-invoice-billing-details-expenses' v-model='view' type='radio' name='view' class='hidden'/>
        {{ $t('billingDetailsExpenses') }}
      </label>
      <label
        class='mr-8 px-4 pb-2 pt-3 rounded-t cursor-pointer'
        :class='selectedStyle("case-invoice-distribution-details")'>
        <input value='case-invoice-distribution-details' v-model='view' type='radio' name='view' class='hidden'/>
        {{ $t('caseInvoiceDistributionDetails') }}
      </label>
      <label
        class='mr-8 px-4 pb-2 pt-3 rounded-t cursor-pointer'
        :class='selectedStyle("invoice-deposits-details")'>
        <input value='invoice-deposits-details' v-model='view' type='radio' name='view' class='hidden'/>
        {{ $t('depositDetails') }}
      </label>
      <label
        class='mr-8 px-4 pb-2 pt-3 rounded-t cursor-pointer'
        :class='selectedStyle("invoice-attachments-details")'>
        <input value='invoice-attachments-details' v-model='view' type='radio' name='view' class='hidden'/>
        {{ $t('attachedFile') }}
      </label>
      <label
        class='mr-8 px-4 pb-2 pt-3 rounded-t cursor-pointer'
        :class='selectedStyle("invoice-entity-details")'>
        <input value='invoice-entity-details' v-model='view' type='radio' name='view' class='hidden'/>
        {{ $t('clientInfo') }}
      </label>
    </div>
    <component 
      :is='view' 
      :total-amount='total_amount' />
    <portal to='sidepanel-footer'>
      <div class='w-full bg-gray-100 pl-8 pr-8 py-2 text-sm shadow-lg'>
        <button
          class='inline-block py-3 px-12 text-white rounded-md font-bold border border-transparent bg-gray-800 hover:bg-gray-900'
          :disabled='deactivateSaveButton'
          @click='saveInvoice'>
          <save-icon class='inline-block h-5' style='margin-top: -5px;' />
          {{ $t('save') }}
        </button>
        <button
          @click='closeEdit'
          class='inline-block mx-4 px-4 py-3 bg-transparent text-gray-600 hover:text-gray-900 border border-transparent rounded-md hover:border-gray-600 hover:shadow-md'>
          {{ $t('cancelAndClose') }}
        </button>
      </div>
    </portal>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import { SaveIcon }  from '@vue-hero-icons/outline'
import CaseInvoiceDetailsTitle        from '@/components/invoices/CaseInvoiceDetailsTitle.vue'
import CaseInvoiceDetailsOverview     from '@/components/invoices/CaseInvoiceDetailsOverview.vue'
import CaseInvoiceBillingDetailsTimesheet      from '@/components/invoices/CaseInvoiceBillingDetailsTimesheet.vue'
import CaseInvoiceBillingDetailsExpenses  from '@/components/invoices/CaseInvoiceBillingDetailsExpenses.vue'
import CaseInvoiceDistributionDetails from '@/components/invoices/CaseInvoiceDistributionDetails.vue'
import InvoiceDepositsDetails         from '@/components/invoices/InvoiceDepositsDetails.vue'
import InvoiceAttachmentsDetails      from '@/components/invoices/InvoiceAttachmentsDetails.vue'
import InvoiceEntityDetails           from '@/components/invoices/InvoiceEntityDetails.vue'


export default {
  name: 'CaseInvoiceDetails',
  components: {
    CaseInvoiceDetailsTitle,
    CaseInvoiceDetailsOverview,
    CaseInvoiceBillingDetailsTimesheet,
    CaseInvoiceBillingDetailsExpenses,
    CaseInvoiceDistributionDetails,
    InvoiceDepositsDetails,
    InvoiceAttachmentsDetails,
    InvoiceEntityDetails,
    SaveIcon,
  },
  data () {
    return {
      view: 'case-invoice-billing-details-timesheet',
    }
  },
  watch: {
    view: {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.setEditingInvoiceTab(newVal)
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapFields('invoices', [
      'editingInvoice.total_amount',
    ]),
    ...mapState('invoices', [
      'editingInvoice',
    ]),
    ...mapGetters('commissions', [
      'totalCommissionRatio',
      'commissionPoolTotalRatio',
      'everyCommissionHasUser',
    ]),
    ...mapGetters('invoiceEmployeeEarnings', [
      'totalEarningRatio',
      'earningPoolTotalRatio',
      'everyEarningHasUser',
    ]),
    ...mapGetters('users', [
      'currentUserName',
      'currentUserEmail',
    ]),
    isCaseInvoice () {
      return this.$router.currentRoute.name === 'Dashboard'
    },
    deactivateSaveButton () {
      let totalRatio = Math.round(this.totalCommissionRatio + this.totalEarningRatio)
      let totalCommissionRatio = Math.round(this.commissionPoolTotalRatio)
      let totalEarningRatio = Math.round(this.earningPoolTotalRatio)
      return (this.view === 'case-invoice-distribution-details') ? !(
        totalRatio == 100 &&
        (totalCommissionRatio == 100 || totalCommissionRatio == 0)  &&
        totalEarningRatio == 100 &&
        this.everyEarningHasUser &&
        this.everyCommissionHasUser) : false
    },
  },
  methods: {
    ...mapMutations('invoices', [
      'setEditingInvoiceTab',
    ]),
    ...mapActions('invoices', [
      'totalUpdateForInvoice',
    ]),
    ...mapActions([
      'sidepanelClose',
    ]),
    closeEdit () {
      if (this.isCaseInvoice) {
        this.$emit('cancel:invoice')
      } else {
        this.sidepanelClose()
      }
    },
    saveInvoice () {
      let message = `${this.currentUserName}(${this.currentUserEmail}) 이 작성한 변경내용을 저장하시겠습니까? 작성자도 변경되니 원하지 않으시면 Cancel & Close를 선택해주세요.`
      this.$confirm(message.replace("\n", "<br />"), 'Warning', {
        confirmButtonText: this.$t('confirmSave'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        this.totalUpdateForInvoice().then(() => {
          this.$message({
            type: 'success',
            message: this.$t('successfullySaved')
          })
        })
        this.closeEdit()
      }).catch(() => {
      })
    },
    selectedStyle (tab) {
      return (tab === this.view) ? 'text-gray-800 font-semibold border-b-2 border-gray-800' : 'border-b-2 border-transparent'
    },
    checkInvoiceQueryParam () {
      let queryParamTab = this.$route.query.invoice_tab
      this.view = (queryParamTab) ? queryParamTab : 'case-invoice-billing-details-timesheet'
    }
  },
  mounted () {
    this.checkInvoiceQueryParam()
  },
}
</script>

<style lang='scss' scoped>
  button:disabled,
  button[disabled] {
    @apply opacity-50 cursor-not-allowed;
  }
</style>
