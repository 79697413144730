import base from './base'

const getTimesheetEntries = (filters) => base
  .get('/timesheet_entries/', { params: filters }).then(resp => resp.data)

const postTimesheetEntry = (timesheetEntryParams) => base
  .post('/timesheet_entries/', timesheetEntryParams).then(resp => resp.data)

const updateTimesheetEntry = (timesheetEntryId, timesheetEntryParams) => base
  .patch(`/timesheet_entries/${timesheetEntryId}`, timesheetEntryParams).then(resp => resp.data)

const deleteTimesheetEntry = (timesheetEntryId) => base
  .delete(`/timesheet_entries/${timesheetEntryId}`).then(resp => resp.data)

export default {
  getTimesheetEntries: getTimesheetEntries,
  postTimesheetEntry: postTimesheetEntry,
  updateTimesheetEntry: updateTimesheetEntry,
  deleteTimesheetEntry: deleteTimesheetEntry,
}
