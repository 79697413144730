<template>
  <label :class='labelCSS'>
    {{labelString}}
    <span v-if='requiredButEmpty' class='text-red-500 py-2 rounded'>*</span>
  </label>
</template>

<script>
export default {
  name: 'CustomFieldLabel',
  props: [
    'customField',
  ],
  computed: {
    requiredButEmpty () {
      return (this.customField && this.customField.required && (this.customField.value === '' || this.customField.value === null))
    },
    labelString () {
      return (this.customField) ? this.customField.custom_field_name : ''
    },
    labelCSS () {
      let cssClasses = 'text-gray-600 flex-shrink-0'
      if (this.requiredButEmpty) {
        cssClasses = cssClasses + ' font-semibold text-gray-800'
      }
      if (this.customField.field_type === 'header') {
        cssClasses = cssClasses + ' text-gray-900 text-sm font-semibold uppercase w-full mt-6'
      }
      return cssClasses
    },
  },
}
</script>
