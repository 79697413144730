<template>
  <div>
    <div v-if='isViewMode'>
      {{formattedNumber}}
    </div>
    <vue-numeric v-else
      v-model='fieldValue'
      output-type='number'
      class='form-input w-1/4 text-right'
      @blur='handleUpdate'
      thousand-separator=',' />
  </div>
</template>

<script>
import numbro from 'numbro'

export default {
  name: 'CustomFieldNumber',
  props: ['field', 'isViewMode'],
  data () {
    return {
      fieldValue: Number(this.field.value),
    }
  },
  computed: {
    formattedNumber () {
      return (this.field.value) ? numbro(this.field.value).format({ thousandSeparated: true }) : ''
    },
  },
  methods: {
    handleUpdate () {
      this.$emit('update-value', {...this.field, value: this.fieldValue})
    },
  },
}
</script>
