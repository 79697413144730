<template>
  <div class='px-2 lg:px-8 pb-16 pt-8'>
    <dashboard-view-editing-name />
    <dashboard-view-editing-access            class='mt-12' v-if='currentDashboardView.mine' />
    <dashboard-view-editing-filters           class='mt-12'/>
    <dashboard-view-editing-layout            class='mt-12' />
    <dashboard-view-editing-grouping-by       class='mt-12' v-if='isCardView' />
    <dashboard-view-editing-display-dates     class='mt-12' v-if='isCalendarView' />
    <dashboard-view-editing-visible-fields    class='mt-12'/>
    <portal to='sidepanel-footer'>
      <dashboard-view-editing-save />
    </portal>
  </div>
</template>

<script>

import { mapState, mapMutations } from 'vuex'
import DashboardViewEditingName            from '@/views/dashboard/DashboardViewEditingName.vue'
import DashboardViewEditingAccess          from '@/views/dashboard/DashboardViewEditingAccess.vue'
import DashboardViewEditingVisibleFields   from '@/views/dashboard/DashboardViewEditingVisibleFields.vue'
import DashboardViewEditingFilters         from '@/views/dashboard/DashboardViewEditingFilters.vue'
import DashboardViewEditingGroupingBy      from '@/views/dashboard/DashboardViewEditingGroupingBy.vue'
import DashboardViewEditingLayout          from '@/views/dashboard/DashboardViewEditingLayout.vue'
import DashboardViewEditingDisplayDates    from '@/views/dashboard/DashboardViewEditingDisplayDates.vue'
import DashboardViewEditingSave            from '@/views/dashboard/DashboardViewEditingSave.vue'

export default {
  name: 'DashboardViedEditing',
  components: {
    DashboardViewEditingName,
    DashboardViewEditingAccess,
    DashboardViewEditingFilters,
    DashboardViewEditingGroupingBy,
    DashboardViewEditingLayout,
    DashboardViewEditingDisplayDates,
    DashboardViewEditingVisibleFields,
    DashboardViewEditingSave,
  },
  watch: {
    currentDashboardView: {
      handler: function () {
        this.setViewEdited(true)
      },
      deep: true
    }
  },
  computed: {
    ...mapState('dashboardViews', [
      'currentDashboardView',
    ]),
    isCardView () {
      return this.currentDashboardView.view_layout === 'Card'
    },
    isCalendarView () {
      return this.currentDashboardView.view_layout === 'Monthly' ||
             this.currentDashboardView.view_layout === 'Weekly'
    },
  },
  methods: {
    ...mapMutations('dashboardViews', [
      'setViewEdited'
    ]),
  }
}
</script>
