import Vue from 'vue'
import Vuex from 'vuex'
import firmsApi from '@/api/v1/firms'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

const state = () => ({
  firms: {
    apply_user_group_to_investments: false,
    custom_field_storage_mode: ''
  },
})

const getters = {
  getCustomFieldStorageMode: (state) => {
    return (state.firms.custom_field_storage_mode) ? state.firms.custom_field_storage_mode : null
  },
  isMsStorageMode: (state, getters) => {
    return getters.getCustomFieldStorageMode === 'sharepoint'
  },
  getField,
}

const actions = {
  getFirms ({ commit }) {
    return new Promise(resolve => {
      firmsApi.getFirms().then(resp => {
        commit('setFirm', resp[0])
        resolve()
      })
    })
  },
}

const mutations = {
  setFirm (state, settings) {
    if (settings) {
      state.firms = settings
    }
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
