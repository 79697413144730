<template>
  <div class='text-sm'>
    <div class='mt-4 flex flex-row justify-start leading-10'>
      <label class='text-gray-600 w-40'>{{ $t('name') }}</label>
      <input type='text' class='form-input flex-grow' v-model='contact_name'>
    </div>
    <div class='mt-4 flex flex-row justify-start leading-10'>
      <label class='text-gray-600 w-40'>{{ $t('shareContactYn') }}</label>
      <div><input type='checkbox' class='form-checkbox' v-model='shareable'></div>
    </div>
    <div class='mt-4 flex flex-row justify-start leading-10'>
      <label class='text-gray-600 w-40' :class='phoneNumberErrorClass'>{{ $t('cellPhoneNo') }}</label>
      <input type='text' class='form-input flex-grow' :class='phoneNumberErrorClass' :placeholder="$t('requiredOnlyNumbers')" v-model='phone_number'>
    </div>
    <div class='mt-4 flex flex-row justify-start leading-10'>
      <label class='text-gray-600 w-40' :class='officeNumberErrorClass'>{{ $t('officeNo') }}</label>
      <input type='text' class='form-input flex-grow' :class='officeNumberErrorClass' :placeholder="$t('onlyNumbers')" v-model='office_phone_number'>
    </div>
    <div class='mt-4 flex flex-row justify-start leading-10'>
      <label class='text-gray-600 w-40' :class='faxErrorClass'>{{ $t('faxNo') }}</label>
      <input type='text' class='form-input flex-grow' :class='faxErrorClass' :placeholder="$t('onlyNumbers')" v-model='fax_number'>
    </div>
    <div class='mt-4 flex flex-row justify-start leading-10'>
      <label class='text-gray-600 w-40'>{{ $t('email') }}</label>
      <input type='text' class='form-input flex-grow' v-model='email'>
    </div>
    <div class='mt-4 flex flex-row justify-start leading-10'>
      <label class='text-gray-600 w-40'>{{ $t('address') }}</label>
      <input type='text' class='form-input flex-grow' v-model='address'>
    </div>
    <div class='mt-4 flex flex-row justify-start leading-10'>
      <label class='text-gray-600 w-40'>{{entityLabelString}}</label>
      <entity-search v-model='editingContact.entity_id'
                     class='inline-block'
                     :entity-name='editingContact.entity_name'
                     :entity-id='editingContact.entity_id'
                     :block-create-entity='true'
                     @update-selected-entity='updateSelectedEntity' />
    </div>
    <div class='mt-4 flex flex-row justify-start leading-10'>
      <label class='text-gray-600 w-40'>{{ $t('jobTitle') }}</label>
      <input type='text' class='form-input flex-grow' v-model='title'>
    </div>
    <div class='mt-4 flex flex-row justify-start leading-10'>
      <label class='text-gray-600 w-40'>{{ $t('department') }}</label>
      <input type='text' class='form-input flex-grow' v-model='department'>
    </div>
    <custom-field
      v-for='customField in drilldownContactFields'
      :key='`contact-edit-fields-${customField.id}`'
      :custom-field='customField'
      class='mt-4'
      :is-horizontal='true' />
  </div>
</template>

<script>
import { mapState, mapMutations }  from 'vuex'
import { mapFields } from 'vuex-map-fields'
import EntitySearch from '@/components/EntitySearch.vue'
import CustomField  from '@/components/custom_fields/CustomField.vue'

export default {
  name: 'ContactEditFields',
  components: {
    EntitySearch,
    CustomField,
  },
  computed: {
    ...mapState('contacts', [
      'drilldownContactFields',
    ]),
    ...mapFields('contacts', [
      'editingContact',
      'editingContact.contact_name',
      'editingContact.entity_id',
      'editingContact.shareable',
      'editingContact.phone_number',
      'editingContact.office_phone_number',
      'editingContact.fax_number',
      'editingContact.email',
      'editingContact.address',
      'editingContact.title',
      'editingContact.department',
    ]),
    entityLabelString () {
      return this.$t('companyClient')
    },
    faxErrorClass () {
      return this.fax_number && this.notNumRegex.test(this.fax_number) ? 'border-red-600 text-red-500' : ''
    },
    phoneNumberErrorClass () {
      return this.phone_number && this.notNumRegex.test(this.phone_number) ? 'border-red-600 text-red-500' : ''
    },
    officeNumberErrorClass () {
      return this.office_phone_number && this.notNumRegex.test(this.office_phone_number) ? 'border-red-600 text-red-500' : ''
    },
    notNumRegex () {
      return /([^0-9-])/g
    },
  },
  methods: {
    ...mapMutations('contacts', [
      'selectEntityForEditingContact'
    ]),
    updateSelectedEntity (entity) {
      this.selectEntityForEditingContact(entity)
    },
  },
}
</script>
