import base from './base'

const getInvoiceEmployeeEarnings = (filters) => base
  .get('/invoice_employee_earnings/', { params: filters }).then(resp => resp.data)

const postInvoiceEmployeeEarning = (earningParams, contentType='application/json') => base
.post('/invoice_employee_earnings/', earningParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const updateInvoiceEmployeeEarning = (earningId, earningParams, contentType='application/json') => base
.patch(`/invoice_employee_earnings/${earningId}`, earningParams, {headers: { 'Content-Type': contentType }}).then(resp => resp.data)

const deleteInvoiceEmployeeEarning = (earningId) => base
.delete(`/invoice_employee_earnings/${earningId}`).then(resp => resp.data)

export default {
  getInvoiceEmployeeEarnings: getInvoiceEmployeeEarnings,
  postInvoiceEmployeeEarning: postInvoiceEmployeeEarning,
  updateInvoiceEmployeeEarning: updateInvoiceEmployeeEarning,
  deleteInvoiceEmployeeEarning: deleteInvoiceEmployeeEarning,
}
