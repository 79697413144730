<template>
  <div class='relative'>
    <vue-editor
      v-model='newMessage'
      :placeholder="$t('newComment')"
      :editor-options='editorOptions'>
    </vue-editor>
    <button
      class='border rounded-md w-24 h-10 text-white text-sm font-semibold absolute'
      style='background-color: #223645; top: .33rem; right: .25rem;'
      :disabled='emptyComment'
      @click='submit()'>
      {{ $t('add') }}
    </button>
    <label class='cursor-pointer w-full text-sm rounded-md pl-4 py-3 pr-32'>
      {{ uploadText }}
      <input @change='fileSelect' type='file' ref='AttachedFile' class='bg-pink-200 hidden'>
    </label>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { VueEditor } from 'vue2-editor'

export default {
  name: 'CommentInput',
  props: ['investmentId'],
  components: {
    VueEditor,
  },
  data () {
    return {
      editorFocused: null,
      editorOptions:{
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline'],
              [{list: 'ordered'}, {list: 'bullet'}, {indent: '-1'}, {indent: '+1'}],
              ['clean'],
            ],
          }
        }
      },
      newMessage: '',
      newFile: '',
      fileInput: null,
    }
  },
  computed: {
    emptyComment () {
      return this.newMessage.trim() === ''
    },
    uploadText () {
      if (this.hasFile) {
        return this.fileInput.name
      } else {
        return this.$t('uploadNewFile')
      }
    },
    hasFile () {
      return this.fileInput
    },
  },
  methods: {
    ...mapActions('changeLogs', [
      'createComment'
    ]),
    submit () {
      let comment = {
        investmentId: this.investmentId,
        value:        this.newMessage,
        attachedFile: this.fileInput
      }
      this.createComment(comment).then(() => {
        this.newMessage = ''
        this.fileInput = null
      })
    },
    fileSelect () {
      this.fileInput = this.$refs.AttachedFile.files[0]
    },
  }
}
</script>
