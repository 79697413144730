<template>
  <header 
    class='w-full px-2 lg:px-8 flex flex-row items-center justify-start relative'
    :class='searchClass'>
    <div class='flex-grow flex flex-row justify-start items-center gap-x-2'>
      <menu-alt-1-icon @click='navMenuOpen' class='h-8 w-8 cursor-pointer flex-shrink-0' />
      <search-all :class='searchStyle' />
      <portal-target v-if='!isSearching' name='page-title'></portal-target>
    </div>    
    <approval-dialog 
      class='absolute top-4 right-0 z-10 mr-2'
      :hidden='isSearching' />
  </header>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { MenuAlt1Icon } from '@vue-hero-icons/outline'
import SearchAll       from '@/components/SearchAll.vue'
import ApprovalDialog  from '@/components/approvals/ApprovalDialog.vue'

export default {
  name: 'AppHeader',
  components: {
    MenuAlt1Icon,
    SearchAll,
    ApprovalDialog,
  },
  computed: {
    ...mapState([
      'isSearching',
    ]),
    searchClass () {  
      return (this.isSearching) ? 'header-search-background h-auto py-8' : 'h-16 lg:h-24'
    },
    searchStyle () {
      return (this.isSearching) ? 'flex-grow w-full' : 'flex-grow-0'
    },
  },
  methods: {
    ...mapActions([
      'navMenuOpen',
    ]),
  },
}
</script>

<style lang='scss' scoped>
  .header-search-background {
    background-color: #F5F5F5;
    // TODO: fix this need to figure out how
    position: sticky;
    top: 0;
    z-index: 10;
    min-height: 4rem;
  }

  @media (min-width: 1024px) {
    .header-search-background {
      min-height: 6rem;
    }
  }

</style>
