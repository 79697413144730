<template>
  <div class='border border-gray-100 p-4 mb-2 rounded-md relative'>
    <div v-if='isDeleteInvestment'>
      {{ $t('deletedObject', [objectLabel]) }}
    </div>
    <div v-else-if='isUpdateInvestment'>
      <div v-if='!!log.value_after.entity_id' class='flex flex-row'>
        <label class='w-32 text-gray-600'>{{ $t('entity') }}</label>
        <div>
          {{log.value_before.entity_name}}
          <arrow-narrow-right-icon class='inline-block mx-2' />
          {{log.value_after.entity_name}}
        </div>
      </div>
      <div v-if='!!log.value_after.investment_name' class='flex flex-row'>
        <label class='w-32 text-gray-600'>{{ $t('objectName', [objectLabel]) }}</label>
        <div>
          {{log.value_before.investment_name}}
          <arrow-narrow-right-icon class='inline-block mx-2' />
          {{log.value_after.investment_name}}
        </div>
      </div>
      <div v-if='!!log.value_after.owner_ids' class='flex flex-row'>
        <label class='w-32 text-gray-600'>{{ $t('personInCharge') }}</label>
        <div>
          {{getUserNames(log.value_before.owner_ids)}}
          <arrow-narrow-right-icon class='inline-block mx-2' />
          {{getUserNames(log.value_after.owner_ids)}}
        </div>
      </div>
    </div>
    <div v-else-if='isCreateInvestment'>
      <div class='flex flex-row'>
        <label class='w-32 text-gray-600'>{{ $t('entity') }}</label>
        <div>{{log.value_after.entity_name}}</div>
      </div>
      <div class='flex flex-row'>
        <label class='w-32 text-gray-600'>{{ $t('objectName', [objectLabel]) }}</label>
        <div>{{log.value_after.investment_name}}</div>
      </div>
      <div class='flex flex-row'>
        <label class='w-32 text-gray-600'>{{ $t('personInCharge') }}</label>
        <div>{{getUserNames(log.value_after.owner_ids)}}</div>
      </div>
    </div>
    <div v-else-if='isCustomField'>
      <span class='inline-block w-32 text-gray-600'>{{log.custom_field_name}}</span>
      <span v-if='!!log.value_before'>
        <span v-if='hasValueBeforeUrl'>
          <a :href='log.value_before_url'
            download>{{log.value_before}}</a>
        </span>
        <span v-else>{{log.value_before}}</span>
        <arrow-narrow-right-icon class='inline-block mx-2' />
      </span>
      <span v-if='hasValueAfterUrl'>
          <a :href='log.value_after_url'
            download>{{log.value_after}}</a>
        </span>
      <span v-else>{{log.value_after}}</span>
    </div>
    <div v-else-if='isComment'>
      <div v-if='isEditing'>
        <div class='fixed lg:static bg-white z-30' style='top:5%; width:98%; left:1%'>
          <div class='bg-cover fixed lg:hidden top-0 left-0 h-full w-full z-10 opacity-70' style='background-color: #223645;'></div>
          <comment-input-edit
            :comment='log'
            @done-editing='toggleEdit'
            class='comment-input-edit bg-white z-20' />
        </div>
      </div>

      <div v-else>
        <div class='ql-editor' v-html='log.value_after'></div>
        <div class='absolute flex flex-row justify-end items-center gap-x-1' style='top:-2rem; right:0;'>
          <button v-if='showEditControl'
            @click='toggleEdit'
            class='p-2 rounded-md bg-gray-100'>
            <pencil-icon class='h-4' />
          </button>
          <button v-if='showDeleteControl'
            @click='confirmDeleteComment(comment)'
            class='p-2 rounded-md bg-gray-100'>
            <x-icon class='h-4' />
          </button>
        </div>
      </div>

      <div v-if='hasCommentFileUrl' class='flex flex-row'>
        <label class='w-32 text-gray-600'>{{ $t('commentFile') }}</label>
          <a :href='log.comment_file_url'
            download>{{log.comment_file}}</a>
      </div>
    </div>

    <div v-else-if='isInvoiceExpense'>
      <template v-for='key in filteredKeys'>
        <div v-if='isValueBefore(key) || isValueAfter(key)' class='flex flex-row' :key='key'>
          <label class='w-32 text-gray-600'>[{{eventObjectLabel}}] {{getColumnName(logType, key)}}</label>
          <div class='text-gray-600'>
            {{valueBefore(key)}}
            <arrow-narrow-right-icon v-if='isValueBefore(key)' class='inline-block pb-1 mx-1' />
            {{valueAfter(key)}}
          </div>
        </div>
      </template>
    </div>
  </div>

</template>

<script>

import dayjs from 'dayjs'
import numbro from 'numbro'
import { mapState, mapGetters, mapActions } from 'vuex'
import { ArrowNarrowRightIcon, PencilIcon } from '@vue-hero-icons/outline'
import {  XIcon } from '@vue-hero-icons/solid'
import CommentInputEdit from '@/components/CommentInputEdit.vue'

export default {
  name: 'ChangeLog',
  components: {
    CommentInputEdit,
    ArrowNarrowRightIcon,
    PencilIcon,
    XIcon,
  },
  props: ['log'],
  data () {
    return {
      isEditing: false,
    }
  },
  computed: {
    ...mapState('users', [
      'users',
    ]),
    ...mapGetters('changeLogs', [
      'getColumnName',
    ]),
    objectLabel () {
      return 'case'
    },
    filteredKeys () {
      return Object.keys(this.log.value_before).filter(key => this.getColumnName(this.log.event_object, key) !== '')
    },
    eventObjectLabel () {
      if (this.log.event_object === 'invoice') {
        return this.$t('bill')
      } else if (this.log.event_object === 'expense') {
        return this.$t('expense')
      } else if (this.log.event_object === 'commission') {
        return this.$t('commission')
      } else if (this.log.event_object === 'earning') {
        return this.$t('earning')
      } else if (this.log.event_object === 'timesheet_entry') {
        return this.$t('timesheet_entry')
      } else if (this.log.event_object === 'deposit_to_invoices') {
        return this.$t('deposit_to_invoices')
      } else {
        return this.log.event_object
      }
    },
    hasValueAfterUrl () {
      return this.log.value_after_url.length > 0
    },
    hasValueBeforeUrl () {
      return this.log.value_before_url.length > 0
    },
    hasCommentFileUrl () {
      return this.log.comment_file_url.length > 0
    },
    isWrittenToday () {
      let today = dayjs(dayjs().format('YYYY-MM-DD'))
      let createdAt = dayjs(this.log.created_at).format('YYYY-MM-DD')
      return today.isSame(createdAt)
    },
    isMyComment () {
      return (this.log.user_id === parseInt(localStorage.getItem('userId')))
    },
    comment () {
      return {
        investmentId: this.log.investment_id,
        id:           this.log.id
      }
    },
    isDeleteInvestment () {
      return this.logType === 'delete investment'
    },
    isUpdateInvestment () {
      return this.logType === 'update investment'
    },
    isCreateInvestment () {
      return this.logType === 'create investment'
    },
    isCustomField () {
      return this.logType === 'custom field'
    },
    isComment () {
      return this.logType === 'comment'
    },
    isInvoiceExpense () {
      return ['invoice', 'expense', 'commission', 'earning', 'timesheet_entry', 'deposit_to_invoices'].includes(this.logType)
    },
    logType () {
      if (['invoice', 'expense', 'commission', 'earning', 'timesheet_entry', 'deposit_to_invoices'].includes(this.log.event_object)) {
        return this.log.event_object
      }
      // investment
      else if (this.log.event_type.includes("delete") && (this.log.event_object != "custom field" && this.log.event_object != "comment")) {
        return 'delete investment'
      }
      else if (this.log.event_type.includes("update") && (this.log.event_object != "custom field" && this.log.event_object != "comment")) {
        return 'update investment'
      }
      else if (this.log.event_type.includes("create") && (this.log.event_object != "custom field" && this.log.event_object != "comment")) {
        return 'create investment'
      }
      // custom_field
      else if (this.log.event_object === "custom field") {
        return 'custom field'
      }
      // comment
      else if (this.log.event_object === "comment") {
        return 'comment'
      }
      else {
        return ''
      }
    },
    showEditControl () {
      return this.isWrittenToday && this.isMyComment && !this.isEditing
    },
    showDeleteControl () {
      return this.isWrittenToday && this.isMyComment
    },
  },
  methods: {
    ...mapActions('changeLogs', [
      'deleteComment',
    ]),
    toggleEdit() {
      this.isEditing = !this.isEditing
    },
    confirmDeleteComment (comment) {
      this.$confirm(`comment 삭제하시겠습니까?`, 'Warning', {
        confirmButtonText: this.$t('delete'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        this.deleteComment(comment).then(() => {
          this.$message({
            type: 'success',
            message: this.$t('deleteConfirmed')
          })
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: this.$t('deleteCanceled')
        })
      })
    },
    getUserName (userId) {
      let found = this.users.find(user => user.id === userId)
      return (found) ? found.name : userId
    },
    getUserNames (userIds) {
      return !userIds ? '' : userIds.map(userId => this.getUserName(userId))
    },
    valueBefore (key) {
      if (this.log.value_before[key] === null) {
        return ''
      } else if (!this.log.value_before[key] || !!this.log.value_before[key]) {
        if (key === 'user_id') {
          return this.log.value_before[key] ? this.getUserName(this.log.value_before[key]) : 'None'
        } else if (Number.isInteger(this.log.value_before[key])) {
          if (key.includes("id")) {
            return numbro(this.log.value_before[key]).format({mantissa: 0, thousandSeparated: false})
          } else {
            return numbro(this.log.value_before[key]).format({mantissa: 0, thousandSeparated: true})
          }
        } else {
          return this.log.value_before[key]
        }
      } else {
        return ''
      }
    },
    valueAfter (key) {
      if (this.log.value_after[key] === null) {
        return ''
      } else if (!this.log.value_after[key] || !!this.log.value_after[key]) {
        if (key === 'user_id') {
          return this.log.value_after[key] ? this.getUserName(this.log.value_after[key]) : 'None'
        } else if (Number.isInteger(this.log.value_after[key])) {
          if (key.includes("id")) {
            return numbro(this.log.value_after[key]).format({mantissa: 0, thousandSeparated: false})
          } else {
            return numbro(this.log.value_after[key]).format({mantissa: 0, thousandSeparated: true})
          }
        } else {
          return this.log.value_after[key]
        }
      } else {
        return ''
      }
    },
    isValueBefore (key) {
      return this.valueBefore(key) !== '' || !!this.valueBefore(key)
    },
    isValueAfter (key) {
      return this.valueAfter(key) !== '' || !!this.valueAfter(key)
    },
  }
}
</script>

<style lang='scss'>
.comment-input-edit {
  height: 90vh;
}

.comment-input-edit .ql-container {
  height: calc(100% - 48px);
}

@media (min-width: 1024px) {
  .comment-input-edit {
    height: auto;
  }

  .comment-input-edit .ql-container {
    height: auto;
  }
}
</style>
