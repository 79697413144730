<template>
  <div class='rounded-b-lg'>
    <expense-entry-input-form
      :expense-entry='expenseEntry'
      :new-attachment.sync='newAttachment'
      @update-expense-entry='(entry) => updateExpenseEntryValue(entry)'
      class='p-4' />
    <div class='mt-4 flex flex-row justify-start items-center gap-x-2 bg-gray-100 p-4 rounded-b-lg text-sm'>
      <button class='px-6 py-3 px-12 bg-gray-900 border rounded-md border-transparent text-white opacity-75 hover:opacity-100 text-sm uppercase'
        @click='saveEditedExpenseEntry'>
        <save-icon class='inline-block h-5' style='margin-top: -5px;' />
        {{ $t('save') }}
      </button>
      <button class='px-4 py-3 border border-transparent hover:border-gray-400 rounded-md border-transparent text-gray-900 opacity-75 hover:opacity-100 text-sm uppercase'
        @click='doneEditing'>
         {{ $t('cancelAndClose') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'
import ExpenseEntryInputForm from '@/components/expenses/ExpenseEntryInputForm.vue'
import { SaveIcon }  from '@vue-hero-icons/outline'

export default {
  name: 'ExpenseEntryEdit',
  components: {
    ExpenseEntryInputForm,
    SaveIcon,
  },
  props: [
    'expenseEntry',
  ],
  data () {
    return {
      editingExpenseEntry: {
        id: 0,
        expense_date: new Date(),
        amount: 0,
        tax_amount: 0,
        charge_vat: false,
        expense_type: '',
        description: '',
        is_billable: false,
        payment_type: '',
        expense_attachments: [],
      },
      newAttachment: {
        id: 0,
        investment_id: 0,
        expense_id: 0,
        attachment: null,
        file_url: '',
        file_name: '',
        changed: false,
        rand: Math.random()
      },
    }
  },
  computed: {
    ...mapGetters('users', [
      'currentUserName',
      'currentUserEmail',
    ]),
  },
  methods: {
    ...mapActions('expenses', [
      'updateExpense',
      'createAttachment',
      'updateAttachment',
    ]),
    doneEditing () {
      this.resetNewAttachment()
      this.$emit('done-editing')
    },
    updateExpenseEntryValue (newExpenseEntry) {
      this.editingExpenseEntry = newExpenseEntry
    },
    resetNewAttachment () {
      this.newAttachment.id = 0
      this.newAttachment.investment_id = 0
      this.newAttachment.expense_id = 0
      this.newAttachment.attachment = null
      this.newAttachment.file_url = ''
      this.newAttachment.file_name = ''
      this.newAttachment.changed = false
      this.newAttachment.rand = Math.random()
    },
    submitAttachment (attachment) {
      if (attachment.id) {
        return this.updateAttachment(attachment)
      } else {
        return this.createAttachment(attachment)
      }
    },
    saveEditedExpenseEntry () {
      this.$confirm(this.$t('saveChangesConfirmDetailed', [this.currentUserName, this.currentUserEmail]), this.$t('warning'), {
        confirmButtonText: this.$t('confirmSave'),
        cancelButtonText: this.$t('cancel'),
        type: 'warning'
      }).then(() => {
        this.updateExpense(this.editingExpenseEntry).then(resp => {
          let attachmentsToUpdate = this.editingExpenseEntry.expense_attachments.filter(exp_att => exp_att.changed)
          if (this.newAttachment.changed) {
            this.newAttachment.expense_id = resp.id
            this.newAttachment.investment_id = resp.investment_id
            attachmentsToUpdate.push(this.newAttachment)
          }
          Promise.all(attachmentsToUpdate.map(attachment => this.submitAttachment(attachment))).then(() => {
            this.$message({
              type: 'success',
              message: this.$t('successfullySaved')
            })
            this.doneEditing()
          })
        })
      }).catch(() => {
      })
    },
  },
  mounted () {
    this.editingExpenseEntry = cloneDeep(this.expenseEntry)
  },
}
</script>
