<template>
  <div v-if='hasFields'
    class='pb-8'>
    <button v-if='hasHeaderField'
      class='uppercase text-sm text-gray-900 font-semibold py-2 w-full text-left' @click='toggleShowSectionFields' style='margin-left: -8px;'>
      <component :is='iconDirection' class='inline-block text-gray-600' style='margin-top: -2px;' />
      {{headerField.custom_field_name}}
      <span class='font-normal'>({{otherFieldsCount}})</span>
    </button>
    <div v-show='showSectionFields'>
      <custom-field
        v-for='customField in otherFieldTemplates'
        :key='`customFieldTemplate-${customField.id}`'
        :custom-field='customField'
        :is-horizontal='isHorizontal'
        :is-view-mode='drilldownIsViewMode'
        :class='fieldSpacing' />
    </div>
  </div>

</template>

<script>
import { mapState } from 'vuex'
import CustomField  from '@/components/custom_fields/CustomField.vue'
import { ChevronRightIcon, ChevronDownIcon }    from '@vue-hero-icons/solid'
import uniqBy from 'lodash/uniqBy'

export default {
  name: 'HeaderDividedCustomFields',
  components: {
    CustomField,
    ChevronRightIcon,
    ChevronDownIcon
  },
  props: [
    'fields',
    'isHorizontal'
  ],
  data () {
    return {
      showSectionFields: false,
    }
  },
  computed: {
    ...mapState('investments', [
      'drilldownIsViewMode',
    ]),
    fieldSpacing () {
      return (this.drilldownIsViewMode) ? 'my-1' : 'my-4'
    },
    hasFields () {
      return this.fields && this.fields.length > 0
    },
    hasHeaderField () {
      return this.otherFields.length < this.fields.length
    },
    headerField () {
      return this.fields.find(field => field.field_type === 'sections')
    },
    iconDirection () {
      return (this.showSectionFields) ? 'chevron-down-icon' : 'chevron-right-icon'
    },
    otherFieldsCount () {
      return this.otherFields.filter(field => field.field_type !== 'header').length
    },
    otherFieldTemplates () {
      return uniqBy(this.otherFields.filter(field => field.field_type !== 'sections'), 'id')
    },
    otherFields () {
      return this.fields.filter(field => field.field_type !== 'sections')
    }
  },
  methods: {
    toggleShowSectionFields () {
      this.showSectionFields = !this.showSectionFields
    }
  },
  mounted () {
    this.showSectionFields = !this.hasHeaderField
  }
}
</script>
