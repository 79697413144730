import base from './base'

const getDashboardViews = () => base
  .get(`/dashboard_views/`).then(resp => resp.data)

const getDashboardView = (dashboardViewId) => base
  .get(`/dashboard_views/${dashboardViewId}`).then(resp => resp.data)

const postDashboardView = (dashboardViewParams) => base
  .post('/dashboard_views/', dashboardViewParams).then(resp => resp.data)

const updateDashboardView = (dashboardViewId, dashboardViewParams) => base
  .patch(`dashboard_views/${dashboardViewId}`, {
    dashboard_view: dashboardViewParams}).then(resp => resp.data)

const deleteDashboardView = (dashboardViewId) => base
  .delete(`/dashboard_views/${dashboardViewId}`).then(resp => resp.data)

export default {
  getDashboardViews: getDashboardViews,
  getDashboardView: getDashboardView,
  postDashboardView: postDashboardView,
  updateDashboardView: updateDashboardView,
  deleteDashboardView: deleteDashboardView,
}
