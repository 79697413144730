<template>
  <div v-if='!isCaseInvoice'
    class='lg:pt-8'>
    <h2 class='text-lg font-semibold uppercase'>
      <router-link
        :to='{ path: "drilldown", query: {investment_id: this.editingInvoice.investment_id, tab: "metadata"} }'
        class='hover:underline' target='_blank'>
        {{editingInvoice.investment_name}}
      </router-link>
    </h2>
    <h3 class='text-xs text-gray-800'>
      {{editingInvoice.investment_casenum}}
    </h3>
    <h3 class='text-sm text-gray-800'>
      {{editingInvoice.entity_name}}
      <span v-if='hasCounterparty'>
        <span class='inline-block px-2 text-gray-600'>v.</span>
        {{editingInvoice.investment_counterparty}}
      </span>
    </h3>
  </div>
</template>

<script>
import { mapState } from 'vuex'


export default {
  name: 'CaseInvoiceDetailsTitle',
  computed: {
    ...mapState('invoices', [
      'editingInvoice',
    ]),
    isCaseInvoice () {
      return this.$router.currentRoute.name === 'Dashboard'
    },
    hasCounterparty () {
      return this.editingInvoice.investment_counterparty 
    }
  },
}
</script>

