import base from './base'

const getCases = (filters) => base
  .get('/lawyer_performance_reports/lawyer_cases/', { params: filters }).then(resp => resp.data)

const getCommissions = (filters) => base
  .get('/lawyer_performance_reports/commissions/', { params: filters }).then(resp => resp.data)

const getMyCommissions = (filters) => base
  .get('/lawyer_performance_reports/my_commissions/', { params: filters }).then(resp => resp.data)

const getPerformanceDetails = (filters) => base
  .get('/lawyer_performance_reports/performance_details/', { params: filters }).then(resp => resp.data)

const getMagamPerformanceDetails = (filters) => base
  .get('/lawyer_performance_reports/magam_performance_details/', { params: filters }).then(resp => resp.data)

const getDiffPerformanceDetails = (filters) => base
  .get('/lawyer_performance_reports/diff_performance_details/', { params: filters }).then(resp => resp.data)

const getMyPerformanceDetails = (filters) => base
  .get('/lawyer_performance_reports/my_performance_details/', { params: filters }).then(resp => resp.data)

const getOverview = (filters) => base
  .get('/lawyer_performance_reports/overview/', { params: filters }).then(resp => resp.data)

const getMyOverview = (filters) => base
  .get('/lawyer_performance_reports/my_overview/', { params: filters }).then(resp => resp.data)

const getTimesheets = (filters) => base
  .get('/lawyer_performance_reports/timesheets/', { params: filters }).then(resp => resp.data)

const getMyTimesheets = (filters) => base
  .get('/lawyer_performance_reports/my_timesheets/', { params: filters }).then(resp => resp.data)

const getLawyerCaseCount = (filters) => base
  .get('/lawyer_performance_reports/lawyer_case_count/', { params: filters }).then(resp => resp.data)

const getTeamOverview = (filters) => base
  .get('/lawyer_performance_reports/team_overview/', { params: filters }).then(resp => resp.data)

const getLawyerTimesheets = (filters) => base
  .get('/lawyer_performance_reports/lawyer_timesheets/', { params: filters }).then(resp => resp.data)

const getCardHistories = (filters) => base
  .get('/lawyer_performance_reports/card_histories/', { params: filters }).then(resp => resp.data)

const getExclusiveCosts = (filters) => base
  .get('/lawyer_performance_reports/exclusive_costs/', { params: filters }).then(resp => resp.data)

const getPerformanceRecord = (filters) => base
  .get('/lawyer_performance_reports/performance_record/', { params: filters }).then(resp => resp.data)

const getAssociateAmount = (filters) => base
  .get('/lawyer_performance_reports/associate_amount/', { params: filters }).then(resp => resp.data)

const getMyAssociateAmount = (filters) => base
  .get('/lawyer_performance_reports/my_associate_amount/', { params: filters }).then(resp => resp.data)

export default {
  getCases: getCases,
  getCommissions: getCommissions,
  getMyCommissions: getMyCommissions,
  getPerformanceDetails: getPerformanceDetails,
  getMagamPerformanceDetails: getMagamPerformanceDetails,
  getDiffPerformanceDetails: getDiffPerformanceDetails,
  getMyPerformanceDetails: getMyPerformanceDetails,
  getOverview: getOverview,
  getMyOverview: getMyOverview,
  getTimesheets: getTimesheets,
  getMyTimesheets: getMyTimesheets,
  getLawyerCaseCount: getLawyerCaseCount,
  getTeamOverview: getTeamOverview,
  getLawyerTimesheets: getLawyerTimesheets,
  getCardHistories: getCardHistories,
  getExclusiveCosts: getExclusiveCosts,
  getPerformanceRecord: getPerformanceRecord,
  getAssociateAmount: getAssociateAmount,
  getMyAssociateAmount: getMyAssociateAmount,
}
