<template>
  <div class='pb-8'>
    <h1 class='inline-block text-xl font-semibold uppercase'>
      {{ drilldownBillableCase.investment_name }}
    </h1>
    <table class='text-sm' v-if='listMode'>
      <thead>
        <tr class='font-semibold uppercase'>
          <th class='px-2 py-2 w-40 text-left'>{{ $t('billingDate') }}</th>
          <th class='px-2 py-2 w-48 border-r text-right'>{{ $t('billableAmountKrw') }}</th>
          <th class='px-2 py-2 w-40 text-left'>{{ $t('depositDate') }}</th>
          <th class='px-2 py-2 w-48 border-r text-right'>{{ $t('depositAmoutKrw') }}</th>
          <th class='text-right px-2 py-2'>{{ $t('status') }}</th>
          <th class='text-left px-2 py-2'>
            <button class='px-4 py-3 bg-black border rounded-md border-transparent text-white opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900'
              @click='addInvoice'>
              <plus-circle-icon class='inline-block' style='margin-top: -4px;' /> {{ $t('newInvoice') }}
            </button>
          </th>
        </tr>
      </thead>
      <tbody>
        <case-invoice-row
          v-for='invoice in invoices'
          :key='`case-invoices-${invoice.id}`'
          class='border-b border-t'
          @edit:invoice='switchToEditMode'
          :invoice='invoice' />
        <tr class='uppercase text-sm font-semibold'>
          <td class='px-2 py-2 text-right'>{{ $t('sum') }}</td>
          <td class='px-2 py-2 text-right'>{{totalAmount}}</td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
    <case-invoice-details v-else @cancel:invoice='switchToListMode' />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { PlusCircleIcon }       from '@vue-hero-icons/outline'
import CaseInvoiceRow from '@/components/invoices/CaseInvoiceRow.vue'
import CaseInvoiceDetails from '@/components/invoices/CaseInvoiceDetails.vue'
import numbro             from 'numbro'
import cloneDeep          from 'lodash/cloneDeep'
import dayjs from 'dayjs'

export default {
  name: 'InvoicesCaseInvoices',
  components: {
    CaseInvoiceDetails,
    CaseInvoiceRow,
    PlusCircleIcon,
  },
  data () {
    return {
      mode: 'list'
    }
  },
  watch: {
    drilldownInvestmentId: {
      handler: function (newId) {
        if (newId > 0) {
          this.getInvoices({ 
            investment_id: newId, 
            start_date: dayjs('2020-12-31').format('YYYY-MM-DD'), 
            end_date: dayjs('2099-12-31').format('YYYY-MM-DD') 
          })
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapState('invoices', [
      'drilldownBillableCase',
      'invoices',
      'newInvoice',
    ]),
    listMode () {
      return this.mode === 'list'
    },
    totalAmount () {
      let sum = 0
      if (this.invoices.length > 0) {
        sum = this.invoices.map(invoice => invoice.total_amount).reduce((a,b) => (a+b))
      }
      return numbro(sum).format({ thousandSeparated: true, mantissa: 1 })
    },
  },
  methods: {
    ...mapActions('invoices', [
      'caseInvoiceDetailsOpen',
      'createInvoice',
      'getInvoices',
      'resetDrilldownBillableCase'
    ]),
    addInvoice () {
      let invoice = cloneDeep(this.newInvoice)
      invoice.investment_id = this.drilldownBillableCase.id
      this.createInvoice(invoice).then(savedInvoice => {
        this.caseInvoiceDetailsOpen(savedInvoice)
      })
    },
    switchToEditMode (invoice) {
      this.mode = 'edit'
      this.caseInvoiceDetailsOpen(invoice)
    },
    switchToListMode () {
      this.mode = 'list'
    },
  },
  beforeDestroy () {
    this.resetDrilldownBillableCase()
  }
}
</script>
