import base from './base'

const getApprovalRequests = (filters={}) => base
  .get('/approval_requests/', {params: filters}).then(resp => resp.data)

const getApprovalRequest = (approvalId) => base
  .get(`/approval_requests/${approvalId}`).then(resp => resp.data)

const getApprovalRequestsForInvestment = (investmentId) => base
  .get(`/approval_requests/investment/?investment_id=${investmentId}`).then(resp => resp.data)

const getNeedMyApprovalResponse = (filters) => base
  .get('/approval_responses/', {params: filters}).then(resp => resp.data)

const getNeedMyApprovalResponseForInvestment = (investmentId=0) => base
  .get(`/approval_responses/investment_request/?investment_id=${investmentId}`).then(resp => resp.data)

const postApprovalRequest = (approvalParams) => base
  .post(`/approval_requests/?approval_response_user_id=${approvalParams.approval_response_user_id}`, approvalParams).then(resp => resp.data)

const updateApprovalRequest = (approvalId, approvalParams) => base
  .patch(`/approval_requests/${approvalId}`, approvalParams).then(resp => resp.data)

const deleteApprovalRequest = (approvalId) => base
  .delete(`/approval_requests/${approvalId}`).then(resp => resp.data)

const updateApprovalResponse = (approvalResponseParams) => base
  .patch(`/approval_responses/${approvalResponseParams.id}`, approvalResponseParams).then(resp => resp.data)


export default {
  deleteApprovalRequest: deleteApprovalRequest,
  getApprovalRequests: getApprovalRequests,
  getApprovalRequest: getApprovalRequest,
  postApprovalRequest: postApprovalRequest,
  updateApprovalRequest: updateApprovalRequest,
  updateApprovalResponse: updateApprovalResponse,
  getApprovalRequestsForInvestment: getApprovalRequestsForInvestment,
  getNeedMyApprovalResponseForInvestment: getNeedMyApprovalResponseForInvestment,
  getNeedMyApprovalResponse: getNeedMyApprovalResponse,
}
