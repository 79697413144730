import Vue from 'vue'
import Vuex from 'vuex'
import timesheetRatesApi from '@/api/v1/timesheet_rates'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

const state = () => ({
  timesheetRates: [],
})

const getters = {
  timesheetRatesCount: state => {
    return state.timesheetRates.length
  },
  getField,
}

const actions = {
  getTimesheetRates ({ commit }) {
    return new Promise((resolve) => {
      timesheetRatesApi.getTimesheetRates().then(resp => {
        commit('setTimesheetRates', resp)
        resolve(resp)
      })
    })
  },
}

const mutations = {
  setTimesheetRates (state, fromApi) {
    state.timesheetRates = fromApi
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
