<template>
  <div>
    <div class='mt-4 flex flex-row justify-start leading-10'>
      <label class='w-1/6'>
        {{ $t('customerName') }}
        <span v-if='!nameValid' class='text-red-500 py-2 rounded'>*</span>
      </label>
      <input class='form-input flex-grow' v-model='drilldownEntityName'>
    </div>
    <div class='mt-4 flex flex-row justify-start leading-10'>
      <label class='w-1/6'>
        {{ $t('corpType') }}
        <span v-if='!entityTypeValid' class='text-red-500 py-2 rounded'>*</span>
      </label>
      <select v-model='drilldownEntityEntityType' class='form-select'>
        <option value='기업'>{{ $t('corporation') }}</option>
        <option value='공기업'>{{ $t('publicCorp') }}</option>
        <option value='공공기관'>{{ $t('publicInstitution') }}</option>
        <option value='개인'>{{ $t('personalBusiness') }}</option>
      </select>
    </div>
    <div v-if='isCompanyType'>
      <div class='mt-4 flex flex-row justify-start leading-10'>
        <label class='inline-block w-1/6'>
          {{ $t('businessRegNo') }}
          <span v-if='!businessLicenseNumberValid' class='text-red-500 py-2 rounded'>*</span>
        </label>
        <input class='form-input w-1/4' v-model='drilldownEntityBusinessLicenseNumber' placeholder='000-00-00000' pattern='\d{3}-\d{2}-\d{5}' v-on:keyup='setVerificationTimer'>
        <span v-if='businessLicenseVerificationValid' class='ml-4 py-2'><check-icon class='text-green-300' /></span>
        <span v-else-if='businessLicenseVerificationVerifying' class='ml-4 text-sm'>...</span>
        <span v-else-if='businessLicenseVerificationInvalid' class='ml-4 text-sm text-red-300'>{{ $t('alreadyExists') }}</span>
      </div>
      <div class='mt-4 flex leading-10'>
        <label class='w-1/6'>
          {{ $t('companyType') }}
          <span v-if='!companyTypeValid' class='text-red-500 py-2 rounded'>*</span>
        </label>
        <select v-model='drilldownEntityCompanyType' class='form-select'>
          <option value='주식회사'>{{ $t('stockCompany') }}</option>
          <option value='유한회사'>{{ $t('limitedCompany') }}</option>
          <option value='기타법인'>{{ $t('otherCorp') }}</option>
        </select>
      </div>
      <div class='mt-4 flex leading-10'>
        <label class='inline-block w-1/6'>회사</label>
        <select v-model='drilldownEntityCompanyId' class='form-select'>
          <option 
            v-for='company in companies' 
            :value='company.id'
            :key='company.id'
          >{{ company.company_name }}</option>
        </select>
      </div>
      <div class='mt-4 flex leading-10'>
        <label class='inline-block w-1/6'>{{ $t('ceoName') }}</label>
        <input class='form-input w-1/4' v-model='drilldownEntityCeoName'>
      </div>
      <div class='mt-4 flex leading-10'>
        <label class='inline-block w-1/6'>{{ $t('businessConditions') }}</label>
        <input class='form-input w-1/4' v-model='drilldownEntityBusinessConditions'>
      </div>
      <div class='mt-4 flex leading-10'>
        <label class='inline-block w-1/6'>{{ $t('event') }}</label>
        <input class='form-input w-1/4' v-model='drilldownEntityBusinessItem'>
      </div>
    </div>
    <div v-else>
      <div class='mt-4 flex leading-10'>
        <label class='inline-block w-1/6'>{{ $t('englishName') }}</label>
        <input class='form-input w-1/4' v-model='drilldownEntityEnglishClientName'>
      </div>
      <div class='mt-4 flex leading-10'>
        <label class='inline-block w-1/6'>{{ $t('birthdayYYMMDD') }}</label>
        <input type='numeric' class='form-input w-1/8' v-model='drilldownEntityBirthDate' pattern='\d{6}' placeholder='000000'>
      </div>
    </div>
    <div class='mt-4 flex leading-10'>
      <label class='inline-block w-1/6'>{{ $t('address') }}</label>
      <input class='form-input w-5/6' v-model='drilldownEntityAddress'>
    </div>
    <div class='mt-4 flex leading-10'>
      <label class='inline-block w-1/6'>{{ $t('currency') }}</label>
        <select v-model='drilldownEntityCurrency' class='form-select'>
          <option label='KRW'>KRW</option>
          <option label='USD'>USD</option>
          <option label='EUR'>EUR</option>
          <option label='CNY'>CNY</option>
          <option label='HKD'>HKD</option>
          <option label='TWD'>TWD</option>
          <option label='GBP'>GBP</option>
          <option label='CAD'>CAD</option>
          <option label='AUD'>AUD</option>
          <option label='JPY'>JPY</option>
        </select>
    </div>
    <div class='mt-4 flex leading-10'>
      <label class='inline-block w-1/6'>
        <input type='checkbox' class='form-checkbox' v-model='drilldownEntityIsSurtax'>
        {{ $t('vat') }}
      </label>
    </div>
    <div class='mt-4 flex leading-10'>
      <label class='inline-block w-1/6'>{{ $t('note') }}</label>
      <textarea
        class='text-textarea w-5/6 border rounded-md px-4 py-1 h-32 leading-tight'
        v-model='drilldownEntityEntityComment'
        :placeholder="$t('referenceDetails')">
      </textarea>
    </div>
  </div>
</template>

<script>
import { mapFields }  from 'vuex-map-fields'
import { mapState, mapActions, mapGetters } from 'vuex'
import { CheckIcon } from 'vue-feather-icons'

export default {
  name: 'EntityDataEditFields',
  components: {
    CheckIcon,
  },
  data () {
    return {
      businessLicenseVerficationState: 'idle', // idle, verifying, valid, invalid
      verificationTimer: null
    }
  },
  watch: {
    drilldownEntityCompanyId: {
      handler (newVal) {
        if (newVal) {
          let company = this.companies.find(company => company.id === newVal)
          if (company) {
            this.drilldownEntityCompanyName = company.company_name
          }
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapFields('entities', {
      drilldownEntityName: 'drilldownEntity.entity_name',
      drilldownEntityAddress: 'drilldownEntity.address',
      drilldownEntityCountry: 'drilldownEntity.country',
      drilldownEntityCeoName: 'drilldownEntity.ceo_name',
      drilldownEntityBusinessConditions: 'drilldownEntity.business_conditions',
      drilldownEntityBusinessItem: 'drilldownEntity.business_item',
      drilldownEntityCompanyType: 'drilldownEntity.company_type',
      drilldownEntityCompanyId: 'drilldownEntity.company_id',
      drilldownEntityCompanyName: 'drilldownEntity.company_name',
      drilldownEntityCurrency: 'drilldownEntity.currency',
      drilldownEntityEntityComment: 'drilldownEntity.entity_comment',
      drilldownEntityEntityType: 'drilldownEntity.entity_type',
      drilldownEntityIsSurtax: 'drilldownEntity.is_surtax',
      drilldownEntityBusinessLicenseNumber: 'drilldownEntity.business_license_number',
      drilldownEntityEnglishClientName: 'drilldownEntity.english_client_name',
      drilldownEntityBirthDate: 'drilldownEntity.birth_date',
      drilldownEntityResidentNumber: 'drilldownEntity.resident_number',
    }),
    ...mapState('companies', [
      'companies',
    ]),
    ...mapGetters('entities', [
      'businessLicenseNumberValid',
      'companyTypeValid',
      'entityTypeValid',
      'isCompanyType',
      'nameValid',
    ]),
    businessLicenseVerificationValid () {
      return this.businessLicenseVerficationState === 'valid'
    },
    businessLicenseVerificationVerifying () {
      return this.businessLicenseVerficationState === 'verifying'
    },
    businessLicenseVerificationInvalid () {
      return this.businessLicenseVerficationState === 'invalid'
    },
  },
  methods: {
    ...mapActions('entities', [
      'getBusinessLicenseNumberValid',
    ]),
    setVerificationTimer () {
      if (this.verificationTimer) {
        clearTimeout(this.verificationTimer)
        this.verificationTimer = null
      }
      this.verificationTimer = setTimeout(() => {
        this.verifyBusinessLicense()
      }, 300)
    },
    verifyBusinessLicense () {
      if (this.drilldownEntityBusinessLicenseNumber) {
        this.businessLicenseVerficationState = 'verifying'
        this.getBusinessLicenseNumberValid().then(resp => {
          if (resp.valid) {
            this.businessLicenseVerficationState = 'valid'
          } else {
            this.businessLicenseVerficationState = 'invalid'
          }
        })
      } else {
        this.businessLicenseVerficationState = 'idle'
      }
    },
  },
}
</script>

<style lang='scss' scoped>
  .form-label {
    @apply block font-normal mb-2 text-gray-600 text-sm tracking-wide uppercase;
  }
</style>
