<template>
  <div tabindex='0'
    @keydown.esc='navMenuClose'>
    <transition name='fade'>
      <nav v-if='showNavMenu' class='fixed top-0 left-0 h-full bg-white shadow-2xl p-12 text-left z-30 overflow-y-auto flex flex-col justify-between' style='width:420px;'>
        <ul class=' uppercase text-sm tracking-wide'>
          <li>
            <img :src='logoURL' class='mb-24 w-24' />
          </li>
          <li>
            <router-link :to='{ name: "Dashboard" }' class='nav-link' @click.native='clickNavMenuLink'>
              <view-grid-icon class='inline-block mr-2' style='margin-top: -4px;' /> {{ $t('manageCases') }}
            </router-link>
          </li>
          <li v-if='isLawyer'>
            <router-link :to='{ name: "MyCases" }' class='nav-link' @click.native='clickNavMenuLink'>
              <paper-clip-icon class='inline-block mr-2' style='margin-top: -4px;' /> {{ $t('myCases') }}
            </router-link>
          </li>
          <li v-if='isAdmin || isLawyer || isSecretary'>
            <router-link :to='{ name: "Schedules" }' class='nav-link' @click.native='clickNavMenuLink'>
              <calendar-icon class='inline-block mr-2' style='margin-top: -4px;' /> {{ $t('schedule') }}
            </router-link>
          </li>
          <li v-if='(isAdmin || isLeaderGroup || isSecretary)'>
            <router-link :to='{ name: "Deliveries" }' class='nav-link' @click.native='clickNavMenuLink'>
              <collection-icon class='inline-block mr-2' style='margin-top: -4px;' /> {{ $t('manageSchedule') }}
            </router-link>
          </li>
          <li v-if='(isAdmin || isLeaderGroup)'>
            <router-link :to='{ name: "RequiredItems" }' class='nav-link' @click.native='clickNavMenuLink'>
              <collection-icon class='inline-block mr-2' style='margin-top: -4px;' /> {{ $t('requiredInfo') }}
            </router-link>
          </li>
          <li>
            <router-link :to='{ name: "MyApprovals" }' class='nav-link' @click.native='clickNavMenuLink'>
              <clipboard-check-icon class='inline-block mr-2' style='margin-top: -4px;' /> {{ $t('approvalHistory') }}
            </router-link>
          </li>
          <li v-if='isLawyer'>
            <router-link :to='{ name: "Timesheet" }' class='nav-link' @click.native='clickNavMenuLink'>
              <clock-icon class='inline-block mr-2' style='margin-top: -4px;' /> {{ $t('myTimesheet') }}
            </router-link>
          </li>
          <li>
            <router-link :to='{ name: "Invoices" }' class='nav-link' @click.native='clickNavMenuLink'>
              <currency-dollar-icon class='inline-block mr-2' style='margin-top: -4px;' /> {{ $t('manageInvoices') }}
            </router-link>
          </li>
          <li>
            <router-link :to='{ name: "Expenses" }' class='nav-link' @click.native='clickNavMenuLink'>
              <credit-card-icon class='inline-block mr-2' style='margin-top: -4px;' /> {{ $t('manageExpenses') }}
            </router-link>
          </li>
          <li>
            <router-link :to='{ name: "Deposits" }' class='nav-link' @click.native='clickNavMenuLink'>
              <library-icon class='inline-block mr-2' style='margin-top: -4px;' /> {{ $t('manageDeposits') }}
            </router-link>
          </li>
          <li v-if='isLawyer'>
            <router-link :to='{ name: "LawyerMyPerformanceReport" }' class='nav-link' @click.native='clickNavMenuLink'>
              <document-report-icon class='inline-block mr-2' style='margin-top: -4px;' /> {{ $t('myStatistics') }}
            </router-link>
          </li>
          <li>
            <router-link :to='{ name: "History" }' class='nav-link' @click.native='clickNavMenuLink'>
              <information-circle-icon class='inline-block mr-2' style='margin-top: -4px;' /> {{ $t('updateHistory') }}
            </router-link>
          </li>
          <li class='mt-12'>
            <router-link :to='{ name: "Entities" }' class='nav-link' @click.native='clickNavMenuLink'>
              <briefcase-icon class='inline-block mr-2' style='margin-top: -4px;' /> {{ $t('manageClients') }}
            </router-link>
          </li>
          <li v-if='isAdmin'>
            <router-link :to='{ name: "CustomFieldTemplates" }' class='nav-link' @click.native='clickNavMenuLink'>
              <server-icon class='inline-block mr-2' style='margin-top: -6px;' /> {{ $t('userColumns') }}
            </router-link>
          </li>
          <li v-if='isTeamLead || isAdmin'>
            <router-link :to='{ name: "ManageAccess" }' class='nav-link' @click.native='clickNavMenuLink'>
              <users-icon class='inline-block mr-2' style='margin-top: -6px;' /> {{ $t('manageRecommendedScreen') }}
            </router-link>
          </li>
          <li v-if='(isBilling || isAdmin || isLeaderGroup || isPartner)'>
            <router-link :to='{ name: "LawyerPerformanceReport" }' class='nav-link' @click.native='clickNavMenuLink'>
              <document-report-icon class='inline-block mr-2' style='margin-top: -4px;' /> {{ $t('lawyerResult') }}
            </router-link>
          </li>
          <li v-if='(isBilling || isAdmin)'>
            <router-link :to='{ name: "MagamPerformances" }' class='nav-link' @click.native='clickNavMenuLink'>
              <document-report-icon class='inline-block mr-2' style='margin-top: -4px;' /> {{ $t('magam') }}
            </router-link>
          </li>
          <li v-if='isAdmin'>
            <router-link :to='{ name: "CasePerformanceReport" }' class='nav-link' @click.native='clickNavMenuLink'>
              <document-report-icon class='inline-block mr-2' style='margin-top: -4px;' /> {{ $t('performanceData') }}
            </router-link>
          </li>
          <li>
            <router-link :to='{ name: "MyPreferences" }' class='nav-link' @click.native='clickNavMenuLink'>
              <user-icon class='inline-block mr-2' style='margin-top: -4px;' /> {{ $t('myPreferences') }}
            </router-link>
          </li>
        </ul>
        <logout-button class='text-left pl-8 mt-16' />
      </nav>
    </transition>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import {
  BriefcaseIcon,
  ClockIcon,
  CurrencyDollarIcon,
  CreditCardIcon,
  ClipboardCheckIcon,
  CollectionIcon,
  DocumentReportIcon,
  InformationCircleIcon,
  LibraryIcon,
  ServerIcon,
  UserIcon,
  UsersIcon,
  ViewGridIcon,
  PaperClipIcon,
  CalendarIcon,
} from '@vue-hero-icons/outline'
import LogoutButton from '@/components/LogoutButton.vue'

export default {
  name: 'NavMenu',
  components: {
    BriefcaseIcon,
    ClockIcon,
    CollectionIcon,
    CurrencyDollarIcon,
    CreditCardIcon,
    DocumentReportIcon,
    InformationCircleIcon,
    LibraryIcon,
    ServerIcon,
    UserIcon,
    UsersIcon,
    ViewGridIcon,
    ClipboardCheckIcon,
    LogoutButton,
    PaperClipIcon,
    CalendarIcon,
  },
  data () {
    return {
      logoURL: process.env.VUE_APP_CLIENT_BASE_URL + '/logo_reach_horizontal.svg',
      showCodeSubmenu: false,
      showTradingSubmenu: true,
      showScheduleSubmenu: false,
    }
  },
  computed: {
    ...mapState([
      'showNavMenu'
    ]),
    ...mapGetters('users', [
      'isAdmin',
      'isTeamLead',
      'isLawyer',
      'isBilling',
      'isSecretary',
      'isBbridgeAdmin',
      'isLeaderGroup',
      'isPartner',
    ]),
  },
  methods: {
    ...mapActions([
      'sidepanelClose',
      'navMenuClose',
      'searchClose',
    ]),
    ...mapActions('users', [
      'getUsers',
    ]),
    clickNavMenuLink () {
      this.searchClose()
      this.sidepanelClose()
      this.navMenuClose()
    },
  },
  mounted () {
    this.getUsers()
  }
}
</script>

<style scoped lang='scss'>
.nav-link {
  @apply transition duration-150 ease-in-out block py-5 opacity-50;
}

.nav-link.router-link-active {
  @apply opacity-100;
  background-color: hsla(204, 41%, 47%, 0.1);
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  margin-left: -1.5rem;
  padding-left: 1.5rem;
  margin-right: -4rem;
  padding-right: 4rem;
}

.nav-link:hover {
  @apply opacity-100;
}

.fade-enter-active,
.fade-leave-active {
  transition: all .2s;
}

nav.fade-enter,
nav.fade-leave-to {
  opacity: 0;
  left: -420px;
}

</style>
