<template>
  <div>
    <history-filters @change-options='changeOptions' @change-filter-params='changeFilterParams' class='mb-4 text-xs' />
    <comment-input :investment-id='drilldownInvestmentId'></comment-input>
    <el-timeline v-if='hasLogs'>
      <timeline-item
        v-for='log in filteredLogs'
        :key='`changeLogs-${log.id}`'
        :log='log'>
        <change-log :log='log' />
      </timeline-item>
    </el-timeline>
    <div v-else class='mx-auto w-64 text-center'>
      {{ $t('emptyFilters') }}
    </div>
  </div>
</template>

<script>
import ChangeLog       from '@/components/investment/ChangeLog.vue'
import TimelineItem    from '@/components/investment/TimelineItem.vue'
import CommentInput    from '@/components/CommentInput.vue'
import HistoryFilters  from '@/views/dashboard/HistoryFilters.vue'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'InvestmentHistory',
  components: {
    ChangeLog,
    CommentInput,
    HistoryFilters,
    TimelineItem,
  },
  data () {
    return {
      filterParams: {
        filterText: ['comment'],
        customFieldTemplateId: 0,
        drilldownInvestmentId: 0
      },
      checkedOptions: ['comment'],
    }
  },
  watch: {
    drilldownInvestmentId: {
      handler: function (newId) {
        this.filterParams.drilldownInvestmentId = newId
        this.filterChangeLogs(this.filterParams)
      },
      immediate: true
    },
  },
  computed: {
    ...mapGetters('investments', [
      'drilldownInvestmentId'
    ]),
    ...mapGetters('changeLogs', [
      'getColumnName',
    ]),
    ...mapState('changeLogs', [
      'changeLogs',
    ]),
    filteredLogs () {
      return this.changeLogs.filter(log => {
        if (['invoice', 'expense', 'deposit_to_invoices'].includes(log.event_object)) {
          return Object.keys(log.value_after).filter(key => this.getColumnName(log.event_object, key) !== '').length > 0 ? log : ''
        } else {
          return log
        }
      })
    },
    hasLogs () {
      return this.filteredLogs.length > 0
    },
  },
  methods: {
    ...mapActions('changeLogs', [
      'filterChangeLogs',
    ]),
    changeOptions (checkedOptions) {
      this.checkedOptions = checkedOptions
    },
    changeFilterParams (filterParams) {
      this.filterChangeLogs(filterParams)
    },
  },
}
</script>
