<template>
  <v-select
    v-model='selectedUserId'
    @input='updateField'
    label='text'
    :reduce='user => user.value'
    :options='userOptions'
    :close-on-select='true'
    class='relative form-select'
    style='width:12rem;'>
    <template v-slot:option='option'>
      <span>{{ option.text }} ({{option.email}})</span>
    </template>
  </v-select>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'TableTypeCustomFieldUser',
  props: ['field', 'row', 'rowIndex', 'colIndex', 'colKey'],
  data () {
    return {
      selectedUserId: this.row[this.colKey]
    }
  },
  computed: {
    ...mapState('users', [
      'users',
    ]),
    userOptions () {
      let usersByRole = (this.field.table_headers[parseInt(this.colIndex)].role)
                          ? this.users.filter(user => this.field.table_headers[parseInt(this.colIndex)].role &&
                                              user.roles.includes(this.field.table_headers[parseInt(this.colIndex)].role))
                          : this.users
      return usersByRole.map(user => {
        return {
          text: user.name,
          value: user.id,
          email: user.email
        }
      })
    }
  },
  methods: {
    updateField () {
      this.$emit('update-value-json', this.rowIndex, this.colKey, this.selectedUserId)
      this.$emit('blur')
    },
  },
}
</script>
