<template>
  <div class='flex flex-row justify-start'>
    <select v-model='selectedCustomFieldTemplate'
      class='form-select w-40'>
      <option
        v-for='template in selectableFieldTypes'
        :key='`parent-template-${template.id}-${template.custom_field_name}`'
        :value='template'
        :disabled='template.disabled'>
        {{template.custom_field_name}}
      </option>
      <option
        v-for='template in filterableCustomFields'
        :key='`parent-template-${template.id}`'
        :disabled='isOrganizingCustomField(template.field_type)'
        :value='template'>
        <span v-if='isOrganizingCustomField(template.field_type)'>----</span>
        {{template.custom_field_name}}
        <span v-if='isOrganizingCustomField(template.field_type)'>----</span>
      </option>
    </select>

    <select v-if='hasFieldType'
      v-model='selectedOperator'
      class='form-select w-20'>
      <option v-for='operator in operators' :key='`operator-${operator}`'>
        {{operator}}
      </option>
    </select>

    <input v-if='isTextType'
      type='text'
      v-model='selectedValue'
      class='form-input flex-grow w-full h-12 block'/>

    <input v-else-if='isNumberType'
      type='number'
      v-model.number='selectedValue'
      class='form-input flex-grow w-full h-12 block'/>

    <select v-else-if='isSelectType'
      v-model='selectedValues'
      multiple
      class='form-select' style='width: calc(100% - 18rem);'>
      <option
        v-for='selectableValue in selectableTemplateValues'
        :key='`parent-template-value-${selectableValue}`'>
        {{selectableValue}}
      </option>
    </select>

    <el-date-picker v-else-if='isDateType'
      v-model='selectedValue'
      format='yyyy-MM-dd'
      type='date'
      class='rule-date-picker'
      :placeholder="$t('pickDay')">
    </el-date-picker>

    <select v-else-if='isContactType'
      v-model='selectedValues'
      multiple
      class='form-select'
      style='width: calc(100% - 18rem);'>
      <option
        v-for='contact in contacts'
        :key='`parent-template-value-${contact.id}`'
        :value='contact.id'>
        {{contact.contact_name}} ({{contact.email}})
      </option>
    </select>

    <user-search v-else-if='isUserType'
        :user-ids='selectedValues'
        :user-names='userNames'
        :is-multiple='true'
        style='width: calc(100% - 18rem);'
        @update-selected-user='updateSelectedUsers' />

    <div v-else class='pl-4 py-3 flex-grow text-gray-500 text-sm border-gray-100 bg-gray-100'>
      {{ $t('selectField') }}
    </div>

    <button
      v-if='hasFieldType'
      class='border p-3 bg-gray-200 rounded-r-md text-sm text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md disabled:cursor-not-allowed'
      style='margin-left: -2px;'
      :disabled='disableAddCondition'
      @click='addRule'>
      <plus-circle-icon class='inline-block' style='margin-top: -2px;' />
    </button>
  </div>
</template>
<script>
import { PlusCircleIcon } from '@vue-hero-icons/outline'
import { mapState, mapGetters } from 'vuex'
import EventBus   from '@/utils/event-bus'
import UserSearch from '@/components/UserSearch.vue'

export default {
  name: 'DashboardViewEditingFiltersCreateRule',
  components: {
    PlusCircleIcon,
    UserSearch
  },
  data () {
    return {
      numberOperators: [
        '=',
        '≠',
        '>',
        '≥',
        '<',
        '≤',
      ],
      stringOperators: [
        'includes',
        'excludes',
      ],
      dateOperators: [
        'before',
        'after',
      ],
      selectableFieldTypes: [
        {id: 0, field_type: 'user', custom_field_name: 'user type'},
        {id: 0, field_type: 'contact', custom_field_name: 'contact type'},
        {id: 0, field_type: 'disabled', custom_field_name: '--------------', disabled: true},
      ],
      selectedCustomFieldTemplate: {},
      selectedOperator: '',
      selectedValue: null,
      selectedValues: [],
      userNames: '',
    }
  },
  watch: {
    selectedCustomFieldTemplate () {
      this.selectedOperator = ''
      this.selectedValue = null
    },
  },
  computed: {
    ...mapState('contacts', [
      'contacts',
    ]),
    ...mapGetters('customFields', [
      'filterableCustomFields'
    ]),
    disableAddCondition () {
      return this.selectedOperator === '' // assume to have an operator, you need to have a template
    },
    hasFieldType () {
      return this.selectedCustomFieldTemplate.field_type &&
             this.selectedCustomFieldTemplate.field_type !== ''
    },
    fieldType () {
      return (this.hasFieldType) ? this.selectedCustomFieldTemplate.field_type : ''
    },
    hasSelectedTemplate () {
      return this.selectedCustomFieldTemplate != {}
    },
    selectableTemplateValues () {
      return this.selectedCustomFieldTemplate.selectable_values
    },
    isSelectType () {
      return this.hasFieldType && ['dropdown', 'checkbox', 'multi-select'].includes(this.fieldType)
    },
    isDateType () {
      return this.hasFieldType && ['date', 'date-range'].includes(this.fieldType)
    },
    isTextType () {
      return this.hasFieldType && ['text', 'paragraph'].includes(this.fieldType)
    },
    isNumberType () {
      return this.hasFieldType && ['number'].includes(this.fieldType)
    },
    isContactType () {
      return this.hasFieldType && this.fieldType === 'contact'
    },
    isUserType () {
      return this.hasFieldType && this.fieldType === 'user'
    },
    operators () {
      switch (this.fieldType) {
        case 'number':
          return this.numberOperators
        case 'date':
        case 'date-range':
          return this.dateOperators
        case 'paragraph':
        case 'text':
        case 'url':
          return this.stringOperators
        case 'dropdown':
        case 'checkbox':
        case 'multi-select':
        case 'contact':
        case 'user':
          return this.stringOperators // TODO (Hana: 2021-4-28) maybe these should be different?
        default:
          return []
      }
    },
    ruleIsNotEmpty () {
      return (this.selectedCustomFieldTemplate.id ||
              this.selectedCustomFieldTemplate.id !== undefined) &&
             this.selectedCustomFieldTemplate.field_type &&
             this.selectedOperator &&
             (this.selectedValue || this.selectedValues.length > 0)
    },
  },
  methods: {
    addRule () {
      if (this.ruleIsNotEmpty) {
        if (this.selectedValues.length > 0) {
          let newRule = {
            class: 'custom_field_template',
            custom_field_template_id: this.selectedCustomFieldTemplate.id,
            custom_field_type: this.selectedCustomFieldTemplate.field_type,
            custom_field_values: this.selectedValues,
            operator: this.selectedOperator
          }
          this.$emit('update:addrule', newRule)
        } else {
          let newRule = {
            class: 'custom_field_template',
            custom_field_template_id: this.selectedCustomFieldTemplate.id,
            custom_field_type: this.selectedCustomFieldTemplate.field_type,
            custom_field_value: this.selectedValue,
            operator: this.selectedOperator
          }
          this.$emit('update:addrule', newRule)
        }

        this.resetInput()
      }
    },
    checkRuleAdd () {
      if (this.ruleIsNotEmpty) {
        this.addRule()
      }
    },
    isOrganizingCustomField (fieldType) {
      return fieldType === 'sections' || fieldType === 'header'
    },
    resetInput () {
      this.selectedOperator = ''
      this.selectedCustomFieldTemplate = {}
      this.selectedValue = null
      this.selectedValues = []
    },
    updateSelectedUsers (ids, nameAndEmails) {
      this.selectedValues = ids
      this.userNames = nameAndEmails
    }
  },
  mounted () {
    EventBus.$on('check-add-rule', () => this.checkRuleAdd())
  },
  beforeDestroy () {
    EventBus.$off('check-add-rule')
  }
}
</script>
