<template>
  <div>
    <div class='mb-4 flex flex-row justify-start items-start'>
      <label class='inline-block w-32 flex-none leading-10'>{{ $t('prosecutionBodyInCharge') }}</label>
      <v-select
       class='flex-grow'
       :value='caseInfo.jurisdiction_name'
       @input='updateCaseInfo("jurisdiction_name", $event)'
       :options='prosecutorNameOptions'>
      </v-select>
    </div>
    <div class='mb-4 flex flex-row justify-start items-start'>
      <label class='inline-block w-32 flex-none leading-10'>{{ $t('prosecutionCaseNo') }}</label>
      <input
        class='form-input flex-grow'
        :value='caseInfo.jurisdiction_case_number'
        @input='updateCaseInfo("jurisdiction_case_number", $event.target.value)'>
    </div>
    <related-infos
      :title="$t('prosecutionInfo')"
      :headers='["담당검사", "호실", "수사관", "비고"]'
      :case-info='caseInfo'></related-infos>
    <jail-infos
      :title="$t('jailInfo')"
      :headers='["구치소", "수용번호"]'
      :case-info='caseInfo' />
    <div class='mb-4 flex flex-row justify-start items-start'>
      <label class='inline-block w-32 flex-none leading-10'>{{ $t('dispositionDate') }}</label>
      <el-date-picker
        v-model='decisionDate'
        @change='updateCaseInfo("decision_date", decisionDate)'
        type='date'
        value-format='yyyy-MM-dd'
        :placeholder="$t('pickDay')">
      </el-date-picker>
    </div>
    <div class='mb-4 flex flex-row justify-start items-start'>
      <label class='inline-block w-32 flex-none leading-10'>{{ $t('dispositionResult') }}</label>
      <input class='form-input flex-grow'
        :value='caseInfo.decision_result'
        @input='updateCaseInfo("decision_result", $event.target.value)'>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import RelatedInfos from '@/components/case_infos/RelatedInfos.vue'
import JailInfos    from '@/components/case_infos/JailInfos.vue'

export default {
  name: 'ProsecutionInfo',
  props: [
    'caseInfo',
  ],
  components: {
    RelatedInfos,
    JailInfos,
  },
  data() {
    return {
      decisionDate: '',
    }
  },
  watch: {
    'caseInfo.decision_date': {
      handler: function (newVal) {
        if (newVal) {
          this.decisionDate = newVal
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapGetters('caseJurisdictions', [
      'jurisdictionByCaseType'
    ]),
    prosecutorNameOptions () {
      return this.jurisdictionByCaseType('검찰')
    }
  },
  methods: {
    updateCaseInfo(key, value) {
      this.$emit('update-case-info', { key, value })
    },
  },
}
</script>
