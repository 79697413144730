<template>
  <div class='pb-8'>
    <div v-for='(info, index) in drilldownInvestmentCaseInfos'
      :key='`${info.id}-${info.rand}-${index}`'
      class='mb-4 border border-solid border-gray-100 relative rounded-md'>
      <case-info-header
        :info='info'
        class='mb-4 rounded-t-md' />
      <component
        :is='view(info.jurisdiction_type)'
        :case-info='info'
        @update-case-info='(update) => handleUpdateCaseInfo(index, update)'
        class='p-4 text-sm' />
    </div>
    <div class='mb-12 flex flex-row items-center gap-x-2 text-sm'>
      <label class='inline-block w-1/12'>{{ $t('jurisdiction') }}</label>
      <v-select
       class='w-40'
       v-model='selectedJurisdictionType'
       :options='jursidictionTypes'>
      </v-select>
      <button
        @click='addCaseInfo'
        class='px-3 py-2 rounded text-sm border border-transparent hover:border-blue-300 hover:shadow'>
        <plus-circle-icon class='inline-block text-gray-700' style='margin-top: -4px' />
        {{ $t('addNewCaseNo') }}
      </button>
    </div>
    <portal to='sidepanel-footer'>
      <div class='pl-8 pb-4 bg-gray-100'>
        <button
          class='mt-4 inline-block py-3 px-16 text-white rounded-md font-bold border border-transparent hover:border-blue-500 hover:shadow-md'
          style='background-color: #223645;'
          @click='save'>
          {{ $t('save') }}
        </button>
      </div>
    </portal>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState }         from 'vuex'
import { mapMultiRowFields }                          from 'vuex-map-fields'
import { PlusCircleIcon, XCircleIcon } from '@vue-hero-icons/outline'
import CaseInfoHeader     from '@/components/case_infos/CaseInfoHeader.vue'
import ArbitrationInfo    from '@/components/case_infos/ArbitrationInfo.vue'
import AdministrationInfo from '@/components/case_infos/AdministrationInfo.vue'
import CourtInfo          from '@/components/case_infos/CourtInfo.vue'
import LaborInfo          from '@/components/case_infos/LaborInfo.vue'
import ProsecutionInfo    from '@/components/case_infos/ProsecutionInfo.vue'
import PoliceInfo         from '@/components/case_infos/PoliceInfo.vue'
import TradeInfo          from '@/components/case_infos/TradeInfo.vue'
import TaxInfo            from '@/components/case_infos/TaxInfo.vue'

export default {
  name: 'InvestmentCaseInfos',
  components: {
    PlusCircleIcon,
    XCircleIcon,
    CaseInfoHeader,
    CourtInfo,
    ProsecutionInfo,
    PoliceInfo,
    TradeInfo,
    ArbitrationInfo,
    AdministrationInfo,
    TaxInfo,
    LaborInfo,
  },
  data () {
    return {
      jursidictionTypes: [
        '법원',
        '검찰',
        '경찰',
        '공정위',
        '중재/조정',
        '행정',
        '세무',
        '노동',
      ],
      selectedJurisdictionType: '',
    }
  },
  watch: {
    'drilldownInvestment.id': {
      handler: function () {
        this.getInvestmentCaseInfos(this.drilldownInvestment.id)
      },
      immediate: true
    },
  },
  computed: {
    ...mapMultiRowFields('investments', [
      'drilldownInvestmentCaseInfos'
    ]),
    ...mapState('caseJurisdictions', [
      'caseJurisdictions'
    ]),
    ...mapState('investments', [
      'drilldownInvestment'
    ]),
  },
  methods: {
    ...mapActions('caseJurisdictions', [
      'getCaseJurisdictions',
    ]),
    ...mapActions('investments', [
      'getInvestmentCaseInfos',
      'updateCaseInfos',
    ]),
    ...mapMutations('investments', [
      'addToDrilldownCaseInfos',
    ]),
    addCaseInfo () {
      let newInfo = {
        id: 0,
        jurisdiction_type: this.selectedJurisdictionType,
        jurisdiction_name: '',
        year: '',
        gubun: '',
        client_name: this.drilldownInvestment ? this.drilldownInvestment.entity_names : '',
        serial_number: '',
        related_infos: [],
        jurisdiction_case_number: '',
        decision_date: null,
        decision_result: '',
        warrant_or_bail: '',
        jail_infos: [],
        contact_id: 0,
        contact_name: '',
        rand: Math.random()
      }
      this.addToDrilldownCaseInfos(newInfo)
    },
    save () {
      this.updateCaseInfos()
    },
    view (type) {
      switch (type) {
        case '법원':
          return 'court-info'
        case '검찰':
          return 'prosecution-info'
        case '경찰':
          return 'police-info'
        case '공정위':
          return 'trade-info'
        case '중재/조정':
          return 'arbitration-info'
        case '행정':
          return 'administration-info'
        case '세무':
          return 'tax-info'
        case '노동':
          return 'labor-info'
        default:
          return 'court-info'
      }
    },
    handleUpdateCaseInfo(index, { key, value }) {
      this.$set(this.drilldownInvestmentCaseInfos[index], key, value)
    }
  },
  mounted () {
    // get jurisdictions when empty, reload only on refresh
    if (this.caseJurisdictions.length === 0) {
      this.getCaseJurisdictions()
    }
  },
}
</script>
