<template>
  <div>
    <h2 class='mb-2 text-lg font-semibold uppercase text-gray-800'>{{ $t('dashboardName') }}</h2>
    <input type='text' class='form-input w-full' v-model='dashboard_view_name' />
  </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
  name: 'DashboardViewEditingName',
  computed: {
    ...mapFields('dashboardViews', [
      'currentDashboardView.dashboard_view_name'
    ]),
  },
}
</script>
