<template>
  <div class='pb-8 overflow-x-auto'>
    <div v-if='drilldownCanViewBilling'>
      <header class='flex flex-row justify-between items-center'>
        <div class='text-lg uppercase font-semibold flex flex-row justify-start gap-x-4 items-center'>
          <h2 class='text-lg uppercase font-semibold'>
            {{ $t('notOnInvoice') }}
          </h2>
          <el-date-picker
            style='width: 15rem;'
            v-model='notOnInvoiceDateRange'
            type='daterange'
            range-separator='~'
            :start-placeholder="$t('startDate')"
            :end-placeholder="$t('endDate')"
            value-format='yyyy-MM-dd'
            size='large'
            :clearable='false'
            :picker-options='pickerOptions'
            @change='getCaseTimesheets'>
          </el-date-picker>
          <button v-if='hasTimeSheetEntriesNotOnInvoice'
            @click='copyToClipboard(filterNotOnInvoice)'
            class='text-xs uppercase pl-2 pr-3 hover:pr-4 py-2 rounded-md border border-transparent hover:border-gray-400 hover:shadow-md opacity-75 hover:opacity-100 flex flex-row items-center'>
            <table-icon class='h-4' />
            <span>{{ $t('copyExcel') }}</span>
          </button>
        </div>
        <button
          class='border py-1 lg:py-2 pl-2 pr-3 flex flex-row items-center gap-x-1 rounded-md border-transparent text-sm text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
          @click='openAddTimesheetEntry()'>
          <plus-circle-icon class='h-5' />
          {{ $t('add') }}
        </button>
      </header>
      <timesheet-entry-create v-if='showAddTimesheetEntry'
        class='my-8 py-8 rounded bg-gray-100'
        :default-user-position='currentUserPosition'
        @done-editing='closeAddTimesheetEntry' />
      <case-timesheets-no-invoice v-bind:filter-not-on-invoice.sync='filterNotOnInvoice'/>

      <header class='mt-8 flex flex-row justify-start gap-x-4 items-center'>
        <h2 class='text-lg uppercase font-semibold'>
          {{ $t('onInvoice') }}
        </h2>
        <date-range-filters @update-date-range='getTimesheetEntriesByDate' />
        <button v-if='hasTimeSheetEntriesOnInvoice'
          @click='copyToClipboard(filterWithInvoice, true)'
          class='text-xs uppercase pl-2 pr-3 hover:pr-4 py-2 rounded-md border border-transparent hover:border-gray-400 hover:shadow-md opacity-75 hover:opacity-100 flex flex-row items-center'>
          <table-icon class='h-4' />
          <span>{{ $t('copyExcel') }}</span>
        </button>
      </header>
      <case-timesheets-with-invoice v-bind:filter-with-invoice.sync='filterWithInvoice' class='' />
    </div>
    <no-billing-access v-else />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { PlusCircleIcon, TableIcon } from '@vue-hero-icons/outline'
import DateRangeFilters          from '@/components/DateRangeFilters.vue'
import NoBillingAccess           from '@/views/dashboard/NoBillingAccess.vue'
import TimesheetEntryCreate      from '@/views/timesheet/TimesheetEntryCreate.vue'
import CaseTimesheetsNoInvoice   from '@/views/timesheet/CaseTimesheetsNoInvoice.vue'
import CaseTimesheetsWithInvoice from '@/views/timesheet/CaseTimesheetsWithInvoice.vue'
import dayjs from 'dayjs'

export default {
  name: 'CaseTimesheet',
  components: {
    CaseTimesheetsNoInvoice,
    CaseTimesheetsWithInvoice,
    DateRangeFilters,
    NoBillingAccess,
    TimesheetEntryCreate,
    PlusCircleIcon,
    TableIcon,
  },
  data () {
    return {
      showAddTimesheetEntry: false,
      notOnInvoiceDateRange: [
        dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
        dayjs().format('YYYY-MM-DD')
      ],
      pickerOptions: {
        shortcuts: [{
          text: this.$t('today'),
          onClick (picker) {
            const end   = dayjs().format('YYYY-MM-DD')
            const start = dayjs().format('YYYY-MM-DD')
            picker.$emit('pick', [start, end])
          }
        }, {
          text: this.$t('lastWeek'),
          onClick (picker) {
            const end   = dayjs().format('YYYY-MM-DD')
            const start = dayjs().subtract(7, 'day').format('YYYY-MM-DD')
            picker.$emit('pick', [start, end])
          }
        }, {
          text: this.$t('lastMonth'),
          onClick (picker) {
            const end = dayjs().format('YYYY-MM-DD')
            const start = dayjs().subtract(1, 'month').format('YYYY-MM-DD')
            picker.$emit('pick', [start, end])
          }
        }, {
          text: this.$t('last3months'),
          onClick (picker) {
            const end = dayjs().format('YYYY-MM-DD')
            const start = dayjs().subtract(3, 'month').format('YYYY-MM-DD')
            picker.$emit('pick', [start, end])
          }
        }]
      },
      filterNotOnInvoice: [],
      filterWithInvoice: [],
    }
  },
  watch: {
    loadParams: {
      handler: function () {
        if (this.drilldownInvestmentId > 0 && this.drilldownCanViewBilling) {
          this.getCaseTimesheets()
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapState('investments', [
      'drilldownCanViewBilling'
    ]),
    ...mapGetters('investments', [
      'drilldownInvestmentId'
    ]),
    ...mapGetters('timesheetEntries', [
      'timesheetEntriesNotOnInvoiceCount',
      'timesheetEntriesWithInvoiceCount',
      'timesheetEntriesNotOnInvoice',
      'timesheetEntriesWithInvoice',
    ]),
    ...mapGetters('users', [
      'currentUserPosition',
    ]),
    loadParams () {
      return `${this.drilldownInvestmentId}-${this.drilldownCanViewBilling}`
    },
    hasTimeSheetEntriesOnInvoice () {
      return this.timesheetEntriesWithInvoiceCount > 0
    },
    hasTimeSheetEntriesNotOnInvoice () {
      return this.timesheetEntriesNotOnInvoiceCount > 0
    },
  },
  methods: {
    ...mapActions('timesheetEntries', [
      'getCaseTimesheetEntries',
      'getCaseTimesheetEntriesByDate',
    ]),
    getCaseTimesheets () {
      this.getCaseTimesheetEntries({
        investment_id: this.drilldownInvestmentId,
        start_date:    this.notOnInvoiceDateRange[0],
        end_date:      this.notOnInvoiceDateRange[1],
      })
    },
    getTimesheetEntriesByDate (dateRange) {
      this.getCaseTimesheetEntriesByDate({
        investment_id: this.drilldownInvestmentId,
        start_date:    dateRange.startDate,
        end_date:      dateRange.endDate,
        has_invoice:   true,
      })
    },
    closeAddTimesheetEntry () {
      this.showAddTimesheetEntry = false
      this.getCaseTimesheets()
    },
    openAddTimesheetEntry () {
      this.showAddTimesheetEntry = true
    },
    copyToClipboard (entries) {
      let headers = [this.$t('workDate'), this.$t('bill'), this.$t('workType'), this.$t('lawyer'), this.$t('position'), this.$t('performanceLawyer'), this.$t('timeCharge'), this.$t('workHours'), this.$t('billingTime'), this.$t('detailsWork')]
      let result = `${headers.join('\t')}\n`
      entries.sort((a, b) => {
        if (a.work_date > b.work_date) {
          return 1
        } else {
          return -1
        }
      }).forEach(entry => {
        let rawData = [entry.work_date, `${entry.invoice_name} (${entry.invoice_id})`,
          entry.work_type, entry.user_name, entry.user_position, entry.performance_lawyer,
          entry.rate, entry.work_hours, entry.billable_hours,
          entry.description.replace(/\r?\n|\r/g, '')]
        result += `${rawData.join('\t')}\n`
      })
      this.$copyText(result).then(() => {
        this.$message({
          type: 'success',
          message: this.$t('copiedToClipboard'),
        })
      })
    }
  },
}
</script>
