<template>
  <div class='pb-24 w-full overflow-x-auto px-4 lg:px-8'>
    <custom-field v-for='(field, index) in drilldownInvestmentFiles'
      :key='`investment-files-${index}`'
      :custom-field='field'
      :is-horizontal='true'
      :is-view-mode='false'
      class='border-b border-dotted pb-4 mb-8' />
    <portal to='sidepanel-footer'>
      <div v-if='hasDrilldownInvestmentFiles'
        class='pl-4 lg:pl-8 py-4 bg-gray-100'>
        <button
          class='border w-48 h-12 text-white rounded-md font-bold'
          style='background-color: #223645;'
          @click='submit()'>
          {{ $t('saveChanges') }}
        </button>
      </div>
    </portal>
  </div>
</template>

<script>

import { mapActions, mapState, mapGetters } from 'vuex'
import CustomField from '@/components/custom_fields/CustomField.vue'

export default {
  name: 'InvestmentFiles',
  components: {
    CustomField,
  },
  watch: {
    drilldownInvestmentId: {
      handler: function (newId) {
        if (newId > 0) {
          this.getCustomFieldsForInvestmentByType('file')
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapState('investments', [
      'drilldownInvestmentCustomFields',
    ]),
    ...mapGetters('investments', [
      'drilldownInvestmentId',
      'drilldownInvestmentFiles',
      'hasDrilldownInvestmentFiles',
    ]),
  },
  methods: {
    ...mapActions('investments', [
      'updateAllCustomFieldValues',
      'getCustomFieldsForInvestmentByType'
    ]),
    submit () {
      this.updateAllCustomFieldValues(this.drilldownInvestmentId).then(() => {
      })
    },
  },
}
</script>
