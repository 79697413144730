<template>
  <div class='overflow-x-auto'>
    <table v-if='isViewMode'>
      <thead>
        <th v-for='col in field.table_headers' :key='`col-header-${col.name}`' class='border text-left px-2 py-2 text-sm whitespace-nowrap'>{{ col.name }}</th>
      </thead>
      <tbody>
        <tr v-for='(row, index) in field.value_json' :key='`row-${JSON.stringify(row)}-${index}`'>
          <td v-for='col in field.table_headers' :key='`read-cell-${index}-${col.name}`' class='border text-left px-2 py-2'>
            {{ cellValue(row[col.name], col.column_type) }}
          </td>
        </tr>
      </tbody>
    </table>
    <div v-else>
      <table class='table-auto'>
        <thead>
          <th v-for='col in field.table_headers'
            :key='`col-header-${col.name}`'
            class='border px-2 py-2 text-left text-sm whitespace-nowrap'>
            {{ col.name }}
          </th>
          <th class='border px-2 py-2'></th>
        </thead>
        <tbody>
          <tr v-for='(row, index) in field.value_json' :key='`row-${JSON.stringify(row)}-${index}`'>
            <td v-for='(col, colIndex) in field.table_headers'
              :key='`cell-${index}-${col.name}`'
              class='border text-left px-2 py-2'
              style='min-width: 120px;'>
              <component
                @focus='focus'
                @blur='blur'
                :is='inputType(col.column_type)'
                :row='row'
                :row-index='index'
                :col-index='colIndex'
                :field='field'
                @update-value-json='updateValueJson'
                :col-key='col.name' />
            </td>
            <td class='border text-center px-2 py-2'>
              <button
                @click='removeRow(index)'>
                <x-circle-icon class='inline-bock text-red-900 opacity-75' />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <button
        @click='addBlankRow'
        class='mt-2 mb-4 px-4 rounded text-sm border border-transparent hover:border-blue-300 hover:shadow'>
        <plus-circle-icon class='inline-block text-gray-700' style='margin-top: -4px' />
        {{ $t('add') }}
      </button>
    </div>
  </div>
</template>

<script>
import TableTypeCustomFieldText     from  '@/components/custom_fields/table_type/TableTypeCustomFieldText.vue'
import TableTypeCustomFieldUser     from  '@/components/custom_fields/table_type/TableTypeCustomFieldUser.vue'
import TableTypeCustomFieldDate     from  '@/components/custom_fields/table_type/TableTypeCustomFieldDate.vue'
import TableTypeCustomFieldNumber   from  '@/components/custom_fields/table_type/TableTypeCustomFieldNumber.vue'
import TableTypeCustomFieldContact  from  '@/components/custom_fields/table_type/TableTypeCustomFieldContact.vue'
import TableTypeCustomFieldUrl      from  '@/components/custom_fields/table_type/TableTypeCustomFieldUrl.vue'
import TableTypeCustomFieldDropdown from  '@/components/custom_fields/table_type/TableTypeCustomFieldDropdown.vue'
import { XCircleIcon, PlusCircleIcon } from '@vue-hero-icons/outline'
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'CustomFieldTable',
  props: [ 'field', 'isViewMode' ],
  components: {
    TableTypeCustomFieldText,
    TableTypeCustomFieldUser,
    TableTypeCustomFieldDate,
    TableTypeCustomFieldNumber,
    TableTypeCustomFieldContact,
    TableTypeCustomFieldUrl,
    TableTypeCustomFieldDropdown,
    PlusCircleIcon,
    XCircleIcon
  },
  data () {
    return {
      blankRow: this.field.table_headers.reduce((map, obj) => {
          map[obj.name] = null
          return map
        }, {}
      ),
    }
  },
  computed: {
    ...mapGetters('contacts', [
      'contactNameFromId'
    ]),
    ...mapState('users', [
      'users'
    ]),
  },
  methods: {
    addBlankRow () {
      this.$emit('update-value', {
        ...this.field, 
        value_json: [...this.field.value_json, (cloneDeep(this.blankRow))]
      })
    },
    cellValue (value, colType) {
      if (colType === 'user') {
        let found = this.users.find(user => user.id === value)
        return found ? found.name : value
      } else if (colType === 'contact') {
        return this.contactNameFromId(value)
      } else {
        return value
      }
    },
    inputType (columnType) {
      return `table-type-custom-field-${columnType}`
    },
    removeRow (index) {
      this.$emit('update-value', {
        ...this.field,
        value_json: this.field.value_json.filter((_, i) => i !== index)
      })
    },
    focus () {
      this.$emit('update-value', {...this.field, focus: true})
    },
    blur () {
      this.$emit('update-value', {...this.field, focus: false})
    },
    updateValueJson (rowIndex, colName, value) {
      let editedField = {...this.field}
      editedField.value_json[rowIndex][colName] = value
      this.$emit('update-value', editedField)
    }
  },
}
</script>
