import Vue               from 'vue'
import Vuex              from 'vuex'
// import router            from '@/router'
import TrackingLogsApi from '@/api/v1/tracking_logs'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)


const state = () => ({
})


const getters = {
  getField,
}


const actions = {
  postDashboardViewed (_, params) {
    return new Promise(resolve => {
      TrackingLogsApi.postDashboardViewed(params).then(resp => {
        resolve(resp)
      })
    })
  },
}


const mutations = {
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
