<template>
  <component :is='layout' id='app'>
    <router-view :layout.sync='layout' />
  </component>
</template>

<script>
import DefaultLayout from '@/layouts/DefaultLayout'

export default {
  name: `App`,
  components: {
    DefaultLayout
  },
  computed: {
    useDivLayout () {
      return [
        'Home', 
        'NewUser',
        'InvoicePdfCreator',
      ].includes(this.$route.name)
    },
    layout () {
      return (this.useDivLayout) ? 'div' : 'DefaultLayout'
    },
  },
  mounted () {
    document.title = process.env.VUE_APP_TITLE
  }
};
</script>

<style lang="scss">

html {
  min-height:100%;
}

body {
  background: linear-gradient(0deg, rgba(246,249,252,1) 30%, rgba(255,255,255,1) 90%) no-repeat;
}

@media print {
  body {
    background: #FFF;
  }
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.linked-text-container {
  a {
    @apply underline;
  }
  a:hover {
    @apply bg-gray-100;
  }
}

:checked + span {
  @apply text-blue-900 font-normal;
}

@font-face {
  font-family: "nanum-myeongjo";
  src: url("../public/NanumMyeongjo-Regular.ttf") format('truetype');
}

@font-face {
  font-family: "HANBatang";
  src: url("../public/HANBatang.ttf") format('truetype');
}

@font-face {
  font-family: "HANDotum";
  src: url("../public/HANDotum.ttf") format('truetype');
}

.nanum-myeongjo {
  font-family:  "nanum-myeongjo", serif;
}

.han-batang {
  font-family: HANBatang, serif;
}
.han-dotum {
  font-family: HANDotum;
}

button,
li,
a,
.standard-transition {
  transition: all 0.15s ease-in-out;
}

</style>
