<template>
  <div>
    <custom-field-template-create-rule @add-rule='addRule' />
    <custom-field-template-existing-rule
      v-for='rule in rules'
      :key='`rule-${rule.custom_field_template_id}-${rule.operator}-${rule.custom_field_value}`'
      :rule='rule'
      @remove-rule='removeRule' />
  </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex'
import CustomFieldTemplateCreateRule   from '@/components/custom_fields/CustomFieldTemplateCreateRule.vue'
import CustomFieldTemplateExistingRule from '@/components/custom_fields/CustomFieldTemplateExistingRule.vue'
export default {
  name: 'CustomFieldTemplateRules',
  components: {
    CustomFieldTemplateCreateRule,
    CustomFieldTemplateExistingRule,
  },
  computed: {
    ...mapState('customFields', [
      'editingCustomField',
    ]),
    rules () {
      return this.editingCustomField.rules.all
    },
  },
  methods: {
    ...mapMutations('customFields', [
      'addToCustomFieldRules',
      'removeFromCustomFieldRules',
    ]),
    addRule (addingRule) {
      let hasRule = this.rules !== undefined &&
                    this.rules.findIndex(rule => {
        return rule.custom_field_template_id === addingRule.selectedTemplateId &&
               rule.custom_field_type === addingRule.selectedFieldType &&
               rule.operator === addingRule.selectedOperator &&
               (rule.custom_field_value === addingRule.selectedValue || rule.custom_field_values === addingRule.selectedValues)
      }) >= 0
      if (hasRule) {
        return
      }
      let newRule = {
        custom_field_template_id:   addingRule.selectedTemplateId,
        custom_field_type:          addingRule.selectedFieldType,
        operator:                   addingRule.selectedOperator,
        custom_field_value:         addingRule.selectedValue,
        custom_field_values:        addingRule.selectedValues
      }
      this.addToCustomFieldRules(newRule)
    },
    removeRule (rule) {
      this.removeFromCustomFieldRules(rule)
    },
  },
}
</script>
