<template>
  <div class='pt-8'>
    <div class='flex flex-row justify-end'>
      <label class='bg-gray-900 py-2 pl-3 pr-4 text-gray-100 rounded-md text-sm cursor-pointer opacity-75 hover:opacity-100 flex flex-row items-center'>
        <plus-circle-icon />
        {{ $t('addFile') }}
        <input @change='fileSelect' type='file' class='hidden'>
      </label>
    </div>
    <div
      v-for='attachment in invoiceAttachments'
      :key='`invoice-${editingInvoice.id}-attachment-${attachment.id}`'
      class='flex flex-row-reverse justify-between items-stretch mt-4 invoice-attachment-row'>
      <button
        class='p-2 opacity-50 hover:opacity-100 hover:bg-red-100 hover:text-red-500'
        @click='deleteInvoiceAttachment(attachment.id)'>
        <x-circle-icon />
      </button>
      <a
        :href='attachment.file_url'
        @click.prevent='downloadFile(attachment)'
        download
        class='p-1 flex-grow hover:bg-gray-100 leading-10 filename-label flex flex-row items-center'>
        {{ attachment.file_name }}
        <span
          class='flex flex-row items-center gap-x-px px-2 text-xs text-gray-600 opacity-75'>
          <document-download-icon class='inline-block h-5' />
          {{ $t('download') }}
        </span>
      </a>
    </div>

    <div>

    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { DocumentDownloadIcon, XCircleIcon, PlusCircleIcon } from '@vue-hero-icons/outline'
import axios from 'axios'

export default {
  name: 'InvoiceAttachmentsDetails',
  components: {
    XCircleIcon,
    PlusCircleIcon,
    DocumentDownloadIcon,
  },
  data () {
    return {
      newAttachment: null
    }
  },
  watch: {
    'editingInvoice.id': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.getInvoiceAttachments(newVal)
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapState('invoices', [
      'editingInvoice',
      'invoiceAttachments',
    ]),
    storeToken () {
      return {'token': localStorage.getItem('access_token.bbridge_reach')}
    },
  },
  methods: {
    ...mapActions('invoices', [
      'getInvoiceAttachments',
      'deleteInvoiceAttachment',
      'createInvoiceAttachment',
    ]),
    downloadFile (attachment) {
      axios.get(attachment.file_url, { responseType: 'blob', headers: { Authorization: `Bearer ${localStorage.getItem('access_token.bbridge_reach')}`} })
        .then(response => {
          const blob = new Blob([response.data], { type: response.headers['content-type'] })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = attachment.file_name
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(() => alert(this.$t('noFileAlert')))
    },
    fileSelect (event) {
      const maxSize = 10485760
      let fileSize = event.target.files[0].size
      let fileSizeMB = (fileSize / 1024 / 1024).toFixed(2)

      if (fileSize > maxSize) {
        this.$message({
          type: 'error',
          message: this.$t('10mbFileUpload', [fileSizeMB])
        })
      } else {
        this.createInvoiceAttachment({file: event.target.files[0]}).then(() => {
          this.$message({
            type: 'success',
            message: this.$t('uploadSuccessDetails', [fileSizeMB])
          })
        }).catch(() => {
          this.$message({
            type: 'error',
            message: this.$t('uploadFailureDetails', [fileSizeMB])
          })
        })
      }
    },
  },
  mounted () {
  }
}
</script>

<style lang='scss' scoped>
  .invoice-attachment-row button:hover + .filename-label {
    @apply bg-red-100 line-through;
  }

  .filename-label span {
    display: none;
  }

  .filename-label:hover {
    @apply border-gray-300 rounded-md shadow-md;
  }


  .filename-label:hover span {
    display: block;
  }
</style>
