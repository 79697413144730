<template>
  <div class='pb-8'>
    <button
      class='uppercase text-sm text-gray-900 font-semibold py-2 w-full text-left' @click='toggleShowSectionFields' style='margin-left: -8px;'>
      <component :is='iconDirection' class='inline-block text-gray-600' style='margin-top: -2px;' />
      {{ $t('attachedDocumentsDetails', [investmentAttachments.length + drilldownUnsavedInvestmentAttachments.length]) }}
    </button>
    <div v-if='editingMode' class='flex flex-row justify-end'>
      <label class='bg-gray-900 py-2 pl-3 pr-4 text-gray-100 rounded-md text-sm cursor-pointer opacity-75 hover:opacity-100 flex flex-row items-center'>
        <plus-circle-icon />
        {{ $t('addFile') }}
        <input @change='fileSelect' type='file' class='hidden'>
      </label>
    </div>
    <div
      v-for='attachment in filteredData'
      :key='`investment-${investment.id}-attachment-${attachment.id}`'
      class='flex flex-row-reverse justify-between items-stretch mt-4 investment-attachment-row'>
      <button
        v-if='editingMode'
        class='p-2 opacity-50 hover:opacity-100 hover:bg-red-100 hover:text-red-500'
        @click='deleteInvestmentAttachment(attachment.id)'>
        <x-circle-icon />
      </button>
      <a
        :href='attachment.file_url'
        @click.prevent='downloadFile(attachment)'
        download
        class='p-1 flex-grow hover:bg-gray-100 leading-10 filename-label flex flex-row items-center'>
        {{ attachment.file_name }}
        <span
          class='flex flex-row items-center gap-x-px px-2 text-xs text-gray-600 opacity-75'>
          <document-download-icon class='inline-block h-5' />
          {{ $t('download') }}
        </span>
      </a>
    </div>

    <div
      v-for='attachment in filteredUnsavedData'
      :key='`investment-${investment.id}-attachment-${attachment.name}`'
      class='flex flex-row-reverse justify-between items-stretch mt-4 investment-attachment-row'>
      <button
        v-if='editingMode'
        class='p-2 opacity-50 hover:opacity-100 hover:bg-red-100 hover:text-red-500'
        @click='removeUnsavedInvestmentAttachment(attachment.name)'>
        <x-circle-icon />
      </button>
      <a
        class='p-1 flex-grow hover:bg-gray-100 leading-10 filename-label flex flex-row items-center'>
        {{ attachment.name }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { DocumentDownloadIcon, XCircleIcon, PlusCircleIcon } from '@vue-hero-icons/outline'
import { ChevronRightIcon, ChevronDownIcon }    from '@vue-hero-icons/solid'
import axios from 'axios'

export default {
  name: 'InvestmentMetadataFiles',
  components: {
    XCircleIcon,
    PlusCircleIcon,
    DocumentDownloadIcon,
    ChevronRightIcon,
    ChevronDownIcon,
  },
  props: [
    'editingMode',
    'investment',
  ],
  data () {
    return {
      newAttachment: null,
      showSectionFields: false,
    }
  },
  computed: {
    ...mapGetters('investments', [
      'isNewInvestment',
    ]),
    ...mapState('investments', [
      'investmentAttachments',
      'drilldownUnsavedInvestmentAttachments',
    ]),
    storeToken () {
      return {'token': localStorage.getItem('access_token.bbridge_reach')}
    },
    iconDirection () {
      return this.showSectionFields ? 'chevron-down-icon' : 'chevron-right-icon'
    },
    hasFiles () {
      return this.investmentAttachments && this.investmentAttachments.length > 0
    },
    filteredData () {
      return this.showSectionFields ? this.investmentAttachments : []
    },
    filteredUnsavedData () {
      return this.showSectionFields ? this.drilldownUnsavedInvestmentAttachments : []
    }
  },
  methods: {
    ...mapActions('investments', [
      'getInvestmentAttachments',
      'deleteInvestmentAttachment',
      'createInvestmentAttachment',
    ]),
    ...mapMutations('investments', [
      'addUnsavedInvestmentAttachment',
      'removeUnsavedInvestmentAttachment',
    ]),
    refreshData () {
      this.getInvestmentAttachments(this.investment.id)
    },
    downloadFile (attachment) {
      axios.get(attachment.file_url, { responseType: 'blob', headers: { Authorization: `Bearer ${localStorage.getItem('access_token.bbridge_reach')}`} })
        .then(response => {
          const blob = new Blob([response.data], { type: response.headers['content-type'] })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = attachment.file_name
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(() => alert(this.$t('noFileAlert')))
    },
    fileSelect (event) {
      const maxSize = 26214400
      let fileSize = event.target.files[0].size
      let fileSizeMB = (fileSize / 1024 / 1024).toFixed(2)

      if (fileSize > maxSize) {
        this.$message({
          type: 'error',
          message: this.$t('25mbFilesizeAlert', [fileSizeMB])
        })
      } else {
        if (this.isNewInvestment) {
          this.addUnsavedInvestmentAttachment(event.target.files[0])
        } else {
          this.createInvestmentAttachment({investment_id: this.investment.id, file: event.target.files[0]}).then(() => {
            this.$message({
              type: 'success',
              message: this.$t('uploadSuccessDetails', [fileSizeMB])
            })
          }).catch(() => {
            this.$message({
              type: 'error',
              message: this.$t('uploadFailureDetails', [fileSizeMB])
            })
          })
        }
      }
    },
    toggleShowSectionFields () {
      this.showSectionFields = !this.showSectionFields
    },
  },
  mounted () {
    this.refreshData()
  }
}
</script>

<style lang='scss' scoped>
  .invoice-attachment-row button:hover + .filename-label {
    @apply bg-red-100 line-through;
  }

  .filename-label span {
    display: none;
  }

  .filename-label:hover {
    @apply border-gray-300 rounded-md shadow-md;
  }


  .filename-label:hover span {
    display: block;
  }
</style>
