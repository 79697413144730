<template>
  <div>
    <investment-metadata-edit-buttons v-if='drilldownIsViewMode'
      :investment='investment'/>
    <div class='pb-24 text-xs'
      :class='readBGStyle'>
      <custom-field v-if='!drilldownIsViewMode'
        :custom-field='slotBasedFields("의뢰인", true)'
        :is-horizontal='horizontalInputs'
        :is-view-mode='drilldownIsViewMode'
        :class='fieldSpacing'>
        <entity-multy-search
          v-model='investment.entity_ids'
          class='inline-block flex-grow'
          :entity-names='entityNamesToArrary'
          :entity-ids='entityIdsToArrary'
          @update-selected-entities='updateSelectedEntities'
          @update-selected-multi-entites='updateSelectedMultiEntities' />
      </custom-field>
      <custom-field v-if='!drilldownIsViewMode'
        :custom-field='slotBasedFields("사건명")'
        :is-horizontal='horizontalInputs'
        :is-view-mode='drilldownIsViewMode'
        :class='fieldSpacing'>
        <input class='form-input block flex-grow' v-model='investment.investment_name'>
      </custom-field>
      <custom-field v-if='editingMode'
        :custom-field='slotBasedFields("상대방")'
        :is-horizontal='horizontalInputs'
        :is-view-mode='drilldownIsViewMode'
        :class='fieldSpacing'>
        <input class='form-input block flex-grow' v-model='investment.counterparty'>
      </custom-field>
      <header-divided-custom-fields v-for='(fields, index) in fieldsDividedByHeaders'
        :key='`headerSection-${index}`'
        :fields='fields'
        :is-horizontal='horizontalInputs' />
      <investment-metadata-files
        :editing-mode='editingMode'
        :investment='investment' />
    </div>
    <portal to='sidepanel-footer'>
      <investment-metadata-action-buttons
        v-if='!drilldownIsViewMode'
        :investment='investment'
        :is-valid-form='true'/>
    </portal>
  </div>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex'
import cloneDeep                            from 'lodash/cloneDeep'
import EntityMultySearch                    from '@/components/EntityMultySearch.vue'
import CustomField                     from '@/components/custom_fields/CustomField.vue'
import HeaderDividedCustomFields       from '@/views/dashboard/HeaderDividedCustomFields.vue'
import InvestmentMetadataActionButtons from '@/views/dashboard/InvestmentMetadataActionButtons.vue'
import InvestmentMetadataEditButtons   from '@/views/dashboard/InvestmentMetadataEditButtons.vue'
import InvestmentMetadataFiles         from '@/views/dashboard/InvestmentMetadataFiles.vue'

export default {
  name: 'InvestmentMetadata',
  components: {
    EntityMultySearch,
    CustomField,
    HeaderDividedCustomFields,
    InvestmentMetadataActionButtons,
    InvestmentMetadataEditButtons,
    InvestmentMetadataFiles,
  },
  data () {
    return {
      horizontalInputs: true,
      investment: {},
      showConfirmDelete: false,
    }
  },
  watch: {
    'drilldownInvestment.id': {
      handler: function () {
        this.investment = cloneDeep(this.drilldownInvestment)
        let lastDashboardViewId = localStorage.getItem('lastDashboardViewId')
        if (lastDashboardViewId) {
          this.getInvestmentCustomFields(lastDashboardViewId)
        } else {
          this.getInvestmentCustomFields()
        }
        this.getInvestmentCaseInfos()
      },
      immediate: true
    },
  },
  computed: {
    ...mapState('investments', [
      'drilldownInvestment',
      'drilldownIsViewMode'
    ]),
    ...mapGetters('investments', [
      'isNewInvestment',
      'requiredEmptyFields',
      'customFields',
      'currentDashboardViewId'
    ]),
    ...mapGetters('dashboardViews', [
      'dashboardFiltersExpressions',
    ]),
    ...mapState('users', [
      'users'
    ]),
    fieldSpacing () {
      return (this.drilldownIsViewMode) ? 'my-2' : 'my-4'
    },
    readBGStyle () {
      return (this.drilldownIsViewMode) ? 'bg-gray-100 p-4 rounded-md mt-4' : ''
    },
    headerSortIndices () {
      return this.customFields.filter(field => field.field_type === 'sections').map(field => field.sort_index)
    },
    isMobile () {
      return window.navigator.userAgent.includes('Android') ||
             window.navigator.userAgent.includes('iPhone')
    },
    fieldsDividedByHeaders () {
      let arr = []
      if (this.headerSortIndices.length > 0) {
        let prev = cloneDeep(this.customFields)
        let arr2 = []
        let subIndex = 0
        this.headerSortIndices.forEach(sortIndex => {
          arr2 = prev.filter(field => field.sort_index < sortIndex)
          subIndex = arr2.length
          arr.push(arr2)
          prev.splice(0, subIndex)
        })
        arr2 = prev.filter(field => field.sort_index >= this.headerSortIndices[this.headerSortIndices.length-1])
        arr.push(arr2)

      } else {
        arr.push(this.customFields)
      }
      return arr
    },
    editingMode () {
      return !this.drilldownIsViewMode
    },
    entityNamesToArrary () {
      if (this.investment.entity_names) {
        return this.investment.entity_names.length > 1 ? this.investment.entity_names.split(',') : [this.investment.entity_names]
      } else {
        return []
      }
    },
    entityIdsToArrary () {
      return this.investment.entity_ids && this.investment.entity_ids.length > 0 ? this.investment.entity_ids : []
    }
  },
  methods: {
    ...mapActions('investments', [
      'getInvestmentCustomFields',
      'getInvestmentCaseInfos',
    ]),
    ...mapActions('dashboardViews', [
      'getIndividualDashboardView',
    ]),
    slotBasedFields (fieldName, required) {
      return {
        custom_field_name: fieldName,
        field_type: 'slotBased',
        required: required,
        rules: [],
      }
    },
    updateInvestmentName () {
      // only update if there is none
      if (!this.investment.investment_name) {
        this.investment.investment_name = ''
      }
    },
    updateSelectedEntities (entityIds, entityNames) {
      this.investment.entity_ids = entityIds
      this.investment.entity_names = entityNames.join(',')
      this.investment.entity_id = entityIds[0]
      this.investment.entity_name = entityNames[0]
      this.updateInvestmentName()
    },
    updateSelectedMultiEntities (entity) {
      const newNames = this.investment.entity_ids && this.investment.entity_ids.length > 0 ? this.investment.entity_names.split(',') : []

      this.investment.entity_ids.push(entity.entityId)
      newNames.push(entity.entityName)
      this.investment.entity_names = newNames.join(',')
      this.investment.entity_id = entity.entityId
      this.investment.entity_name = entity.entityName
      this.updateInvestmentName()
    },
  },
  mounted () {
    this.horizontalInputs = !this.isMobile

    if (this.currentDashboardViewId === 0) {
       this.getIndividualDashboardView(localStorage.getItem('lastDashboardViewId'))
    }
  }
}
</script>
