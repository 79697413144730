import Vue          from 'vue'
import Vuex         from 'vuex'
import approvalsApi from '@/api/v1/approvals'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

const state = () => ({
  approvalResponses: [],
  approvalRequests: [],
  requestTypes: [
    {name: 'General', type: 'general', routerLink: ''},
    {name: '---------------------', type: 'line', routerLink: ''},
  ],
  statusTypes: [
    'request',
    'approved',
    'rejected',
  ]
})


const getters = {
  myApprovalResponsesCount: state => {
    return state.approvalResponses.length
  },
  myApprovalRequestsCount: state => {
    return state.approvalRequests.length
  },
  approvalResponsesByInvestment: state => investmentId => {
    return state.approvalResponses.filter(response => response.investment_id === investmentId)
  },
  approvalRequestsByInvestment: state => investmentId => {
    return state.approvalRequests.filter(request => request.investment_id === investmentId)
  },
  getField,
}


const actions = {
  getApprovalRequests ({ commit, dispatch }, filters={}) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      approvalsApi.getApprovalRequests(filters).then(resp => {
        commit('setApprovalRequests', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },  
  getNeedMyApprovalResponse ({ commit, dispatch }, filters={}) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      approvalsApi.getNeedMyApprovalResponse(filters).then(resp => {
        commit('setApprovalResponses', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  getNeedMyApprovalTotal ({ commit, dispatch }) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      approvalsApi.getNeedMyApprovalResponseForInvestment().then(resp => {
        commit('setApprovalResponses', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  updateApprovalStatus ({ commit, dispatch }, input) {
    dispatch('activateLoading', null, { root : true })
    input.approvalResponses.forEach(approvalResponse => {
      approvalsApi.updateApprovalResponse({id: approvalResponse.id, status: input.status.status, comment: input.status.comment }).then(resp => {
        commit('removeApprovalResponseFromList', resp.id)
      })
    })
    dispatch('deactiveLoading', null, { root : true })
  },
}


const mutations = {
  removeApprovalResponseFromList (state, approvalResponseId) {
    const index = state.approvalResponses.findIndex(approvalResponse => approvalResponse.id === approvalResponseId)
    if (index >= 0) {
      state.approvalResponses.splice(index, 1)
    }
  },
  setApprovalResponses (state, approvalResponses) {
    state.approvalResponses = approvalResponses
  },
  setApprovalRequests (state, approvalRequests) {
    state.approvalRequests = approvalRequests
  },
  updateField
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
