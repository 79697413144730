import { render, staticRenderFns } from "./LaborInfo.vue?vue&type=template&id=65170ac0"
import script from "./LaborInfo.vue?vue&type=script&lang=js"
export * from "./LaborInfo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports