<template>
  <div class='expense-entry-edit'>
    <div class='flex flex-row justify-start items-center mt-4'>
      <label class='block w-32 flex-shrink-0 text-sm uppercase text-gray-700'>{{ $t('expenseDate') }}</label>
      <el-date-picker
        v-model='updatingExpenseEntry.expense_date'
        class='w-40'
        value-format='yyyy-MM-dd'
        type='date'
        :placeholder="$t('pickDay')" />
    </div>
    <div class='flex flex-row justify-start items-center mt-4'>
      <label class='block w-32 flex-shrink-0 text-sm uppercase text-gray-700'>{{ $t('expenseAmount') }}</label>
      <vue-numeric
        v-model='updatingExpenseEntry.amount'
        output-type='number'
        class='form-input w-40 text-left'
        :minus='false'
        separator=','/>
    </div>
    <div class='flex flex-row justify-start items-center mt-4'>
      <label class='block w-32 flex-shrink-0 text-sm uppercase text-gray-700'>{{ $t('expenseType') }}</label>
      <select
        v-model='updatingExpenseEntry.expense_type'
        class='form-select w-40'>
        <option
          v-for='expenseType in expenseTypes'
          :key='`case-expense-option-type-${expenseType}`'
          :value='expenseType'>
          {{expenseType}}
        </option>
      </select>
    </div>
    <div class='flex flex-row justify-start items-center mt-4'>
      <label class='block w-32 flex-shrink-0 text-sm uppercase text-gray-700'>{{ $t('vatIncluded') }}</label>
      <input type='checkbox' v-model='updatingExpenseEntry.charge_vat' @change='updateExpenseTax'>
    </div>
    <div class='flex flex-row justify-start items-center mt-4'>
      <label class='block w-32 flex-shrink-0 text-sm uppercase text-gray-700'>{{ $t('billableYn') }}</label>
      <input type='checkbox' v-model='updatingExpenseEntry.is_billable'>
    </div>
    <div class='flex flex-row justify-start items-center mt-4'>
      <label class='block w-32 flex-shrink-0 text-sm uppercase text-gray-700'>{{ $t('paymentType') }}</label>
      <select
        v-model='updatingExpenseEntry.payment_type'
        class='form-select w-40'>
        <option
          v-for='paymentType in paymentTypes'
          :key='`case-expense-payment-type-${paymentType}`'
          :value='paymentType'>
          {{paymentType}}
        </option>
      </select>
    </div>
    <div class='flex flex-row justify-start items-center mt-4'>
      <label class='block w-32 flex-shrink-0 text-sm uppercase text-gray-700'>{{ $t('note') }}</label>
      <input
        class='form-input w-full'
        type='text'
        v-model='updatingExpenseEntry.description' />
    </div>
    <div class='flex flex-row justify-start items-center mt-4'>
      <label class='block w-32 flex-shrink-0 text-sm uppercase text-gray-700'>{{ $t('link') }}</label>
      <input
        class='form-input w-full'
        type='text'
        v-model='updatingExpenseEntry.external_url' />
    </div>
    <div class='flex flex-row justify-start items-center mt-4'>
      <label class='block w-32 flex-shrink-0 text-sm uppercase text-gray-700'>{{ $t('attachedFile') }}</label>
      <expense-attachment v-for='expenseAttachment in updatingExpenseEntry.expense_attachments'
        :key='`editing-${updatingExpenseEntry.id}-attachment-${expenseAttachment.id}`'
        :expense-attachment='expenseAttachment'
        @update-attachment='updateExpenseAttachment'
        class='mb-2' />
      <expense-attachment
        :expense-attachment='newAttachment'
        @update-attachment='updateExpenseAttachment' />
    </div>
    <div class='flex flex-row justify-start items-center mt-4'>
      <label class='block w-32 flex-shrink-0 text-sm uppercase text-gray-700'></label>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ExpenseAttachment from '@/components/expenses/ExpenseAttachment.vue'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'ExpenseEntryInputForm',
  components: {
    ExpenseAttachment,
  },
  data () {
    return {
      updatingExpenseEntry : {},
    }
  },
  props: [
    'expenseEntry',
    'newAttachment',
  ],
  watch: {
    updatingExpenseEntry: {
      handler: function () {
        this.$emit('update-expense-entry', this.updatingExpenseEntry)
      },
      deep: true,
    },
  },
  computed: {
    ...mapState('expenses', [
      'expenseTypes',
      'paymentTypes',
    ]),
  },
  methods: {
    ...mapActions('expenses', [
      'updateAttachment',
    ]),
    updateExpenseAttachment (expenseAttachment) {
      if (expenseAttachment.id) {
        this.updateAttachment(expenseAttachment)
      } else {
        this.$emit('update:new-attachment', expenseAttachment)
      }
    },
    updateExpenseTax () {
      if (this.updatingExpenseEntry.charge_vat) {
        this.updatingExpenseEntry.tax_amount = Math.floor(parseFloat(this.updatingExpenseEntry.amount) / 11)
      } else {
        this.updatingExpenseEntry.tax_amount = 0
      }
    }
  },
  mounted () {
    this.updatingExpenseEntry = cloneDeep(this.expenseEntry)
  },
}
</script>
