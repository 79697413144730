<template>
  <div>
    <div class='mb-4 flex flex-row justify-start items-start'>
      <label class='inline-block w-32 flex-none leading-10'>{{ $t('policeBodyInCharge') }}</label>
      <v-select
       class='flex-grow'
       :value='caseInfo.jurisdiction_name'
       @input='updateCaseInfo("jurisdiction_name", $event)'
       :options='policeNameOptions'>
      </v-select>
    </div>
    <div class='mb-4 flex flex-row justify-start items-start'>
      <label class='inline-block w-32 flex-none leading-10'>{{ $t('policeCaseNo') }}</label>
      <input
        class='form-input flex-grow'
        :value='caseInfo.jurisdiction_case_number'
        @input='updateCaseInfo("jurisdiction_case_number", $event.target.value)'>
    </div>
    <related-infos
      :title="$t('policeInfo')"
      :headers='["담당수사관", "부서", "연락처"]'
      :case-info='caseInfo' />
    <div class='mb-4 flex flex-row justify-start items-start'>
      <label class='inline-block w-32 flex-none leading-10'>{{ $t('warrantBail') }}</label>
      <v-select
       class='flex-grow'
       :value='caseInfo.warrant_or_bail'
        @input='updateCaseInfo("warrant_or_bail", $event)'
       :options='["영장", "보석"]'>
      </v-select>
    </div>
    <jail-infos
      :title="$t('jailInfo')"
      :headers='["구치소", "수용번호"]'
      :case-info='caseInfo' />
    <div class='mb-4 flex flex-row justify-start items-start'>
      <label class='inline-block w-32 flex-none leading-10'>{{ $t('result') }}</label>
      <input class='form-input flex-grow'
        :value='caseInfo.decision_result'
        @input='updateCaseInfo("decision_result", $event.target.value)'>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import RelatedInfos from '@/components/case_infos/RelatedInfos.vue'
import JailInfos    from '@/components/case_infos/JailInfos.vue'

export default {
  name: 'PoliceInfo',
  props: [
    'caseInfo',
  ],
  components: {
    JailInfos,
    RelatedInfos,
  },
  computed: {
    ...mapGetters('caseJurisdictions', [
      'jurisdictionByCaseType'
    ]),
    policeNameOptions () {
      return this.jurisdictionByCaseType('경찰')
    }
  },
  methods: {
    updateCaseInfo(key, value) {
      this.$emit('update-case-info', { key, value })
    }
  },
}
</script>
