<template>
  <div class="rounded-md shadow-sm">
    <!-- TODO: cleanup hidden -->
    <span class="inline-block items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm hidden">
      URL
    </span>
    <input class='form-input inline-block' type='text' v-model='val' v-on:keyup.enter='updateField' @focus='$emit("focus")' @blur='updateField'>
  </div>
</template>

<script>

export default {
  name: 'TableTypeCustomFieldUrl',
  props: ['row', 'rowIndex', 'colKey'],
  data () {
    return {
      val: this.row[this.colKey]
    }
  },
  methods: {
    updateField () {
      this.$emit('update-value-json', this.rowIndex, this.colKey, this.val)
      this.$emit('blur')
    }
  },
}
</script>
