<template>
  <span>
    <component :is='contactShareableIcon'
      class='h-4 inline-block'
      :style='verticalOffsetStyle'
      :class='shareStyle' />
    {{shareStatusString}}
  </span>
</template>

<script>
import { EyeOffIcon } from '@vue-hero-icons/outline'
import { EyeIcon }    from '@vue-hero-icons/solid'
export default {
  name: 'ContactShareStatus',
  components: {
    EyeIcon,
    EyeOffIcon,
  },
  props: ['shareable', 'verticalOffset'],
  computed: {
    contactShareableIcon () {
      return (this.shareable) ? 'eye-icon' : 'eye-off-icon'
    },
    shareStyle () {
      return (this.shareable) ? 'text-green-500' : 'text-red-800'
    },
    shareStatusString () {
      return (this.shareable) ? 'shareable' : 'private'
    },
    verticalOffsetStyle () {
      return (this.verticalOffset) ? `margin-top: -${this.verticalOffset}px` : ''
    }
  }
}
</script>
