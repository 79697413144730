<template>
  <v-select
    v-model='selectedContactId'
    @input='updateField'
    label='text'
    :reduce='contact => contact.value'
    :options='contactOptions'
    :close-on-select='true'
    class='relative'
    style='width:12rem;'>
    <template v-slot:option='option'>
      <span>{{ option.text }}</span>
    </template>
  </v-select>
</template>
<script>
import { mapState } from 'vuex'

export default {
  name: 'TableTypeCustomFieldContact',
  props: ['row', 'rowIndex', 'colKey'],
  data () {
    return {
      selectedContactId: this.row[this.colKey]
    }
  },
  computed: {
    ...mapState('contacts', [
      'contacts',
    ]),
    contactOptions () {
      return this.contacts.map(contact => {
        return {
          text: contact.contact_name,
          value: contact.id
        }
      })
    }
  },
  methods: {
    updateField () {
      this.$emit('update-value-json', this.rowIndex, this.colKey, this.selectedContactId)
      this.$emit('blur')
    },
  },
}
</script>
