<template>
  <div v-if='isViewMode'
     class='whitespace-pre-line'>{{fieldValue}}</div>
  <textarea v-else
    v-model='fieldValue'
    @blur='handleUpdate'
    rows='3'
    class='form-textarea'></textarea>
</template>

<script>

export default {
  name: 'CustomFieldParagraph',
  props: ['field', 'isViewMode'],
  data() {
    return {
      fieldValue: this.field.value
    }
  },
  methods: {
    handleUpdate () {
      this.$emit('update-value', { ...this.field, value: this.fieldValue })
    }
  }
}
</script>
