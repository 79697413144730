import Vue               from 'vue'
import Vuex              from 'vuex'
import router            from '@/router'
import dashboardViewsApi from '@/api/v1/dashboard_views'
import cloneDeep         from 'lodash/cloneDeep'
import flatten           from 'lodash/flatten'
import dayjs             from 'dayjs'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

const newDashboardView = {
  id: 0,
  calendar_field_template_ids: [],
  dashboard_view_name: '',
  display_on_card_template_ids: [],
  filters: {
    logical_operator: 'and',
    expressions: []
  },
  show_list_view_id: false,
  show_list_view_name: true,
  show_case_infos: false,
  show_empty_display_on_values: false,
  show_group_by_value_unassigned: false,
  show_created_by_me: false,
  group_by_custom_template_id: 0,
  group_by_values: [],
  mapping_field_template_ids: [],
  schedule_types: [],
  shared: false,
  view_layout: 'Card',
}

function customFieldValueFromExpression (expression) {
  if (Number.isInteger(expression.custom_field_template_id) && expression.custom_field_template_id > 0) {
    return {custom_field_template_id: expression.custom_field_template_id, custom_field_value: expression.custom_field_value}
  } else {
    return null
  }
}

const state = () => ({
  archiveMode: 'active',
  currentDashboardView: cloneDeep(newDashboardView),
  dashboardViews: [],
  weeklyStartDate:  dayjs().startOf('week').add(1, 'day').format('YYYY-MM-DD'),
  weeklyEndDate:    dayjs().endOf('week').add(1, 'day').format('YYYY-MM-DD'),
  monthlyStartDate: dayjs().startOf('month').format('YYYY-MM-DD'),
  monthlyEndDate:   dayjs().endOf('month').format('YYYY-MM-DD'),
  selectedDate:     dayjs().format('YYYY-MM-DD'),
  dashboardSortByField: 0,
  dashboardSortByDirection: 1, // 1 for asc, -1 for dsc
})


const getters = {
  dashboardViewParams: (state) => {
    return {
      calendar_field_template_ids:    state.currentDashboardView.calendar_field_template_ids,
      dashboard_view_name:            state.currentDashboardView.dashboard_view_name,
      display_on_card_template_ids:   state.currentDashboardView.display_on_card_template_ids,
      filters:                        state.currentDashboardView.filters,
      show_list_view_id:              state.currentDashboardView.show_list_view_id,
      show_list_view_name:            state.currentDashboardView.show_list_view_name,
      show_case_infos:                state.currentDashboardView.show_case_infos,
      show_empty_display_on_values:   state.currentDashboardView.show_empty_display_on_values,
      show_group_by_value_unassigned: state.currentDashboardView.show_group_by_value_unassigned,
      show_created_by_me:             state.currentDashboardView.show_created_by_me,
      group_by_custom_template_id:    state.currentDashboardView.group_by_custom_template_id,
      group_by_values:                state.currentDashboardView.group_by_values,
      mapping_field_template_ids:     state.currentDashboardView.mapping_field_template_ids,
      schedule_types:                 state.currentDashboardView.schedule_types,
      shared:                         state.currentDashboardView.shared,
      view_layout:                    state.currentDashboardView.view_layout,
      weekly_configurations:          state.currentDashboardView.weekly_configurations,
    }
  },
  currentDashboardViewLayout: state => {
    return (state.currentDashboardView.view_layout) ? state.currentDashboardView.view_layout : 'Card'
  },
  filterCustomFieldValues: state => {
    return flatten(state.currentDashboardView.filters.expressions.map(expression1 => {
      if (expression1.expressions !== undefined) {
        return expression1.expressions.map(expression2 => customFieldValueFromExpression(expression2))
      } else {
        return customFieldValueFromExpression(expression1)
      }
    }))
  },
  isCurrentDashboardNew: state => {
    return state.currentDashboardView.id === null
  },
  sharedDashboardViews: state => {
    return state.dashboardViews.filter((d) => d.shared)
  },
  dashboardFiltersExpressions: state => {
    if (state.currentDashboardView.filters.expressions.length > 0) {
      return state.currentDashboardView.filters.expressions
    } else {
      return []
    }
  },
  filteredInvestmentsParams: state => {
    let params = {
      filters: state.currentDashboardView.filters
    }
    if (state.currentDashboardView.view_layout === 'Weekly') {
      params['from_date'] = state.weeklyStartDate
      params['to_date'] =   state.weeklyEndDate
    } else if (state.currentDashboardView.view_layout === 'Monthly') {
      params['from_date'] = state.monthlyStartDate
      params['to_date'] =   state.monthlyEndDate
    }
    if (state.archiveMode === 'archived') {
      params['archived'] = true
    }
    return params
  },
  calendarDateQueryParams: state => {
    let params = {}
    if (state.currentDashboardView.view_layout === 'Weekly') {
      params['from_date']     = state.weeklyStartDate
      params['to_date']       = state.weeklyEndDate
      params['selected_date'] = state.selectedDate
    } else if (state.currentDashboardView.view_layout === 'Monthly') {
      params['from_date']     = state.monthlyStartDate
      params['to_date']       = state.monthlyEndDate
    }
    return params
  },
  getField,
}


const actions = {
  getDashboardViews ({ commit, dispatch }) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      dashboardViewsApi.getDashboardViews().then(resp => {
        commit('setDashboardViews', resp)
        dispatch('setupInitialDashboard')
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  getIndividualDashboardView ({ commit, dispatch }, dashboardViewId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      dashboardViewsApi.getDashboardView(dashboardViewId).then(resp => {
        commit('setCurrentDashboardView', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  createNewDashboardView ({ state, commit, dispatch }) { // doesn't send to the API, just for the front end
    let dashboard = cloneDeep(state.currentDashboardView)
    dashboard.dashboard_view_name += '(copy)'
    dashboard.id                   = null
    dashboard.isEdited             = true
    dashboard.shared               = false
    commit('addToDashboardViews', dashboard)
    dispatch('selectDashboardView', dashboard)
  },
  createDashboardView ({ getters, commit, dispatch }) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      dashboardViewsApi.postDashboardView(getters.dashboardViewParams).then(resp => {
        commit('updateCurrentDashboardView', resp)
        commit('addToDashboardViews', resp)
        commit('removeFromDashboardViews', null)
        commit('setViewEdited', false)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  updateDashboardInvestments ({ dispatch, getters }) {
    dispatch('investments/getFilteredInvestments', getters.filteredInvestmentsParams, { root : true })
  },
  deleteDashboardView ({ commit, dispatch, state }) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      dashboardViewsApi.deleteDashboardView(state.currentDashboardView.id).then(resp => {
        commit('removeFromDashboardViews', resp.id)
        dispatch('selectDashboardView', state.dashboardViews[0])
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  selectDashboardView ({ getters, state, commit, dispatch }, dashboardView) {
    let previousDashboardId = state.currentDashboardView.id
    commit('updateDashboardViewInList', state.currentDashboardView)
    commit('setCurrentDashboardView', dashboardView)
    localStorage.setItem('lastDashboardViewId', dashboardView.id)
    if (previousDashboardId !== dashboardView.id) {
      dispatch('updateDashboardInvestments')
    }

    let queryParams = cloneDeep(router.history.current.query)
    if (parseInt(queryParams.dashboard_view_id) !== dashboardView.id) {
      queryParams['dashboard_view_id'] = dashboardView.id
      router.replace({ query: {...queryParams, ...getters.calendarDateQueryParams} })
    }
  },
  setupInitialDashboard ({ state, dispatch, commit }) {
    // first check to see if you have dashboards
    if (state.dashboardViews.length > 0) {
      let selectedView = null
      // see if there is a dashboard in the url
      let queryParams = router.history.current.query
      if (queryParams.dashboard_view_id) {
        selectedView = state.dashboardViews.find(view => view.id === parseInt(queryParams.dashboard_view_id))
      } else if (localStorage.getItem('lastDashboardViewId')) { // else look in localstorage
        selectedView = state.dashboardViews.find(view => view.id === parseInt(localStorage.getItem('lastDashboardViewId')))
      }
      // else pick the first one
      if (!selectedView) {
        selectedView = state.dashboardViews[0]
      }
      if (queryParams.from_date && queryParams.to_date) {
        if (selectedView.view_layout === 'Weekly') {
          commit('updateSelectedDate', queryParams.selected_date)
          commit('updateWeeklyDates', {fromDate: queryParams.from_date, toDate: queryParams.to_date})
        }
        if (selectedView.view_layout === 'Monthly') {
          commit('updateMonthlyDates', {fromDate: queryParams.from_date, toDate: queryParams.to_date})
        }
      }
      dispatch('selectDashboardView', selectedView)
    }
  },
  updateDashboardView ({ state, getters, commit, dispatch }) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      dashboardViewsApi.updateDashboardView(state.currentDashboardView.id, getters.dashboardViewParams).then(resp => {
        commit('setViewEdited', false)
        commit('updateDashboardViewInList', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  saveNewOrExistingDashboard ({ getters, dispatch }) {
    return new Promise(resolve => {
      if (getters.isCurrentDashboardNew) {
        dispatch('createDashboardView')
      } else {
        dispatch('updateDashboardView')
      }
    resolve()
    })
  },
  dashboardEditingOpen ({ state, dispatch }, dashboardView) {
    if (dashboardView.id !== state.currentDashboardView.id) {
      dispatch('selectDashboardView', dashboardView)
    }
    dispatch('sidepanelOpen', { componentName: 'dashboard-view-editing' }, { root : true })
  },
  listViewSortByField ({ state, commit }, fieldId) {
    if (fieldId === state.dashboardSortByField) {
      let newDirection =  state.dashboardSortByDirection * -1
      commit('setDashboardSortByDirection', newDirection)
    } else {
      commit('setDashboardSortByDirection', 1)
      commit('setDashboardSortByField', fieldId)
    }
  },
}


const mutations = {
  setDashboardSortByDirection (state, direction) {
    state.dashboardSortByDirection = direction
  },
  setDashboardSortByField (state, fieldId) {
    state.dashboardSortByField = fieldId
  },
  setLogicalOperator (state, operator) {
    state.dashboardLogicalOperator = operator
  },
  addToDashboardViews (state, dashboardView) {
    state.dashboardViews.push(dashboardView)
  },
  removeFromDashboardViews (state, dashboardViewId) {
    const index = state.dashboardViews.findIndex(dashboardView => dashboardView.id === dashboardViewId)
    if (index >= 0) {
      state.dashboardViews.splice(index, 1)
    }
  },
  setCurrentDashboardView (state, dashboardView) {
    state.currentDashboardView = dashboardView
    if (Object.keys(state.currentDashboardView.filters).length === 0) {
      state.currentDashboardView.filters = {
        logical_operator: 'and',
        expressions: []
      }
    }
  },
  setDashboardViews (state, dashboardViews) {
    state.dashboardViews = dashboardViews
  },
  setViewEdited (state, boolean) {
    state.currentDashboardView['isEdited'] = boolean
  },
  updateDashboardViewInList (state, dashboardView)  {
    const index = state.dashboardViews.findIndex(view => view.id === dashboardView.id)
    if (index >= 0) {
      state.dashboardViews.splice(index, 1, dashboardView)
    }
  },
  updateCurrentDashboardViewFilters (state, newFilters) {
    state.currentDashboardView.filters = newFilters
  },
  updateCurrentDashboardView (state, dashboardView) {
    state.currentDashboardView = dashboardView
    localStorage.setItem('lastDashboardViewId', dashboardView.id)
  },
  updateWeeklyDates (state, {fromDate, toDate}) {
    state.weeklyStartDate = fromDate
    state.weeklyEndDate = toDate
  },
  updateMonthlyDates (state, {fromDate, toDate}) {
    state.monthlyStartDate = fromDate
    state.monthlyEndDate = toDate
  },
  updateSelectedDate (state, date) {
    state.selectedDate = date
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
