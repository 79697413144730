import Vue          from 'vue'
import Vuex         from 'vuex'
import expensesApi  from '@/api/v1/expenses'
import InvoicesHelpers from '@/utils/invoices-helpers.js'
import cloneDeep    from 'lodash/cloneDeep'
import dayjs        from 'dayjs'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

const newExpense = {
  id: 0,
  investment_id: 0,
  case_name: '',
  user_id: 0,
  expense_date: dayjs().format('YYYY-MM-DD'),
  expense_type: '',
  amount: 0,
  tax_amount: 0,
  description: '',
  charge_vat: false,
  is_billable: false,
  payment_type: '',
  attachment: null,
  file_url: '',
  file_name: '',
  external_url: '',
}

const state = () => ({
  expenses: [],
  editingExpense: cloneDeep(newExpense),
  selectedUserFilterIds: [],
  filterStartDate: dayjs().format('YYYY-MM-DD'),
  filterEndDate: dayjs().format('YYYY-MM-DD'),
  expenseTypes: [
    '소송비용',
    '수임반환',
    '기타',
  ],
  paymentTypes: [
    '기지출',
    '지출요청',
    '지출완료',
    '',
  ],
  selectedExpenseIds: [],
  createNewInvoiceFromExpenses: false,
})


const getters = {
  hasSelectedExpenseIds: state => {
    return state.selectedExpenseIds.length > 0
  },
  firstSelectedExpenseDescription: state => {
    if (state.selectedExpenseIds.length > 0) {
      let exp = state.expenses.find(expense => expense.id === state.selectedExpenseIds[0])
      return exp ? exp.description : ''
    } else {
      return ''
    }
  },
  firstSelectedExpenseInvoiceType: (state) => {
    if (state.selectedExpenseIds.length > 0) {
      let exp = state.expenses.find(expense => expense.id === state.selectedExpenseIds[0])
      if (exp) {
        let invoiceTypes = InvoicesHelpers.selectableInvoiceTypes()
        if (invoiceTypes.includes('소송비용') && exp.expense_type === '소송비용') {
          return '소송비용'
        } else if (invoiceTypes.includes('기타비용') && exp.expense_type === '기타') {
          return '기타비용'
        } else {
          return ''
        }
      } else {
        return ''
      }
    } else {
      return ''
    }
  },
  getField,
}


const actions = {
  getExpenses ({ commit, dispatch }, filters) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      expensesApi.getExpenses(filters).then(resp => {
        commit('setExpenses', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  updateExpense ({ commit, dispatch }, expense) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      expensesApi.updateExpense(expense.id, expense).then(resp => {
        commit('updateExpense', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  updateExpenses ({ state, dispatch }) {
    return Promise.all(
      state.expenses.map(expense => dispatch('updateExpense', expense))
    )
  },
  batchUpdateInvoiceIds ({ state, commit, dispatch, rootGetters }, invoiceId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      expensesApi.batchUpdateExpenseInoviceIds(state.selectedExpenseIds, invoiceId).then(resp => {
        commit('updateExpenseInvoices', rootGetters['invoices/invoiceWithId'](invoiceId))
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  deleteExpense ({ commit, dispatch }, expenseId) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      expensesApi.deleteExpense(expenseId).then(resp => {
        commit('removeExpenseFromList', resp.id)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  createExpense ({ commit, dispatch }, expense) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      expensesApi.postExpense(expense).then(resp => {
        commit('addExpense', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    }).then((resp) => {
      let params = {investment_id: expense.investment_id}
      dispatch('magams/createChangePerformances', params, { root : true })
      return resp
    })
  },
  createAttachment ({commit, dispatch}, attachment) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      let data = new FormData()
      data.append('expense_attachment[expense_id]', attachment.expense_id)
      data.append('expense_attachment[investment_id]', attachment.investment_id)
      data.append('expense_attachment[attachment]', attachment.attachment)
      expensesApi.postExpenseAttachment(data).then(resp => {
        commit('addAttachment', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  updateAttachment ({commit, dispatch}, attachment) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      let data = new FormData()
      data.append('expense_attachment[expense_id]', attachment.expense_id)
      data.append('expense_attachment[investment_id]', attachment.investment_id)
      data.append('expense_attachment[attachment]', attachment.attachment)
      expensesApi.updateExpenseAttachment(attachment.id, data).then(resp => {
        commit('updateAttachment', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  deleteAttachment ({commit, dispatch}, attachment) {
    return new Promise(resolve => {
      dispatch('activateLoading', null, { root : true })
      expensesApi.deleteExpenseAttachment(attachment.id, attachment).then(resp => {
        commit('removeExpenseAttachment', resp)
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  updateSelectedUserFilterIds ({ commit }, userIds) {
    commit('setSelectedUserFilterIds', userIds)
  },
  updateFilterDateRange ({ commit }, dateRange) {
    commit('setFilterDateRange', dateRange)
  },
  resetSelectedExpenseIds ({ commit }) {
    commit('setSelectedExpenseIds', [])
    commit('setCreateNewInvoiceFromExpenses', false)
  },
  setSelectedExpenses ({ commit }) {
    commit('setExpenseAsSelected')
  },
}


const mutations = {
  setSelectedExpenseIds (state, expenseIdsArray) {
    state.selectedExpenseIds = expenseIdsArray
  },
  setExpenseAsSelected (state) {
    let expenses = state.expenses.filter(expense => state.selectedExpenseIds.includes(expense.id))
    expenses.forEach(expense => {
      expense.selectExpense = true
    })
  },
  addSelectedExpenseId (state, newExpenseId) {
    let index = state.selectedExpenseIds.findIndex(expenseId => expenseId === newExpenseId)
    if (index === -1) {
      state.selectedExpenseIds.push(newExpenseId)
    } else {
      // do nothing. Is aready in the list
    }
  },
  removeSelectedExpenseId (state, newExpenseId) {
    let index = state.selectedExpenseIds.findIndex(expenseId => expenseId === newExpenseId)
    if (index === -1) {
      // do nothing, is already not in the list
    } else {
      state.selectedExpenseIds.splice(index, 1)
    }
  },
  addExpense (state, expense) {
    state.expenses.unshift(expense)
  },
  addAttachment (state, attachment) {
    const expenseIndex = state.expenses.findIndex(expense => expense.id === attachment.expense_id)
    if (expenseIndex >= 0) {
      state.expenses[expenseIndex].expense_attachments.unshift(attachment)
    }
  },
  removeExpenseFromList (state, expenseId) {
    const index = state.expenses.findIndex(expense => expense.id === expenseId)
    if (index >= 0) {
      state.expenses.splice(index, 1)
    }
  },
  removeExpenseAttachment (state, attachment) {
    const expenseIndex = state.expenses.findIndex(expense => expense.id === attachment.expense_id)
    if (expenseIndex >= 0) {
      const attachmentIndex = state.expenses[expenseIndex].expense_attachments.findIndex(expAttachment => expAttachment.id === attachment.id)
      if (attachmentIndex >= 0) {
        state.expenses[expenseIndex].expense_attachments.splice(attachmentIndex, 1)
      }
    }
  },
  setCreateNewInvoiceFromExpenses (state, boolean) {
    state.createNewInvoiceFromExpenses = boolean
  },
  setFilterDateRange (state, dateRange) {
    state.filterStartDate = dateRange.startDate
    state.filterEndDate = dateRange.endDate
  },
  setSelectedUserFilterIds (state, userIds) {
    state.selectedUserFilterIds = userIds
  },
  setExpenses (state, fromApi) {
    state.expenses = fromApi
  },
  updateExpense (state, expense)  {
    const index = state.expenses.findIndex(c => c.id === expense.id)
    if (index >= 0) {
      state.expenses.splice(index, 1, expense)
    }
  },
  updateAttachment (state, attachment) {
    const expenseIndex = state.expenses.findIndex(expense => expense.id === attachment.expense_id)
    if (expenseIndex >= 0) {
      const attachmentIndex = state.expenses[expenseIndex].expense_attachments.findIndex(expAttachment => expAttachment.id === attachment.id)
      if (attachmentIndex >= 0) {
        state.expenses[expenseIndex].expense_attachments[attachmentIndex] = attachment
      }
    }
  },
  updateExpenseInvoices (state, invoice) {
    let expenses = state.expenses.filter(expense => state.selectedExpenseIds.includes(expense.id))
    expenses.forEach(expense => {
      expense.invoice_id     = invoice.id
      expense.invoice_status = invoice.status
      expense.invoice_title  = invoice.invoice_title
    })
  },
  updateField
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
