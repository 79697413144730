<template>
  <div
    class='bg-white w-full px-4 lg:px-8 lg:pb-8 overflow-y-auto z-10'>
    <div class='pt-4 lg:pt-8 pb-4 sticky top-0 bg-white flex flex-row justify-start items-center gap-x-4'>
      <h1 class='text-lg lg:text-2xl font-medium tracking-wide'>{{ resultsCountString }}</h1>
      <label class='p-2 text-left font-light cursor-pointer'>
        <input type='checkbox'
          class='form-checkbox mr-1'
          v-model='searchInvestmentsInProgress'>
          <span class='text-sm whitespace-no-wrap'>{{ $t('ongoingCase') }}</span>
      </label>
    </div>
    <table class='table-fixed text-sm'>
      <thead>
        <tr class='border-b'>
          <th
            class='pr-2 py-2 text-left'
            style='min-width: 12rem;'>ID</th>
          <th
            class='px-2 py-2 text-left'
            style='min-width: 12rem;'>{{ $t('lawyer') }}</th>
          <th
            class='px-2 py-2 text-left'
            style='min-width: 12rem;'>{{ $t('caseNo') }}</th>
          <th class='pr-2 py-2 text-left'
            style='min-width: 12rem;'>{{ $t('client') }}</th>
          <th
            class='px-2 py-2 text-left'
            style='min-width: 12rem;'>{{ $t('counterpart') }}</th>
          <th class='pl-2 py-2 text-left'
            style='min-width: 12rem;'>{{ $t('caseName') }}</th>
          <th class='pl-2 py-2 text-left'
            style='min-width: 12rem;'>{{ $t('unarchived') }}</th>
        </tr>
      </thead>
      <tbody>
        <search-result-investment v-for='investment in investmentSearchResults'
          :key='`investment-${investment.id}`'
          :investment='investment'
          class='border-b hover:bg-blue-100 rounded cursor-pointer'
          @click.native='investmentDrilldown(investment)' />
      </tbody>
    </table>
    <div class='flex pt-8 justify-center'>
      <button 
        v-if='showMoreButton' 
        @click='loadMore' 
        class='text-blue-700 text-sms py-2 px-4 rounded border'>
        {{ $t('loadMore') }}
      </button>
    </div>
    <!-- Add these back in later: Hana (2021/09/01) -->
    <!-- <contact-card v-for='contact in contactSearchResults'
          :contact='contact'
          :key='`contact-${contact.id}`'
          class='max-w-xl shadow-lg mt-10'
          @click.native='contactDrilldown(contact)'></contact-card> -->
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { mapFields } from 'vuex-map-fields'
import SearchResultInvestment from '@/components/search/SearchResultInvestment.vue'
// import ContactCard    from '@/components/contacts/ContactCard.vue'
export default {
  name: 'SearchResults',
  components: {
    // ContactCard,
    SearchResultInvestment,
  },
  watch: {
    'searchText': {
      handler: function (newText, oldText) {      
        if (newText !== oldText) {
          this.setSearchResultsPageIndex(1)
          this.resetShowMoreButton()
        }
      },
    },
  },
  computed: {
    resultsCount () {
      return this.investmentSearchResults.length + this.contactSearchResults.length
    },
    resultsCountString () {
      return this.showMoreButton ? this.$t('searchCaseDetail', [`(${this.resultsCount}+)`]) : this.$t('searchCaseDetail', [`(${this.resultsCount})`])
    },
    ...mapState([
      'showAdvancedSearch',
    ]),
    ...mapState('investments', {
      investmentSearchResults: 'searchResults',
      currentPage: 'searchResultsPage',
      showMoreButton: 'showMoreButton',
      searchText: 'searchText',
    }),
    ...mapState('contacts', {
      contactSearchResults: 'searchResults',
    }),
    ...mapFields('investments', [
      'searchInvestmentsInProgress',
    ]),
  },
  methods: {
    ...mapActions('investments', [
      'investmentDetailsOpen',
      'loadMoreSearchResults',
      'loadMoreSearchResultsAdvanced',
      'setSearchResultsPageIndex',
      'resetShowMoreButton',
    ]),
    ...mapActions('contacts', [
      'contactDrilldownOpen',
    ]),
    contactDrilldown (contact) {
      this.contactDrilldownOpen(contact)
    },
    investmentDrilldown (investment) {
      this.investmentDetailsOpen(investment)
    },
    loadMore () {
      if (this.showAdvancedSearch) {
        this.loadMoreSearchResultsAdvanced()
      } else {
        this.loadMoreSearchResults()
      }
    }
  },
}
</script>

<style lang='scss' scoped>
.search-results {
  top: 4rem; 
  height: calc(100% - 4rem);
}

@media (min-width: 800px) {
    .search-results {
      top: 6rem; 
      height: calc(100% - 6rem);
    }
  }
</style>
