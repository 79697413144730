<template>
  <div>
    <div class='sticky top-0 left-0 bg-white z-10'>
      <div class='pt-8 px-2 lg:px-8'>
        <h1 class='text-xl font-semibold uppercase'>
          {{drilldownEntityName}}
        </h1>
      </div>
      <div class='tabs-container border-b mt-4 pl-8 flex flex-row justify-start text-sm text-gray-600 overflow-x-auto'>
        <div class='tab' :class='selectedTab("data")'    @click='setActiveTab("data")'>{{ $t('data') }}</div>
        <div class='tab' :class='selectedTab("related")' @click='setActiveTab("related")'>{{ $t('numberOfCases', [cases.length]) }}</div>
      </div>
    </div>
    <component 
      :is='tabContentComponent'
      :display-in-modal='false'
      :cases='cases'
      @done-editing='doneEditing' 
      class='px-2 lg:px-8' />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import investmentsApi from '@/api/v1/investments.js'
import EntityCases    from '@/views/entities/EntityCases.vue'
import EntityDataEdit from '@/views/entities/EntityDataEdit.vue'

export default {
  name: 'EntityDrilldown',
  components: {
    EntityDataEdit,
    EntityCases,
  },
  data () {
    return {
      activeTab: 'data',
      cases: [],
    }
  },
  watch: {
    'drilldownEntityId': {
      handler: function (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.getEntity(newVal).then(resp => {
            this.setDrilldownEntity(resp)
          })
          this.getFilteredInvestments()
        }
        // need to also get single Entity here
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('entities', [
      'drilldownEntityName',
      'drilldownEntityId',
    ]),
    tabContentComponent () {
      switch (this.activeTab) {
        case 'data':
          return 'entity-data-edit'
        case 'related':
          return 'entity-cases'
        default:
          return 'div'
      }
    },
    getInvestmentParams () {
      return {
        entity_id: this.drilldownEntityId,
        dashboard_view_id: 0
      }
    },
  },
  methods: {
    ...mapActions([
      'sidepanelClose',
    ]),
    ...mapMutations('entities', [
      'setDrilldownEntity',
    ]),
    ...mapActions('entities', [
      'resetDrilldownEntity',
      'getEntity',
    ]),
    getFilteredInvestments () { 
      investmentsApi.getInvestments(this.getInvestmentParams).then(resp => { // we should move this call out to be part of the call for the entity details content
        this.cases = resp
      })
    },
    doneEditing () {
      this.sidepanelClose()
    },
    setActiveTab (tab) {
      this.activeTab = tab
    },
    selectedTab (tab) {
      return (this.activeTab === tab) ? 'selected' : ''
    },
  },
  beforeDestroy () {
    this.resetDrilldownEntity()
  }
}
</script>

<style lang='scss' scoped>
  .tab {
    @apply uppercase tracking-wide px-8 pb-2 pt-4 cursor-pointer;
  }

  .tab:hover {
    @apply bg-gray-100;
  }

  .tab.selected {
    @apply font-semibold;
    color: #0D4C76;
    border-bottom: solid 2px #0D4C76;
  }
</style>
