import Vue             from 'vue'
import Vuex            from 'vuex'
import customFieldsApi from '@/api/v1/custom_fields'
import cloneDeep       from 'lodash/cloneDeep'

Vue.use(Vuex)

const emptyCustomFieldTemplate = {
  custom_field_name: '',
  field_type: '',
  selectable_values: [],
  table_headers: [],
  required: false,
  recurring: false,
  rules: {},
  sort_index: -1,
  template_type: '',
  template_metadata: {},
  table_selectable_values: [],
}

const state = () => ({
  customFieldTemplates: [],
  customFieldValues: [],
  editingCustomField: cloneDeep(emptyCustomFieldTemplate),
  fieldTypes: [
    'checkbox',
    'contact',
    'date',
    'date-range',
    'dropdown',
    'file',
    'text',
    'paragraph',
    'multi-select',
    'number',
    'user',
    'url',
    'table',
    'header',
    'sections',
    'fund',
  ],
  recurrableTemplateTypes: [
    'file',
    'date',
    'contact',
    'user',
    'number',
    'text',
    'paragraph',
    'dropdown',
  ],
  requirableTemplateTypes: [
    'file',
    'date',
    'contact',
    'user',
    'number',
    'text',
    'paragraph',
    'dropdown',
  ],
  templateTypes: [
    'entity',
    'case',
    'contact'
  ],
})

const getters = {
  editingCustomFieldId: (state, getters) => {
    return (getters.isEditingExistingCustomFieldTemplate) ? state.editingCustomField.id : 0
  },
  customFieldNameFromId: state => customFieldTemplateId => {
    let found = state.customFieldTemplates.find(customField => customField.id === customFieldTemplateId)
    return (found) ? found.custom_field_name : ''
  },
  customFieldTemplateFromId: state => customFieldTemplateId => {
    return state.customFieldTemplates.find(customField => customField.id === customFieldTemplateId)
  },
  customFieldsByTypes: state => fieldTypes => {
    return state.customFieldTemplates.filter(field => fieldTypes.includes(field.field_type))
  },
  customFieldsForDisplay: state => {
    return state.customFieldTemplates.filter(field => field.template_type === 'case')

  },
  filterableCustomFields: state => {
    return state.customFieldTemplates.filter(template => {
        return [
          'checkbox',
          'contact',
          'date',
          'date-range',
          'dropdown',
          'text',
          'paragraph',
          'multi-select',
          'number',
          'sections',
          'header',
          'user',
          'url',
          ].includes(template.field_type)
      })
  },
  isEditingExistingCustomFieldTemplate: state => {
    return !!(state.editingCustomField.id)
  },
  isSelectableTemplate: state => {
    return state.editingCustomField.field_type === 'checkbox' ||
           state.editingCustomField.field_type === 'multi-select' ||
           state.editingCustomField.field_type === 'dropdown'
  },
  isTableTemplate: state => {
    return state.editingCustomField.field_type === 'table'
  },
}

const actions = {
  createCustomFieldTemplate ({ commit, dispatch, state }) {
    return new Promise((resolve) => {
      dispatch('activateLoading', null, { root : true })
      customFieldsApi.postCustomFieldTemplate(state.editingCustomField).then((resp) => {
        commit('addToCustomFieldTemplates', resp)
        commit('resetEditingCustomField')
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  customFieldTemplateDetailsOpen ({ commit, dispatch }, customFieldTemplate) {
    commit('setEditingCustomFieldTemplate', customFieldTemplate)
    dispatch('sidepanelOpen', { componentName: 'custom-field-template-details' }, { root : true })
  },
  deleteCustomFieldTemplate ({ commit, dispatch }, customFieldTemplateId) {
    return new Promise((resolve) => {
      dispatch('activateLoading', null, { root : true })
      customFieldsApi.deleteCustomFieldTemplate(customFieldTemplateId).then(() => {
        commit('removeFromCustomFieldTemplates', customFieldTemplateId)
        dispatch('deactiveLoading', null, { root : true })
        resolve()
      })
    })
  },
  editCustomFieldTemplate ({ commit, dispatch, state }) {
    return new Promise((resolve) => {
      dispatch('activateLoading', null, { root : true })
      customFieldsApi.updateCustomFieldTemplate(state.editingCustomField.id, state.editingCustomField).then((resp) => {
        commit('replaceInCustomFieldTemplates', resp)
        commit('resetEditingCustomField')
        dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
  getCustomFieldTemplates ({ commit, dispatch }) {
    dispatch('activateLoading', null, { root : true })
    customFieldsApi.getCustomFieldTemplates().then(resp => {
      commit('setCustomFieldTemplates', resp)
      dispatch('deactiveLoading', null, { root : true })
    })
  },
  newCustomField ({ dispatch }) {
    dispatch('customFieldTemplateDetailsOpen', cloneDeep(emptyCustomFieldTemplate))
  },
  updateCustomFieldOrdering (context, templates){
    if (templates.length > 0) {
      let data = new FormData()

      for (let i = 0; i < templates.length; i++) {
        data.append('custom_field_templates[][id]', templates[i].id)
        data.append('custom_field_templates[][sort_index]', i+1)
      }

      return new Promise((resolve) => {
        customFieldsApi.updateCustomFieldOrdering(data).then(() => {
          context.dispatch('getCustomFieldTemplates')
          resolve()
        })
      })
    }
  },
  getCustomFieldValuesOfType ({ commit, dispatch }, field_type) {
    dispatch('activateLoading', null, { root : true })
    customFieldsApi.getCustomFieldValuesOfType(field_type).then(resp => {
      commit('setCustomFieldValues', resp)
      dispatch('deactiveLoading', null, { root : true })
    })
  },
  createOrUpdateCustomFieldValue ({ dispatch }, field) {
    dispatch('activateLoading', null, { root : true })
    customFieldsApi.createOrUpdateCustomFieldValue(field).then(() => {
      dispatch('deactiveLoading', null, { root : true })
    })
  },
}

const mutations = {
  addToCustomFieldTemplates (state, fromApi) {
    state.customFieldTemplates.push(fromApi)
  },
  addToCustomFieldRules (state, rule) {
    if (state.editingCustomField.rules.all === undefined) {
      state.editingCustomField.rules = { all: [] }
    }
    state.editingCustomField.rules = { all: state.editingCustomField.rules.all.concat(rule) }
  },
  addToSelectableValues (state, additionalValue) {
    state.editingCustomField.selectable_values.push(additionalValue)
  },
  addToTableHeaders (state, additionalTableData) {
    state.editingCustomField.table_headers.push(additionalTableData.header)
    if (additionalTableData.header.column_type === 'dropdown') {
      state.editingCustomField.table_selectable_values.push(additionalTableData.values)
    }
  },
  removeFromSelectableValues (state, removingValue) {
    const index = state.editingCustomField.selectable_values.indexOf(removingValue)
    if (index >= 0) {
      state.editingCustomField.selectable_values.splice(index, 1)
    }
  },
  removeFromTableHeaders (state, removingHeaderName) {
    const headerIndex = state.editingCustomField.table_headers.findIndex(header => header.name === removingHeaderName)
    if (headerIndex >= 0) {
      state.editingCustomField.table_headers.splice(headerIndex, 1)
    }
    const valueIndex = state.editingCustomField.table_selectable_values.findIndex(value => value.name === removingHeaderName)
    if (valueIndex >= 0) {
      state.editingCustomField.table_selectable_values.splice(valueIndex, 1)
    }
  },
  removeFromCustomFieldTemplates (state, customFieldTemplateId) {
    const index = state.customFieldTemplates.findIndex(customFieldTemplate => customFieldTemplate.id === customFieldTemplateId)
    if (index >= 0) {
      state.customFieldTemplates.splice(index, 1)
    }
  },
  removeFromCustomFieldRules (state, rule) {
    if (state.editingCustomField.rules.all === undefined) {
      state.editingCustomField.rules = { all: [] }
    }
    state.editingCustomField.rules = { all: state.editingCustomField.rules.all.filter(rulei => rulei !== rule) }
  },
  replaceInCustomFieldTemplates (state, fromApi) {
    const index = state.customFieldTemplates.findIndex(customFieldTemplate => customFieldTemplate.id === fromApi.id)
    if (index >= 0) {
      state.customFieldTemplates.splice(index, 1, fromApi)
    }
  },
  resetEditingCustomField (state) {
    state.editingCustomField = cloneDeep(emptyCustomFieldTemplate)
  },
  setEditingCustomFieldTemplate (state, customFieldTemplate) {
    state.editingCustomField = { ...customFieldTemplate }
    if (customFieldTemplate.custom_field_name === ''){
      state.editingCustomField.selectable_values = []
    }
  },
  setCustomFieldTemplates (state, fromApi) {
    state.customFieldTemplates = fromApi
  },
  setCustomFieldValues (state, fromApi) {
    state.customFieldValues = fromApi
  },
  updateCustomFieldTemplate (state, fromApi) {
    state.CustomFieldTemplate = fromApi
  },
  updateCustomFieldName (state, customFieldName) {
    state.editingCustomField.custom_field_name = customFieldName
  },
  updateCustomFieldType (state, fieldType) {
    state.editingCustomField.field_type = fieldType
  },
  updateCustomFieldRequired (state, required) {
    state.editingCustomField.required = required
  },
  updateCustomFieldRecurring (state, recurring) {
    state.editingCustomField.recurring = recurring
  },
  updateCustomFieldRules (state, rules) {
    state.editingCustomField.rules = rules
  },
  updateCustomFieldTemplateType (state, templateType) {
    state.editingCustomField.template_type = templateType
  },
  updateCustomFieldSelectableValues (state, values) {
    state.editingCustomField.selectable_values = values
  },
  updateCustomFieldTemplateMetadata (state, value) {
    state.editingCustomField.template_metadata = {role: value}
  },
  updateCustomFieldTableHeaders (state, headers) {
    state.editingCustomField.table_headers = headers
  },
  updateCustomFieldTableSelectableValues (state, values) {
    state.editingCustomField.table_selectable_values = values
  },
  resetCustomFieldSelectableValues (state) {
    state.editingCustomField.selectable_values = []
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
