<template>
  <div class=''>
    <h2>{{ $t('newEntryFor', [timesheetEntry.user_name]) }}</h2>
    <timesheet-entry-input-form 
      :timesheet-entry.sync='timesheetEntry'/>
    <div class='mt-4 flex flex-row justify-start items-center gap-x-2'>
      <button class='px-4 py-2 bg-gray-900 border rounded-md border-transparent text-white opacity-75 hover:opacity-100 text-sm uppercase'
        @click='addTimesheetEntry(true)'
        :disabled='disableAddTimeSheetRow(timesheetEntry)'>
        <plus-circle-icon class='inline-block h-5' style='margin-top: -4px;' />
        {{ $t('create') }} {{ $t('andAddAnother') }}
      </button>
      <button class='px-4 py-2 bg-gray-100 border border-gray-500 rounded-md border-transparent text-gray-900 opacity-75 hover:opacity-100 text-sm uppercase'
        @click='addTimesheetEntry(false)'
        :disabled='disableAddTimeSheetRow(timesheetEntry)'>
        {{ $t('addAndClose') }}
      </button>
      <button class='px-4 py-2 bg-gray-100 border border-transparent hover:border-gray-400 rounded-md text-gray-900 opacity-75 hover:opacity-100 text-sm uppercase'
        @click='doneEditing'>
        {{ $t('close') }}
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { PlusCircleIcon } from '@vue-hero-icons/outline'
import TimesheetEntryInputForm from '@/components/timesheets/TimesheetEntryInputForm.vue'
import dayjs from 'dayjs'

export default {
  name: 'TimesheetEntryCreate',
  components: {
    PlusCircleIcon,
    TimesheetEntryInputForm,
  },
  props: [
    'defaultUserPosition',
  ],
  data () {
    return {
      timesheetEntry: {
        id: 0,
        investment_id: 0,
        user_id: 0,
        user_name: '',
        work_date: dayjs().format('YYYY-MM-DD'),
        work_hours: 0,
        work_type: 'General',
        detailed_work_type: '',
        billable_hours: 0,
        description: '',
        status: '',
        invoice_id: 0,
        rate: 0,
        user_position: this.defaultUserPosition,
      },
    }
  },
  computed: {
    ...mapGetters('users', [
      'currentUserId',
      'currentUserName',
      'currentUserEmail',
    ]),
    ...mapGetters('investments', [
      'drilldownInvestmentId'
    ]),
    ...mapState('investments', [
      'drilldownInvestmentCustomFields'
    ]),
    ...mapState('investments', [
      'investmentsSearch',
    ]),
    ...mapState('timesheetEntries', [
      'caseWorkTypes',
    ]),
  },
  methods: {
    ...mapActions('timesheetEntries', [
      'createTimesheetEntry',
      'getCaseTimesheetEntries',
    ]),
    isSelectedDetailedWorkTypeValid (timesheet) {
      const isGeneral = timesheet.work_type && timesheet.work_type.toLowerCase() === 'general'
      const hasDetailedWorkType = timesheet.detailed_work_type && timesheet.detailed_work_type.length > 0
      return !isGeneral || hasDetailedWorkType
    },
    disableAddTimeSheetRow (disableAddTimeSheetRow) {
        return  !disableAddTimeSheetRow.invoice_id || !this.isSelectedDetailedWorkTypeValid(disableAddTimeSheetRow)
    },
    doneEditing () {
      this.resetNewTimesheetEntry()
      this.$emit('done-editing')
    },
    resetNewTimesheetEntry () {
      this.timesheetEntry.work_date = dayjs().format('YYYY-MM-DD')
      this.timesheetEntry.invoice_id = 0
      this.timesheetEntry.work_hours = 0
      this.timesheetEntry.billable_hours = 0
      this.timesheetEntry.description = ''
      this.timesheetEntry.status = 'General'
      // this.timesheetEntry.rate = 0 // don't reset the rate, so we can continue to enter in values
    },
    appendTimesheetData () {
      this.timesheetEntry.investment_id = this.drilldownInvestmentId
      if (!this.timesheetEntry.user_id) {
        this.timesheetEntry.user_id = this.currentUserId
      }
      if (!this.timesheetEntry.user_name) {
        this.timesheetEntry.user_name = this.currentUserName
      }
      if (this.drilldownInvestmentCustomFields.length > 0) {
        let workTypeField = this.drilldownInvestmentCustomFields.find(field => field.custom_field_name === '업무구분')
        if (workTypeField && workTypeField.value && this.caseWorkTypes.includes(workTypeField.value)) {
          this.timesheetEntry.work_type = workTypeField.value
        }
      }
    },
    addTimesheetEntry (addAnotherBoolean) {
      this.$confirm(
        this.$t('addTimesheetConfirmMsgDetailed', 
        (this.timesheetEntry.user_name == this.currentUserName) ? [this.currentUserName, this.currentUserEmail] : [this.timesheetEntry.user_name, `ID: ${this.timesheetEntry.user_id}`]), 
        'Warning', {
          confirmButtonText: this.$t('confirmSave'),
          cancelButtonText: this.$t('cancel'),
          type: 'warning'
      }).then(() => {
        this.appendTimesheetData()
        this.createTimesheetEntry(this.timesheetEntry).then(() => {
          this.resetNewTimesheetEntry()
          if (addAnotherBoolean) {
            this.getCaseTimesheetEntries({
              investment_id: this.drilldownInvestmentId,
              start_date:    dayjs().subtract(4, 'week').format('YYYY-MM-DD'),
              end_date:      dayjs().format('YYYY-MM-DD'),
            })
          } else {
            this.doneEditing()
          }
          this.$message({
            type: 'success',
            message: this.$t('successfullySaved')
          })
        })
      }).catch(() => {
      })
    },
  },
  mounted () {
    this.appendTimesheetData()
  }
}
</script>
