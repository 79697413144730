<template>
  <div v-if='drilldownCanViewBilling'>
    <header class='flex flex-row justify-between items-center'>
      <div class='text-lg uppercase font-semibold flex flex-row justify-start gap-x-4 items-center'>
        <h2 class='text-lg uppercase font-semibold'>
          {{ $t('expenses') }}
        </h2>
        <button
          @click='copyToClipboard'
          class='text-xs uppercase pl-2 pr-3 hover:pr-4 py-2 rounded-md border border-transparent hover:border-gray-400 hover:shadow-md opacity-75 hover:opacity-100 flex flex-row items-center whitespace-no-wrap'>
          <table-icon class='inline-block h-4' />
          <span>{{ $t('copyExcel') }}</span>
        </button>
        <div v-if='hasSelectedExpenseIds'
          class='flex flex-row justify-start items-stretch'>
          <select
            v-model='invoiceToAttachExpenses'
            class='border-l border-t border-b p-2 max-w-lg rounded-l-md border-gray-400 text-sm'>
            <option v-for='invoice in invoices'
              :key='`invoice-to-attach-to-expenses-${invoice.id}`'
              :value='invoice.id'>{{invoice.invoice_title}} ({{invoice.invoice_type}})</option>
            <option :value='0'>{{ $t('newInvoice') }}</option>
          </select>
          <button
            @click='attachExpensesToInvoices'
            class='text-xs uppercase block pl-3 pr-3 py-3 rounded-r-md border border-gray-400 hover:border-gray-400 hover:shadow-md opacity-75 hover:opacity-100'>
            <span>{{ $t('attach') }}</span>
          </button>
        </div>
      </div>
      <button
        class='border py-1 lg:py-2 pl-2 pr-3 flex flex-row items-center gap-x-1 rounded-md border-transparent text-sm text-gray-700 opacity-75 hover:opacity-100 hover:border-blue-300 hover:text-gray-900 hover:shadow-md'
        @click='openAddExpenseEntry()'>
        <plus-circle-icon class='inline-block h-5' />
        {{ $t('add') }}
      </button>
    </header>
    <expense-entry-create v-if='showAddExpenseEntry'
      class='my-8 p-8 rounded bg-gray-100'
      @done-editing='closeAddExpenseEntry' />
    <div class='overflow-x-auto'>
      <table v-if='hasExpenses'
        class='mt-4 min-w-full text-sm'>
        <thead>
          <tr class='whitespace-no-wrap'>
            <th class='w-8 text-center'></th>
            <th class='px-2 py-2 text-left border-r'>{{ $t('expenseDate') }}</th>
            <th class='px-2 py-2 text-right border-r'>{{ $t('expenseAmount') }}</th>
            <th class='px-2 py-2 border-r'>{{ $t('type') }}</th>
            <th class='px-2 py-2 text-center border-r'>{{ $t('vat') }}</th>
            <th class='px-2 py-2 text-center border-r'>{{ $t('billableYn') }}</th>
            <th class='px-2 py-2 text-left'>{{ $t('reflectedBill') }}</th>
            <th class='px-2 py-2 text-left'>{{ $t('billingDepositStatus') }}</th>
            <th class='px-2 py-2 text-left'>{{ $t('paymentType') }}</th>
            <th class='px-2 py-2 text-left'>{{ $t('note') }}</th>
            <th class='px-2 py-2 text-left'>{{ $t('link') }}</th>
            <th class='px-2 py-2 text-left'>{{ $t('attachedFile') }}</th>
            <th class='px-2 py-2 text-left'>{{ $t('updatedBy') }}</th>
            <th class='px-2 py-2'></th>
          </tr>
        </thead>
        <case-expenses-row
          v-for='expense in expenses'
          :key='`case-expenses-${expense.id}`'
          class='border-b border-t'
          :expense='expense'
          @update-selected-expense='updateSelectedExpense' />
         <tfoot>
          <tr class='uppercase text-sm font-bold whitespace-no-wrap'>
            <td></td>
            <td class='px-2 py-2 border-r'>{{ $t('sum') }}</td>
            <td class='px-2 py-2 border-r text-right'>{{totalExpenses}}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tfoot>
      </table>
      <div v-else
        class='text-center p-8 bg-gray-100 text-sm mt-8'>
        {{ $t('noExpenseDetails') }}
      </div>
    </div>
  </div>
  <no-billing-access v-else />
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
import { TableIcon, PlusCircleIcon } from '@vue-hero-icons/outline'
import ExpenseEntryCreate  from '@/components/expenses/ExpenseEntryCreate.vue'
import CaseExpensesRow     from '@/components/expenses/CaseExpensesRow.vue'
import NoBillingAccess     from '@/views/dashboard/NoBillingAccess.vue'
import StringHelpers       from '@/utils/string-helpers'
import dayjs               from 'dayjs'

export default {
  name: 'CaseExpenses',
  components: {
    ExpenseEntryCreate,
    CaseExpensesRow,
    NoBillingAccess,
    TableIcon,
    PlusCircleIcon,
  },
  data () {
    return {
      showAddExpenseEntry: false,
      invoiceToAttachExpenses: 0,
    }
  },
  watch: {
    drilldownInvestmentId: {
      handler: function (newId) {
        if (newId > 0 && this.drilldownCanViewBilling) {
          this.resetSelectedExpenseIds()
          this.getExpenses({ investment_id: newId }).then(() => this.setSelectedExpenses())
        }
      },
      immediate: true
    },
    drilldownCanViewBilling: { // have to watch incase somsetimes
      handler: function (newVal) {
        if (newVal) {
          this.resetSelectedExpenseIds()
          this.getExpenses({ investment_id: this.drilldownInvestmentId }).then(() => this.setSelectedExpenses())
        }
      },
    },
    hasSelectedExpenseIds: {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.getInvoicesForCase()
        }
      }
    },
  },
  computed: {
    ...mapState('expenses', [
      'expenses',
      'selectedExpenseIds',
    ]),
    ...mapState('invoices', [
      'invoices',
    ]),
    ...mapGetters('expenses', [
      'hasSelectedExpenseIds',
    ]),
    ...mapState('investments', [
      'drilldownInvestment',
      'drilldownCanViewBilling',
    ]),
    ...mapGetters('investments', [
      'drilldownInvestmentId',
    ]),
    totalExpenses () {
      let sum = 0
      if (this.expenses.length > 0) {
        sum = this.expenses.map(expense => expense.amount).reduce((a,b) => (a+b))
      }
      return StringHelpers.koreanMoney(sum)
    },
    hasExpenses () {
      return this.expenses.length > 0
    },
  },
  methods: {
    ...mapActions('expenses', [
      'getExpenses',
      'batchUpdateInvoiceIds',
      'setSelectedExpenses',
      'resetSelectedExpenseIds',
    ]),
    ...mapActions('invoices', [
      'getInvoices',
    ]),
    ...mapMutations('expenses', [
      'addSelectedExpenseId',
      'removeSelectedExpenseId',
      'setCreateNewInvoiceFromExpenses',
    ]),
    getInvoicesForCase () {
      this.getInvoices({
        investment_id: this.drilldownInvestmentId,
        start_date:    dayjs('2010-01-01').format('YYYY-MM-DD'),
        end_date:      dayjs('2099-12-31').format('YYYY-MM-DD')
      })
    },
    closeAddExpenseEntry () {
      this.showAddExpenseEntry = false
    },
    openAddExpenseEntry () {
      this.showAddExpenseEntry = true
    },
    copyToClipboard () {
      let headers = [this.$t('expenseDate'), this.$t('lawyer'), this.$t('expenseAmount'), this.$t('type'), this.$t('billableYn'), this.$t('reflectedBill'), this.$t('billingDepositStatus'), this.$t('paymentType'), this.$t('note')]
      let result = `${headers.join('\t')}\n`
      this.expenses.forEach(expense => {
        let rawData = [expense.expense_date, expense.user_names, expense.amount, expense.expense_type,
          expense.is_billable, expense.invoice_title, expense.invoice_status, expense.payment_type,
          expense.description.replace(/\r?\n|\r/g, '')]
        result += `${rawData.join('\t')}\n`
      })
      this.$copyText(result).then(() => {
        this.$message({
          type: 'success',
          message: this.$t('copiedToClipboard'),
        })
      })
    },
    attachExpensesToInvoices () {
      if (this.invoiceToAttachExpenses) {
        this.batchUpdateInvoiceIds(this.invoiceToAttachExpenses)
      } else {
        this.createNewInvoiceWithSelectedExpenses()
      }
    },
    createNewInvoiceWithSelectedExpenses () {
      this.setCreateNewInvoiceFromExpenses(true)
      this.$emit('update-selected-tab', 'invoices')
    },
    updateSelectedExpense (paramObj) {
      if (paramObj.status) { // add
        this.addSelectedExpenseId(paramObj.expenseId)
      } else {
        this.removeSelectedExpenseId(paramObj.expenseId)
      }
    },
  },
}
</script>
