<template>
  <div>
    <div v-if='hasFile || hasPendingFile'>
      <a :href='decodedURL'
        @click.prevent='downloadFile'
        download
        target='_blank'
        class='inline-block bg-gray-100 py-1 px-1 hover:underline hover:bg-transparent h-12 leading-10'>{{ selectedFileName }}</a>
    </div>
    <div>
      <label class='inline-block bg-gray-100 py-1 px-4 uppercase text-blue-800 flex-grow h-12 leading-10 cursor-pointer hover:bg-gray-200'>
        {{ uploadText }}
        <input @change='fileSelect' type='file' ref='expenseAttachment' class='bg-pink-200 hidden'>
      </label>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ExpenseAttachment',
  props: ['expenseAttachment'],
  data () {
    return {
      fileInput: null,
    }
  },
  watch: {
    'expenseAttachment.changed': {
      handler: function (newVal) {
        // reset when changed to false
        if (!newVal) {
          this.fileInput = null
        }
      },
    },
  },
  computed: {
    uploadText () {
      if (this.hasFile) {
        return this.$t('changeFile')
      } else {
        return this.$t('registerFile')
      }
    },
    decodedURL () {
      return decodeURI(this.fileUrl) || ''
    },
    fileUrl () {
      return this.expenseAttachment.file_url
    },
    fileName () {
      return this.expenseAttachment.file_name
    },
    selectedFileName () {
      return this.fileInput ? this.fileInput.name : this.fileName
    },
    hasFile () {
      return this.fileUrl
    },
    hasPendingFile () {
      return this.fileInput
    },
  },
  methods: {
    fileSelect () {
      this.fileInput = this.$refs.expenseAttachment.files[0]
      this.$emit('update-attachment', {...this.expenseAttachment,
        file_url: URL.createObjectURL(this.fileInput),
        file_name: this.fileInput.name,
        attachment: this.fileInput,
        changed: true,
      })
    },
    downloadFile () {
      axios.get(this.fileUrl, { responseType: 'blob', headers: { Authorization: `Bearer ${localStorage.getItem('access_token.bbridge_reach')}`} })
        .then(response => {
          const blob = new Blob([response.data], { type: response.headers['content-type'] })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = this.fileName
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(() => alert(this.$t('noFileAlert')))
    },
  }
}
</script>
