<template>
  <div class='w-full overflow-x-auto'>
    <table
      v-if='hasInvestmentContacts'
      class='table-fixed text-sm'>
      <thead>
        <tr class='border-b'>
          <th class='pr-2 py-2 w-32 text-left whitespace-no-wrap border-r'>{{ $t('name') }}</th>
          <th class='px-2 py-2 w-32 text-left whitespace-no-wrap border-r'>{{ $t('company') }}</th>
          <th class='px-2 py-2 w-32 text-left whitespace-no-wrap border-r'>{{ $t('jobTitle') }}</th>
          <th class='px-2 py-2 w-32 text-left whitespace-no-wrap border-r'>{{ $t('phone') }}</th>
          <th class='px-2 py-2 w-32 text-left whitespace-no-wrap border-r'>{{ $t('cellPhone') }}</th>
          <th class='px-2 py-2 w-32 text-left whitespace-no-wrap border-r'>{{ $t('fax') }}</th>
          <th class='px-2 py-2 w-32 text-left whitespace-no-wrap border-r'>{{ $t('email') }}</th>
          <th class='px-2 py-2 w-24 text-center whitespace-no-wrap border-r'>{{ $t('clientYn') }}</th>
          <th class='px-2 py-2 text-left whitespace-no-wrap border-r'>{{ $t('role') }}</th>
          <th class='px-2 py-2 w-32 text-left whitespace-no-wrap'>{{ $t('message') }}</th>
        </tr>
      </thead>
      <tbody>
        <investment-contacts-row
          v-for='contact in contactsInList'
          :key='`contact-on-investment-${contact.id}`'
          :contact='contact'
          :edit-mode='false'
          @update-contact="updateContact(contact)"
          class='border-b' />
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mapMultiRowFields } from 'vuex-map-fields'
import InvestmentContactsRow from '@/views/dashboard/InvestmentContactsRow.vue'

export default {
  name: 'EntityDataView',
  components: {
    InvestmentContactsRow,
  },
  props: [
    'entity'
  ],
  watch: {
    'drilldownInvestmentId': {
      handler: function (newVal) {
        this.getContactsForInvestment(newVal)
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters('investments', [
      'drilldownInvestmentId',
    ]),
    ...mapMultiRowFields('investments', [
      'drilldownInvestmentContacts',
    ]),
    hasInvestmentContacts () {
      return this.drilldownInvestmentContacts.length > 0
    },
    contactsInList () {
      return this.drilldownInvestmentContacts.filter(contact => !contact.to_delete)
    },
  },
  methods: {
    ...mapActions('investments', [
      'getContactsForInvestment',
    ]),
    refreshData () {
      this.getContactsForInvestment(this.drilldownInvestmentId)
    },
    updateContact(updatedContact) {
      const index = this.drilldownInvestmentContacts.findIndex(contact => contact.id === updatedContact.id);

      if (index !== -1) {
        this.$set(this.drilldownInvestmentContacts, index, updatedContact)
      }
    },
  },
}
</script>
