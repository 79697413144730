<template>
    <div v-if='isViewMode'>
    </div>
</template>

<script>

export default {
  name: 'CustomFieldHeader',
  props: [ 'field', 'isViewMode' ]
}
</script>
