<template>
  <div>
    <div v-if='isViewMode'>
      {{ fundShortNames }}
    </div>
    <div v-else>
      <v-select
        v-model='selectedValues'
        label='short_name'
        :options='funds'
        :close-on-select='false'
        multiple
        @input='handleUpdate'
        class='w-64'>
        <template v-slot:option='option'>
          {{ option.short_name }}
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'CustomFieldFund',
  props: ['field', 'isViewMode'],
  data () {
    return {
      selectedValues: [],
    }
  },
  watch: {
    'field.value_json': {
      handler: function (newVal) {
        if (this.field && newVal) {
          let ids = newVal.map(value => value = parseInt(value))
          this.selectedValues = this.funds.filter(fund => ids.includes(fund.id))
        } else {
          this.selectedValues = []
        }
      },
      immediate: true
    }
  },
  computed: {
    ...mapState('funds', [
      'funds'
    ]),
    ...mapGetters('funds', [
      'fundShortNamesFromIds'
    ]),
    fundShortNames () {
      return this.fundShortNamesFromIds(this.selectedValuesIds)
    },
    selectedValuesIds () {
      return this.selectedValues.map(value => value.id)
    }
  },
  methods: {
    handleUpdate () {
      let updated = {}
      if (this.field) {
        updated = cloneDeep(this.field)
      }
      updated.value = this.selectedValuesIds.join(',')
      updated.value_json = this.selectedValuesIds
      this.$emit('update-value', updated)
    },
  },
}
</script>
