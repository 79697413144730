<template>
  <div class='h-full w-full'>
    <app-header class='app-header' />
    <search-results v-show='isSearching' />
    <div v-show='!isSearching'><slot></slot></div>
    <nav-menu class='nav-menu' />
    <sidepanel><portal-target name='sidepanel'></portal-target></sidepanel>
    <modal><portal-target name='modals'></portal-target></modal>
    <overlay @click.native='closeContent' />
    <loading-indicator v-if='showMainPageLoadingIndicator' />
  </div>
</template>

<script>
import AppHeader        from '@/components/AppHeader.vue'
import LoadingIndicator from '@/components/LoadingIndicator.vue'
import Modal            from '@/components/Modal.vue'
import NavMenu          from '@/components/NavMenu.vue'
import Overlay          from '@/components/Overlay.vue'
import SearchResults    from '@/components/SearchResults.vue'
import Sidepanel        from '@/components/Sidepanel.vue'
import EventBus         from '@/utils/event-bus'
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'

export default {
  name: 'DefaultLayout',
  components: {
    AppHeader,
    LoadingIndicator,
    Modal,
    NavMenu,
    Overlay,
    SearchResults,
    Sidepanel,
  },
  computed: {
    ...mapState([
      'isSearching',
      'showSidepanel',
    ]),
    ...mapGetters([
      'showMainPageLoadingIndicator',
    ]),
    isReadyWithToken () {
      return this.$auth.isAuthenticated && !this.$auth.loading
    },
  },
  watch: {
    isReadyWithToken: {
      handler: function (newVal) {
        if (newVal) {
          if (localStorage.getItem('roles') &&
              localStorage.getItem('userId') &&
              localStorage.getItem('userName') &&
              localStorage.getItem('email') &&
              localStorage.getItem('firmId')) { // if there is already in the store, then, don't have to hit the API again, and cna just pull data
            this.$gtag.set({
              user_id: localStorage.getItem('userId'),
              user_roles: localStorage.getItem('roles')
            })
            this.updateCurrentUserFromLocalStorage()
            this.getFirms()
          } else { // get info from the API if we don't have all the user info
            this.getCurrentUserInfo().then(() => {
              this.$gtag.set({
                user_id: localStorage.getItem('userId'),
                user_roles: localStorage.getItem('roles')
              })
              this.getFirms()
            })
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions([
      'modalClose',
      'navMenuClose',
      'sidepanelClose',
    ]),
    ...mapActions('firms', [
      'getFirms',
    ]),
    ...mapActions('users', [
      'getCurrentUserInfo',
    ]),
    ...mapMutations([
      'setShowPopover',
    ]),
    ...mapMutations('users', [
      'updateCurrentUserFromLocalStorage',
    ]),
    closeContent () {
      // refactor this so there is an order, where top stuff comes first.... modal - navmenu - sidepanel
      if (this.$store.state.showModal) {
        this.$store.dispatch('modalClose')
      } else if (this.$store.state.showNavMenu) {
        this.$store.dispatch('navMenuClose')
      } else if (this.$store.state.showPopover) {
        this.setShowPopover(false)
      } else {
        console.log('clicked overlay')
      }
    },
  },
  mounted () {
    document.onkeydown = (evt) => {
      evt = evt || window.event
      var isEscape = false
      if ("key" in evt) {
        isEscape = (evt.key === "Escape" || evt.key === "Esc")
      } else {
        isEscape = (evt.keyCode === 27)
      }
      if (isEscape) {
        if (this.$store.state.showModal) {
          this.$store.dispatch('modalClose')
        } else if (this.showSidepanel) {
          this.sidepanelClose()
        } else {
          EventBus.$emit('close-search')
        }
      }
    }
  }
}
</script>

<style lang='scss' scoped>

@media print {
  @page {
    size: auto;
    margin: 0;
  }

  .app-header,
  .nav-menu {
    display: none;
  }
}

</style>
