<template>
  <div :class='layoutCSS'>
    <custom-field-label v-if='!hideLabel'
      :custom-field='customField'
      :class='labelLayoutCSS' />
    <custom-field-value v-if='isSingleField'
      :field='customFieldFromValue(customField)'
      :class='valueLayoutCSS'
      :is-view-mode='isViewMode'/>
    <custom-field-value-recurring v-else-if='isRecurringField' 
      :class='valueLayoutCSS'
      :custom-field='customField'
      :is-view-mode='isViewMode' />
    <slot v-else-if='isSlotBasedField' />
  </div>
</template>

<script>
import cloneDeep                 from 'lodash/cloneDeep'
import CustomFieldLabel          from '@/components/custom_fields/CustomFieldLabel.vue'
import CustomFieldValue          from '@/components/custom_fields/CustomFieldValue.vue'
import CustomFieldValueRecurring from '@/components/custom_fields/CustomFieldValueRecurring.vue'


export default {
  name: 'CustomField',
  components: {
    CustomFieldLabel,
    CustomFieldValue,
    CustomFieldValueRecurring,
  },
  props: [
    'customField',
    'hideLabel',
    'isHorizontal',
    'isViewMode',
  ],
  computed: {
    isRecurringField () {
      return this.customField.recurring
    },
    isSingleField () {
      return !this.customField.recurring &&
              this.customField.field_type !== 'slotBased' &&
              this.customField.field_type !== 'header'
    },
    isSlotBasedField () {
      return this.customField.field_type === 'slotBased'
    },
    layoutCSS () {
      return (this.isHorizontal) && !this.hideLabel ? 'flex flex-row' : ''
    },
    labelLayoutCSS () {
      return (this.isHorizontal) ? 'w-56 leading-10' : ''
    },
    valueLayoutCSS () {
      return (this.isHorizontal) ? 'flex-grow leading-10' : 'w-full'
    },
  },
  methods: {
    // refactor this out: (Hana: 2021-08-26)
    customFieldFromValue (value) {
      let field = cloneDeep(value)
      return field
    },
  }
}
</script>
