import Vue              from 'vue'
import Vuex             from 'vuex'
import exclusiveCostApi from '@/api/v1/exclusive_costs'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

const state = () => ({
  exclusiveCosts: [],
})

const getters = {
  getField,
}

const actions = {
  uploadExclusiveCosts (context, fileData) {
    return new Promise(resolve => {
      context.dispatch('activateLoading', null, { root : true })
      exclusiveCostApi.uploadExclusiveCosts(fileData).then(resp => {
        context.dispatch('deactiveLoading', null, { root : true })
        resolve(resp)
      })
    })
  },
}

const mutations = {
  setexclusiveCosts (state, fromApi) {
    state.exclusiveCosts = fromApi
  },
  updateField,
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
